import * as d3 from 'd3'
import moment from 'moment'

export default {
  methods: {
    draw_SkylineCharts() {

      let self = this
      this.boxGroup = this.SkylineCharts
      .append('g')
      .attr('class', 'skyline_boxes')
      .attr('transform', `translate(${this.timeline.baseX}, 0)`)

      d3.selection.prototype.moveToFront = function() {
        return this.each(function(){
          this.parentNode.appendChild(this);
        });
      }

      this.timeline.week.forEach((w, j) => {

        let drawY = this.timeline.baseY - this.skyline.skyBox.height - (this.skyline.skyBox.gap/2)
        let currentSheet = this.sheet.prefix
        let dateFiltered = null

        // Not display boxes when the value of Total is 0
        if (this.sheet.zero == 'Y') dateFiltered = this.rawData.filter(f => f.cDate == w.eDate)
        else dateFiltered = this.rawData.filter(f => f.cDate == w.eDate)

        dateFiltered.forEach((d, i) => {
          // Box마다 유니크한 id(Index) 부여
          let index = 0
          this.rawData.forEach((r,k) => {
            if (r[this.skyline.refColumn] == d[this.skyline.refColumn]) index = k
          })
          
          let x1 = this.timeline.scale(d.sDate) + this.skyline.skyBox.gap
          let y1 = drawY
          let width_ = this.timeline.scale(d.cDate) - this.timeline.scale(d.sDate) - this.skyline.skyBox.gap

          let Boxes = this.boxGroup
          .append('g')
          .attr('id', `BOX_${index}`) // d[this.skyline.refColumn] --> index
          .attr('transform', `translate(${x1},${y1})`)
          .style('cursor', 'pointer')
          .on('mouseover', function() {
            let factor = (self.skyline.scale - 1) / 2
            let scale_x = width_ * factor
            let scale_y = self.skyline.skyBox.height * factor
            
            d3.select(this).moveToFront().transition().duration(150)
            .attr('transform', `translate(${x1-scale_x},${y1-scale_y}) scale(${self.skyline.scale})`)

            d3.select('#Detail_Group').transition().duration(300)
            .style('opacity', 1)

            self.mouseOver_Detail(d[self.skyline.refColumn]) // ---> Detailed Information
          })
          .on('mouseout', function(){
            d3.select(this).transition().duration(150)
            .attr('transform', `translate(${x1},${y1}) scale(1)`)

            d3.select('#Detail_Group').transition().delay(500).duration(300)
            .style('opacity', self.detail.onOff == 'ON' ? 1 : 0)
          })
          .on('click', () => { this.link_Skyline(d[this.skyline.refColumn]) })

          Boxes
          .append('rect') // Background
          .attr('x', 0).attr('y',0)
          .attr('rx', d.radius)
          .attr('ry', d.radius)
          .attr('width', width_)
          .attr('height', this.skyline.skyBox.height)
          .attr('fill', '#fff')
          .attr('fill-opacity', d.opacity)
          .attr('stroke', d.sColor)
          .attr('stroke-width', d.sWidth)

          Boxes
          .append('rect') // Fill Color
          .attr('x', 0).attr('y',0)
          .attr('rx', d.radius)
          .attr('ry', d.radius)
          .attr('width', d.type == 'PRO' || d.type == 'REMAIN' ? width_ * (d[`${currentSheet}_PRO`]/100) : width_)
          .attr('height', this.skyline.skyBox.height)
          .attr('fill', d.bColor)
          .attr('fill-opacity', d.opacity)

          drawY = drawY - this.skyline.skyBox.height - (this.skyline.skyBox.gap)

          if(i == dateFiltered.length-1) {
            this.boxGroup
            .append('text')
            .attr('class', 'block_group_count')
            .attr('transform', `translate(${x1 + (width_/2)}, ${drawY + this.skyline.skyBox.height - 5})`)
            .style('font-family', 'roboto')
            .style('font-size', 9)
            .style('fill', '#44A9DF')
            .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
            .attr('visibility', 'visible')
            .text(dateFiltered.length)
            .style('cursor', 'pointer')
            .on('mouseover', function() {
              d3.select(this).transition().duration(300).style('opacity', .5).style('fill', 'red')
            })
            .on('mouseout', function(){
              d3.select(this).transition().duration(300).style('opacity', 1).style('fill', '#44A9DF')
            })
            .on('click', () => { 
              // console.log(
              //   'Week start Date:', moment(w.eDate).format('YYYY-MM-DD'),
              //   'Week end Date:', moment(w.fDate).format('YYYY-MM-DD')
              // )
              this.link_Subsystem({sDate: moment(w.sDate).format('YYYY-MM-DD'), eDate: moment(w.eDate).format('YYYY-MM-DD')})
            })
          }
        })
      })
    },

    

    
  }
}
