import gmx_zMixins         from '../../../../../../includes/Jmixins/zMixins'

// Locally declared common Data-Props & SVG Drawing Support

import lmx_watching             from './watching'

// Charts 
import lmx_LocalValues          from './set_LocalValues'
import lmx_draw_Header          from './draw_Header'
import lmx_draw_Timeline        from './draw_Timeline'
import lmx_draw_Gantt           from './draw_Gantt'
import lmx_draw_Summary_Item    from './draw_Summary_Item'

import lmx_event_Expansion      from './event_Expansion'
import lmx_event_Binding        from './event_Binding'

import lmx_events               from './Events'
import lmx_set_PDF              from './set_PDF'


export default {
  
  mixins: [
    
    gmx_zMixins, 
    
    // Local Mixins
    lmx_watching,
    lmx_LocalValues,
    lmx_events,
    
    lmx_draw_Header,
    lmx_draw_Timeline,
    lmx_draw_Gantt,
    lmx_draw_Summary_Item,
    
    lmx_event_Expansion,
    lmx_event_Binding,
    lmx_set_PDF,
  ],

}
