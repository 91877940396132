import icon from '../../../../../../includes/primitives/icons'
export default {
  
  methods: {

    draw_Controller() {

      this.stg_Slide = this.stg_Canvas
      .append('g')
      .attr('id', `SLIDE_${this.localId}`)
      .attr('transform', `translate(10, 40)`)
      .style('font-family', 'roboto')

      let x_ = 5 - ((this.tables.length + this.tables.gap)*this.unit.tblNo) * (this.unit.count-1)

      this.rawData.forEach((d,i)=> {

        let slideItem = this.stg_Slide
        .append('g')
        .attr('id', `SLIDE_${i}_${this.localId}`)
        .attr('transform', `translate(${x_}, 0)`)

        slideItem
        .append('line')
        .attr('x1', this.tables.length + (this.tables.gap/2)).attr('y1', 0)
        .attr('x2', this.tables.length + (this.tables.gap/2)).attr('y2', 180)
        .attr('stroke', '#bcbcbc') .attr('stroke-width', .2)

        this.draw_Title(slideItem, d, x_)
        this.draw_SSMCC(slideItem, d, i, x_, 40)

        this.draw_Sheet(slideItem,
          {
            name: this.sheet.name[2],
            code: this.sheet.code[2],
            x: x_,
            y: 100,
            no: i,
            data: d,
            bColor: this.tables.bColor[1],
            opacity: this.tables.opacity[1],
          })
        this.draw_Sheet(slideItem,
          {
            name: this.sheet.name[3],
            code: this.sheet.code[3],
            x: x_,
            y: 140,
            no: i,
            data: d,
            bColor: this.tables.bColor[2],
            opacity: this.tables.opacity[2],
          })
          
        this.draw_Sheet(slideItem,
          {
            name: this.sheet.name[4],
            code: this.sheet.code[4],
            x: x_,
            y: 180,
            no: i,
            data: d,
            bColor: this.tables.bColor[3],
            opacity: this.tables.opacity[3],
          })
        this.draw_Sheet(slideItem,
          {
            name: this.sheet.name[5],
            code: this.sheet.code[5],
            x: x_,
            y: 220,
            no: i,
            data: d,
            bColor: this.tables.bColor[4],
            opacity: this.tables.opacity[4],
          })

        x_ += this.tables.length + this.tables.gap
      })
    },
  }
} 