const math = require('mathjs')
import * as d3 from 'd3'
export default {
  methods: {

    get_durationCurveData(sDate_, fDate_, maxQuantity_, limited_) {
      // console.log('Duration: ', sDate_ - fDate_) // ----------------> Console

      let x1 = 0
      let y1 = 0
      let x2 = sDate_ - fDate_
      let y2 = maxQuantity_
      let x3 = x1 + (x1 - x2)
      let y3 = y2

      const coefficients = this.findQuadraticCoefficients(x1, y1, x2, y2, x3, y3)
      let a = coefficients[0][0], b = coefficients[1][0], c = coefficients[2][0]

      let period = x2
      let curveData = []
      let j = 1
      for (let i = 1; i <= period; i++) {
        let result = a * j * j + b * j + c
        curveData.push({days: i, volume: Math.round(result * 100) / 100})
        j++
      }
      curveData.push({days: x2+1, volume: limited_})
      this.durationValues = curveData.map(m=> {return m.volume})
      return curveData
    },


    findQuadraticCoefficients(x1, y1, x2, y2, x3, y3) {
      const A = math.matrix([
          [Math.pow(x1, 2), x1, 1],
          [Math.pow(x2, 2), x2, 1],
          [Math.pow(x3, 2), x3, 1]
      ])
  
      const B = math.matrix([[y1], [y2], [y3]])
      const coefficients = math.multiply(math.inv(A), B)
      return coefficients._data
    },

    // 물량에 해당하는 기간의 일수를 구한다
    get_DurationDays(v) {
      if(isNaN(v)) return 0
 
      let valid_ = this.durationValues.find(val => val >= v)
      if(!valid_) return (this.schedule.sDate-this.schedule.fDate)*2
      let increment_ = 100

      while(!valid_) {
        valid_ = valid_.map(v => v * increment_).find(val => val > v)
        increment_ = increment_ * 100
      }
      return this.durationValues.findIndex(f=> f == valid_)+1
    },

  }
}