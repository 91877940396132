import * as d3 from 'd3'
import Defs from '../../../../../../includes/primitives/colorDefs'
import __C from '../../../../../../includes/primitives/_constant_crisp'
export default {
  data: () => ({
    RESOURCE: `${__C.HOST_NAME_RESOURCE}/resources`,

    innerSvg:       null,
    stg_Header:     null,
    stg_Summary:    null,

    rawData:        null,
    grassroots:     null,
    turnover:       {}, 
    sheets:         {}, 
    unitSummary:    {}, 

    zoom: {
      in:   {scale: null, x: null, y: null},
      out:  {scale: null, x: null, y: null},
    },
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    set_LocalValues(){
      
      if(this.getSelectItems) {
        this.pageOptions = JSON.parse(this.getSelectItems)
      }
      
      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      let sql1 = JSON.parse(JSON.stringify(this.Queries.SQL1[0]))
      let sql2 = JSON.parse(JSON.stringify(this.Queries.SQL2[0]))

      sql1.WD_TOT = sql1.SSMCC_TOT
      sql1.WD_ACT = sql1.SSMCC_WD
      sql1.WD_REM = sql1.SSMCC_TOT - sql1.SSMCC_WD
      sql1.WD_PRO = (sql1.SSMCC_WD / sql1.SSMCC_TOT) * 100

      sql1.MC_TOT = sql1.A_TOT + sql1.AP_TOT
      sql1.MC_ACT = sql1.A_ACT + sql1.AP_ACT
      sql1.MC_PRO = (sql1.MC_ACT / sql1.MC_TOT) * 100

      sql1.SC_TOT = sql1.B_TOT + sql1.BP_TOT
      sql1.SC_ACT = sql1.B_ACT + sql1.BP_ACT
      sql1.SC_PRO = (sql1.SC_ACT / sql1.SC_TOT) * 100

      this.grassroots = sql1


      // Overall Summary
      this.turnover = {
        title:            sql2['TO_TITLE']          .replace(/\s/g, '').split('/'),
        bgColor:          sql2['TO_COLOR']          .replace(/\s/g, '').split('/'),
        bColor:           sql2['TO_BAR_COLOR']      .replace(/\s/g, '').split('/'),
      }
      this.sheets = {
        mTitle:           sql2['SHEET_TITLE']       .replace(/\s/g, '').split('/'),
        sTitle:           sql2['SHEET_SUB_TITLE']   .replace(/\s/g, '').split('/'),
        bgColor:          sql2['SHEET_COLOR']       .replace(/\s/g, '').split('/'),
      }


      // Summary by Unit
      this.unitSummary = {
        tWidth:           sql2['TITLE_WIDTH'],
        lHeight:          sql2['LINEHEIGHT'],
        code:             sql2['CODE']          .replace(/\s/g, '').split('/'),
        name:             sql2['NAME']          .replace(/\s/g, ' ').split('/'),
        vColor:           sql2['VALUE_COLOR']   .replace(/\s/g, '').split('/'),
        bColor:           sql2['BAR_COLOR']     .replace(/\s/g, '').split('/'),
        height:           null,
      }
      this.unitSummary.height = this.unitSummary.lHeight * this.unitSummary.code.length + 20

    },



    setPriority() {
      if(this.stage_Header) this.stage_Header.moveToFront()
    },

    loadSvg(selection, data) {
      return d3.xml(`${this.RESOURCE}${data.url}`).then(source => {
        let svg_ = source.documentElement
        selection.node().append(svg_)

        svg_ = d3.select(svg_)
        .attr('x', data.x)
        .attr('y', data.y)

        return new Promise(resolve => {
          resolve(svg_)
        })
      })
    },

    //Calculate the scale value and coordinates when the image is displayed
    setZoomScale(width_, height_, in_, out_) {
      this.zoom.in.scale = in_
      this.zoom.in.x = (this.Canvas.CanvasWidth - (width_ * in_)) / 2
      this.zoom.in.y = (this.Canvas.CanvasHeight - (height_ * in_)) / 2

      this.zoom.out.scale = out_
      this.zoom.out.x = (this.Canvas.CanvasWidth - (width_ * out_)) / 2
      this.zoom.out.y = (this.Canvas.CanvasHeight - (height_ * out_)) / 2
    },

    set_Gradients() {
      let defs = this.svg.append('defs')

      Defs.setDefs_Gradients(this.svg, 'V', 'LinearA4', 'Pink', [.8, .5, .0, .5], this.localId)
      Defs.setDefs_Gradients(this.svg, 'V', 'LinearA4', 'LightBlue', [.8, .5, .0, .5], this.localId)
      Defs.setDefs_Gradients(this.svg, 'V', 'LinearA4', 'Yellow', [.8, .5, .0, .5], this.localId)
      Defs.setDefs_Gradients(this.svg, 'V', 'LinearA4', 'YellowGreen', [.8, .5, .0, .5], this.localId)

      let legendB = defs.append('linearGradient')
      .attr('id', `BTN`).attr('x1', 0).attr('x2', 1).attr('y1', 0).attr('y2', 1)
      legendB.append('stop').attr('stop-color', '#D2D8D9').attr('offset', '0').attr('stop-opacity', 1)
      legendB.append('stop').attr('stop-color', '#FFFFFF').attr('offset', '1').attr('stop-opacity', 1)

      let legendC = defs.append('linearGradient')
      .attr('id', `PHOTO`).attr('x1', 0).attr('x2', 1).attr('y1', 0).attr('y2', 1)
      legendC.append('stop').attr('stop-color', '#B4E3FA').attr('offset', '0').attr('stop-opacity', 1)
      legendC.append('stop').attr('stop-color', '#83D2F5').attr('offset', '1').attr('stop-opacity', 1)

      
    },
  }
}