import * as d3 from 'd3'

export default {

  methods: {

    draw_Legends() {
      this.legendGroup = this.svg
      .append('g')
      .attr('id', 'Legend')
      .attr('transform', `translate(${this.legends.x}, ${this.legends.y})`)

      this.legendGroup
      .append('text')
      .attr('transform', `translate(0, 35)`)
      .style('font-size', 12).style('fill', '#000').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(`Legends`)
      
      // this.legendGroup
      // .append('text')
      // .attr('transform', `translate(10, 53)`)
      // .style('font-size', 10.5).attr('font-weight', 400).style('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      // .text(`Base on`)
      
      this.legendGroup
      .append('text')
      .attr('transform', `translate(10, 53)`)
      // .attr('transform', `translate(10, 95)`)
      .style('font-size', 10.5).attr('font-weight', 400).style('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(`Subsystem Status`)
      
      // this.legendGroup
      // .append('text')
      // // .attr('transform', `translate(10, 137)`)
      // .attr('transform', `translate(200, 53)`)
      // .style('font-size', 10.5).attr('font-weight', 400).style('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      // .text(`Target month`)

      // this.legend_RefCol()
      // this.legend_TargetMonth()
      this.legend_Sheet() // ---> below
    },

    legend_RefCol() {
      let x_ = 0
      
      this.refGroup = this.legendGroup
      .append('g')
      .attr('id', `Ref_Group`)
      .attr('transform', `translate(18, 70)`)

      this.refCol.forEach((r, i) => {
        let legendItem = this.refGroup
        .append('g')
        .attr('id', `ref_${r.col}`)
        .attr('transform', `translate(${x_}, 0)`)
        .attr('font-family', 'roboto')
        .style('cursor', 'pointer')
        .on('mouseover', () => { 
          d3.select(`#ref_${r.col}`).attr('opacity', 0.5) 
        })
        .on('mouseout', () => { 
          d3.select(`#ref_${r.col}`).attr('opacity', this.legends.refStatus == r.col ? 1 : 0.8) 
        })
        .on('click', () => {
          d3.select(`#ref_${this.legends.refStatus}`).attr('opacity', 1)
          d3.select(`#ref_text_${this.legends.refStatus}`).attr('fill', '#000')

          this.legends.refStatus = r.col
          d3.select(`#ref_${r.col}`).style('opacity', 1)
          d3.select(`#ref_text_${r.col}`).attr('fill', '#EC407A')
          
          this.redraw_Skyline()
          this.set_selectedItems() // for export pdf
        })
        
        legendItem
        .append('rect')
        .attr('id', `ref_box_${r.col}`)
        .attr('transform', `translate(-5, -5)`)
        .attr('rx', 2).attr('ry', 2)
        .attr('width', 10).style('height', 10)
        .attr('stroke', '#bcbcbc')
        .attr('stroke-width', 1).attr('stroke-opacity', .5)
        .attr('fill', '#fff')

        legendItem
        .append('text')
        .attr('id', `ref_text_${r.col}`)
        .attr('transform', `translate(11, 1)`)
        .attr('font-size', 9)
        .attr('fill', r.col == this.legends.refStatus ? '#EC407A' : '#000')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(`${r.text}`)

        x_ += this.getNodeElValue(`#ref_${r.col}`, 'width') + 12
      })
    },

    legend_Sheet() {
      let x_ = 0

      this.sheetGroup = this.legendGroup
      .append('g')
      .attr('id', `Sheet_Group`)
      .attr('transform', `translate(18, 70)`)
      // .attr('transform', `translate(18, 112)`)

      this.sheet.styles.forEach((s, i) => {
        
        let legendItem = this.sheetGroup
        .append('g')
        .attr('id', `Sheet_${s.status}`)
        .attr('transform', `translate(${x_}, 0)`)
        .attr('font-family', 'roboto')
        .style('cursor', 'pointer')
        .on('mouseover', () => { 
          d3.select(`#Sheet_${s.status}`).attr('opacity', 0.5) 
        })
        .on('mouseout', () => { 
          d3.select(`#Sheet_${s.status}`).attr('opacity', this.legends.sheetStatus == s.status ? 1 : 0.8) 
        })
        .on('click', () => {
          d3.select(`#Sheet_${this.legends.sheetStatus}`).attr('opacity', 1)
          d3.select(`#Sheet_text_${this.legends.sheetStatus}`).attr('fill', '#000')

          this.legends.sheetStatus = s.status
          this.legends.layerStatus = s.cstd
          d3.select(`#Sheet_${s.status}`).style('opacity', 1)
          d3.select(`#Sheet_text_${s.status}`).attr('fill', '#EC407A')
          
          this.filtering_Search('ITR') // ---> Event.js
          this.filterStatus = 'ON'
        })
        
        legendItem
        .append('rect')
        .attr('id', `Sheet_box_${s.status}`)
        .attr('transform', `translate(-5, -5)`)
        .attr('rx', 2).attr('ry', 2)
        .attr('width', 10).style('height', 10)
        .attr('stroke', '#bcbcbc')
        .attr('stroke-width', 1).attr('stroke-opacity', .5)
        .attr('fill', s.color)

        legendItem
        .append('text')
        .attr('id', `Sheet_text_${s.status}`)
        .attr('transform', `translate(11, 1)`)
        .attr('font-size', 9)
        .attr('fill', s.status == this.legends.sheetStatus ? '#EC407A' : '#000')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(`${s.text} (${this.sheet.values[i]})`)

        x_ += this.getNodeElValue(`#Sheet_${s.status}`, 'width') + 12
      })
      
    },

    legend_TargetMonth() {
      let x_ = 0
      
      this.monthGroup = this.legendGroup
      .append('g')
      .attr('id', `Month_Group`)
      .attr('transform', `translate(208, 70)`)

      this.targetMonth.forEach((r, i) => {
        let legendItem = this.monthGroup
        .append('g')
        .attr('id', `target_${r.status}`)
        .attr('transform', `translate(${x_}, 0)`)
        .attr('font-family', 'roboto')
        .style('cursor', 'pointer')
        .on('mouseover', () => { 
          d3.select(`#target_${r.status}`).attr('opacity', 0.5) 
        })
        .on('mouseout', () => { 
          d3.select(`#target_${r.status}`).attr('opacity', this.legends.targetStatus == r ? 1 : 0.8) 
        })
        .on('click', () => {
          d3.select(`#target_${this.legends.targetStatus}`).attr('opacity', 1)
          d3.select(`#target_text_${this.legends.targetStatus}`).attr('fill', '#000')

          this.legends.targetStatus = r.status
          d3.select(`#target_${r.status}`).style('opacity', 1)
          d3.select(`#target_text_${r.status}`).attr('fill', '#EC407A')
          
          this.redraw_Skyline()
          this.set_selectedItems() // for export pdf
        })
        
        legendItem
        .append('rect')
        .attr('id', `target_box_${r.status}`)
        .attr('transform', `translate(-5, -5)`)
        .attr('rx', 2).attr('ry', 2)
        .attr('width', 10).style('height', 10)
        .attr('stroke', '#bcbcbc')
        .attr('stroke-width', 1).attr('stroke-opacity', .5)
        .attr('fill', '#fff')

        legendItem
        .append('text')
        .attr('id', `target_text_${r.status}`)
        .attr('transform', `translate(11, 1)`)
        .attr('font-size', 9)
        .attr('fill', r.status == this.legends.targetStatus ? '#EC407A' : '#000')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(`${r.text}`)

        x_ += this.getNodeElValue(`#target_${r.status}`, 'width') + 12
      })
    },



  }
}