import Defs from '../../../../../../includes/primitives/colorDefs'
import color from '../../../../../../includes/primitives/Color_Data'
import __C from '../../../../../../includes/primitives/_constant_crisp'
import * as d3 from 'd3'
import moment from 'moment'
export default {
  data: () => ({
    RESOURCE: `${__C.HOST_NAME_RESOURCE}/resources`,

    Area_Canvas:    null,
    Area_Charts:    null,

    Overall:        [],
    Trend:          [],
    selectedTrend:  'A',
    Weeks:          [],
    Radar:          {
      ES: [],
      GR: [],
    },

    // for pdf
    selectedItems:  null,
    getItems:       null,

    Radial:         [],
    pie: {x: 950, y: 1400, ra: 250, spin: -90},
    color21:        color.color21.bright,
    
    trendChart: {
    
      width:        330,
      height:       70,

      grid:         null,
    },

    turnover: null, // Check Turnover for Trendchart ('Y' / null)
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    set_LocalValues(){
      
      this.Overall = JSON.parse(JSON.stringify(this.DataItems))
      this.Trend = JSON.parse(JSON.stringify(this.Queries.SQL1))
      this.Weeks = JSON.parse(JSON.stringify(this.Queries.SQL2))
      let notSort = JSON.parse(JSON.stringify(this.Queries.SQL3))
      let sorted = JSON.parse(JSON.stringify(this.Queries.SQL4))

      // Scope Overall
      this.Overall.forEach(d=> {
        d.WD_TOT = d.SSMCC_TOT
        d.WD_ACT = d.SSMCC_WD
        d.WD_REM = d.SSMCC_TOT - d.SSMCC_WD
        d.WD_PRO = (d.SSMCC_WD / d.SSMCC_TOT) * 100
      })

      // Radar Chart
      let items = ['A', 'B', 'C', 'AP', 'BP', 'CP', 'WD', 'SSMCC', ]
      let name = ['A ITRs', 'B ITRs', 'C ITRs', 'Punch A', 'Punch B', 'Punch C', 'Walkdown', 'Turnover', ]

      name.forEach((d,i)=> {
        this.Radar.ES.push({
          NAME: d,
          VALUE: this.Overall.find(f=> f.SCOPE == 'Existing Site')[`${items[i]}_PRO`]
        })
        this.Radar.GR.push({
          NAME: d,
          VALUE: this.Overall.find(f=> f.SCOPE == 'Grassroots')[`${items[i]}_PRO`]
        })
      })

      // Radial Chart
      notSort.forEach(d=> {
        d.AB_TOT = d.A_TOT + d.B_TOT
        d.AB_ACT = d.A_ACT + d.B_ACT
        d.AB_REM = d.A_REM + d.B_REM
        d.AB_PRO = (d.AB_ACT/d.AB_TOT) * 100
      })

      sorted.forEach(d=> {
        let getData = notSort.find(f=> f.UNIT == d. UNIT)
        this.Radial.push(getData)
      })
    },




    set_Gradients() {
      Defs.setDefs_Gradients(this.svg, 'H', 'LinearA4', 'LightBlue', [.9, .8, .9, 0], this.localId)
      Defs.setDefs_Gradients(this.svg, 'H', 'LinearA4', 'Yellow', [.9, .8, .9, 0], this.localId)

      var defs = this.svg.append('defs')

      var filter = defs.append('filter')
      .attr('id', 'dropshadow')

      filter.append('feGaussianBlur')
      .attr('in', 'SourceAlpha')
      .attr('stdDeviation', 1)
      .attr('result', 'blurOut')
      filter.append('feOffset')
      .attr('in', 'blurOut')
      .attr('dx', .3)
      .attr('dy', .3)
      .attr('result', 'offsetBlur')

      var feMerge = filter.append('feMerge')

      feMerge.append('feMergeNode')
      .attr('in', 'offsetBlur')
      feMerge.append('feMergeNode')
      .attr('in', 'SourceGraphic')
    },


    set_Timeline() {
      let sql3 = JSON.parse(JSON.stringify(this.Queries.SQL3[0]))

      this.timeline = {
        start           : sql3['TIMELINE_START'],
        cutoff          : sql3['CUTOFF'],
        length          : this.chart.width,
        
        startDate       : sql3['START_DATE'],
        endDate         : sql3['END_DATE'],
        weekEnd         : sql3['WEEKEND'],
        day             : [],
        week            : [],
        month           : [],
        year            : [],
        scale           : null,
      }
      this.init_gmx_TimelineValues(this.timeline, 'day')

      // Grid
      this.grid = []
      for (let i = 0; i < 6; i++) {
        this.grid.push(this.chart.height - ((this.chart.height / 5) * i))
      }

    },

    loadSvg(selection, data) {
      return d3.xml(`${this.RESOURCE}${data.url}`).then(source => {
        let svg_ = source.documentElement
        selection.node().append(svg_)

        svg_ = d3.select(svg_)
        .attr('x', data.x)
        .attr('y', data.y)

        return new Promise(resolve => {
          resolve(svg_)
        })
      })
    },

    set_selectedItems(){  
      this.selectedItems = { 
        ...this.selectedItems,
        selectedTrend: this.selectedTrend,
      }
    },
  }
}