import * as d3 from 'd3'

export default {
  data: () => ({
    sCanvasArea:    null,
    sChartArea:     null,

    //pdf
    selectedItems : null,
    getItems      : null,
    filterStatus  : 'OFF',
    searchStatus  : 'OFF',
    searchNote    : '',

    rawData:        [],
    cutOff:         null,

    canvas:         null,
    timeline:       {},
    skyline:        {},
    axis:           null,
    charts:         [],
    weekData:       [],
    grid:           [],
    maxPrimary:     0,
    maxSecoundary:  0,
    scale:          {primary: null, secondary: null},
    
    sheetStatus:    'TOTAL',

    search: {
      input: '',
      results: [],
    }
  }),


  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },


  methods: {

    setDefaultValues(){
      this.rawData = JSON.parse(JSON.stringify(this.DataItems))

      this.chartData = JSON.parse(JSON.stringify(this.Queries.SQL1)) 
      let sql2 = this.Queries.SQL2[0] // Canvas
      let sql3 = this.Queries.SQL3[0] // Axis
      let sql4 = this.Queries.SQL4[0] // Bar & Line Chart Style
      let sql5 = this.Queries.SQL5[0] // Detail infomation Box
      this.weekData = JSON.parse(JSON.stringify(this.Queries.SQL6)) 
      
      this.cutOff = sql4.CUTOFF

      this.rawData.filter(f=> f.CSTD_ACTUAL !== undefined).forEach(d=> {
        d.A_STATUS = 'TURNOVER'
      })
      
      //Chart Data
      let tot = this.chartData[0].ACT_REM
      this.chartData.forEach(d=> {
        d.PLAN_REM = tot - d.PLAN_CUM
      })

      // Cnavas ---------------------------------------------------------------------------
      this.canvas = {
        width           : sql2['CANVAS_WIDTH'],
        height          : sql2['CANVAS_HEIGHT'],
        sColor          : sql2['CANVAS_STROKE'],
        sWidth          : sql2['CANVAS_STROKE_WIDTH'],
        main: {
          y             : sql2['TITLE_MAIN_Y'],
          name          : sql2['TITLE_MAIN_TITLE'],
          tColor        : sql2['TITLE_MAIN_COLOR'],
          tSize         : sql2['TITLE_MAIN_SIZE'],
        },
        sub: {
          y             : sql2['TITLE_SUB_Y'],
          name          : sql2['TITLE_SUB_TITLE'],
          tColor        : sql2['TITLE_SUB_COLOR'],
          tSize         : sql2['TITLE_SUB_SIZE'],
        },
        padding : {
          top           : sql2['PADDING_TOP'],
          right         : sql2['PADDING_RIGHT'],
          bottom        : sql2['PADDING_BOTTOM'],
          left          : sql2['PADDING_LEFT'],
        },
        chart : {
          width         : sql2['CANVAS_WIDTH'] - sql2['PADDING_LEFT'] - sql2['PADDING_RIGHT'],
          height        : sql2['CANVAS_HEIGHT'] - sql2['PADDING_TOP'] - sql2['PADDING_BOTTOM'],
        },
        
      }

      // Axis -----------------------------------------------------------------------------
      this.axis = {
        type            : sql3['TYPE'].          replace(/\s/g, '').split('/'),
        visible         : sql3['VISIBLE'].       replace(/\s/g, '').split('/'),
        maxValue        : sql3['MAX_VALUE'].     replace(/\s/g, '').split('/').map(Number),
        name            : sql3['TITLE'].         replace(/\s/g, ' ').split('/'),
        gap             : sql3['TITLE_GAP'].     replace(/\s/g, '').split('/').map(Number),
        tSize           : sql3['TITLE_SIZE'].    replace(/\s/g, '').split('/').map(Number),
        tColor          : sql3['TITLE_COLOR'].   replace(/\s/g, '').split('/'),
        fSize           : sql3['TEXT_SIZE'].     replace(/\s/g, '').split('/').map(Number),
        fColor          : sql3['TEXT_COLOR'].    replace(/\s/g, '').split('/'),
        unit            : sql3['TEXT_UNIT'].     replace(/\s/g, '').split('/'),
        align           : sql3['ALIGN'].         replace(/\s/g, '').split('/'),
        rotate          : sql3['ROTATE'].        replace(/\s/g, '').split('/').map(Number),
        sColor          : sql3['STROKE'].        replace(/\s/g, '').split('/'),
        sWidth          : sql3['STROKE_WIDTH'].  replace(/\s/g, '').split('/').map(Number),

        grid: {
          visible       : sql3['GRID_VISIBLE'],
          sColor        : sql3['GRID_STROKE'],
          sWidth        : sql3['GRID_STROKE_WIDTH'],
          opacity       : sql3['GRID_OPACITY'],
        }
      }


      // Timeline ------------------------------------------------------------------------------------
      this.timeline = {
        cutoff          : sql4['CUTOFF'],
        length          : this.canvas.chart.width,
        
        startDate       : sql4['SKY_START'],
        endDate         : sql4['SKY_END'],
        refColumn       : sql4['SKY_REF_DATE'], // ***
        week            : [],
        month           : [],
        year            : [],
        scale           : null,
      }

      // Skyline -------------------------------------------------------------------------------------
      let skyName       = sql4['SKY_NAME'].replace(/\s/g, '').split('/')
      this.skyline = {
        refColumn       : sql4['SKY_REF_BOX'], // ***
        refStatus       : sql4['SKY_REF_STATUS'], // ***
        height          : sql4['SKY_BOX_HEIGHT'],
        styles          : [],
        scale           : sql4['SKY_BOX_SCALE'],
      }
      skyName.forEach((d,i)=> {
        this.skyline.styles[i] = {
          name          : sql4['SKY_NAME'].                  replace(/\s/g, '').split('/')[i],
          status        : sql4['SKY_STATUS'].                replace(/\s/g, '').split('/')[i],
          bColor        : sql4['SKY_COLOR'].                 replace(/\s/g, '').split('/')[i],
          opacity       : Number(sql4['SKY_OPACITY'].        replace(/\s/g, '').split('/')[i]),
          value         : 0,
        }
      })

      // Styles for Chart ----------------------------------------------------------------------------
      let sn = 0
      let visible = sql4['LINE_VISIBLE'].replace(/\s/g, '').split('/')

      visible.forEach((d,i)=> {
        if(d !== 'Y') return
        this.charts[sn] = {
          name          :        sql4['LINE_NAME'].         replace(/\s/g, ' ').split('/')[i],
          visible       :        sql4['LINE_VISIBLE'].      replace(/\s/g, '').split('/')[i],
          axis          :        sql4['LINE_AXIS'].         replace(/\s/g, '').split('/')[i],
          code          :        sql4['LINE_CODE'].         replace(/\s/g, '').split('/')[i],
          cutoff        :        sql4['LINE_CUTOFF'].       replace(/\s/g, '').split('/')[i],
          type          :        sql4['LINE_TYPE'].         replace(/\s/g, '').split('/')[i],
          radius        : Number(sql4['LINE_RADIUS'].       replace(/\s/g, '').split('/')[i]),
          cColor        :        sql4['LINE_FILL_COLOR'].   replace(/\s/g, '').split('/')[i],
          sColor        :        sql4['LINE_STROKE_COLOR']. replace(/\s/g, '').split('/')[i],
          sWidth        : Number(sql4['LINE_STROKE_WIDTH']. replace(/\s/g, '').split('/')[i]),
          dash          : Number(sql4['LINE_DASH'].         replace(/\s/g, '').split('/')[i]),
          opacity       : Number(sql4['LINE_OPACITY'].      replace(/\s/g, '').split('/')[i]),
          animation     : Number(sql4['LINE_ANIMATION'].    replace(/\s/g, '').split('/')[i]),
          value:{
            visible     :        sql4['LINE_VALUE_VISIBLE'].replace(/\s/g, '').split('/')[i],
            size        : Number(sql4['LINE_VALUE_SIZE'].   replace(/\s/g, '').split('/')[i]),
            color       :        sql4['LINE_VALUE_COLOR'].  replace(/\s/g, '').split('/')[i],
          }
        }
        sn++
      })
      // console.log(this.charts)


      // Detail infomation Box -------------------------------------------------------------------------------
      if(sql5) {
        this.detail = {
          visible:            sql5.DETAIL_VISIBLE,
          onOff:              sql5.DETAIL_ONOFF,
          x:                  sql5.DETAIL_X,
          y:                  sql5.DETAIL_Y,
          width:              sql5.DETAIL_WIDTH,
          tSize:              sql5.DETAIL_TEXT_SIZE,
          lineHeight:         sql5.DETAIL_LINEHEIGHT,
          codes:              sql5.DETAIL_CODE.replace(/\s/g, '').split('/'),
          name:               sql5.DETAIL_NAME.replace(/\s/g, '').split('/'),
          type:               sql5.DETAIL_TYPE.replace(/\s/g, '').split('/'),
          tColor:              sql5.DETAIL_VALUE_COLOR.replace(/\s/g, '').split('/'),
          bColor:              sql5.DETAIL_BAR_COLOR.replace(/\s/g, '').split('/'),
          wrapSize:           null,
          item: {
            name: [],
          },
          title: {
            width:            sql5.DETAIL_TITLE_WIDTH,
            tColor:           sql5.DETAIL_TITLE_COLOR,
          }
        }
        this.detail.height = sql5.DETAIL_LINEHEIGHT * this.detail.codes.length + 20
      }
      
    },

    set_selectedItems(){  
      this.selectedItems = { 
        ...this.selectedItems,
        filterStatus: this.filterStatus,
        sheetStatus : this.sheetStatus,
      }
    },
  }
}