import moment from 'moment'
import * as d3 from 'd3'

export default {
  methods: {

    set_Axies(maxPrimary) {
      let this_ = this
      // let maxPrimary = this.getMaxBound_v2(d3.max(this.timeline.week, c => c.value))
      // let maxPrimary = 7500

      this.scale.Primary = d3.scaleLinear()
      .domain([0, maxPrimary])
      .range([this.canvas.chart.height, 0])

      this.grid.forEach((d,i)=> {
        d3.select(`#Axis_Primary_${i}`)
        .text((maxPrimary) * (i*2)/10)
      })

      let real_Timeline = this.timeline.week.filter(f=> f.eDate >= new Date(this.timeline.start))
      this.scale.X = d3.scaleBand()
      .domain(real_Timeline.map(d => d.eDate ))
      .range([0, this.canvas.chart.width]).padding(0.3)

      this.Area_Charts = this.svg
      .append('g')
      .attr('id', `Chart_Area_${this.chart_Count}`)
      .attr('transform', `translate(${this.canvas.padding.left+.5}, ${this.canvas.padding.top+.5})`)
      .attr('font-family', 'roboto') // rage / roboto
      .attr('opacity', 1).style('cursor', 'default')
      .on('mouseover', function() {

        let getIdx = d3.select(this).attr('id').slice(-1)

        d3.select(`Chart_Area_${getIdx}`).moveToFront()
        this_.condition_List.forEach(d=> {
          d3.select(`#CONDITION_BOX_${d.count}`).transition().duration(150).attr('fill', d.count == getIdx ? '#EBEBEB' : '#fff')
        })
      })
      .on('mouseout', function() {
        this_.condition_List.forEach(d=> {
          d3.select(`#CONDITION_BOX_${d.count}`).transition().duration(150).attr('fill', '#fff')
        })
      })
    },
    
  }
}