import * as d3 from 'd3'

export default {
  methods: {
    
    draw_Summary_Item(selection, item) {

      let groupX = this.timeline.scale(new Date(item.MS_DATE)) + this.timeline.column - this.Gantt.ix

      let Summary = selection
      .append('g')
      .attr('id', `SUMMARY_ITEM_${item.CODE}`)
      .attr('transform', `translate(${this.Gantt.ix}, ${item.y})`)
      .style('font-family', 'roboto').attr('cursor', 'default').style('visibility', item.bind == 'closed' ? 'hidden' : 'visible')

      // draw_FlowLine 
      let flowColor = '#EC407A'

      let flowLine = Summary
      .append('g')
      .attr('transform', `translate(${(groupX-200) - 440.5}, 34.5)`)
      this.draw_FlowLine(flowLine, flowColor)


      // MC & Pre-Commissioning --------------------------------------------------------------------------
      let completion = Summary
      .append('g')
      .attr('transform', `translate(${(groupX-200) - 315}, 25)`)

      completion
      .append('rect')
      .attr('x', 5.5).attr('y', 0.5)
      .attr('width', 160).attr('height', 18).attr('opacity', .5)
      .attr('fill', '#fff').attr('stroke', flowColor).attr('stroke-width', .5)

      completion
      .append('text')
      .attr('x', 85).attr('y', 13).attr('text-anchor', 'middle')
      .attr('font-size', 11).attr('fill', '#000').text('A & B ITRs Status')

      this.draw_barChart(completion, 'A ITRs', 'A', 35, item)
      this.draw_barChart(completion, 'B ITRs', 'B', 55, item)

      // Punch ------------------------------------------------------------------------------------
      let Punch = Summary
      .append('g')
      .attr('transform', `translate(${(groupX-200) - 135}, 25)`)

      Punch
      .append('rect')
      .attr('x', 5.5).attr('y', 0.5)
      .attr('width', 150).attr('height', 18).attr('opacity', .5)
      .attr('fill', '#fff').attr('stroke', flowColor).attr('stroke-width', .5)

      Punch
      .append('text')
      .attr('x', 85).attr('y', 13).attr('text-anchor', 'middle')
      .attr('font-size', 11).attr('fill', '#000').text('Punch Status')

      this.draw_barChart(Punch, 'Punch A', 'AP', 35, item)
      this.draw_barChart(Punch, 'Punch B', 'BP', 55, item)

      // Subsystem ----------------------------------------------------------------------------------------
      let subsystem = Summary
      .append('g')
      .attr('transform', `translate(${(groupX-165)}, 25)`)

      subsystem
      .append('rect')
      .attr('x', 5.5).attr('y', 0.5)
      .attr('width', 150).attr('height', 18).attr('opacity', .5)
      .attr('fill', '#fff').attr('stroke', flowColor).attr('stroke-width', .5)

      subsystem
      .append('text')
      .attr('x', 85).attr('y', 13).attr('text-anchor', 'middle')
      .attr('font-size', 11).attr('fill', '#000').text('Subsystem Turnover')

      this.draw_barChart(subsystem, 'Turnover', 'SSMCC', 35, item)
      this.draw_barChart(subsystem, 'Walkdown', 'WD', 55, item)
    },


    draw_barChart(selection, name, code, y_, d) {

      let barX = 55
      let proLen = 75
      let TOT = d[`${code}_TOT`]

      let progress = selection
      .append('g')
      .attr('transform', `translate(10, ${y_})`)
      .attr('font-size', 9)

      progress
      .append('text')
      .attr('x', 0).attr('y', 0)
      .attr('fill', TOT == 0 ? '#E0E0DF' : '#333')
      .text(name)
      
      // Total
      progress
      .append('text')
      .attr('x', barX-2).attr('y', 0)
      .attr('fill', TOT == 0 ? '#E0E0DF' : '#333')
      .attr('text-anchor', 'end')
      .text(TOT)
      .attr('cursor', 'pointer')
      .call(this.call_Summary, {CP21_ID: d.MAP_ID, item: code, coltype: 'TOT'})

      progress
      .append('rect')
      .attr('x', barX).attr('y', -9).attr('opacity', .2)
      .attr('width', proLen).attr('height', 10).attr('fill', TOT == 0 ? '#E0E0DF' : `url(#${this.localId}_Pink_)`)

      let PRO = (d[`${code}_PRO`]/100) * proLen
      progress
      .append('rect')
      .attr('x', barX).attr('y', -9).attr('opacity', .5)
      .attr('width', PRO).attr('height', 10).attr('fill', `url(#${this.localId}_Pink_)`)

      // Actual
      progress
      .append('text')
      .attr('y', 0).attr('fill', TOT == 0 ? '#E0E0DF' : '#333')
      .attr('x', d[`${code}_PRO`] < 60 ? barX + PRO + 2 : barX + PRO - 2)
      .attr('text-anchor', d[`${code}_PRO`] < 60 ? 'start' : 'end')
      .text(d[`${code}_ACT`])
      .attr('cursor', 'pointer')
      .call(this.call_Summary, {CP21_ID: d.MAP_ID, item: code, coltype: 'ACT'})

      // Remain
      progress
      .append('text')
      .attr('x', barX+proLen+2).attr('y', 0)
      .attr('fill', TOT == 0 ? '#E0E0DF' : '#333').attr('text-anchor', 'start')
      .text(d[`${code}_REM`])
      .attr('cursor', 'pointer')
      .call(this.call_Summary, {CP21_ID: d.MAP_ID, item: code, coltype: 'REM'})
    },



    draw_FlowLine(selection, flowColor) {

      selection
      .append('line')
      .attr('x1', 290.5).attr('y1', 0)
      .attr('x2', 310.5).attr('y2', 0)
      .attr('stroke', flowColor).attr('stroke-width', 1).attr('opacity', .5)

      selection
      .append('line')
      .attr('x1', 460.5).attr('y1', 0)
      .attr('x2', 480.5).attr('y2', 0)
      .attr('stroke', flowColor).attr('stroke-width', 1).attr('opacity', .5)

      selection
      .append('line')
      .attr('x1', 631).attr('y1', 0)
      .attr('x2', 640).attr('y2', 0)
      .attr('stroke', flowColor).attr('stroke-width', 1).attr('opacity', .5)

      selection
      .append('line')
      .attr('x1', 640).attr('y1', 0)
      .attr('x2', 640).attr('y2', -40)
      .attr('stroke', flowColor).attr('stroke-width', 1).attr('opacity', .5)
    }

    
  }
}
