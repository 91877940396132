import moment from 'moment'
import * as d3 from 'd3'

export default {
  methods: {
    axis_Controller() {

      this.sChartArea = this.sCanvasArea
      .append('g')
      .attr('id', `Chart_Area_${this.localId}`)
      .attr('transform', `translate(${this.canvas.padding.left}, ${this.canvas.padding.top})`)
      .attr('font-family', 'roboto') // rage / roboto

      this.draw_Timeline()
      if(this.axis.visible[0] == 'Y') this.draw_Axis_Primary()
      if(this.axis.visible[1] == 'Y') this.draw_Axis_Secondary()
    },


    draw_Timeline() {    
    // console.log(this.timeline.week)
      let Timeline = this.sChartArea
      .append('g')
      .attr('transform', `translate(0, ${this.canvas.chart.height})`)
      .style('font-family', 'roboto')

      Timeline
      .append('line')
      .attr('x1', 0).attr('y1', 0).attr('x2', this.canvas.chart.width).attr('y2', 0)
      .attr('stroke', '#bcbcbc').attr('storke-width', .3)

      // Week Text 
      Timeline
      .append('g')
      .selectAll('text')
      .data(this.timeline.week) // *** week
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate)).attr('y', 4)
      .style('font-size', 9).attr('fill', '#757575')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
      .attr('cursor', 'default')
      .text(d => d.name)

      Timeline
      .append('g')
      .selectAll('path')
      .data(this.timeline.week) // *** week
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        if (i != this.timeline.week.length-1) return `M${this.timeline.scale(d.eDate)+ 1}, 0 V 4` // *** week
      })
      .attr('stroke', '#bcbcbc')
      .attr('stroke-width', .5)

      // cutoff Week highlight
      let cutoff = moment(this.timeline.cutoff).format('YYYY-MM-DD')

      this.timeline.week.forEach(d => {
        if (moment(d.eDate).format('YYYY-MM-DD') == cutoff) {
          Timeline
          .append('rect')
          .attr('x',this.timeline.scale(d.sDate) + 1)
          .attr('y',.5)
          .attr('width', this.timeline.scale(d.eDate) - this.timeline.scale(d.sDate))
          .attr('height',14)
          .style('opacity', 0.3)
          .attr('fill','#00b0f0')
          .attr('stroke','#bcbcbc')
          .attr('stroke-width',0.5)               
        }
      })

      // Month Text
      Timeline
      .append('g')
      .selectAll('text')
      .data(this.timeline.month) // *** week
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate)).attr('y', 15)
      .style('font-size', 9).attr('fill', '#757575')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
      .attr('cursor', 'default')
      .text(d => d.name)

      Timeline
      .append('g')
      .selectAll('path')
      .data(this.timeline.month) // *** week
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        if (i != this.timeline.month.length-1) return `M${this.timeline.scale(d.eDate)+ 1}, 0 V 22` // *** week
      })
      .attr('stroke', '#bcbcbc')
      .attr('stroke-width', .5)



      // Year Text & Timeline Seperator for the Year
      Timeline
      .append('g')
      .selectAll('text')
      .data(this.timeline.year)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate))
      .attr('y', 28)
      .style('font-size', 10)
      .attr('fill', '#757575')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
      .attr('cursor', 'default')
      .text(d => d.name)

      Timeline
      .append('g')
      .selectAll('path')
      .data(this.timeline.year)
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        if (i != this.timeline.year.length-1) return `M${this.timeline.scale(d.eDate) + 1}, 0 V 35`
      })
      .attr('stroke', '#bcbcbc')
      .attr('stroke-width', .5)
    }, 



    draw_Axis_Primary() {
      const Primary = 0
      let chart = this.canvas.chart
      let axis = this.axis

      let sPrimary = this.sChartArea
      sPrimary
      .selectAll('line1')
      .data(this.grid)
      .enter()
      .append('line')
      .attr('x1', - 5)
      .attr('x2', 0)
      .attr('y1', d => d)
      .attr('y2', d => d)
      .attr('stroke', axis.sColor[Primary])
      .attr('stroke-width', axis.sWidth[Primary])

      sPrimary
      .selectAll('text1')
      .data(this.grid)
      .enter()
      .append('text')
      .attr('transform', d => `translate(${-7}, ${d + 1})`)
      .attr('font-size', axis.fSize[Primary])
      .attr('fill', axis.fColor[Primary])
      .attr('text-anchor', axis.align[Primary])
      .attr('alignment-baseline', 'middle')
      .text((d,i) => {
        if(axis.unit[Primary] == 'K') {
          return `${(this.maxPrimary * (i*2)/10)/1000}${axis.unit[Primary]}`
        } else {
          return `${this.maxPrimary * (i*2)/10}${axis.unit[Primary]}`
        }
      })

      sPrimary
      .append('line')
      .attr('x1', 0).attr('y1', 0)
      .attr('x2', 0).attr('y2', chart.height)
      .attr('stroke', axis.sColor[Primary]).attr('stroke-width', axis.sWidth[Primary])

      sPrimary
      .append('text')
      .attr('transform', d => `translate(${-axis.gap[Primary]}, ${chart.height/2}) rotate(-90)`)
      .attr('font-size', axis.tSize[Primary]).attr('fill', axis.tColor[Primary])
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(axis.name[Primary])
    },



    draw_Axis_Secondary() {
      const Secondary = 1
      let chart = this.canvas.chart
      let axis = this.axis

      let sSecoundary = this.sChartArea
      sSecoundary
      .selectAll('line2')
      .data(this.grid)
      .enter()
      .append('line')
      .attr('x1', chart.width)
      .attr('x2', chart.width + 5)
      .attr('y1', d => d)
      .attr('y2', d => d)
      .attr('stroke', axis.sColor[Secondary])
      .attr('stroke-width', axis.sWidth[Secondary])

      sSecoundary
      .selectAll('text2')
      .data(this.grid)
      .enter()
      .append('text')
      .attr('transform', d => `translate(${chart.width + 7}, ${d + 1})`)
      .attr('font-size', axis.fSize[Secondary])
      .attr('fill', axis.fColor[Secondary])
      .attr('text-anchor', axis.align[Secondary])
      .attr('alignment-baseline', 'middle')
      .text((d,i) => {
        if(axis.unit[Secondary] == 'K') {
          return `${(this.maxSecoundary * (i*2)/10)/1000}${axis.unit[Secondary]}`
        } else {
          return `${this.maxSecoundary * (i*2)/10}${axis.unit[Secondary]}`
        }
      })

      sSecoundary
      .append('line')
      .attr('x1', chart.width).attr('y1', 0)
      .attr('x2', chart.width).attr('y2', chart.height)
      .attr('stroke', axis.sColor[Secondary]).attr('stroke-width', axis.sWidth[Secondary])

      sSecoundary
      .append('text')
      .attr('transform', d => `translate(${chart.width + axis.gap[Secondary]}, ${chart.height/2}) rotate(-90)`)
      .attr('font-size', axis.tSize[Secondary]).attr('fill', axis.tColor[Secondary])
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(axis.name[Secondary])
    },

    
  }
}