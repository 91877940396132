import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms() {

      this.Area_Canvas = this.svg
      .append('g')
      .attr('id', `CANVAS_${this.localId}`)
      .attr('transform', `translate(0,0)`)
      .style('font-family', 'roboto').attr('cursor', 'default')

      this.loadSvg(
        this.Area_Canvas, {
        x   : 0,
        y   : 0,
        url : `/CRISP/LOGOs/Homepage.svg`
      }).then(() => {

        d3.select('#Homepage').attr('viewBox', `0 0 ${this.Canvas.CanvasWidth} ${this.Canvas.CanvasHeight}`)
        d3.selectAll('text').style('font-family', 'roboto')

        this.Area_Canvas
        .append('line')
        .attr('x1', 50).attr('y1', 570.5)
        .attr('x2', 1250).attr('y2', 570.5)
        .attr('stroke', '#bcbcbc').attr('stroke-width', .5)

        // this.Area_Canvas
        // .append('line')
        // .attr('x1', 50).attr('y1', 1030.5)
        // .attr('x2', 1250).attr('y2', 1030.5)
        // .attr('stroke', '#bcbcbc').attr('stroke-width', .5)


        // Overall Summary on Header
        this.draw_ScopeSummary()

        // Radar Chart for each Scope
        this.draw_Radar()

        // Trend Chart & Week Performance
        this.draw_Trends()
        this.draw_Weeks()
        
        // this.draw_GR_Radial()
        // this.draw_ES_Radial()
        
        this.drawNotes()
        this.drawTexts()
      })
    },

  }
}