import gmx_zMixins         from '../../../../../../includes/Jmixins/zMixins'

// Locally declared common Data-Props & SVG Drawing Support

import lmx_watching             from './watching'

// Charts 
import lmx_LocalValues          from './set_LocalValues'

import lmx_draw_Header          from './draw_Header'
import lmx_header_Sheets        from './header_Sheets'
import lmx_header_Discipline    from './header_Discipline'
import lmx_header_BarChart      from './header_BarChart'
import lmx_reset_Charts         from './reset_Charts'

import lmx_Axis                 from './draw_Axis'
import lmx_Charts               from './draw_Charts'
import lmx_Legends              from './draw_Legends'

import lmx_events               from './Events'

export default {
  
  mixins: [
    gmx_zMixins, 
    
    // Local Mixins
    lmx_watching,
    lmx_LocalValues,
    lmx_events,

    lmx_draw_Header,
    lmx_header_Sheets,
    lmx_header_Discipline,
    lmx_header_BarChart,

    lmx_reset_Charts,
    
    lmx_Axis,
    lmx_Charts,
    lmx_Legends,
    
  ],

}
