import * as d3 from 'd3'

export default {
  methods: {

    draw_ITR_Chart(selection, unit, item_, x_, y_, idx_) {
      
      let chartBase = this.colHeight-20

      let ITRChart = selection
      .append('g')
      .attr('transform', `translate(${x_+25}, ${y_ - chartBase})`)
      .attr('font-family', 'Segoe UI')

      let data = this.Sheets.filter(f=> f.UNIT == unit.UNIT && f.SUBSYS_NO == 'OV' && f.ITEM == item_)

      // Scale X
      this.scaleX = d3.scaleBand()
      .domain(data.map(d => d.CDATE ))
      .range([0, 220])

      // ITRChart
      // .append('line')
      // .attr('x1', 0).attr('y1', chartBase).attr('x2', 220).attr('y2', chartBase)
      // .attr('stroke', '#bcbcbc').attr('stroke-width', 1).attr('stroke-opacity', .3)


      // Scale Y
      let maxY = this.getMaxBound_v2(this.Sheets.find(f=> f.UNIT == unit.UNIT && f.SUBSYS_NO == 'OV' && f.ITEM == item_).TOTAL)
      this.scaleY = d3.scaleLinear()
      .domain([0, maxY])
      .range([chartBase, 0])
      
      // ITRChart
      // .append('line')
      // .attr('x1', 0).attr('y1', 0).attr('x2', 0).attr('y2', chartBase)
      // .attr('stroke', '#bcbcbc').attr('stroke-width', 1).attr('stroke-opacity', .3)

      this.Grids.forEach((d,i)=> {
        ITRChart
        .append('line')
        .attr('x1', -3).attr('x2', 0)
        .attr('y1', d).attr('y2', d)
        .attr('stroke', '#bcbcbc').attr('stroke-width', .5)

        ITRChart
        .append('text')
        .attr('id', `AxisY_UNIT${idx_}_${item_}_${i}`)
        .attr('transform', `translate(${-4}, ${d + 1})`)
        .attr('font-size', 7)
        .attr('fill', '#757575').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(((maxY) * (i*2)/10))
      })
      


      // Draw Chart ---------------------------------------------------------------------------------------------------------
      let lineFunc = d3.line()
      .x(d => this.scaleX(d.CDATE) + 5)
      .y(d => this.scaleY(d.REMAIN_CUM))

      ITRChart
      .append('g')
      .attr('id', `LINE_CHART_${this.chart_Count}`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      ITRChart
      .append('path')
      .attr('d', lineFunc(data))
      .attr('stroke', this.color21[idx_]).attr('stroke-width', 2)
      .style('fill', 'none')

      // ITRChart
      // .selectAll(`circle_`)
      // .data(data)
      // .enter()
      // .append('circle')
      // .attr('cx', d => this.scaleX(d.CDATE) + 5)
      // .attr('cy', d=> this.scaleY(d.REMAIN_CUM))
      // .attr('r', 1.5)
      // .attr('stroke', this.color21[idx_]).attr('stroke-width', 1).attr('stroke-opacity', 1)
      // .attr('fill', '#fff')
    },

    

    
    
  }
}