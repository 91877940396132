import * as d3 from 'd3'

export default {
  methods: {

    draw_Header(selection, unit, x_, y_, idx_) {

      let UnitHeader = selection
      .append('g')
      .attr('transform', `translate(${x_}, ${y_})`)
      .attr('font-family', 'Segoe UI')

      UnitHeader
      .append('rect')
      .attr('x', -80).attr('y', -153)
      .attr('width', 40).attr('height', 16)
      .attr('fill', '#FFF495')
      .attr('stroke', '#bcbcbc').attr('stroke-width', .5).attr('stroke-opacity', .9)

      UnitHeader
      .append('text')
      .attr('x', -44).attr('y', -141).attr('font-size', 12).attr('fill', '#000').attr('text-anchor', 'end')
      .text(unit.MAX_PLAN.substr(5,5))

      let UnitName = UnitHeader
      .append('g')
      .attr('transform', `translate(0, 10)`)
      .attr('opacity', 1).attr('cursor', 'pointer')
      .on('mouseover', function() {
        d3.select(this).transition().duration(150).attr('opacity', .3)
      })
      .on('mouseout', function() {
        d3.select(this).transition().duration(150).attr('opacity', 1)
      })
      .on('click', ()=> {
        this.redraw_Overall(unit, idx_) // ---> redraw
      })

      UnitName
      .append('text')
      .attr('id', `UNIT_${idx_}`)
      .attr('x', -25).attr('y', -151)
      .attr('font-size', 14).attr('fill', '#333').attr('font-weight', 500)
      .text(`${unit.UNIT}`)

      UnitName
      .append('text')
      .attr('x', -25).attr('y', -140).attr('font-size', 9).attr('fill', '#bcbcbc')
      .text(`${unit.UNIT_DESC}`)

      



      // Draw Header Line Elements ---------------------------------------------------------
      let unitlen = this.getNodeElValue( `#UNIT_${idx_}`, 'width') + 15 - 30
      UnitHeader
      .append('line')
      .attr('x1', unitlen).attr('y1', -145).attr('x2', 1180).attr('y2', -145)
      .attr('stroke', '#757575').attr('stroke-width', .5).attr('stroke-opacity', .9)

      UnitHeader
      .append('rect')
      .attr('x', 458).attr('y', -153)
      .attr('width', 90).attr('height', 16)
      .attr('fill', '#fff').attr('stroke', '#757575').attr('stroke-opacity', .2)

      // System No
      UnitHeader
      .append('text')
      .attr('id', `SYS_${idx_}`) 
      .attr('x', 470).attr('y', -142)
      .attr('font-size', 11).attr('fill', '#333').attr('text-anchor', 'start')
      .text(`Overall Status`)

      // A ITRs
      UnitHeader
      .append('rect')
      .attr('x', 754).attr('y', -153)
      .attr('width', 63).attr('height', 15)
      .attr('fill', '#fff').attr('stroke', '#757575').attr('stroke-opacity', .2)

      // B ITRs
      UnitHeader
      .append('rect')
      .attr('x', 1024).attr('y', -153)
      .attr('width', 63).attr('height', 15)
      .attr('fill', '#fff').attr('stroke', '#757575').attr('stroke-opacity', .2)

      // SSMCC / Walk down / Turnover
      UnitHeader
      .append('text')
      .attr('id', `SYS_${idx_}`)
      .attr('x', -25).attr('y', -117).attr('cursor', 'pointer').attr('opacity', 1)
      .attr('font-size', 11).attr('fill', '#44A9DF').attr('font-weight', 500)
      .text(this.Systems.filter(f => f.UNIT == unit.UNIT).length)
      .call(this.call_SubSystem, {unit: unit.UNIT, item: 'SSMCC', cols: 'TOT'}) // ---> Event.js

      UnitHeader
      .append('text')
      .attr('x', -5).attr('y', -117)
      .attr('font-size', 11).attr('fill', '#757575').attr('cursor', 'pointer').attr('opacity', 1)
      .text(`Walked down: ${unit.SSMCC_WD}`)
      .call(this.call_SubSystem, {unit: unit.UNIT, item: 'WD', cols: 'ACT'}) // ---> Event.js

      UnitHeader
      .append('text')
      .attr('x', 90).attr('y', -117)
      .attr('font-size', 11).attr('fill', '#757575').attr('cursor', 'pointer').attr('opacity', 1)
      .text(`Turned over: ${unit.SSMCC_ACT}`)
      .call(this.call_SubSystem, {unit: unit.UNIT, item: 'SSMCC', cols: 'ACT'}) // ---> Event.js

    },

  }
}