import icon from '../../../../../../includes/primitives/icons'
import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms(page_) {
      
      this.unit.count = page_

      this.stg_Canvas = this.svg
      .append('g')
      .attr('id', `SLIDE_CANVAS_${this.localId}`)
      .attr('transform', `translate(0,0)`)
      .style('font-family', 'roboto')

      this.stg_Canvas
      .append('text')
      .attr('id', `SLIDE_TITLE_${this.localId}`)
      .attr('transform', `translate(10, 20)`)
      .style('font-size', this.unit.fSize).style('fill', this.unit.fColor)
      .text(this.unit.title)

      let titleSize = this.getNodeElValue(`#SLIDE_TITLE_${this.localId}`, 'width') + 20.5

      // LEFT Button
      let left = this.stg_Canvas
      .append('g')
      .attr('id', `Btn_LEFT_${this.localId}`)
      .attr('transform', `translate(${titleSize}, 7)`)
      .style('visibility', this.unit.count == 1 ? 'hidden' : 'visible')
      .attr('cursor', 'pointer')
      .on('mouseover', () => {d3.select(`#Btn_LEFT_${this.localId}`).attr('opacity', .5)})
      .on('mouseout', () => {d3.select(`#Btn_LEFT_${this.localId}`).attr('opacity', 1)})
      .on('click', ()=> {
        this.move_Slide('left')
      })
      left
      .append('path')
      .attr('transform', `translate(0,1)`)
      .attr('fill', '#83D2F5').attr('d', icon.arrow.left)
      left
      .append('rect')
      .attr('x', -3).attr('y', 0)
      .attr('fill', '#000').attr('opacity', 0).attr('width', 15).attr('height', 16)
      
      // Right Button
      let right = this.stg_Canvas
      .append('g')
      .attr('id', `Btn_RIGHT_${this.localId}`)
      .attr('transform', `translate(${titleSize + 16}, 7)`)
      .attr('cursor', 'pointer')
      .attr('visibility', this.unit.count == this.paging ? 'hidden' : 'visible')
      .on('mouseover', () => {d3.select(`#Btn_RIGHT_${this.localId}`).attr('opacity', .5)})
      .on('mouseout', () => {d3.select(`#Btn_RIGHT_${this.localId}`).attr('opacity', 1)})
      .on('click', ()=> {
        this.move_Slide('right')
      })
      right
      .append('path')
      .attr('transform', `translate(0, 1)`)
      .attr('fill', '#83D2F5').attr('d', icon.arrow.right)
      right
      .append('rect')
      .attr('x', -4).attr('y', 0)
      .attr('fill', '#000').attr('opacity', 0).attr('width', 15).attr('height', 16)
    },

    move_Slide(btn_) {

      switch(btn_) {
        case 'left':
          this.slideX += (this.tables.length + this.tables.gap) * this.unit.tblNo
          this.unit.count -= 1
        break

        case 'right':
          this.slideX -= (this.tables.length + this.tables.gap) * this.unit.tblNo
          this.unit.count += 1
        break
      }
      // console.log(this.unit.count, page)

      if(this.unit.count == 1) d3.select(`#Btn_LEFT_${this.localId}`).style('visibility', 'hidden')
      else d3.select(`#Btn_LEFT_${this.localId}`).style('visibility', 'visible ')

      if(this.unit.count == this.paging) d3.select(`#Btn_RIGHT_${this.localId}`).style('visibility', 'hidden')
      else d3.select(`#Btn_RIGHT_${this.localId}`).style('visibility', 'visible ')

      d3.select(`#SLIDE_${this.localId}`)
      .transition().duration(500)//.ease(d3.PolyInOut.exponent(3))
      .ease(d3.easePolyInOut)
      .attr('transform', `translate(${this.slideX}, 40)`)

      this.set_selectedItems()
    },
  }
}