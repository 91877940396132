import Defs from '../../../../../../includes/primitives/colorDefs'
import * as d3 from 'd3'

export default {
  data: () => ({
    Area_Canvas:    null,
    Area_Chart:     null,

    //pdf
    selectedItems : null,
    getItems      : null,
    pdfCheckstatus: {
      A_Status: 'OFF', B_Status:'OFF', A_Check : true, B_Check:true,
      AD_Status: 'OFF', BD_Status:'OFF'
    },
    pdfCheckdisc: [],

    rawData:        null,
    Overall:        null,
    Discipline:     null,
    Turnover:       null,
    cutOff:         null,

    canvas:         null,
    timeline:       null,
    axis:           null,
    charts:         null,

    grid:           null,
    barWidth:       null,
    scale:          {primary: null, secondary: null},

    status: [
      {item: 'A', disc: 'OV', checked: true},
      {item: 'B', disc: 'OV', checked: true},
    ],

    barColors:[
      '#83D2F5', '#FFDA00', '#F7BACF', '#8BC248', '#44A9DF', '#FAAA18', '#CECECE', '#F35E90', '#8BC248', '#B5549B',
      '#83D2F5', '#FFDA00', '#F7BACF', '#8BC248', '#44A9DF', '#FAAA18', '#CECECE', '#F35E90', '#8BC248', '#B5549B',
    ],

    current_Unit: 'OV',
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    setDefaultValues(){
    
      let unit = 'OV'
      if(!(this.FilterValues.UNITCODE && this.FilterValues.UNITCODE=='Overall')){
        unit = this.FilterValues.UNITCODE
      }
      
      if(!this.FilterValues.UNITCODE) {
        console.log('[USER: #DEBUG# FilterValues null] DEFAULT UNIT SET [Overall] ')
        unit = 'OV'
      }
      //for pdf
      if(this.FilterValues.UNITCODE && this.current_Unit != unit){
        this.selectedItems = {}
        this.pdfCheckdisc = []
        this.pdfCheckstatus = { 
          A_Status: 'OFF', B_Status:'OFF', A_Check : true, B_Check:true,
          AD_Status: 'OFF', BD_Status:'OFF'
        }
  
      }

      this.current_Unit = unit

      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      this.Overall = JSON.parse(JSON.stringify(this.Queries.SQL1[0]))
      this.Discipline = JSON.parse(JSON.stringify(this.Queries.SQL2))
      this.Turnover = JSON.parse(JSON.stringify(this.Queries.SQL3))

      this.Discipline.forEach(d => {
        d.DISC == 'OV' ? d.checked = true : d.checked = false
        d.max_Wk = this.getMaxBound_v2(d3.max(this.rawData.filter(f=> f.ITEM == d.ITEM && f.DISC == d.DISC), c => c.CLOSED_WK))
        d.max_Cumul = this.getMaxBound_v2(this.rawData.find(f=> f.ITEM == d.ITEM && f.DISC == d.DISC).TOTAL)
      })
      // console.log(this.Discipline)

      let sql4 = JSON.parse(JSON.stringify(this.Queries.SQL4[0]))
      
      // Cnavas ---------------------------------------------------------------------------
      this.canvas = {
        width           : sql4['CANVAS_WIDTH'],
        height          : sql4['CANVAS_HEIGHT'],
        
        padding : {
          top           : sql4['PADDING_TOP'],
          right         : sql4['PADDING_RIGHT'],
          bottom        : sql4['PADDING_BOTTOM'],
          left          : sql4['PADDING_LEFT'],
        },
        chart : {
          width         : sql4['CANVAS_WIDTH'] - sql4['PADDING_LEFT'] - sql4['PADDING_RIGHT'],
          height        : sql4['CANVAS_HEIGHT'] - sql4['PADDING_TOP'] - sql4['PADDING_BOTTOM'],
        },
      }

      // Timeline ------------------------------------------------------------------------------------
      this.timeline = {
        length          : this.canvas.chart.width,
        startDate       : sql4['START_DATE'],
        endDate         : sql4['END_DATE'],
        weekEnd         : sql4['WEEKEND'],
        cutoff          : sql4['CUTOFF'],
        day             : [],
        week            : [],
        month           : [],
        year            : [],
        scale           : null,
      }

      // Grids
      this.grid = []
      for (let i = 0; i < 6; i++) {
        this.grid.push(this.canvas.chart.height - ((this.canvas.chart.height / 5) * i))
      } 
      
      this.init_gmx_TimelineValues(this.timeline, 'day')
      this.barWidth = this.canvas.chart.width / this.timeline.week.length
      
    },

    set_Gradients() {
      // set Gradient color for the Timeline
      Defs.setDefs_Gradients(this.svg, 'V', 'LinearA4', 'LightBlue', [.8, .5, .0, .5], this.localId)
      Defs.setDefs_Gradients(this.svg, 'V', 'LinearA4', 'Yellow', [.8, .5, .0, .5], this.localId)
      Defs.setDefs_Gradients(this.svg, 'V', 'LinearA4', 'YellowGreen', [.8, .5, .0, .5], this.localId)
    },

    set_selectedItems(){  
      this.selectedItems = { 
        ...this.selectedItems,
        pdfCheckstatus:this.pdfCheckstatus,
        pdfCheckdisc:this.pdfCheckdisc
      }
    },
  }
}