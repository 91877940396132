import * as d3 from 'd3'

export default {
  methods: {

    chart_Controller() {

      // Skyline Chart
      this.draw_SkylineCharts()

      // Line Charts
      let scale = null
      this.charts.forEach((d,i)=> {
        if(d.axis == 'PRIMARY') scale = this.scale.Primary
        if(d.axis == 'SECOUNDARY') scale = this.scale.Secondary

        if(d.cutoff == 'Y') {
          let data_ = this.chartData.filter((f=> f.CDATE <= this.cutOff))
          this.draw_lineChart(scale, data_, d, i)
        } else {
          this.draw_lineChart(scale, this.chartData, d, i)
        }
      })
    },


    draw_SkylineCharts() {
      let self = this
      this.timeline.week.forEach((w, i) => {  // *** week
        let drawY = this.canvas.chart.height - this.skyline.height - 2
        let dateFiltered = this.rawData.filter(f => f.cDate == w.eDate)
        
        dateFiltered.forEach((d, i) => {
          let x1 = this.timeline.scale(d.sDate) + 2
          let y1 = drawY
          let width_ = this.timeline.scale(d.cDate) - this.timeline.scale(d.sDate) - 2
          
          let Boxes = this.sChartArea
          .append('g')
          .attr('id', `BOX_${d[this.skyline.refColumn]}`)
          .attr('transform', `translate(${x1},${y1})`)
          .attr('opacity', 1)
          .attr('visibility', 'vidsible')
          .attr('cursor', 'pointer')
          .on('mouseover', function() {
            if(d.hover !== 'Y') return

            let factor = (self.skyline.scale - 1) / 2
            let scale_x = width_ * factor
            let scale_y = self.skyline.height * factor
            
            d3.select(this).moveToFront().transition().duration(150)
            .attr('transform', `translate(${x1-scale_x},${y1-scale_y}) scale(${self.skyline.scale})`)

            d3.select('#Detail_Group').transition().duration(300)
            .style('opacity', 1)

            self.mouseOver_Detail(d[self.skyline.refColumn]) // ---> Detailed Information
          })
          .on('mouseout', function(){
            if(d.hover !== 'Y') return

            d3.select(this).moveToBack().transition().duration(150)
            .attr('transform', `translate(${x1},${y1}) scale(1)`)
            
            d3.select('#Detail_Group').transition().delay(1000).duration(300)
            .style('opacity', self.detail.onOff == 'ON' ? 1 : 0)
          })
          .on('click', () => { this.link_Skyline(d[this.skyline.refColumn]) })



          Boxes
          .append('rect') // Fill Color
          .attr('x', 0).attr('y',0)
          .attr('rx', 0).attr('ry', 0)
          .attr('width', width_).attr('height', this.skyline.height)
          .attr('fill', d.bColor).attr('fill-opacity', d.opacity)
          .attr('stroke', '#bcbcbc').attr('stroke-width', .1)

          drawY = drawY - this.skyline.height - 2
        })
      })
    },


    draw_lineChart(scale_, data_, chart_, sn_) {

      let lineFunc = d3.line()
      .x(d => this.timeline.scale(new Date(d.CDATE)))
      .y(d => scale_(d[chart_.code]))

      let lineZero = d3.line()
      .x(d => this.timeline.scale(new Date(d.CDATE)))
      .y(scale_(0))

      let lineChart = this.sChartArea
      .append('g')
      .attr('id', `BAR_${sn_}_${this.localId}`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      lineChart
      .append('path')
      .attr('d', lineZero(data_))
      .style('stroke', chart_.sColor)
      .style("stroke-dasharray", (chart_.dash,chart_.dash))
      .style('stroke-width', chart_.sWidth)
      .attr('opacity', chart_.opacity)
      .style('fill', 'none')
      .transition().duration(chart_.animation).delay(sn_*(chart_.animation/2))
      .attr('d', lineFunc(data_))

      lineChart
      .selectAll(`circle${sn_}_${this.localId}`)
      .data(data_)
      .enter()
      .append('circle')
      .attr('cx', d => this.timeline.scale(new Date(d.CDATE)))
      .attr('cy', d => scale_(0))
      .attr('r', chart_.radius)
      .style('stroke', chart_.sColor)
      .style('stroke-width', chart_.sWidth)
      .attr('stroke-opacity', chart_.opacity)
      .style('fill', chart_.cColor)
      .transition().duration(chart_.animation).delay(sn_*(chart_.animation/2))
      .attr('cy', d=> scale_(d[chart_.code]))

      if(chart_.value.visible == 'Y') {
        lineChart
        .selectAll(`text_L${sn_}`)
        .data(data_)
        .enter()
        .append('text')
        .attr('x', d => this.timeline.scale(new Date(d.CDATE)))
        .attr('y', d => scale_(0) - chart_.radius - 3)
        .attr('fill', chart_.value.color)
        .attr('font-size', chart_.value.size)
        .attr('text-anchor', 'middle')
        .text(d=> d[chart_.code])
        .transition().duration(chart_.animation).delay(sn_*(chart_.animation/2))
        .attr('y', d => scale_(d[chart_.code]) - chart_.radius - 3)
      }
    },
  }
}