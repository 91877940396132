import * as d3 from 'd3'

export default {
  methods: {

    draw_Deviation() {

      let deviation = this.Area_Chart
      .append('g')
      .attr('id', `Deviation`)
      .attr('transform', `translate(500, -45)`)
      .attr('opacity', 0)

      let chart = this.DiscType.find(f=> f.ITEM == 'OV')
      let data = this.rawData.find(f=> f.CDATE == this.timeline.cutoff)

      deviation
      .append('text')
      .attr('id', 'DEV_PLAN')
      .attr('x', 0).attr('y', 0)
      .attr('font-size', 11).attr('fill', '#8BC248').attr('text-anchor', 'end')
      .text(`P6 Total: ${data.PL_TOTAL}`)

      deviation
      .append('text')
      .attr('id', 'DEV_ACTUAL')
      .attr('x', 0).attr('y', 15)
      .attr('font-size', 11).attr('fill', '#4CAE4E').attr('text-anchor', 'end')
      .text(`Hexagon Total: ${data.TOTAL}`)

      deviation
      .append('text')
      .attr('id', 'DEV_VALUE')
      .attr('x', 20).attr('y', 8)
      .attr('font-size', 11)
      .attr('fill', data.TOTAL - data.PL_TOTAL < 0 ? '#EC407A' : '#44A9DF')
      .text(`Delta: ${parseInt(data.TOTAL) - parseInt(data.PL_TOTAL)}`)
    


      // DELTA INFORMATION
      let deltaInfo = this.Area_Chart
      .append('g')
      .attr('id', `Deviation`)
      .attr('transform', `translate(10, -65)`)

      deltaInfo
      .append('rect')
      .attr('x', 0).attr('y', 0).attr('rx', 5)
      .attr('width', 200).attr('height', 50).attr('fill', '#fff')
      .attr('stroke', '#bcbcbc').attr('stroke-width', 1).attr('stroke-opacity', .3)

      // Delta
      let deltaValue = data.PL_REMAIN_CUM - data.REMAIN_CUM
      deltaInfo
      .append('text')
      .attr('x', 20).attr('y', 20).attr('font-size', 12).attr('fill', '#000')
      .text(`Outstanding Delta`)

      deltaInfo
      .append('text')
      .attr('id', 'DELTA_VALUE')
      .attr('x', 160).attr('y', 20).attr('font-size', 15).attr('text-anchor', 'end')
      .attr('fill', deltaValue < 0 ? '#EC407A': '#44A9DF')
      .text(deltaValue)

      deltaInfo
      .append('path')
      .attr('id', 'DELTA_ICON')
      .attr('transform', deltaValue < 0 ? `translate(165, 8) rotate(0)` : `translate(177, 21) rotate(180)`)
      
      .attr('d', 'M8.2,6.6V0H3.7v6.6c-2.1,0-3.8,0-3.7,0c-0.3,0,6,7.1,6,7.1c0,0,6.2-7.1,6-7.1C12,6.6,10.3,6.6,8.2,6.6z')
      .attr('fill', deltaValue < 0 ? '#EC407A': '#44A9DF')
      .text(deltaValue)

      // Plan
      deltaInfo
      .append('text')
      .attr('x', 22).attr('y', 40).attr('font-size', 10)
      .attr('fill', '#bcbcbc').attr('text-anchor', 'start')
      .text(`Plan`)

      deltaInfo
      .append('text')
      .attr('id', 'PLAN_VALUE')
      .attr('x', 45).attr('y', 40).attr('font-size', 12)
      .attr('fill', '#757575').attr('text-anchor', 'start')
      .text(data.PL_REMAIN_CUM)

      // Actual
      deltaInfo
      .append('text')
      .attr('x', 110).attr('y', 40).attr('font-size', 10)
      .attr('fill', '#bcbcbc').attr('text-anchor', 'start')
      .text(`Actual`)

      deltaInfo
      .append('text')
      .attr('id', 'ACTUAL_VALUE')
      .attr('x', 145).attr('y', 40).attr('font-size', 12)
      .attr('fill', '#757575').attr('text-anchor', 'start')
      .text(data.REMAIN_CUM)
    }

  }
}