import * as d3 from 'd3'

export default {
  methods: {

    call_Unit(unit, unit_no) {
      let dataSource = {KEY1: unit, KEY2: unit_no}

      let reqtext = `JSONUNIT`

      let request_ = this.getRequestColumnProps(reqtext, dataSource, this.ColumnProps)
      if(!request_) return

      let filterString_ = ''
      
      if(request_.iFilters.filterString) {
        request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
      } else {
        request_.iFilters.filterString = filterString_||''
      }
      
      request_ = {...request_,
        filters : {
          ...this.FilterValues,
          ...request_.filters,
        },
        iFilters: { 
          filterString : request_.iFilters.filterString||'',
          inputFilter : request_.iFilters.inputFilter,},
      }
      this.$emit('request-action', request_)
    },


    call_List(unit) {
      let dataSource = {KEY1: unit}

      let reqtext = `JSONLIST`

      let request_ = this.getRequestColumnProps(reqtext, dataSource, this.ColumnProps)
      if(!request_) return

      let filterString_ = ''
      
      if(request_.iFilters.filterString) {
        request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
      } else {
        request_.iFilters.filterString = filterString_||''
      }
      
      request_ = {...request_,
        filters : {
          ...this.FilterValues,
          ...request_.filters,
        },
        iFilters: { 
          filterString : request_.iFilters.filterString||'',
          inputFilter : request_.iFilters.inputFilter,},
      }
      this.$emit('request-action', request_)
    },


    call_Subsystem(unit, subsys) {
      let dataSource = {KEY1: unit, KEY2: subsys}

      let reqtext = `JSONSUBSYS`

      let request_ = this.getRequestColumnProps(reqtext, dataSource, this.ColumnProps)
      if(!request_) return

      let filterString_ = ''
      
      if(request_.iFilters.filterString) {
        request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
      } else {
        request_.iFilters.filterString = filterString_||''
      }
      
      request_ = {...request_,
        filters : {
          ...this.FilterValues,
          ...request_.filters,
        },
        iFilters: { 
          filterString : request_.iFilters.filterString||'',
          inputFilter : request_.iFilters.inputFilter,},
      }
      this.$emit('request-action', request_)
    },


    call_Sheet(unit_no, sht, status) {
      let dataSource = {KEY1: unit_no}

      let reqtext = `JSONITR`

      let request_ = this.getRequestColumnProps(reqtext, dataSource, this.ColumnProps)
      if(!request_) return

      let filterString_ = ''

      if (status == 'TOT') {
        filterString_ += `[CATEGORY] = '${sht}'`
      } else if (status == 'ACT') {
        filterString_ += `[CATEGORY] = '${sht}' AND [STATUS] = 'C'`
      } else {
        filterString_ += `[CATEGORY] = '${sht}' AND [STATUS] <> 'C'`
      }
      
      if(request_.iFilters.filterString) {
        request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
      } else {
        request_.iFilters.filterString = filterString_||''
      }
      
      request_ = {...request_,
        filters : {
          ...this.FilterValues,
          ...request_.filters,
        },
        iFilters: { 
          filterString : request_.iFilters.filterString||'',
          inputFilter : request_.iFilters.inputFilter,},
      }
      this.$emit('request-action', request_)
    },



    link_Action(link_) {
      // set for link key value   ex) let dataSource = {KEY1:d.PKG,KEY2:d.DISC...}
      let dataSource = link_.code == 'UNIT'? dataSource = {KEY1: link_.scope, KEY2: link_.unit, KEY3: link_.unit_No}:{}
      // if delValue is 'Y', set dataSource
      // if(this.delValue.length > 0){
      //   this.delValue.forEach((d, i) => {
      //     dataSource = {...dataSource, [`KEY${i+1}`]: this.rawData[_style.idx][d]}
      //   })
      // }
      let reqtext = `JSON${(link_.code =='SSMCC'||link_.code =='WD')? 'SSMCC':link_.code}`  // JSONSSMCC , JSONWD, JSONA, JSONAP,JSONUNIT
      // set for request from A/G jsonProps
      let request_ = this.getRequestColumnProps(reqtext, dataSource, this.ColumnProps)
      if(!request_) return
      // if filterCol is exist, set filterString_
      let filterString_ = ''

      let scope_ = link_.scope == 'Grassroots'? 'Grassroots' : link_.scope == 'Existing Site'? 'Existing Site' : 'OV'
      let coltype_ = link_.coltype
      let code_ = link_.code
      let unit_ = link_.unit

      // Grassroots UNIT SUMMARY
      if( code_ == 'SSMCC' || link_.code == 'WD') {
        if( coltype_ == 'TOT'){
          filterString_ += `[UNIT] = '${unit_}' AND [SSMCC_TOT] = '${coltype_}'`
        } else if ( code_ == 'SSMCC' & coltype_ == 'ACT'){
          filterString_ += `[UNIT] = '${unit_}' AND [SSMCC_ACT] = '${coltype_}'`
        } else if ( code_ == 'SSMCC' & coltype_ == 'REM'){
          filterString_ += `[UNIT] = '${unit_}' AND [SSMCC_ACT] = ''`
        } else if ( code_ == 'WD' & coltype_ == 'ACT'){
          filterString_ += `[UNIT] = '${unit_}' AND [SSMCC_WD] = '${code_}'`
        } else if ( code_ == 'WD' & coltype_ == 'REM'){
          filterString_ += `[UNIT] = '${unit_}' AND [SSMCC_WD] = ''`
        }
      } else if ( code_ == 'UNIT') {
        filterString_ += `[SCOPE] = '${scope_}'`
      // SHEET & PUNCH
      } else {
        code_ = (link_.code == 'A'||link_.code == 'B')? link_.code : link_.code.substr(0,1)

        if(coltype_ == 'TOT'){
          filterString_ += `[UNIT] = '${unit_}' AND [CATEGORY] = '${code_}'`
        } else if(coltype_ == 'ACT'){
          filterString_ += `[UNIT] = '${unit_}' AND [CATEGORY] = '${code_}' AND [STATUS] = 'C'`
        } else {
          filterString_ += `[UNIT] = '${unit_}' AND [CATEGORY] = '${code_}' AND [STATUS] <> 'C'`
        }
      }
      // if A/G iFilters is exist, (filterString_ + iFilters)
      if(request_.iFilters.filterString) {
        request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
      } else {
        request_.iFilters.filterString = filterString_||''
      }
      
      // combine filterString 
      request_ = {...request_,
        filters : {
          ...this.FilterValues,
          ...request_.filters,
        },
        iFilters: { 
          filterString : request_.iFilters.filterString||'',
          inputFilter : request_.iFilters.inputFilter,},
      }
      this.$emit('request-action', request_)
    },
  }
}