const math = require('mathjs')

export default {
  methods: {

    func_QuantiryCurve(totalVol, minVol, maxVol) {
      function findQuadraticCoefficients(x1, y1, x2, y2, x3, y3) {
          // 행렬 A 구성
          const A = math.matrix([
              [Math.pow(x1, 2), x1, 1],
              [Math.pow(x2, 2), x2, 1],
              [Math.pow(x3, 2), x3, 1]
          ])
      
          // 결과 벡터 B 구성
          const B = math.matrix([
              [y1],
              [y2],
              [y3]
          ])
      
          // 행렬 A의 역행렬 계산
          const coefficients = math.multiply(math.inv(A), B)
      
          // 계수 배열 반환
          return coefficients._data
      }

      let minDuration = 0         // <-- 수정 가능
      // let totalVol = 51234           // <-- 수정 가능
      // let minVol = 750            // <-- 수정 가능
      // let maxVol = 750           // <-- 수정 가능
      
      let du = 1
      let roundedResult = []
      let total = 0
      let gap = 0
      // total이 totalVol을 넘는 지점의 d(duration)을 구하기
      for (du; du < 1000; du++) {
        let maxDuration = du
        let x1 = minDuration
        let y1 = minVol
        let x2 = (maxDuration + x1) / 2
        let y2 = maxVol
        let x3 = maxDuration
        let y3 = minVol
        // 예시: 세 점을 통해 이차방정식 계수 구하기
        const coefficients = findQuadraticCoefficients(x1, y1, x2, y2, x3, y3)
        
        let a = coefficients[0][0], b = coefficients[1][0], c = coefficients[2][0]

        for (let i = 0; i < du+1; i++) {
          let result = a * i * i + b * i + c
          total = total + result
          roundedResult[i] = Math.round(result)
        }
        if (total > totalVol) {
          gap = Math.round((total - totalVol) / (du-2))
          
          for (let k = 1; k < du-1; k++) {
            roundedResult[k] = roundedResult[k] - gap
          }

          break
        }
        total = 0
      }

    let result = 0
    roundedResult.forEach(d => {
      result = result + d
    })
    
    let tGap = totalVol - result
    
    if (tGap !== 0) {
      if (tGap > 0) { for (let i = 0; i < tGap; i++) {roundedResult[du - tGap + i] = roundedResult[du - tGap + i] + 1}}
      else if (tGap < 0) { for (let i = 0; i < (tGap * -1); i++) {roundedResult[du + tGap + i] = roundedResult[du + tGap + i] - 1}}
      
      result = 0
      roundedResult.forEach(d => {
        result = result + d
      })
    }
      // console.log(roundedResult)                  // 각 duration일 때 task Vol의 값
      // console.log('duration : ' + du)             // max duration
      // console.log('result : ' + result)           // max duration까지 task Vol의 총 합

      return roundedResult
    },

    
  }
}