import gmx_zMixins         from '../../../../../../includes/Jmixins/zMixins'

// Locally declared common Data-Props & SVG Drawing Support

import lmx_Events                     from './Events'
import lmx_Watching                   from './Watching'

import lmx_LocalValues                from './set_LocalValues'
import lmx_PlanValues                 from './set_PlanValues'
import func_ResourceCurve             from './func_ResourceCurve'
import func_DurationData              from './func_DurationData'
import func_QuantityCurve             from './func_QuantityCurve'

import lmx_Axis                       from './set_Axis'

import lmx_draw_Header                from './draw_Header'
import lmx_header_Hexagon             from './header_Hexagon'
import lmx_header_Trends              from './header_Trends'
import lmx_header_Duration            from './header_Duration'
import lmx_header_Resource            from './header_Resource'

import lmx_draw_Axis                  from './draw_Axis'
import lmx_draw_ChartPlan             from './draw_ChartPlan'
import lmx_draw_Condition             from './draw_Condition'
import lmx_draw_ChartActual           from './draw_ChartActual'
import lmx_draw_ChartQuantity         from './draw_ChartQuantity'


import lmx_draw_popups                from './draw_popups'

export default {
  
  mixins: [
    gmx_zMixins, 

    // Local Mixins
    lmx_Events,
    lmx_Watching,

    lmx_LocalValues,
    lmx_PlanValues,
    func_ResourceCurve,
    func_DurationData,
    func_QuantityCurve,

    lmx_Axis,
    
    lmx_draw_Header,
    lmx_header_Hexagon,
    lmx_header_Trends,
    lmx_header_Duration,
    lmx_header_Resource,

    lmx_draw_Axis,
    lmx_draw_ChartPlan,
    lmx_draw_Condition,
    lmx_draw_ChartActual,
    lmx_draw_ChartQuantity,

    lmx_draw_popups,
  ],

}
