import moment from 'moment'
import * as d3 from 'd3'

export default {
  methods: {

    draw_GantteCharts() {

      let GantteChart = this.Area_Canvas
      .append('g')
      .attr('id', `Charts_Group`)
      .attr('transform', `translate(${this.timeline.x}, ${this.timeline.y + 5.5})`)

      this.Gantt.forEach((d,i)=> {
        let sDate = this.timeline.scale(moment(d.PLAN_MIN))
        let eDate = this.timeline.scale(moment(d.PLAN_MAX))
        let length = eDate - sDate
        let step = 25
        let PRO = (d[`${this.ITR}_PRO`]/100) * length

        let charts = GantteChart
        .append('g')
        .attr('id', `GANTT_${d.UNIT_NO}`)
        .attr('transform', `translate(0,0)`)
        .attr('font-family', 'roboto').style('cursor', 'default').attr('opacity', 1)
        .style('cursor', 'pointer')
        .on('mouseover', ()=> {
          d3.select(`#pieTitle`).transition().duration(150).attr('opacity', 0)
          this.mouse_Event('over', d.UNIT_NO)
          d3.select(`#unitSTATUS_A_TOT`).text(d.A_TOT.toLocaleString('ko-KR'))
          d3.select(`#unitSTATUS_A_ACT`).text(d.A_ACT.toLocaleString('ko-KR'))
          d3.select(`#unitSTATUS_A_REM`).text(d.A_REM.toLocaleString('ko-KR'))
          d3.select(`#unitSTATUS_B_TOT`).text(d.B_TOT.toLocaleString('ko-KR'))
          d3.select(`#unitSTATUS_B_ACT`).text(d.B_ACT.toLocaleString('ko-KR'))
          d3.select(`#unitSTATUS_B_REM`).text(d.B_REM.toLocaleString('ko-KR'))
          d3.select(`#unitSTATUS`).transition().duration(150).attr('opacity', 1)
        })
        .on('mouseout', ()=> {
          this.mouse_Event('out', d.UNIT_NO)
          d3.select(`#unitSTATUS`).transition().duration(150).attr('opacity', 0)
          d3.select(`#pieTitle`).transition().duration(150).attr('opacity', 1)
        })
        .on('click', ()=> {
          this.call_Unit(d.UNIT, d.UNIT_NO) // --> Event.js
        })

        charts
        .append('rect')
        .attr('x', sDate).attr('y', i*step + 30).attr('opacity', .5)
        .attr('width', length).attr('height', 3).attr('fill', '#E0E0DF')

        charts
        .append('rect')
        .attr('x', sDate).attr('y', i*step + 30)
        .attr('width', PRO).attr('height', 3).attr('fill', this.color21[i])

        // Progress
        charts
        .append('text')
        .attr('x', sDate + length+ 3).attr('y', i*step + 35)
        .attr('font-size', 8.5).attr('fill', '#757575')
        .attr('text-anchor', 'start')
        .text(()=> {
          let date = d.PLAN_MAX.split('-').map(Number)
          return `${date[2]}/${date[1]}`
        })

        // .text((d.TO_PRO*100).toFixed(1) + '%')

        // UNIT name
        charts
        .append('text')
        .attr('x', sDate - 2).attr('y', i*step + 35)
        .attr('font-size', 10).attr('fill', '#000').attr('text-anchor', 'end')
        .text(d.UNIT)
      })

    },






    draw_SheetA(y_) {

      let sheetA = this.Area_Canvas
      .append('g')
      .attr('transform', `translate(800, ${y_})`)
      .attr('font-family', 'roboto').style('cursor', 'default')

      sheetA
      .append('text')
      .attr('x', 0).attr('y', 0)
      .attr('font-size', 12).attr('fill', '#333').attr('text-anchor', 'start')
      .text(`A ITRs`)

      sheetA
      .append('line')
      .attr('x1', 0).attr('y1', 3).attr('x2', 280).attr('y2', 3)
      .attr('stroke', '#757575').attr('stroke-width', 1).attr('opacity', .3)
    },
   
    
    
  }
}