import * as d3 from 'd3'

export default {
  methods: {

    header_Sheets() {
      let Overall = this.Area_Canvas
      .append('g')
      .attr('transform',`translate(20, 90)`)
      .style('font-family', 'roboto').style('cursor', 'default')

      // A Check Box for Overall --------------------------------------------
      let Asheet = Overall
      .append('g')
      .attr('id', `CHECK_GROUP_A_OV`)
      .attr('transform',`translate(0, 0)`)
      .attr('cursor', 'pointer').attr('opacity', 1)
      .on('mouseover', function() {
        d3.select(this).attr('opacity', .5)
      })

      .on('mouseout', function() {
        d3.select(this).attr('opacity', 1)
      })
      .on('click', ()=> {
        // let d = this.Discipline.find(f=> f.ITEM == 'A' && f.DISC == 'OV')
        // if(d.checked) {
        //   d3.select(`#DISC_A_OV`).attr('opacity', 0)
        //   d.checked = false
        // } else {
        //   d3.select(`#DISC_A_OV`).attr('opacity', 1)
        //   d.checked = true
        // }
        //for pdf
        this.sheet_Check({ITEM:'A',DISC:'OV',ROUTE:'SCREEN',CHECKED:''})
        //this.reset_Charts()
      })
      Asheet
      .append('text')
      .attr('transform',`translate(25, 38)`)
      .attr('font-size', 10).attr('fill', '#333').attr('text-anchor', 'start')
      .text(`A Sheet`)

      Asheet
      .append('rect')
      .attr('x', 11).attr('y', 29)
      .attr('width', 10).attr('height', 10).attr('fill', '#fff').attr('stroke-opacity', .2)
      .attr('stroke', '#000').attr('stroke-width', .5)

      Asheet
      .append('path')
      .attr('id', `DISC_A_OV`)
      .attr('transform',`translate(6, 13)`)
      .attr('d', `M7,${20} 9,${24} 13,${18}`).attr('opacity', 1)
      .attr('fill', 'none').attr('stroke', '#333').attr('stroke-width', 0.5)

      // B Check Box for Overall --------------------------------------------
      let Bsheet = Overall
      .append('g')
      .attr('id', `CHECK_GROUP_B_OV`)
      .attr('transform',`translate(240, 0)`)
      .attr('cursor', 'pointer').attr('opacity', 1)
      .on('mouseover', function() {
        d3.select(this).attr('opacity', .5)
      })

      .on('mouseout', function() {
        d3.select(this).attr('opacity', 1)
      })
      .on('click', ()=> {
        // let d = this.Discipline.find(f=> f.ITEM == 'B' && f.DISC == 'OV')
        // if(d.checked) {
        //   d3.select(`#DISC_B_OV`).attr('opacity', 0)
        //   d.checked = false
        // } else {
        //   d3.select(`#DISC_B_OV`).attr('opacity', 1)
        //   d.checked = true
        // }
        //for pdf
        this.sheet_Check({ITEM:'B',DISC:'OV',ROUTE:'SCREEN',CHECKED:''})
        // this.reset_Charts()
      })
      Bsheet
      .append('text')
      .attr('transform',`translate(25, 38)`)
      .attr('font-size', 10).attr('fill', '#333').attr('text-anchor', 'start')
      .text(`B Sheet`)

      Bsheet
      .append('rect')
      .attr('x', 11).attr('y', 29)
      .attr('width', 10).attr('height', 10).attr('fill', '#fff').attr('stroke-opacity', .2)
      .attr('stroke', '#000').attr('stroke-width', .5)

      Bsheet
      .append('path')
      .attr('id', `DISC_B_OV`)
      .attr('transform',`translate(6, 13)`)
      .attr('d', `M7,${20} 9,${24} 13,${18}`).attr('opacity', 1)
      .attr('fill', 'none').attr('stroke', '#333').attr('stroke-width', 0.5)

      this.sheet_Summary(Overall, {
        x: 10,
        main: 'A ITRs',
        sub: 'Status of A ITRs & Punch A',
        sht: ['A', 'AP'],
        bColor: ['#83D2F5', '#bcbcbc'],
      })

      this.sheet_Summary(Overall, {
        x: 225,
        main: 'B ITRs',
        sub: 'Status of B ITRs & Punch B',
        sht: ['B', 'BP'],
        bColor: ['#d0d01e', '#bcbcbc'],
      })
    },

    sheet_Check(__d){
      let d = this.Discipline.find(f=> f.ITEM == __d.ITEM && f.DISC == __d.DISC)
      //화면처리
      if(__d.ROUTE=='SCREEN'){
        if(d.checked) {
          d3.select(`#DISC_${__d.ITEM}_OV`).attr('opacity', 0)
          d.checked = false
        } else {
          d3.select(`#DISC_${__d.ITEM}_OV`).attr('opacity', 1)
          d.checked = true
        }
        if(__d.ITEM == 'A'){
          this.pdfCheckstatus.A_Check = d.checked
          this.pdfCheckstatus.A_Status = 'ON'
        } else {
          this.pdfCheckstatus.B_Check = d.checked
          this.pdfCheckstatus.B_Status = 'ON'
        }
      //PDF
      } else {
        // let d = this.Discipline.find(f=> f.ITEM == 'A' && f.DISC == 'OV')
        // console.log(d)
        d3.select(`#DISC_${__d.ITEM}_OV`).attr('opacity', __d.CHECKED?1:0)
        d.checked = __d.CHECKED
      }

      this.reset_Charts()
    },

    sheet_Summary(selection, d) {

      let sheets = selection
      .append('g')
      .attr('transform',`translate(${d.x}, 0)`)
      .style('font-family', 'roboto').style('cursor', 'default')

      sheets
      .append('text')
      .attr('transform',`translate(0, 5)`).attr('font-size', 11).attr('fill', '#000').attr('text-anchor', 'start')
      .text(d.main)

      sheets
      .append('text')
      .attr('transform',`translate(0, 15)`).attr('font-size', 9).attr('fill', '#BDBCBC').attr('text-anchor', 'start')
      .text(d.sub)

      this.sheet_BarChart(selection, {x: d.x, y: 40, sht: d.sht[0], color: d.bColor[0]})
      this.sheet_BarChart(selection, {x: d.x, y: 85, sht: d.sht[1], color: d.bColor[1]})
    },

    sheet_BarChart(selection, d) {
      
      let sheets = selection
      .append('g')
      .attr('transform',`translate(${d.x+25}, ${d.y})`)

      sheets
      .append('text')
      .attr('transform',`translate(-10, -2)`)
      .attr('font-size', 10).attr('fill', '#000').attr('text-anchor', 'start')
      .text(()=> {
        // if(d.sht == 'A' || d.sht == 'B') return `${d.sht} Sheet`
        if(d.sht == 'AP' || d.sht == 'BP') return `Punch ${d.sht.charAt(0)}`
      })

      sheets
      .append('text')
      .attr('transform',`translate(38, 17)`)
      .attr('font-size', 10).attr('fill', '#333').attr('text-anchor', 'end')
      .attr('cursor', 'pointer')
      .text(this.Overall[`${d.sht}_TOT`])
      .call(this.call_ITRs, {item: d.sht, type: 'TOT'})
      
      sheets
      .append('rect')
      .attr('x', 40).attr('y', 7.5).attr('width', 100).attr('height', 12)
      .attr('fill', '#bcbcbc').attr('opacity', .3)

      sheets
      .append('rect')
      .attr('x', 40).attr('y', 7.5)
      .attr('width', this.Overall[`${d.sht}_PRO`]).attr('height', 12)
      .attr('fill', d.color).attr('opacity', 1)

      let PRO = this.Overall[`${d.sht}_PRO`]
      sheets
      .append('text')
      .attr('x',  PRO > 60 ? 38 + PRO : 42 + PRO)
      .attr('y', 17)
      .attr('font-size', 10).attr('fill', '#333')
      .attr('text-anchor', PRO > 60 ? 'end' : 'start')
      .attr('cursor', 'pointer')
      .text(this.Overall[`${d.sht}_ACT`])
      .call(this.call_ITRs, {item: d.sht, type: 'ACT'})

      sheets
      .append('text')
      .attr('transform',`translate(142, 17)`)
      .attr('font-size', 10).attr('fill', '#333').attr('text-anchor', 'start')
      .attr('cursor', 'pointer')
      .text(this.Overall[`${d.sht}_REM`])
      .call(this.call_ITRs, {item: d.sht, type: 'REM'})
    },

  }
}