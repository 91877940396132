import gmx_zMixins         from '../../../../../../includes/Jmixins/zMixins'

// Locally declared common Data-Props & SVG Drawing Support

import lmx_Events                     from './Events'
import lmx_Watching                   from './Watching'

import lmx_LocalValues                from './set_LocalValues'
import lmx_draw_Forms                 from './draw_Forms'
import lmx_draw_Overall               from './draw_Overall'
import lmx_draw_BarCharts             from './draw_BarCharts'
import lmx_draw_Summary               from './draw_Summary'



export default {
  
  mixins: [
    gmx_zMixins, 

    // Local Mixins
    lmx_Events,
    lmx_Watching,

    lmx_LocalValues,
    lmx_draw_Forms,
    lmx_draw_Overall,
    lmx_draw_BarCharts,
    lmx_draw_Summary,
  ],

}
