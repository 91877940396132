import gmx_zMixins         from '../../../../../../includes/Jmixins/zMixins'

// Locally declared common Data-Props & SVG Drawing Support

import lmx_Events                     from './Events'
import lmx_Watching                   from './Watching'

import lmx_LocalValues                from './set_LocalValues'
import lmx_draw_Forms                 from './draw_Forms'
import lmx_draw_Summary               from './draw_Summary'

import lmx_draw_RadarChart            from './draw_RadarChart'
import lmx_draw_Trends                from './draw_Trends'
import lmx_draw_Weeks                 from './draw_Weeks'

import lmx_draw_GR_Radial             from './draw_GR_Radial'
import lmx_draw_GR_RadialChart        from './draw_GR_RadialChart'

import lmx_draw_ES_Radial             from './draw_ES_Radial'

export default {
  
  mixins: [
    gmx_zMixins, 

    // Local Mixins
    lmx_Events,
    lmx_Watching,

    lmx_LocalValues,
    lmx_draw_Forms,
    lmx_draw_Summary,

    lmx_draw_RadarChart,
    lmx_draw_Trends,
    lmx_draw_Weeks,

    lmx_draw_GR_Radial,
    lmx_draw_GR_RadialChart,

    lmx_draw_ES_Radial,
  ],

}
