export default {
  
  methods: {

    // BAR CHART 
    head_BarCharts(selection, d) {
      let data = this.Overall
      let s = {
        top: 65,
        barH: 120,
        barW: 40,
        pLeft: 15,
        pRight: 15,
      }

      let barChart = selection
      .append('g')
      .attr('transform', `translate(-5, 0)`)
      .style('font-family', 'roboto')

      barChart
      .append('text')
      .attr('transform',`translate(10, 25)`)
      .attr('font-size', 11).attr('fill', '#000').attr('text-anchor', 'start')
      .text(d.title)

      barChart
      .append('text')
      .attr('transform',`translate(10, 35)`)
      .attr('font-size', 9).attr('fill', '#BDBCBC').attr('text-anchor', 'start')
      .text('ITRs Status by Discipline')

      // BAR CHART - Background
      barChart
      .append('rect')
      .attr('x', s.pLeft).attr('y', s.top)
      .attr('rx', 3).attr('ry', 3)
      .attr('fill', d.color).attr('opacity', .3)
      .attr('width', s.barW).attr('height',s.barH)

       // BAR CHART - Actual bar chart
      let pro = data[`${d.item}_PRO`]
      let proLev =  s.top + s.barH - (s.barH*(pro/100))
      barChart
      .append('rect')
      .attr('x', s.pLeft).attr('y', proLev)
      .attr('rx', 3).attr('ry', 3)
      .attr('fill', `url(#${this.localId}_${d.bColor}_V)`)
      .attr('width', s.barW).attr('height', s.barH*(pro/100))

      // Value - Progress
      barChart 
      .append('text')
      .attr('x', s.pLeft + s.barW/2)
      .attr('y', pro > 20 ? proLev + 3 : proLev - 3)
      .attr('font-size', 9).attr('text-anchor', 'middle')
      .attr('fill', '#fff')
      .attr('alignment-baseline', pro > 20 ? 'hanging' :'alphabetic') // alphabetic
      .text(pro.toFixed(1) + '%')
      
      // Value - Total 
      barChart 
      .append('text')
      .attr('x', s.pLeft + s.barW/2).attr('y', s.top - 3)
      .attr('font-size', 9).attr('text-anchor', 'middle')
      .attr('fill', '#757575')
      .attr('alignment-baseline', 'alphabetic') // alphabetic
      .attr('cursor', 'pointer')
      .text(data[`${d.item}_TOT`])
      .call(this.call_ITRs, {item: d.item, type: 'TOT'})
      
      // Value - Actual
      barChart
      .append('text')
      .attr('x', s.pLeft + s.barW + s.pRight - 5).attr('y', s.top + s.barH - (pro/2))
      .attr('font-size', 9).attr('fill', '#757575').attr('alignment-baseline', 'middle')
      .attr('cursor', 'pointer')
      .text(data[`${d.item}_ACT`])
      .call(this.call_ITRs, {item: d.item, type: 'ACT'})

       // Value - Remain
      barChart
      .append('text')
      .attr('x', s.pLeft + s.barW + s.pRight - 5).attr('y', s.top + (s.barH-pro)/2)
      .attr('font-size', 9).attr('fill', '#757575').attr('alignment-baseline', 'middle')
      .attr('cursor', 'pointer')
      .text(data[`${d.item}_REM`])
      .call(this.call_ITRs, {item: d.item, type: 'REM'})
      
       // LINE ------------------------------
      barChart
      .append('line')
      .attr('x1', s.pLeft + s.barW + 5.5).attr('y1', s.top +.5).attr('x2', s.pLeft + s.barW + 5.5).attr('y2', s.top + .5 + s.barH)
      .attr('stroke', '#bcbcbc').attr('stroke-width', .4)

      barChart
      .append('line')
      .attr('x1', s.pLeft + s.barW + 2).attr('y1', s.top-.5).attr('x2', s.pLeft + s.barW + 12).attr('y2', s.top-.5)
      .attr('stroke', '#bcbcbc').attr('stroke-width', .4)

      barChart
      .append('line')
      .attr('x1', s.pLeft + s.barW + 2).attr('y1', s.top + s.barH - pro).attr('x2', s.pLeft + s.barW + 12).attr('y2', s.top + s.barH - pro)
      .attr('stroke', '#bcbcbc').attr('stroke-width', .4)

      barChart
      .append('line')
      .attr('x1', s.pLeft + s.barW + 2).attr('y1', s.top + s.barH).attr('x2', s.pLeft + s.barW + 12).attr('y2', s.top + s.barH)
      .attr('stroke', '#bcbcbc').attr('stroke-width', .4)
    },
    
  }
}