import * as d3 from 'd3'

export default {
  methods: {
    
    exe_Expansion_All(d) {

      let plusY = this.Summary.height * this.rawData.filter(f=> f.GROUP == d.name && f.bind == 'closed').length

      // Changing Canvas and _canvas_border_background__ Size 
      this.Canvas.CanvasHeight += plusY
      d3.select(`.canvas`).attr('height', this.Canvas.CanvasHeight)
      d3.select(`._canvas_border_background__${this.localId}`).transition().duration(150).attr('height', this.Canvas.CanvasHeight)
      d3.selectAll(`.tLine`).attr('y2', this.Canvas.CanvasHeight - 100)

      this.MilestoneGroup.forEach((group, g)=> {

        // Click Group보다 아래의 Group들 처리
        if(d.idx < g) { 
          group.y += plusY
          d3.select(`#MS_GROUP_${g}`).transition().duration(150).attr('transform', `translate(${this.Gantt.gx}, ${group.y})`)

          this.rawData.filter(f=> f.GROUP == group.name).forEach(item => {
            item.y += plusY
            d3.select(`#ITEM_GROUP_${item.CODE}`).transition().duration(150).attr('transform', `translate(${this.Gantt.ix}, ${item.y})`)
            d3.select(`#SUMMARY_ITEM_${item.CODE}`).transition().duration(150).attr('transform', `translate(${this.Gantt.ix}, ${item.y})`)
          })
        }

        // Click Group일 경우 
        if(d.idx == group.idx) { 

          group.bind = 'open' 
          d3.select(`#ICONS_ALL_${d.idx}`).attr('d', this.icons.reduce)

          let cumul_Y = 0
          this.rawData.filter(f=> f.GROUP == group.name).forEach((item, i) => {
            item.y += cumul_Y
            if(i>0) { // 첫번째는 제외됨
              d3.select(`#ITEM_GROUP_${item.CODE}`).transition().duration(150).attr('transform', `translate(${this.Gantt.ix}, ${item.y})`)
              setTimeout(() => {
                d3.select(`#SUMMARY_ITEM_${item.CODE}`).attr('transform', `translate(${this.Gantt.ix}, ${item.y})`).style('visibility', 'visible')
              }, 100)
            }
            if(item.bind == 'closed') cumul_Y += this.Summary.height

            item.bind = 'open'
            setTimeout(() => {
              d3.select(`#ITEM_ICON_${item.CODE}`).text('-')
              d3.select(`#SUMMARY_ITEM_${item.CODE}`).transition().duration(150).style('visibility', 'visible')
              d3.select(`#ITEM_NAME_${item.CODE}`).attr('fill', '#44A9DF')
            }, 100)

            
          })
        }
      })
      this.pdf_selectedItems() // ----- for PDF
    },



    exe_Reduced_All(d) {

      let minusY = this.Summary.height * this.rawData.filter(f=> f.GROUP == d.name && f.bind == 'open').length

      // Changing Canvas and _canvas_border_background__ Size 
      this.Canvas.CanvasHeight -= minusY
      d3.select(`.canvas`).attr('height', this.Canvas.CanvasHeight)
      d3.select(`._canvas_border_background__${this.localId}`).transition().duration(150).attr('height', this.Canvas.CanvasHeight)
      d3.selectAll(`.tLine`).attr('y2', this.Canvas.CanvasHeight - 100)

      this.MilestoneGroup.forEach((group, g)=> {

        // Click Group보다 아래의 Group들 처리
        if(d.idx < g) { 
          group.y -= minusY
          d3.select(`#MS_GROUP_${g}`).transition().duration(150).attr('transform', `translate(${this.Gantt.gx}, ${group.y})`)

          this.rawData.filter(f=> f.GROUP == group.name).forEach(item => {
            item.y -= minusY
            d3.select(`#ITEM_GROUP_${item.CODE}`).transition().duration(150).attr('transform', `translate(${this.Gantt.ix}, ${item.y})`)
            d3.select(`#SUMMARY_ITEM_${item.CODE}`).attr('transform', `translate(${this.Gantt.ix}, ${item.y})`)
          })
        }

        // Click Group일 경우
        if(d.idx == group.idx) { 
          
          group.bind = 'closed' 
          d3.select(`#ICONS_ALL_${d.idx}`).attr('d', this.icons.expand)

          let cumul_Y = 0
          this.rawData.filter(f=> f.GROUP == group.name).forEach((item, i) => {
            item.y -= cumul_Y
            if(i>0) { // 첫번째는 제외됨
              d3.select(`#ITEM_GROUP_${item.CODE}`).transition().duration(150).attr('transform', `translate(${this.Gantt.ix}, ${item.y})`)
              d3.select(`#SUMMARY_ITEM_${item.CODE}`).attr('transform', `translate(${this.Gantt.ix}, ${item.y})`).style('visibility', 'hidden')
            }
            if(item.bind == 'open') cumul_Y += this.Summary.height

            item.bind = 'closed'
            d3.select(`#ITEM_ICON_${item.CODE}`).text('+')
            d3.select(`#SUMMARY_ITEM_${item.CODE}`).transition().duration(150).style('visibility', 'hidden')
            d3.select(`#ITEM_NAME_${item.CODE}`).attr('fill', '#333')
          })
        }

      })
      this.pdf_selectedItems() // ----- for PDF
    },

  }
}
