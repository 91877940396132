import * as d3 from 'd3'

export default {

  methods: {

    draw_Header() {

      this.formSvg = this.svg
      
      this.formSvg
      .append('text')
      .attr('transform', `translate(${this.timeline.baseX}, 50)`)
      .style('font-family', 'roboto').style('font-size', 32).style('fill', 'skyblue')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(this.skyline.titleMain)

      this.formSvg
      .append('text')
      .attr('transform', `translate(${this.timeline.baseX}, 75)`)
      .style('font-size', 12)
      .style('fill', '#757575')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(this.skyline.titleSub)
    },

    
    draw_Legends() {
      this.legendGroup = this.svg
      .append('g')
      .attr('id', 'Legend')
      .attr('transform', `translate(${this.legends.x}, ${this.legends.y})`)

      this.legendGroup
      .append('text')
      .attr('transform', `translate(0, 35)`)
      .style('font-size', 12).style('fill', '#000').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(`Legends`) 

      this.legend_Sheet()     // ---> below
    },


    legend_Sheet() {
      let x_ = 0

      let legendGroup = this.legendGroup
        .append('g')
        .attr('id', `Sheet_Group`)
        .attr('transform', `translate(0, 55)`)

      this.sheet.styles.forEach((s, i) => {
         let legendItem = legendGroup
        .append('g')
        .attr('id', `Sheet_${s.status}`)
        .attr('transform', `translate(${x_ + 18}, 0)`)
        .attr('font-family', 'roboto')
        .style('opacity', this.legends.sheetStatus == s.status ? 1 : 0.7)
        .style('cursor', 'pointer')
        .on('mouseover', () => { 
          d3.select(`#Sheet_${s.status}`).style('opacity', 0.3) 
        })
        .on('mouseout', () => { 
          d3.select(`#Sheet_${s.status}`).style('opacity', this.legends.sheetStatus == s.status ? 1 : 0.7) 
        })
        .on('click', ()=> {
          d3.select(`#Sheet_${this.legends.sheetStatus}`).style('opacity', 0.7)
          d3.select(`#Sheet_text_${this.legends.sheetStatus}`).attr('font-weight', 400).attr('fill', this.sheet.titleColor)
          
          this.legends.sheetStatus = s.status
          d3.select(`#Sheet_${s.status}`).style('opacity', 1)
          d3.select(`#Sheet_text_${s.status}`).attr('font-weight', 600).attr('fill', '#000')

          this.filtering_Search('sheet') // ---> Event.js
        })
        
        legendItem
        .append('rect')
        .attr('id', `Sheet_box_${s.status}`)
        .attr('transform', `translate(-5, -6)`)
        .attr('rx', 2).attr('ry', 2)
        .attr('width', 12).style('height', 12)
        .attr('stroke', s.sColor)
        .attr('stroke-width', s.sWidth)
        .attr('fill', s.color)

        legendItem
        .append('text')
        .attr('id', `Sheet_text_${s.status}`)
        .attr('transform', `translate(11, 1)`)
        .attr('font-size', 10)
        .attr('font-weight', this.legends.sheetStatus == s.status ? 600 : 400)
        .attr('fill', '#000')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(`${s.text}`)

        x_ += this.getNodeElValue(`#Sheet_${s.status}`, 'width') + 12
      })
    },

    
  }
}