import * as d3 from 'd3'

export default {
  methods: {
    /*
      call_IcicleChart(selection, m)
      call_MilestoneList(selection, d)
      call_SelectAll(selection)
    */

    call_IcicleChart(selection, m) {
      selection
      .on('mouseover', () => {
        selection
        .transition().duration(50)
        .style('opacity', .7)
  
        selection.moveToFront()

        d3.select(`#highlight_${m.MS}`)
        .style('visibility', 'visible')
      })

      .on('mouseout', () => {
        selection
        .transition().duration(50)
        .style('opacity', 1)

        d3.select(`#highlight_${m.MS}`)
        .style('visibility', 'hidden')
      })
    },

    call_MilestoneList(selection, d) {
      selection
      .on('click', ()=> {
        d3.select(`#checked_${d.MS}`)
        .style('visibility', () => {
          if(d.checked == 'Y') {
            d.checked = 'N'
            d3.select(`#icicle_${d.MS}`).style('visibility', 'hidden')
            return 'hidden'
          } else {
            d.checked = 'Y'
            d3.select(`#icicle_${d.MS}`).style('visibility', 'visible')
            return 'visible'
          }
        })
      })
      .on('mouseover', () => {
        selection.transition().duration(150)
        .style('opacity', .7)
        highlight('over', d.MS) // --->
      })
      .on('mouseout', () => {
        selection.transition().duration(150)
        .style('opacity', 1)
        highlight('out', d.MS) // --->
      })

      let self = this
      let highlight = function(event, no) {
        self.msList.forEach( d => {
          if ( event == 'over') {
            d3.select(`#icicle_${d.MS}`).transition().duration(100)
            .style('opacity', d.MS == no ? 1 : .2)

          } else if ( event == 'out') {
            d3.select(`#icicle_${d.MS}`).transition().duration(100)
            .style('opacity', 1)
          }
        })
      }
    },
    
    call_SelectAll(selection) {
      selection
      .on('mouseover', () => {
        selection
        .transition().duration(50).style('opacity', .7)
      })
      .on('mouseout', () => {
        selection
        .transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        if(this.msList.checkStatus == 'select') {
          selectAll()
          this.msList.checkStatus = 'unselect'
          d3.select('#slelectAll')
          .attr('fill', '#B5549B')
          .text('Unselect all')
        } else {
          unselectAll()
          this.msList.checkStatus = 'select'
          d3.select('#slelectAll')
          .attr('fill', '#44A9DF')
          .text('Select all')
        }
      })

      let self = this
      let selectAll = function() {
        self.msList.forEach( d => {
          d.checked = 'Y'
          d3.select(`#checked_${d.MS}`)
          .style('visibility', 'visible')
          d3.select(`#icicle_${d.MS}`)
          .style('visibility', 'visible')
        })
      }

      let unselectAll = function() {
        self.msList.forEach( d => {
          d.checked = 'N'
          d3.select(`#checked_${d.MS}`)
          .style('visibility', 'hidden')
          d3.select(`#icicle_${d.MS}`)
          .style('visibility', 'hidden')
        })
      }
    },

    link_Action(link_) {
      // set for link key value   ex) let dataSource = {KEY1:d.PKG,KEY2:d.DISC...}
      let dataSource = link_.code == 'UNIT'? dataSource = {KEY1: link_.scope, KEY2: link_.unit, KEY3: link_.unit_No}:{}
      // if delValue is 'Y', set dataSource
      // if(this.delValue.length > 0){
      //   this.delValue.forEach((d, i) => {
      //     dataSource = {...dataSource, [`KEY${i+1}`]: this.rawData[_style.idx][d]}
      //   })
      // }
      let reqtext = `JSON${(link_.code =='SSMCC'||link_.code =='WD')? 'SSMCC':link_.code}`  // JSONSSMCC , JSONWD, JSONA, JSONAP,JSONUNIT
      // set for request from A/G jsonProps
      let request_ = this.getRequestColumnProps(reqtext, dataSource, this.ColumnProps)
      if(!request_) return

      // if filterCol is exist, set filterString_
      let filterString_ = ''

      let scope_ = link_.scope == 'Grassroots'? 'Grassroots' : link_.scope == 'Existing Site'? 'Existing Site' : 'OV'
      let coltype_ = link_.coltype
      let code_ = link_.code
      let unit_ = link_.unit

      // Grassroots UNIT SUMMARY
      if( code_ == 'SSMCC' || link_.code == 'WD') {
        if( coltype_ == 'TOT'){
          filterString_ += `[UNIT] = '${unit_}' AND [SSMCC_TOT] = '${coltype_}'`
        } else if ( code_ == 'SSMCC' & coltype_ == 'ACT'){
          filterString_ += `[UNIT] = '${unit_}' AND [SSMCC_ACT] = '${coltype_}'`
        } else if ( code_ == 'SSMCC' & coltype_ == 'REM'){
          filterString_ += `[UNIT] = '${unit_}' AND [SSMCC_ACT] = ''`
        } else if ( code_ == 'WD' & coltype_ == 'ACT'){
          filterString_ += `[UNIT] = '${unit_}' AND [SSMCC_WD] = '${code_}'`
        } else if ( code_ == 'WD' & coltype_ == 'REM'){
          filterString_ += `[UNIT] = '${unit_}' AND [SSMCC_WD] = ''`
        }
      } else if ( code_ == 'UNIT') {
        filterString_ += `[SCOPE] = '${scope_}'`
      // SHEET & PUNCH
      } else {
        code_ = (link_.code == 'A'||link_.code == 'B')? link_.code : link_.code.substr(0,1)

        if(coltype_ == 'TOT'){
          filterString_ += `[UNIT] = '${unit_}' AND [CATEGORY] = '${code_}'`
        } else if(coltype_ == 'ACT'){
          filterString_ += `[UNIT] = '${unit_}' AND [CATEGORY] = '${code_}' AND [STATUS] = 'C'`
        } else {
          filterString_ += `[UNIT] = '${unit_}' AND [CATEGORY] = '${code_}' AND [STATUS] <> 'C'`
        }
      }
      console.log('filterString_',filterString_)
      // if A/G iFilters is exist, (filterString_ + iFilters)
      if(request_.iFilters.filterString) {
        request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
      } else {
        request_.iFilters.filterString = filterString_||''
      }
      
      // combine filterString 
      request_ = {...request_,
        filters : {
          ...this.FilterValues,
          ...request_.filters,
        },
        iFilters: { 
          filterString : request_.iFilters.filterString||'',
          inputFilter : request_.iFilters.inputFilter,},
      }
      this.$emit('request-action', request_)
    },
  }
}