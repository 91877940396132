import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js'

export default {
  methods: {
    init_Scene() {
      this.originalColors = {}
      this.originPosition = {}
      this.originTransform = {}

      // 장면 추가
      this.scene = new THREE.Scene()
      this.scene.background = new THREE.Color(0xffffff)

      const aspect = this.SceneWidth / this.SceneHeight
      const d = 50

      // ISO view 카메라 추가
      this.camera = new THREE.OrthographicCamera(-d * aspect, d * aspect, d, -d , 1, 10000)
      this.camera.position.set(100, 150, 50)
      this.camera.lookAt(new THREE.Vector3(0, 0, 0))

      // 카메라 추가
      // this.camera = new THREE.PerspectiveCamera(50, this.SceneWidth / this.SceneHeight, 1, 10000)
      // this.camera.position.set(100, 150, 50)
      // this.camera.lookAt(new THREE.Vector3(0, 0, 0))

      // 빛 추가
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.9)
      this.scene.add(ambientLight)

      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5)
      directionalLight.position.set(-50, 100, 50).normalize()
      this.scene.add(directionalLight)
      directionalLight.castShadow = true

      // 장면 랜더러 추가
      this.renderer = new THREE.WebGLRenderer({ alpha: true })
      this.renderer.setPixelRatio(window.devicePixelRatio)
      this.renderer.setSize(this.SceneWidth, this.SceneHeight)
      this.renderer.shadowMap.enabled = true
      this.$refs.threeContainer.appendChild(this.renderer.domElement)

      this.controls = new OrbitControls(this.camera, this.renderer.domElement)
      this.controls.minDistance = 10
      this.controls.maxDistance = 250
      this.controls.maxPolarAngle = Math.PI / 2

      // RGBE 로더를 사용하여 HDR 이미지 로드 및 환경 맵 설정
      if (this.rgbeLoader == 'ON') {
        const rgbeLoader = new RGBELoader()
        rgbeLoader.load(`${this.RESOURCE}/3D/hdr/${this.hdrFile}`, (loadedTexture) => {
          loadedTexture.mapping = THREE.EquirectangularReflectionMapping
          this.scene.environment = loadedTexture
          this.texture = loadedTexture // 텍스처를 전역 변수로 저장
        })
      }
      this.load_FBXModel() // FBX 모델 로드

      // 레이캐스터와 마우스 2D좌표 생성
      this.raycaster = new THREE.Raycaster()
      this.mouse = new THREE.Vector2()

      // 마우스 이동 이벤트 추가
      this.$refs.threeContainer.addEventListener('mousemove', this.onMouseMove.bind(this))

      // 마우스 클릭 이벤트 추가
      this.$refs.threeContainer.addEventListener('click', this.onMouseClick.bind(this))
    },
    
    animate() {
      requestAnimationFrame(this.animate.bind(this))
      this.controls.update()
      this.renderer.render(this.scene, this.camera)
    },
  }
}