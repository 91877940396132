import moment from 'moment'
import * as d3 from 'd3'

export default {
  methods: {

    plan_Controller() {

      let barGap = 1
      let barWidth = (this.scale.X.bandwidth()/2)-(barGap/2)
   
      // Call Chart
      let data = this.timeline.week.filter(f=> f.eDate >= new Date(this.timeline.start))

      this.plan_barChart(data, this.scale.Primary, barWidth)
      this.plan_lineChart(data, this.scale.Secondary)
    },


    plan_barChart(_data, scale_, width_) {

      let chart = this.canvas.chart
      let pk = this.condition_List.find(f=> f.count == this.chart_Count)

      this.Area_Charts
      .selectAll(`rect_`)
      .data(_data)
      .enter()
      .append('rect')
      .attr('x', d => this.scale.X(d.eDate) + 0)
      .attr('y', d => scale_(d.value))
      .attr('height', d => chart.height - scale_(d.value))
      .attr('width', width_)
      .attr('fill', this.colors[this.chart_Count])
      .attr('opacity', .4)

      // Peak-Time Plan Average Value
      let startDate = moment(pk.pk_date).add(-(this.peakTime*7), 'days').toDate()
      let finishDate = moment(pk.pk_date).add(+(this.peakTime*7), 'days').toDate()

      this.Area_Charts
      .append('line')
      .attr('x1', this.scale.X(startDate))
      .attr('y1', scale_(pk.pk_avg))
      .attr('x2', this.scale.X(finishDate)+this.scale.X.bandwidth()/2)
      .attr('y2', scale_(pk.pk_avg))
      .attr('stroke', this.colors[this.chart_Count]).attr('stroke-width', .5)

      this.Area_Charts
      .append('rect')
      .attr('x', this.scale.X(startDate)-97)
      .attr('y', scale_(pk.pk_avg)-7)
      .attr('width', 97).attr('height', 13).attr('fill', '#fff')
      .attr('stroke', this.colors[this.chart_Count]).attr('stroke-width', .3)

      this.Area_Charts
      .append('text')
      .attr('x', this.scale.X(startDate)-4).attr('y', scale_(pk.pk_avg))
      .attr('fill', this.status.vColor).attr('font-size', 10)
      .attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text(`${this.peakTime*2+1}weeks Avg.: ${pk.pk_avg}`)

      // Pick Value
      this.Area_Charts
      .append('line')
      .attr('x1', this.scale.X(pk.pk_date))
      .attr('y1', scale_(pk.pk_max))
      .attr('x2', this.scale.X(pk.pk_date) - 25)
      .attr('y2', scale_(pk.pk_max)-25)
      .attr('stroke', this.colors[this.chart_Count]).attr('stroke-width', .3)

      this.Area_Charts
      .append('rect')
      .attr('x', this.scale.X(pk.pk_date)-105)
      .attr('y', scale_(pk.pk_max)-38)
      .attr('width', 80).attr('height', 13).attr('fill', '#fff')
      .attr('stroke', this.colors[this.chart_Count]).attr('stroke-width', .3)

      this.Area_Charts
      .append('text')
      .attr('x', this.scale.X(pk.pk_date) - 65)
      .attr('y', scale_(pk.pk_max)-28)
      .attr('fill', this.status.vColor).attr('font-size', 10).attr('text-anchor', 'middle')
      .text(`Peak Value: ${pk.pk_max}`)
    },


    plan_lineChart(data_, scale_, no_) {
      this.Xband = this.scale.X.bandwidth() / 2

      let lineFunc = d3.line()
      .x(d => this.scale.X(d.eDate) + this.Xband)
      .y(d => scale_(d.pro))

      let this_ = this
      let lineChart = this.Area_Charts
      .append('g')
      .attr('id', `LINE_CHART_${this.chart_Count}`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')
  
      // Draw Chart Tag
      lineChart
      .append('path')
      .attr('d', lineFunc(data_))
      .style('stroke', this.colors[this.chart_Count])
      .style("stroke-dasharray", (2,2))
      .style('stroke-width', 2)
      .attr('opacity', .4)
      .style('fill', 'none')

      lineChart
      .selectAll(`circle_${no_}`)
      .data(data_)
      .enter()
      .append('circle')
      .attr('cx', d => this.scale.X(d.eDate) + this.Xband)
      .attr('cy', d=> scale_(d.pro))
      .attr('r', 1.5)
      .style('stroke', this.colors[this.chart_Count])
      .style('stroke-width', 2)
      .attr('stroke-opacity', .4)
      .style('fill', this.colors[this.chart_Count])


      let from = 45 + (this.chart_Count*2)
      let tag = {
        x1: this.scale.X(data_[from].eDate)+this.Xband,
        y1: scale_(data_[from].pro),
        x2: (this.scale.X(data_[from].eDate)+this.Xband) - 50,
        y2: scale_(data_[from].pro) - 30.5,
      }

      lineChart
      .append('line')
      .attr('x1', tag.x1).attr('y1', tag.y1)
      .attr('x2', tag.x2).attr('y2', tag.y2)
      .attr('stroke', this.colors[this.chart_Count]).attr('stroke-width', .5)

      lineChart
      .append('line')
      .attr('x1', tag.x2).attr('y1', tag.y2)
      .attr('x2', tag.x2-50).attr('y2', tag.y2)
      .attr('stroke', this.colors[this.chart_Count]).attr('stroke-width', .5)

      lineChart
      .append('rect')
      .attr('x', tag.x2-50 - 12).attr('y', tag.y2 - 6)
      .attr('width', 12).attr('height', 12)
      .attr('fill', this.colors[this.chart_Count])
      .attr('stroke', '#bcbcbc').attr('stroke-width', .5)

      lineChart
      .append('text')
      .attr('x', tag.x2-50 - 6).attr('y', tag.y2 +1)
      .attr('font-size', 10).attr('fill', '#333')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(this.chart_Count)
    },

  }
}