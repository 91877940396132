import * as d3 from 'd3'

export default {

  methods: {
    header_HexagonStatus() {

      // Required Values -----------------------------------------------------------------------------------
      let total = this.rawData.map(d=> {return d[this.schedule.code]})
      let actual = this.rawData.map(d=> {return d[`${this.schedule.code.charAt(0)}_ACT`]})
      let system = this.rawData.length
      let current = [
        {title: 'Total',        value: 0},
        {title: 'Actual',       value: 0},
        {title: 'Outstanding',  value: 0},
      ]
      current[0].value = total.reduce((a, b) => (a + b))
      current[1].value = actual.reduce((a, b) => (a + b))
      current[2].value = current[0].value - current[1].value

      current[0].avg = current[0].value/system
      current[1].avg = current[1].value/system
      current[2].avg = current[2].value/system

      // Subsystem Status -----------------------------------------------------------------------------------
      let subsystem = this.svg
      
      .append('g')
      .attr('transform',`translate(20.5, 10.5)`)
      .attr('font-family', 'roboto')
      .style('cursor', 'default')

      subsystem
      .append('text')
      .attr('x', 0).attr('y', 20).attr('font-size', 15)
      .attr('fill', this.status.mColor).attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Subsystem')

      subsystem
      .append('text')
      .attr('x', 0).attr('y', 33).attr('font-size', 10.5)
      .attr('fill', this.status.sColor).attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Total no. of subsystem')

      subsystem
      .append('text')
      .attr('x', 160).attr('y', 55).attr('font-size', 14).attr('font-weight', 400)
      .attr('fill', this.status.vColor).attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text(this.rawData.length)

      subsystem
      .append('text')
      .attr('x', 0).attr('y', 80).attr('font-size', 15)
      .attr('fill', '#fff').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Average')

      subsystem
      .append('text')
      .attr('x', 0).attr('y', 93).attr('font-size', 10.5)
      .attr('fill', this.status.sColor).attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Average no. of checksheet')

      let y_= 110
      current.forEach((d,i)=> {

        let shtItem = subsystem
        .append('g')
        .attr('transform',`translate(0, ${y_})`)

        shtItem
        .append('text')
        .attr('x', 110).attr('y', 20).attr('font-size', 13)
        .attr('fill', this.status.nColor).attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(d.title)

        shtItem
        .append('text')
        .attr('x', 160).attr('y', 20).attr('font-size', 14).attr('font-weight', 400)
        .attr('fill', this.status.vColor).attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(d.avg.toFixed(1))

        y_ += 25
      })


      // Checksheet Status -----------------------------------------------------------------------------------
      let checksheet = subsystem

      .append('g')
      .attr('transform',`translate(180, 0)`)

      checksheet
      .append('text')
      .attr('x', 0).attr('y', 20).attr('font-size', 15)
      .attr('fill', this.status.mColor).attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Checksheet')

      checksheet
      .append('text')
      .attr('x', 0).attr('y', 33).attr('font-size', 10.5)
      .attr('fill', this.status.sColor).attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Total no. of A checksheet')

      checksheet
      .append('path')
      .attr('id', 'QTY_TOTAL')
      .attr('transform',`translate(0, 50) scale(.7)`)
      .attr('d', this.arrow.chart).attr('fill', this.status.vColor)
      .style('cursor', 'pointer')
      .on('mouseover', function() {
        d3.select(this).attr('opacity', .5)
      })
      .on('mouseout', function() {
        d3.select(this).attr('opacity', 1)
      })
      .on('click', ()=> {
        this.schedule_Onoff('A_TOT')

        // for export pdf
        this.set_selectedItems({})
      })

      checksheet
      .append('path')
      .attr('id', 'QTY_REMAIN')
      .attr('transform',`translate(0, 150) scale(.7)`)
      .attr('d', this.arrow.chart).attr('fill', this.status.sColor)
      .style('cursor', 'pointer')
      .on('mouseover', function() {
        d3.select(this).attr('opacity', .5)
      })
      .on('mouseout', function() {
        d3.select(this).attr('opacity', 1)
      })
      .on('click', ()=> {
        this.schedule_Onoff('A_REM')

        // for export pdf
        this.set_selectedItems({})
      })

      checksheet
      .append('text')
      .attr('x', 160).attr('y', 55).attr('font-size', 14).attr('font-weight', 400)
      .attr('fill', this.status.vColor).attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text(current[0].value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))

      checksheet
      .append('text')
      .attr('x', 0).attr('y', 80).attr('font-size', 15)
      .attr('fill', this.status.mColor).attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Current Status')

      checksheet
      .append('text')
      .attr('x', 0).attr('y', 93).attr('font-size', 10.5)
      .attr('fill', this.status.sColor).attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Status of A checksheet')

      y_ = 110
      for(let i=1; i<3; i++) {

        let volItem = checksheet
        .append('g')
        .attr('transform',`translate(0, ${y_})`)

        volItem
        .append('text')
        .attr('x', 100).attr('y', 20).attr('font-size', 13)
        .attr('fill', this.status.nColor).attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(current[i].title)

        volItem
        .append('text')
        .attr('x', 160).attr('y', 20).attr('font-size', 14).attr('font-weight', 400)
        .attr('fill', this.status.vColor).attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(current[i].value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))

        y_ += 25
      }

      let volItem = checksheet
      .append('g')
      .attr('transform',`translate(0, ${y_})`)

      volItem
      .append('text')
      .attr('x', 100).attr('y', 20).attr('font-size', 13)
      .attr('fill', this.status.nColor).attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text('Progress')

      volItem
      .append('text')
      .attr('x', 160).attr('y', 20).attr('font-size', 14).attr('font-weight', 400)
      .attr('fill', this.status.vColor).attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text(`${((current[1].value/current[0].value)*100).toFixed(1)}%`)
  

    },

    schedule_Onoff(sw){
      
      this.schedule.code = sw

      if(sw=='A_TOT'){
        d3.select(`#QTY_TOTAL`).attr('fill', this.status.vColor)
        d3.select(`#QTY_REMAIN`).attr('fill', this.status.sColor)
        //this.clear_Charts()
        //this.generate_Charts()
      } else {
        d3.select(`#QTY_REMAIN`).attr('fill', this.status.vColor)
        d3.select(`#QTY_TOTAL`).attr('fill', this.status.sColor)
        //this.clear_Charts()
        //this.generate_Charts()
      }
      // Don't generate_Charts 
      this.clear_Charts()
    }

  }
}
