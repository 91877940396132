import * as d3 from 'd3'

export default {

  methods: {
    header_TrendsChart() {

      // Trend History -----------------------------------------------------------------------------------
      let trends = this.svg
      .append('g')
      .attr('transform',`translate(380, 90)`)
      .style('cursor', 'default')

      trends
      .append('text')
      .attr('x', 0).attr('y', 20).attr('font-size', 15)
      .attr('fill', this.status.mColor).attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Last 12 weeks Trends Chart')

      trends
      .append('text')
      .attr('id', 'Trend_SubTitle')
      .attr('x', 0).attr('y', 33).attr('font-size', 10.5)
      .attr('fill', this.status.sColor).attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Number of closed A checksheet by week')

      let toggle = trends
      .append('g')
      .attr('transform',`translate(402, 25)`)

      toggle
      .append('text')
      .attr('id', 'CREATED')
      .attr('x', -5).attr('y', 0).attr('font-size', 10.5)
      .attr('fill', this.trend_Status == 'CREATED_WK' ? this.status.vColor : this.status.sColor)
      .attr('text-anchor', 'end').style('cursor', 'pointer')
      .text('Created')
      .on('mouseover', () => {
        d3.select(`#CREATED`).transition().duration(100).attr('fill', this.status.vColor)
      })
      .on('mouseout', () => {
        d3.select(`#CREATED`).transition().duration(100).attr('fill', this.trend_Status == 'CREATED_WK' ? this.status.vColor : this.status.sColor)
      })
      .on('click', ()=> {
        this.trend_Onoff('CREATED_WK')

        // for export pdf
        this.set_selectedItems({})

      })


      toggle
      .append('text')
      .attr('x', 0).attr('y', 0).attr('font-size', 10.5)
      .attr('fill', this.status.sColor).attr('text-anchor', 'middle')
      .text('/')

      toggle
      .append('text')
      .attr('id', 'CLOSED')
      .attr('x', 5).attr('y', 0).attr('font-size', 10.5)
      .attr('fill', this.trend_Status == 'CLOSED_WK' ? this.status.vColor : this.status.sColor)
      .attr('text-anchor', 'start').style('cursor', 'pointer')
      .text('Closed')
      .on('mouseover', () => {
        d3.select(`#CLOSED`).transition().duration(100).attr('fill', this.status.vColor)
      })
      .on('mouseout', () => {
        d3.select(`#CLOSED`).transition().duration(100).attr('fill', this.trend_Status == 'CLOSED_WK' ? this.status.vColor : this.status.sColor)
      })
      .on('click', ()=> {
        this.trend_Onoff('CLOSED_WK')

        // for export pdf
        this.set_selectedItems({})
      })

      
      let max = this.getMaxBound_v2(d3.max(this.weekTrend, w => w.CLOSED_WK))

      let scale = d3.scaleLinear()
      .domain([0, max])
      .range([50, 0])

      let x_ = 5
      this.weekTrend.forEach((d,i)=> {

        trends
        .append('rect')
        .attr('id', `TREND_BAR_${i}`)
        .attr('x', x_).attr('y', scale(d.CLOSED_WK) + 53)
        .attr('width', 10).attr('height', 50 - scale(d.CLOSED_WK))
        .attr('fill', '#fff')

        trends
        .append('text')
        .attr('id', `TREND_TEXT_${i}`)
        .attr('x', x_+5).attr('y', scale(d.CLOSED_WK) + 50).attr('font-size', 9)
        .attr('fill', this.status.vColor).attr('text-anchor', 'middle')
        .text(d.CLOSED_WK)

        trends
        .append('text')
        .attr('x', x_+5).attr('y', 113).attr('font-size', 9)
        .attr('fill', '#B0F8F6').attr('text-anchor', 'middle')
        .text(12-i)

        x_ += 25
      })

      let sum = 0
      let j = 0
      let y_ = 50
      for(let i=11; i>=0; i--) {
        sum += this.weekTrend[i].CLOSED_WK
        if(j==3 || j==7 || j==11 ) {
          trends
          .append('text')
          .attr('x', 410).attr('y', y_).attr('font-size', 12)
          .attr('fill', this.status.nColor).attr('text-anchor', 'end')
          .text(`${(j+1)} Weeks`)

          trends
          .append('text')
          .attr('id', `WeekValue_${i}`)
          .attr('x', 440).attr('y', y_).attr('font-size', 11)
          .attr('fill', this.status.vColor ).attr('text-anchor', 'end')
          .text(parseInt(sum/(j+1)))
          y_ += 25
        }
        j++
      }

    },

    trend_Onoff(sw){

      this.trend_Status = sw

      if(sw=='CREATED_WK'){
        d3.select(`#CLOSED`).transition().duration(100).attr('fill', this.status.sColor)
        d3.select(`#CREATED`).transition().duration(100).attr('fill', this.status.vColor)
        this.redraw_Trends(sw)
        d3.select('#Trend_SubTitle').text('Number of created A checksheet by week')
      } else {
        d3.select(`#CREATED`).transition().duration(100).attr('fill', this.status.sColor)
        d3.select(`#CLOSED`).transition().duration(100).attr('fill', this.status.vColor)
        this.redraw_Trends(sw)
        d3.select('#Trend_SubTitle').text('Number of closed A checksheet by week')
      }
    },

    redraw_Trends(str) {
      
      let max = this.getMaxBound_v2(d3.max(this.weekTrend, w => w[str]))

      let scale = d3.scaleLinear()
      .domain([0, max])
      .range([50, 0])

      this.weekTrend.forEach((d,i)=> {
        d3.select(`#TREND_BAR_${i}`).transition().duration(500)
        .attr('y', scale(d[str]) + 53)
        .attr('height', 50 - scale(d[str]))

        let last = d3.select(`#TREND_TEXT_${i}`).text()
        d3.select(`#TREND_TEXT_${i}`).transition().ease(d3.easeLinear).duration(500)
        .attr('y', scale(d[str]) + 50)
        .tween("text", function() {
          const interpolator = d3.interpolateNumber(last, d[str])
          return function(t) {
            d3.select(`#TREND_TEXT_${i}`).text(Math.round(interpolator(t)))
          }
        })
      })

      let sum = 0
      let j = 0
      let y_ = 50
      for(let i=11; i>=0; i--) {
        sum += this.weekTrend[i][str]
        if(j==3 || j==7 || j==11 ) {
          d3.select(`#WeekValue_${i}`).text(parseInt(sum/(j+1)))
        }
        j++
      }
    }

  }
}
