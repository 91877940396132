import * as d3 from 'd3'
export default {
  
  methods: {

    draw_ScopeSummary() {

      this.draw_Summary(this.Area_Canvas, {
        x: 45,
        y: 310,
        scope: {
          title: 'Existing Site',
          tColor: '#fff',
          bColor: 'LightBlue',
        },
        summary:{
          bColor: '#88C8EB',
          tColor: '#fff',
        },
        bColor: ['#0080C5', '#44A9DF', '#83D2F5']
      })

      this.draw_Summary(this.Area_Canvas, {
        x: 700,
        y: 310,
        scope: {
          title: 'Grassroots',
          tColor: '#000',
          bColor: 'Yellow',
        },
        summary:{
          bColor: '#FEED57',
          tColor: '#bcbcbc',
        },
        bColor: ['#E6C501', '#EFDC38', '#F3E671']
      })

    },


    draw_Summary(selection, d) {

      let data = this.Overall.find(f=> f.SCOPE == d.scope.title)

      let overall = selection
      .append('g')
      .attr('transform', `translate(${d.x}, ${d.y})`)
      .style('font-family', 'roboto').attr('cursor', 'default')
      
      overall
      .append('text')
      .attr('x', 0).attr('y', 30).attr('font-weight', 450)
      .attr('font-size', 18).attr('fill', '#000').text(d.scope.title)

      // MC & Pre-Commissioning -------------------------------------
      let completion = overall
      .append('g')
      .attr('transform', `translate(0, 40)`)

      completion
      .append('rect')
      .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3)
      .attr('width', 270).attr('height', 80).attr('opacity', .8)
      .attr('fill', d.summary.bColor).attr('stroke', '#bcbcbc').attr('stroke-width', .5)

      completion
      .append('text')
      .attr('x', 10).attr('y', 19)
      .attr('font-size', 12).attr('fill', '#000').text('A & B ITRs Status')

      completion
      .append('text')
      .attr('x', 10).attr('y', 30)
      .attr('font-size', 9).attr('fill', d.summary.tColor).text('MC & Pre-Commissioning')

      this.draw_barChart(completion, 'B ITRs', 'B', 50, d.bColor[1], data)
      this.draw_barChart(completion, 'A ITRs', 'A', 70, d.bColor[1], data)


      // Subsystem ---------------------------------------------------
      let subsystem = overall
      .append('g')
      .attr('transform', `translate(280, 40)`)

      subsystem
      .append('rect')
      .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3)
      .attr('width', 270).attr('height', 80).attr('opacity', 9)
      .attr('fill', d.summary.bColor).attr('stroke', '#bcbcbc').attr('stroke-width', .5)

      subsystem
      .append('text')
      .attr('x', 10).attr('y', 19)
      .attr('font-size', 12).attr('fill', '#000').text('Walkdown  & Turnover')

      subsystem
      .append('text')
      .attr('x', 10).attr('y', 30)
      .attr('font-size', 9).attr('fill', d.summary.tColor).text('Subsystem Walkdown  & Turnover')

      this.draw_barChart(subsystem, 'Turnover', 'SSMCC', 50, d.bColor[0], data)
      this.draw_barChart(subsystem, 'Walkdown', 'WD', 70, d.bColor[0], data)


      // Punch -----------------------------------------------
      let Punch = overall
      .append('g')
      .attr('transform', `translate(0, 130)`)

      Punch
      .append('rect')
      .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3)
      .attr('width', 270).attr('height', 100).attr('opacity', .4)
      .attr('fill', d.summary.bColor).attr('stroke', '#bcbcbc').attr('stroke-width', .5)

      Punch
      .append('text')
      .attr('x', 10).attr('y', 19)
      .attr('font-size', 12).attr('fill', '#000').text('Punch Status')

      Punch
      .append('text')
      .attr('x', 10).attr('y', 30)
      .attr('font-size', 9).attr('fill', d.summary.tColor).text('A/B/C Punch')

      this.draw_barChart(Punch, 'Punch A', 'AP', 50, d.bColor[2], data)
      this.draw_barChart(Punch, 'Punch B', 'BP', 70, d.bColor[2], data)
      this.draw_barChart(Punch, 'Punch C', 'CP', 90, d.bColor[2], data)

      // Commissioning -----------------------------------------------
      let commissioning = overall
      .append('g')
      .attr('transform', `translate(280, 130)`)

      commissioning
      .append('rect')
      .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3)
      .attr('width', 270).attr('height', 62).attr('opacity', 1)
      .attr('fill', d.summary.bColor).attr('stroke', '#bcbcbc').attr('stroke-width', .5)

      commissioning
      .append('text')
      .attr('x', 10).attr('y', 19)
      .attr('font-size', 12).attr('fill', '#000').text('C ITRs Status')

      commissioning
      .append('text')
      .attr('x', 10).attr('y', 30)
      .attr('font-size', 9).attr('fill', d.summary.tColor).text('Commissioning')

      this.draw_barChart(commissioning, 'C ITRs', 'C', 50, d.bColor[0], data)

    },



    draw_barChart(selection, name, code, y_, bColor, d) {

      let barX = 75
      let proLen = 120
      let progress = selection

      .append('g')
      .attr('transform', `translate(30, ${y_})`)
      .attr('font-size', 10)

      progress
      .append('text')
      .attr('x', 0).attr('y', 0)
      .attr('fill', '#333')
      .text(name)
      
      // Total
      progress
      .append('text')
      .attr('x', barX-3).attr('y', 0)
      .attr('fill', '#333').attr('text-anchor', 'end')
      .text(d[`${code}_TOT`])
      .attr('cursor', 'pointer')
      .call(this.call_Summary, {scope: d.SCOPE, item: code, coltype: 'TOT'})

      progress
      .append('rect')
      .attr('x', barX).attr('y', -9).attr('opacity', .5)
      .attr('width', proLen).attr('height', 10).attr('fill', '#E0E0DF')

      let PRO = (d[`${code}_PRO`]/100) * proLen
      progress
      .append('rect')
      .attr('x', barX).attr('y', -9).attr('opacity', .8)
      .attr('width', PRO).attr('height', 10).attr('fill', bColor)

      // Actual
      progress
      .append('text')
      .attr('y', 0).attr('fill', '#333')
      .attr('x', d[`${code}_PRO`] < 60 ? barX + PRO + 2 : barX + PRO - 2)
      .attr('text-anchor', d[`${code}_PRO`] < 60 ? 'start' : 'end')
      .text(d[`${code}_ACT`])
      .attr('cursor', 'pointer')
      .call(this.call_Summary, {scope: d.SCOPE, item: code, coltype: 'ACT'})

      // Remain
      progress
      .append('text')
      .attr('x', barX+proLen+3).attr('y', 0)
      .attr('fill', '#333').attr('text-anchor', 'start')
      .text(d[`${code}_REM`])
      .attr('cursor', 'pointer')
      .call(this.call_Summary, {scope: d.SCOPE, item: code, coltype: 'REM'})
    },
   
  }
}