import Defs from '../../../../../../includes/primitives/colorDefs'
import __C from '../../../../../../includes/primitives/_constant_crisp'
import * as d3 from 'd3'

export default {
  data: () => ({
    RESOURCE:         `${__C.HOST_NAME_RESOURCE}/resources`,
    
    //pdf
    selectedItems : null,
    getItems      : null,

    getGroup:         null,
    getItem:          null,

    Area_Canvas:      null,

    rawData:          null,
    timeline:         null,

    MilestoneGroup:   [],
    Gantt: {
      gx:             5,
      ix:             25,
      item_Height:    20,
    },
    Summary: {
      height:   100
    },
    icons: {
      plus: `M11.2,9V2.1C11.2,1,10.2,0,9,0H2.1C1,0,0,1,0,2.1V9c0,1.2,1,2.1,2.1,2.1H9C10.2,11.2,11.2,10.2,11.2,9z M8.8,6.4H6.4v2.4H4.8V6.4H2.4V4.8h2.4V2.4l1.6,0v2.4h2.4V6.4z`,
      minus: `M9,0H2.1C0.9,0,0,0.9,0,2.1V9c0,1.2,0.9,2.1,2.1,2.1H9c1.2,0,2.1-0.9,2.1-2.1V2.1C11.1,0.9,10.2,0,9,0z M8.8,6.4H2.4V4.7h6.4V6.4z`,
      expand: 'M4.8,8.4V2.8h3c0,0-3.9-2.8-3.9-2.8S0.1,2.8,0.1,2.8H3v5.6H0.1l3.8,2.8l3.9-2.8H4.8z',
      reduce: 'M4,5.2l3.8-3.3H5.4V0H2.5v1.9H0.1L4,5.2L0.1,8.5l2.4,0.1v1.9c0,0,2.9,0,2.9,0s0-1.9,0-1.9h2.4L4,5.2z',
      ms_date: 'M5,8.5l5-6.7L6.7,2.7V0c0,0-3.3,0-3.3,0v2.6L0,1.8L5,8.5z',
    }
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    set_UserValues(){
      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      let sql1 = JSON.parse(JSON.stringify(this.Queries.SQL1[0]))

      this.rawData.forEach(d => {
        d.WD_TOT = d.SSMCC_TOT
        d.WD_ACT = d.SSMCC_WD
        d.WD_REM = d.SSMCC_TOT - d.SSMCC_WD
        d.SSMCC_TOT > 0 ? d.WD_PRO = (d.SSMCC_WD/d.SSMCC_TOT) * 100 : d.WD_PRO = 0

        d.AB_TOT = d.A_TOT + d.B_TOT
        d.AB_ACT = d.A_ACT + d.B_ACT
        d.AB_REM = d.A_REM + d.B_REM
        d.A_TOT + d.B_TOT > 0 ? d.AB_PRO = (d.AB_ACT / d.AB_TOT) * 100 : d.AB_PRO = 0
      })

      // Milestone Group -------------------------------------------------------------------------
      let result = [ ...new Set(this.rawData.map(d => d.GROUP)) ]
      result.forEach(d=> {
        this.MilestoneGroup.push({ name: d, count: this.rawData.filter(f=> f.GROUP == d).length})
      })

      let sn = 0
      let y = 0
      this.MilestoneGroup.forEach((g, i)=> {

        g.B_TOT = this.rawData.filter(f=> f.GROUP == g.name).map(d=> {return d.B_TOT}).reduce((a, b) => (a + b))
        g.B_ACT = this.rawData.filter(f=> f.GROUP == g.name).map(d=> {return d.B_ACT}).reduce((a, b) => (a + b))
        g.B_PRO = (g.B_ACT / g.B_TOT) * 100
        
        g.y = y
        g.bind = 'closed', //
        g.itemDisplay = 'visible', // visibla / hidden

        g.idx = i,
        y += this.Gantt.item_Height + 3

        g.max = d3.max(this.rawData.filter(f=> f.GROUP == g.name), c => c.MAX_PLAN)
        g.min = d3.min(this.rawData.filter(f=> f.GROUP == g.name), c => c.MIN_PLAN)

        let max = d3.max(this.rawData.filter(f=> f.GROUP == g.name), c => c.MS_DATE)
        this.rawData.filter(f=> f.GROUP == g.name).forEach(d => {
          (d.MS_DATE == max) ? d.max = 'Y' : d.max = 'N'
          d.y = y
          d.bind = 'closed',
          d.idx = sn
          y += this.Gantt.item_Height
          sn += 1
        })

        y += 7
      })
      // console.log(this.rawData)


      // Timeline ---------------------------------------------------------------------------------
      this.timeline = {
        cutoff        : sql1['CUTOFF'],
        
        x             : sql1['X'],
        y             : sql1['Y'],
        length        : sql1['LENGTH'],
        height        : sql1['HEIGHT'],
        column        : sql1['COLUMN_WIDTH'],
        startDate     : sql1['START_DATE'],
        endDate       : sql1['END_DATE'],
        weekEnd       : sql1['WEEKEND'],

        week          : [],
        month         : [],
        year          : [],
        scale         : null,
      }

      this.init_gmx_Set_Timeline_Month(this.timeline)
    },


    set_Styles() {
      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `shadowTimeline`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#3d3d3d').attr('offset', '0').attr('stop-opacity', 0.5)
      lightBlue.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 0.3)

      Defs.setDefs_Gradients(this.svg, '', 'LinearA4', 'LightGray', [ 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 'ICON', 'LinearA4', 'LightGray', [ 0.5, 1.0, 0.5, -.5], this.localId)
      Defs.setDefs_Gradients(this.svg, '', 'LinearA4', 'LightBlue', [.5, 1, .5, -.5], this.localId)
      Defs.setDefs_Gradients(this.svg, '', 'LinearA4', 'Pink', [.5, 1, .5, -.5], this.localId)
    },

  }
}