<template>
  <div :id="localId" class="svg_wrapper"></div>
</template>

<script>
import mx_Core from './includes/mixins/zMixins'        
import * as d3 from 'd3'
export default {
  name: "j-chart-plan_generator",
  mixins: [mx_Core],
  props: {
    id: String,
    modeExport: Boolean,
    getSelectItems: String,  

    FilterValues: { type: Object, default: () => ({}) },
    ColumnProps: { type: Array, default: () => ([]) },
    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    Note: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    Queries: { type: Object, default: () => ({}) },
  },


  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
    // console.log('this.id:', this.localId)
  },

  mounted() {
    this.register(this.draw)
  },

  methods: {
    draw() {
      if (!this.ready2draw) {
        return
      }

      this.clear()
      this.drawCanvas()
      this.drawing() // <------ Drawing Start
      this.drawNotes()
      this.drawTexts()

    },

    drawing() {
      // this.set_Gradients()
      this.set_LocalValues()
      this.set_Gradients()
      this.draw_Header()
      // this.get_Right_Distribution(1, 1)
      
      // for export pdf
      this.getItems = this.getSelectItems ? JSON.parse(this.getSelectItems) : {}
      if(this.getSelectItems){
        if(this.getItems) {
          //Duration
          if(this.getItems.start_X) this.start_X = this.getItems.start_X
          if(this.getItems.finish_X) this.finish_X = this.getItems.finish_X
          if(this.getItems.bar_Width) this.bar_Width = this.getItems.bar_Width
          if(this.getItems.start_X && this.getItems.finish_X && this.getItems.bar_Width) this.duration_Drag()

          if(this.getItems.chart_List){
            this.condition_Pdf = JSON.parse(JSON.stringify(this.getItems.chart_List[0])) 
          }

          // //Schedule Code //clear_Charts()가 있어서 제일먼저 해야 함 
          this.schedule_Onoff(this.getItems.sche_Code? this.getItems.sche_Code:'A_TOT')

          //Chart Gen Status          
          if(this.getItems.chart_Status == 'ON'){
            if(this.getItems.chart_Count != -1){
              this.condition_Pdf.forEach((d,i) =>{
                this.schedule.sDate = d.sDate
                this.schedule.fDate = d.fDate
                this.schedule.currResource = d.curve
                this.generate_Charts()
              })
            }
          } else {
            this.clear_Charts()  
          } 
          //Actual Status
          this.actual_Onoff(this.getItems.act_Status? this.getItems.act_Status:'OFF')
          
          //Trend Status
          this.trend_Onoff(this.getItems.trend_Status? this.getItems.trend_Status:'CLOSED_WK')
          //Help
          if(this.getItems.action=='HELP'){
            this.getItems.help_Status == 'RESOURCE'? this.help_Resource() : this.help_Duration()
          }

        } 
        
      }
    },

  }
}
</script>

<!-- <style lang="scss" scoped>
  .svg_wrapper {
    width: 1300px;
    height: 170px;

    overflow: hidden;
  }
</style> -->