export default {

  methods: {

    set_Checksheet(){
      let sql3 = this.Queries.SQL3[0]

      this.sheet = {
        prefix        : 'AB',
        styles        : [],
        values        : [],
      }
    
      let sheetStatus = sql3.SHEET_STATUS.replace(/\s/g, '').split('/')

      sheetStatus.forEach((d,i)=> {
        this.sheet.styles[i] = {
          text        : sql3.SHEET_TITLE.replace(/\s/g, ' ').split('/')[i],
          status      : sql3.SHEET_STATUS.replace(/\s/g, '').split('/')[i],
          type        : sql3.SHEET_TYPE.replace(/\s/g, '').split('/')[i],
          color       : sql3.SHEET_COLOR.replace(/\s/g, '').split('/')[i],
          opacity     : Number( sql3.SHEET_OPACITY.replace(/\s/g, '').split('/')[i]),
        }
      })
      this.set_SkylineStatus(this.sheet.prefix) // ---> below
    },

    
    set_Punch(){
      let sql3 = this.Queries.SQL3[0]
      
      this.punch = {
        prefix        : 'AP',
        styles        : [],
        values        : [],
      }
      let punchStatus = sql3[`PUNCH_STATUS`].replace(/\s/g, '').split('/')

      punchStatus.forEach((d,i)=> {
        this.punch.styles[i] = {
          text        : sql3[`PUNCH_NAME`].trim().split('/')[i],
          status      : sql3[`PUNCH_STATUS`].replace(/\s/g, '').split('/')[i],
        }
      })
    },
    

    set_SkylineStatus(prefix_) {
      // Setting for the Status styles in skyline 
      this.sheet.styles.forEach(s => {

        this.rawData.filter(f => f[`${prefix_}_STATUS`] == s.status).forEach(d => {
          d.type     = s.type
          d.bColor   = s.color
          d.opacity  = s.opacity
        })
      })
    },
  }
}