import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms() {

      this.Area_Canvas = this.svg
      .append('g')
      .attr('transform', 'translate(0, 0)')

      // ballon
      let ballon = this.Area_Canvas
      .append('g')
      .attr('id', `Ballon_Group`)
      .attr('transform', `translate(0, 0.5)`)
      .attr('font-family', 'roboto').attr('opacity', 0)
      .style('cursor', 'default')

      ballon
      .append('path')
      .attr('id', `Ballon_Border`)
      .attr('transform', `translate(-7, -22)`)
      .attr('d', `M74.2,0.2h-72c-1.1,0-2,0.9-2,2v11c0,1.1,0.9,2,2,2H4l2.8,7l-0.2-7h67.6c1.1,0,2-0.9,2-2v-11 C76.2,1.1,75.3,0.2,74.2,0.2z`)
      .attr('fill', '#fff').attr('stroke', '#FAAA18').attr('stroke-width', 1).attr('stroke-opacity', 1)

      ballon
      .append('text')
      .attr('id', `Ballon_Text`)
      .attr('x', -3.5).attr('y', -10.5)
      .attr('font-size', 11).attr('fill', '#333').attr('font-weight', 430)
      .text(`21100-U-6102`)


      


      // Drawing Unit
      let unitGroup = this.Area_Canvas
      .append('g')
      .attr('transform', `translate(20.5, 80.5)`)
      .attr('font-family', 'Segoe UI').style('cursor', 'default')


      let x = 0, y = 50
      this.rawData.forEach((d,i)=> {

        
        this.draw_Timeline(unitGroup, d, x, y)
        
        y += (d.SSMCC_TOT * 12)

        this.draw_Skyline(unitGroup, d, x, y, i)


       
      })
    },

  }
}