export default {
  data: () => ({
    sOptions:       null,
    sTimeline:      null,
    sIcicleChart:   null,
    sTable:         null,

    //pdf
    selectedItems : null,
    getItems      : null,
    selectedList  : [],
    filterStatus  : 'OFF',
    
    rawData:        [],
    msList:         null,
    icicle:         {},

    options: {
      x: 35,
      y: 60,
      milestone: {
        name: ['Waypoint', 'CSU'],
        code: ['WP', 'MS'],
        cStatus: 'MS'
      },

      schedule: {
        name: ['None', 'on-Schedule', 'Ahead', 'Behind', 'Completed'],
        code: ['NONE', 'ON',          'AHEAD', 'BEHIND', 'COMPLETED'],
        count: [],
        cStatus: 'NONE',
      }
    },

    styles: {
      plan: {  x: 0, y: 0, year: 14, month: 9, },
      actual: { year: 14, month: 9, topMargin: 40,},
    },

    icon: {},
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    set_LocalValues(){

      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      this.msList = JSON.parse(JSON.stringify(this.Queries.SQL1))
      let sql2 = JSON.parse(JSON.stringify(this.Queries.SQL2[0]))
      let sql3 = JSON.parse(JSON.stringify(this.Queries.SQL3[0]))

      // Icircle Chart --------------------------------------------------------------------------------
      this.icicle = {
        x:              sql2['ICICLE_X'],
        y:              sql2['ICICLE_Y'],
        padding: {
          left:         sql2['ICICLE_PADDING_LEFT'],
          top:          sql2['ICICLE_PADDING_TOP'],
        },
        cutoff:         sql2['CUTOFF'],
      }

      // Timeline -------------------------------------------------------------------------------------
      this.timeline = {
        plan: {
          startDate:    sql2['START_DATE'],
          endDate:      sql2['END_DATE'],
          width: null,
          scale: null,
          monthWidth:   30,
          values: { year: [], month: [], },
          float: { start: 0, end: 0 },
        },
        actual: {
          startDate:    sql2['HIS_START'],
          endDate:      sql2['HIS_END'],
          width: null,
          scale: null,
          monthWidth:   30,  
          values: { year: [], month: [], },
          float: { start: 0, end: 0 },
        }
      }
      
      // Milestone Table -------------------------------------------------------------------------------
      this.msTable = {
        x:            sql2['TBL_X'],
        y:            sql2['TBL_Y'],
        length:       0,
        checkStatus:  sql2['TBL_CHCKED'],
        col: {
          x:          [],
          name:       [],
          code:       [],
          width:      [],
          align:      [],
        }
      }

      let columns = sql2['TBL_VISIBLE'].replace(/\s/g, '').split('/')
      let no_ = 0
      columns.forEach((d,i)=> {
        if(d == 'Y') {
          this.msTable.col.name[no_] =          sql2['TBL_NAME'].replace(/\s/g, ' ').split('/')[i]
          this.msTable.col.code[no_] =          sql2['TBL_CODE'].replace(/\s/g, '').split('/')[i]
          this.msTable.col.width[no_] = Number( sql2['TBL_WIDTH'].replace(/\s/g, '').split('/')[i])
          this.msTable.col.align[no_] =         sql2['TBL_ALIGN'].replace(/\s/g, '').split('/')[i] 
          no_ ++
        }
      })

      let x_ = 0
      this.msTable.col.width.forEach( (d,i)=> {
        if(this.msTable.col.align[i] == 'start') this.msTable.col.x[i] = x_
        if(this.msTable.col.align[i] == 'middle') this.msTable.col.x[i] = x_ + (d/2)
        if(this.msTable.col.align[i] == 'end') this.msTable.col.x[i] = x_ + d
        x_ += d
        this.msTable.length += d
      })


      // Detail infomation Box -------------------------------------------------------------------------------
      if(sql3) {
        this.detail = {
          visible:            sql3.DETAIL_VISIBLE,
          onOff:              sql3.DETAIL_ONOFF,
          x:                  sql3.DETAIL_X,
          y:                  sql3.DETAIL_Y,
          width:              sql3.DETAIL_WIDTH,
          tSize:              sql3.DETAIL_TEXT_SIZE,
          lineHeight:         sql3.DETAIL_LINEHEIGHT,
          codes:              sql3.DETAIL_CODE.replace(/\s/g, '').split('/'),
          name:               sql3.DETAIL_NAME.replace(/\s/g, '').split('/'),
          type:               sql3.DETAIL_TYPE.replace(/\s/g, '').split('/'),
          tColor:             sql3.DETAIL_VALUE_COLOR.replace(/\s/g, '').split('/'),
          bColor:             sql3.DETAIL_BAR_COLOR.replace(/\s/g, '').split('/'),
          col:                sql3.DETAIL_COLUMN.replace(/\s/g, '').split('/').map(Number),
          wrapSize:           null,
          item: {
            name: [],
          },
          title: {
            width:            sql3.DETAIL_TITLE_WIDTH,
            tColor:           sql3.DETAIL_TITLE_COLOR,
          }
        }
        this.detail.height = 100
        // this.detail.height = sql3.DETAIL_LINEHEIGHT * this.detail.codes.length + 20
      }
    },

    set_selectedItems(){  
      this.selectedItems = { 
        ...this.selectedItems,
        filterStatus : 'ON',
        ms_cStatus   : this.options.milestone.cStatus,
        sc_cStatus   : this.options.schedule.cStatus,
        sel_Status   : this.msList.checkStatus=='select'?'unselect':'select',
        schedule     : this.options.schedule,
        milestone     : this.options.milestone,
        selectedList : this.selectedList
      }
    },

  }

}