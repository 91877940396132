import Defs from '../../../../../../includes/primitives/colorDefs'
import * as d3 from 'd3'
export default {
  data: () => ({

    innerSvg:       null,
    Area_Canvas:    null,

    rawData:        null,
    Systems:        null,
    
    Gantt:          {},
    timeline:       {},
    scaleX:         null,
    scaleY:         null,

    turnover:       {}, 



    colHeight:      180,
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    set_LocalValues(){
      
      if(this.getSelectItems) {
        this.pageOptions = JSON.parse(this.getSelectItems)
      }
      
      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      this.Systems = JSON.parse(JSON.stringify(this.Queries.SQL1))
      let sql2 = JSON.parse(JSON.stringify(this.Queries.SQL2[0]))

      
      
      this.Systems.forEach(d=> {
        d.AB_TOT = d.A_TOT + d.B_TOT
        d.AB_ACT = d.A_ACT + d.B_ACT
        d.AB_REM = d.A_REM + d.B_REM
        d.AB_PRO = (d.AB_ACT / d.AB_TOT) * 100
      })

      // Timeline ------------------------------------------------------------------------------------
      this.timeline = {
        cutoff        : sql2['CUTOFF'],
        
        length        : 0,
        height        : 35,
        column        : 400,
        startDate     : '2023-09-01',
        endDate       : '2024-11-29',
        weekEnd       : 'friday',

        week          : [],
        month         : [],
        year          : [],
        scale         : null,
      }
      // this.init_gmx_Set_Timeline_Month(this.timeline)


      // Skyline ------------------------------------------------------------------------------------
      this.init_gmx_SkylineValues_Week(
        this.Systems, 
        {
          startDate:    this.timeline.startDate,
          endDate:      this.timeline.endDate,
          weekEnd:      this.timeline.weekEnd,
          skyBox_Width: 11,
          skyBox_Gap:   1 // *** week
        }
      )

      this.rawData.forEach(d=> {
        d.AB_TOT = d.A_TOT + d.B_TOT
        d.AB_ACT = d.A_ACT + d.B_ACT
        d.AB_REM = d.A_REM + d.B_REM
        d.AB_PRO = (d.AB_ACT / d.AB_TOT) * 100

        this.Systems.filter(f=> f.UNIT == d.UNIT).forEach(d => {
          d.cDate = d.CSTD_PLAN ? this.get_gmx_TimelineCDate_Week(d.CSTD_PLAN).eDate : null
        })
        let lastDate = d3.max(this.Systems.filter(f=> f.UNIT == d.UNIT && f.CSTD_PLAN), c => c.cDate)

        this.Systems.filter(f=> f.UNIT == d.UNIT && f.cDate == null).forEach(s => {
          s.cDate = lastDate
        })
      })
      

    },




    set_Gradients() {
      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `shadowTimeline`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#3d3d3d').attr('offset', '0').attr('stop-opacity', 0.5)
      lightBlue.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 0.3)

      Defs.setDefs_Gradients(this.svg, '', 'LinearA4', 'LightGray', [ 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 'ICON', 'LinearA4', 'LightGray', [ 0.5, 1.0, 0.5, -.5], this.localId)
      Defs.setDefs_Gradients(this.svg, '', 'LinearA4', 'LightBlue', [.5, 1, .5, -.5], this.localId)
      Defs.setDefs_Gradients(this.svg, '', 'LinearA4', 'Pink', [.5, 1, .5, -.5], this.localId)
    },
  }
}