import moment from 'moment'
export default {

  methods: {

    set_PlanValues(sch){
      
      this.db_Total = 0
      this.completeDate = '2024-01-01'

      this.durationCurveData = this.get_durationCurveData(sch.sDate, sch.fDate, sch.max, sch.limite)
      // this.durationCurveData.forEach((d,i)=> {console.log(d.days, d.volume)}) // ----------------> Console

      this.rawData.forEach((d,i)=> {

        if(d.A_REM == 0) return

        // 각 subsystem의 Qty에 대한 일수 구하기
        let getDuration = this.get_DurationDays(d[this.schedule.code]) 

        
        let start_No = getDuration + this.schedule.fDate - 1

        let start_Date = moment(moment(d.CSTD_PLAN).add(-(start_No), 'days')) // ***
        let diffDays = start_Date.diff(moment(this.timeline.cutoff), "days")-1 // ***


        d.SDATE = moment(moment(d.CSTD_PLAN).add(-(start_No), 'days')).format('YYYY-MM-DD')
        d.FDATE = moment(moment(d.CSTD_PLAN).add(-this.schedule.fDate, 'days')).format('YYYY-MM-DD')
        d.BUDGET = []

        // Cutoff 이전 물량 이후로 이동 시키기
        if(diffDays < 0) {
          let move_SDATE = moment(moment(d.SDATE).add(-diffDays, 'days')).format('YYYY-MM-DD')
          let move_FDATE = moment(moment(d.FDATE).add(-diffDays, 'days')).format('YYYY-MM-DD')
          // console.log({
          //   'SDATE': d.SDATE,
          //   'FDATE': d.FDATE,
          //   'SHEET:': d.A_REM,
          //   'DiffDays:': diffDays,
          //   'moved SDATE': move_SDATE,
          //   'moved FDATE': move_FDATE,
          // })
          d.SDATE = move_SDATE
          d.FDATE = move_FDATE
        }
        
        // A Sheet 마지막 완료일
        if(d.FDATE > this.completeDate) this.completeDate = d.FDATE

        let getValues = []
        getValues = this.assign_RecourceCurve(this.schedule.currResource,getDuration, d[this.schedule.code])

        let nextDay = moment(moment(d.SDATE).format('YYYY-MM-DD 23:59:59')).toDate()
        for(let i=0; i<getDuration; i++) {
          let value_ = {
            cDate: moment(nextDay).format('YYYY-MM-DD'),
            value: getValues[i]
          }
          d.BUDGET.push(value_)
          nextDay = moment(nextDay).add(1, 'days').toDate()
        }
        this.db_Total += d[this.schedule.code]
      })
      

      // Daily cumulative -------------------------------------------------------
      this.timeline.day.forEach((time,t)=> {
        this.rawData.filter(f=> f.A_REM > 0).forEach((d,i)=> {
          let fValue = d.BUDGET.find(f=> f.cDate == time.cDate)
          if(fValue) time.value += fValue.value
        })
      })


      // Get Weekly cumulative --------------------------------------------------
      let chart_Total_Qty = this.rawData.map(d=> {return d[`A_ACT`]}).reduce((a, b) => (a + b))
      let cd = this.condition_List.find(f=> f.count == this.chart_Count)

      this.timeline.week.forEach((wk,w)=> {
        let fromDate = moment(moment(wk.eDate).add(-6, 'days').format('YYYY-MM-DD')).toDate()
        let arrWeek = this.timeline.day.filter(f=> moment(f.cDate) >= fromDate && moment(f.cDate) <= wk.eDate).map(d=> {return d.value})
        wk.value = arrWeek.reduce((a, b) => (a + b))
        chart_Total_Qty += wk.value
        wk.cumul = chart_Total_Qty

        // Peak Time
        if(cd.pk_max < wk.value) {
          cd.pk_idx = w
          cd.pk_date = wk.eDate
          cd.pk_max = parseInt(wk.value)
        }
      })
      let weeks9 = this.timeline.week.filter((f,i)=> i >= (cd.pk_idx-this.peakTime) && i <= (cd.pk_idx+this.peakTime)).map(m=> {return m.value})
      cd.pk_avg = parseInt(weeks9.reduce((a, b) => (a + b))/(this.peakTime*2+1))
      // console.log(cd)


      // Week Cumulative Progress(%) --------------------------------------------
      this.timeline.week.forEach((wk,w)=> {
        wk.pro = (wk.cumul/chart_Total_Qty) * 100
      })
      console.log('TOTAL:', parseInt(chart_Total_Qty), '/', this.db_Total)
    },

    



    set_ForecastValues(){
      let cd  = this.condition_List.find(f=> f.count == this.chart_Count)
      let min = parseInt(cd.qty_min)
      let max = parseInt(cd.qty_max)

      let forecastData = this.func_QuantiryCurve(this.timeNow.REM, min, max)
      // console.log(forecastData)

      let week_Timeline = this.timeline.week.filter(f=> f.eDate > new Date(this.timeline.cutoff))
      let maxLen = week_Timeline.length 

      week_Timeline[0].value = 0.001
      week_Timeline[0].cumul = this.timeNow.ACT
      week_Timeline[0].pro = this.timeNow.PRO

      let cumulative = this.timeNow.ACT

      forecastData.forEach((d,i)=> {
        if(i > maxLen-2) return
        cumulative += d
        week_Timeline[i+1].value = d
        week_Timeline[i+1].cumul = cumulative
        week_Timeline[i+1].pro = (cumulative/this.timeNow.TOT)*100
      })
      
      week_Timeline.filter(f=> f.pro == undefined).forEach(d=> {
        d.value = 0
        d.cumul = cumulative
        d.pro = 100
      })
      
      // console.log(this.timeline.week.filter(f=> f.eDate > new Date(this.timeline.cutoff)))



      
    },
  }
}