import Defs from '../../../../../../includes/primitives/colorDefs'
import * as d3 from 'd3'

export default {
  data: () => ({

    formSvg       : null,
    legendGroup   : null,
    boxGroup      : null,
    sheetGroup    : null,
    punchGroup    : null,
    refGroup      : null,
    monthGroup    : null,

    //pdf
    selectedItems : null,
    getItems      : null,
    filterStatus  : 'OFF',
    searchStatus  : 'OFF',
    searchNote    : '',
    
    rawData       : [],
    targetData    : [],
    skylineData   : [],
    Title         : null,
    subTitle      : null,

    timeline      : {},
    skyline       : {},
    legends       : {},
    commType      : [
      {text: 'None',     sColor: '#bcbcbc', },
      {text: 'Comm',     sColor: '#000',    },
      {text: 'Non-Comm', sColor: '#bcbcbc', },
    ],
    refCol        : [
      {text: 'Plan',      col: 'CSTD_PLAN'},
      {text: 'Forecast',  col: 'FORECAST_DATE'},
    ],
    targetMonth   : [
      {text: 'None'         , status: 'NONE',},
      {text: 'Current month', status: 'CURRENT_MONTH',},
      {text: 'Next month'   , status: 'NEXT_MONTH',},
    ],
    sheet         : {},
    punch         : {},
    detail        : {},
    filters       : [],
    viewOver      : {
      owner: 'Owner',
      subsys: ['Walkdown','Turnover'],
      color:  ['LightBlue', 'Yellow']
    },
    viewSubsys    : {
      title: ['Turnover Plan', 'Forecast', 'Walkdown', 'Turnover Actual', 'Description'],
      value: ['CSTD_PLAN', 'FORECAST_DATE', 'CSTD_WALKDOWN', 'CSTD_ACTUAL', 'SUBSYS_DESC'],
    },
    group         : {
      ITRs: [], Punch: [],
      type: ['ITRs', 'Punch']
    },
    tubeScale     : null,

    Toggle        : 'on',

    currentUnit   : '',
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {
  
    setDefaultValues(){

      let unit = 'OV'
      // if(!(this.FilterValues.UNITCODE && this.FilterValues.UNITCODE=='ALL UNIT')){
      //   unit = this.FilterValues.UNITCODE
      // }
      
      // if(!this.FilterValues.UNITCODE) {
      //   console.log('[USER: #DEBUG# FilterValues null] DEFAULT UNIT SET [ARU] ')
      //   unit = 'OV'
      // }
      this.currentUnit = unit

      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      // if(unit !== 'OV') this.rawData = this.rawData.filter(f=> f.UNIT == this.currentUnit)
      
      this.rawData.forEach(d=> {
        d.AB_TOT = d.A_TOT + d.B_TOT
        d.AB_ACT = d.A_ACT + d.B_ACT
        d.AB_REM = d.A_REM + d.B_REM
        d.AB_PRO = (d.AB_ACT / d.AB_TOT) * 100
        if(d.AB_ACT == 0) d.AB_STATUS = 'NOTSTARTED'
        if(d.AB_PRO > 0 && d.AB_PRO < 100) d.AB_STATUS = 'INPROGRESS'
        if(d.AB_PRO == 100) d.AB_STATUS = 'COMPLETED'
        if(d.CSTD_ACTUAL != null || d.CSTD_ACTUAL != undefined) {
          d.CERT_TYPE == 'PTOCGR' ? d.AB_STATUS = 'PTO' : d.AB_STATUS = 'SSMCC'
        }
      })

      
      let sql1 = this.Queries.SQL1[0] // Timeline
      let sql2 = this.Queries.SQL2[0] // Skyline
    
      let sql3 = null
      if (this.Queries.SQL2) sql3 = this.Queries.SQL3[0] //Legends
      
      let sql4 = null
      if (this.Queries.SQL4[0]) sql4 = this.Queries.SQL4[0] //Detail Style
      // if (this.Queries.SQL4) this.filters = JSON.parse(JSON.stringify(this.Queries.SQL4[1])) //Detail Filter

      // Timeline -------------------------------------------------------------------------------
      this.timeline = {
        baseX           : sql1.X,
        baseY           : sql1.Y,
        length          : sql1.LENGTH,
        cutoff          : sql1.CUTOFF,
        startDate       : sql1.START,
        endDate         : sql1.END,
        refColumn       : sql1.REF_DATE, // ***
        weekEnd         : sql1.WEEKEND,
        week            : [],
        month           : [],
        year            : [],
        scale           : null,
        height          : null,
        font            : sql1.FONT,
      }

      // Skyline Properties -------------------------------------------------------------------------------
      
      this.skyline = {
        skyBox: {
          height      : sql2.BOX_HEIGHT,
          width       : sql2.BOX_WIDTH,
          gap         : 2,
        },
        refColumn     : sql2.REF_COLUMN, // ***
        scale         : sql2.BOX_HOVER_SCALE,
      },

      this.search = {
        x             : sql2.SEARCH_X,
        y             : sql2.SEARCH_Y,
        visible       : sql2.SEARCH_VISIBLE,
        note          : sql2.SEARCH_NOTE,
        targets       : [''],
        input         : '',
        results       : [],
      },

      this.legends = {
        commStatus    : 'None',
        sheetStatus   : 'TOTAL',
        punchStatus   : 'NONE',
        refStatus     : 'CSTD_PLAN',
        targetStatus  : 'NONE',
        layerStatus   : '',
        visible       : sql2.LEGEND_VISIBLE,
        x             : sql2.LEGEND_X,
        y             : sql2.LEGEND_Y,
      }

      // Plan/Forecast refcolumn switching
      if (this.FilterValues) {
        if (this.FilterValues.PLFC) {
          let col = this.FilterValues.PLFC == 'Forecast' ? 'FORECAST_DATE' : 'CSTD_PLAN'
          this.timeline.refColumn = col
        }
      }

      // Status Styles -------------------------------------------------------------------------------
      this.set_Checksheet()
      this.set_Punch()

      // Create Dynamic Chart Data
      this.set_TargetData()

      this.count_CommType(this.targetData)
      this.count_Sheet(this.targetData)
      this.count_Punch(this.targetData)

      // Detail infomation Box -------------------------------------------------------------------------------
      if(sql4) {
        this.detail = {
          visible:            sql4.DETAIL_VISIBLE,
          onOff:              sql4.DETAIL_ONOFF,
          x:                  sql4.DETAIL_X,
          y:                  sql4.DETAIL_Y,
          width:              sql4.DETAIL_WIDTH,
          tSize:              sql4.DETAIL_TEXT_SIZE,
          lineHeight:         sql4.DETAIL_LINEHEIGHT,
          codes:              sql4.DETAIL_CODE.replace(/\s/g, '').split('/'),
          name:               sql4.DETAIL_NAME.replace(/\s/g, '').split('/'),
          type:               sql4.DETAIL_TYPE.replace(/\s/g, '').split('/'),
          group:              sql4.DETAIL_GROUP.replace(/\s/g, '').split('/'),
          tColor:             sql4.DETAIL_VALUE_COLOR.replace(/\s/g, '').split('/'),
          bColor:             sql4.DETAIL_BAR_COLOR.replace(/\s/g, '').split('/'),
          wrapSize:           null,
          item: {
            name: [],
          },
          title: {
            width:            sql4.DETAIL_TITLE_WIDTH,
          }
        }
        this.detail.height = sql4.DETAIL_LINEHEIGHT * this.detail.codes.length + 30
      }
      this.set_SkylineData() // ---> below
    },

    set_SkylineData() {
      this.skylineData = this.targetData.filter(f => f[this.timeline.refColumn] >= this.timeline.startDate && f[this.timeline.refColumn] <= this.timeline.endDate)
      
      // ### call the global Function for Skyline Values ###
      this.init_gmx_SkylineValues_Week(
        this.skylineData, 
        {
          refColumn:    this.timeline.refColumn,
          skyBox_Width: this.skyline.skyBox.width,
          skyBox_Gap:   this.skyline.skyBox.gap,
          startDate:    this.timeline.startDate,
          endDate:      this.timeline.endDate,
          weekEnd:      this.timeline.weekEnd
        }
      )
            
      this.skylineData.forEach(d => {
        let date__  = this.get_gmx_TimelineCDate_Week(d[this.timeline.refColumn])
        d.cDate     = date__.eDate
        d.sDate     = date__.sDate
        d.date      = d3.timeParse('%Y-%m-%d')(d[this.timeline.refColumn])
      })

    },

    set_Styles() {
      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `shadowTimeline`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#3d3d3d').attr('offset', '0').attr('stop-opacity', 0.5)
      lightBlue.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 0.3)

      Defs.setDefs_Gradients(this.svg, 'TL', 'LinearA4', 'LightGray', [ 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 'plan', 'LinearA4', 'LightBlue', [.5, .8, .5, 0], this.localId)

      Defs.setDefs_Gradients(this.svg, 'Walkdown', 'LinearA4', 'LightBlue', [0.1, 1, 1, -.1], this.localId)
      Defs.setDefs_Gradients(this.svg, 'Turnover', 'LinearA4', 'Yellow', [0.1, .8, 1, 0], this.localId)
      Defs.setDefs_Gradients(this.svg, 'Plan', 'LinearA4', 'LightGray', [-0.2, 0.8, 1.6, 0.6], this.localId)
      Defs.setDefs_Gradients(this.svg, 'Forecast', 'LinearA4', 'LightGray', [-0.2, 0.8, 1.6, 0.6], this.localId)
    },

    set_TubeScale() {
      this.tubeScale = d3.scaleLinear()
      .domain([0, this.targetData.length])
      .range([0, 110])
    },

    set_selectedItems() {  
      this.selectedItems = { 
        ...this.selectedItems,
        filterStatus: this.filterStatus,
        refStatus : this.legends.refStatus,
        targetStatus : this.legends.targetStatus,
        sheetStatus : this.legends.sheetStatus,
      }
    },

    set_TargetData() {
      this.targetData = JSON.parse(JSON.stringify(this.rawData))

      let cutoff = new Date(this.timeline.cutoff)
      let current_Year = cutoff.getFullYear()
      let current_Month = cutoff.getMonth()
      let next_Year = current_Month == 11 ? current_Year + 1 : current_Year
      let next_Month = current_Month == 11 ? 0 : current_Month + 1

      let refCol = this.legends.refStatus == 'CSTD_PLAN' ? 'PL_CDATE' : 'FC_CDATE'

      if (this.legends.targetStatus == 'CURRENT_MONTH') {
        this.targetData = this.targetData.filter(f => new Date(f[refCol]).getFullYear() == current_Year && new Date(f[refCol]).getMonth() == current_Month)
      } else if (this.legends.targetStatus == 'NEXT_MONTH') {
        this.targetData = this.targetData.filter(f => new Date(f[refCol]).getFullYear() == next_Year && new Date(f[refCol]).getMonth() == next_Month)
      }
    },
  }
}