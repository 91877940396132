import gmx_zMixins         from '../../../../../../includes/Jmixins/zMixins'

// Locally declared common Data-Props & SVG Drawing Support

import lmx_watching           from './Watching'

// Charts 
import lmx_LocalValues          from './set_LocalValues'
import lmx_Forms                from './draw_Forms'

import lmx_Events               from './Events'
import lmx_Summary              from './draw_Summary'

export default {
  mixins: [
    gmx_zMixins, 

    // Local Mixins
    lmx_watching,
    lmx_LocalValues,
    lmx_Forms,
    lmx_Events,

    lmx_Summary,
    
  ],
}
