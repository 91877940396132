export default {

  methods: {

    count_CommType(data_){
      
      this.commType.forEach((d,i)=> {
        if (d.text == 'None') d.values = data_.length
        else d.values = data_.filter(f=> f.COMM_TYPE == d.text).length
      })
    },

    
    count_Sheet(data_){
      // console.log(data_)
      this.sheet.styles.forEach((s,i)=> {
        if (s.status == 'TOTAL') this.sheet.values[0] = data_.length
        else this.sheet.values[i] = data_.filter(f=> f.AB_STATUS == s.status).length
      })
    },


    count_Punch(data_) {

      this.punch.styles.forEach( (d,i)=> {

        switch(d.status) {
          case 'NONE':
            d.filtered = data_
          break

          case 'CREATE':
            d.filtered = data_.filter(f=> f.AP_TOT > 0)
          break

          case 'NOTSTARTED':
            d.filtered = data_.filter(f=> f.AP_TOT > 0 && f.AP_ACT == 0)
          break

          case 'OPENED':
            d.filtered = data_.filter(f=> f.AP_TOT > 0 && f.AP_REM > 0)
          break

          case 'CLEARED':
            d.filtered = data_.filter(f=> f.AP_TOT > 0 && f.AP_REM == 0)
          break
        }
        this.punch.values[d.status] = d.filtered.length
      })
      // console.log(this.punch.values)
    },
    
  }
}