<template>
  <div :id="localId" class="svg_wrapper"></div>
</template>

<script>
import * as d3 from 'd3'
import mx_Core from './includes/mixins/zMixins'        
export default {
  name: "j-Skyline_Milestone",
  mixins: [mx_Core],
  props: {
    id: String,
    modeExport: Boolean,
    getSelectItems: String,  

    FilterValues: { type: Object, default: () => ({}) },
    ColumnProps: { type: Array, default: () => ([]) },
    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    Note: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    Queries: { type: Object, default: () => ({}) },
  },

  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
    // console.log('this.id:', this.localId)

  },
 
  mounted() {
    this.register(this.draw)
  },

  methods: {
    draw() {
      if (!this.ready2draw) {
        return
      }

      this.clear()
      this.set_UserValues()
      this.set_Styles()
      this.drawCanvas()
      
      
      this.drawing() // <------ Drawing Start
      this.drawNotes()
      this.drawTexts()
      this.complete()
    },

    drawing() {
       
      // for Exporting PDF file
      this.getItems = this.getSelectItems ? JSON.parse(this.getSelectItems) : {}
      if(this.getItems.status == 'Y'){
        this.getGroup = this.getItems.group
        this.getItem = this.getItems.item

        this.MilestoneGroup.forEach((d,i)=> {
          d.y = this.getGroup[i].y
          d.bind = this.getGroup[i].bind
          d.itemDisplay = this.getGroup[i].itemDisplay
        })

        this.rawData.forEach((d,i) => {
          d.y = this.getItem[i].y
          d.bind = this.getItem[i].bind
        })

        let height = this.getItems.height
        d3.select(`.canvas`).attr('height', height)
        d3.select(`._canvas_border_background__${this.localId}`).attr('height', height)

        this.draw_Header()
        this.draw_Timeline()
        this.draw_GanttCharts()
        // }
      } else {
        this.draw_Header()
        this.draw_Timeline()
        this.draw_GanttCharts()
      }
    },
  }
}

</script>

<style lang="scss" scoped>
</style>