export default {
  methods: {

    draw_Legends() {

      let legend = this.Area_Canvas
      .append('g')
      .attr('transform', `translate(20.5, 100.5)`)
      .attr('font-family', 'Segoe UI').style('cursor', 'default')

      // Progress
      legend
      .append('rect')
      .attr('x', 0).attr('y', 0)
      .attr('width', 10).attr('height', 10).attr('fill', '#FFDA00').attr('opacity', .2)
      .attr('stroke-width', 1).attr('stroke', '#bcbcbc').attr('stroke-opacity', .2)
      legend
      .append('rect')
      .attr('x', 10).attr('y', 0)
      .attr('width', 10).attr('height', 10).attr('fill', '#FFDA00').attr('opacity', .4)
      .attr('stroke-width', 1).attr('stroke', '#bcbcbc').attr('stroke-opacity', .2)
      legend
      .append('rect')
      .attr('x', 20).attr('y', 0)
      .attr('width', 10).attr('height', 10).attr('fill', '#FFDA00').attr('opacity', .6)
      .attr('stroke-width', 1).attr('stroke', '#bcbcbc').attr('stroke-opacity', .2)
      legend
      .append('rect')
      .attr('x', 30).attr('y', 0)
      .attr('width', 10).attr('height', 10).attr('fill', '#FFDA00').attr('opacity', .8)
      .attr('stroke-width', 1).attr('stroke', '#bcbcbc').attr('stroke-opacity', .2)
      legend
      .append('rect')
      .attr('x', 40).attr('y', 0)
      .attr('width', 10).attr('height', 10).attr('fill', '#FFDA00').attr('opacity', 1)
      .attr('stroke-width', 1).attr('stroke', '#bcbcbc').attr('stroke-opacity', .2)

      legend
      .append('text')
      .attr('x', 55).attr('y', 8)
      .attr('font-size', 10).attr('fill', '#757575')
      .text('Progress')

      // Walkdown
      legend
      .append('rect')
      .attr('x', 115).attr('y', 0)
      .attr('width', 10).attr('height', 10).attr('fill', '#fff')
      .attr('stroke', '#000').attr('stroke-opacity', .5)

      legend
      .append('text')
      .attr('x', 130).attr('y', 8)
      .attr('font-size', 10).attr('fill', '#757575')
      .text('Walkdown')

      // Turnover
      legend
      .append('rect')
      .attr('x', 200).attr('y', 0)
      .attr('width', 10).attr('height', 10).attr('fill', '#4CAE4E')
      .attr('stroke', '#757575').attr('stroke-opacity', .2)

      legend
      .append('text')
      .attr('x', 215).attr('y', 8)
      .attr('font-size', 10).attr('fill', '#757575')
      .text('Turnover')

    },

  }
}