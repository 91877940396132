import moment from 'moment'
import * as d3 from 'd3'

export default {
  methods: {

    chart_Actual_Controller() {
      let real_Timeline = this.timeline.week.filter(f=> f.eDate >= new Date(this.timeline.start))

      this.scale.X = d3.scaleBand()
      .domain(real_Timeline.map(d => moment(d.eDate).format('YYYY-MM-DD')))
      .range([0, this.canvas.chart.width]).padding(0.3)
      
      this.Actual_Chart = this.svg
      .append('g')
      .attr('id', `Actual_Chart`)
      .attr('transform', `translate(${this.canvas.padding.left+.5}, ${this.canvas.padding.top+.5})`)
      .attr('opacity', this.act_Status == 'ON' ? 1 : 0)
      .attr('font-family', 'roboto') // rage / roboto

      // Set bandwidth of the Bar Chart
      let barGap = 1
      let barWidth = (this.scale.X.bandwidth()/2)-(barGap/2)

      // Call Chart
      let data = this.actData.filter(f=> f.CDATE >= this.timeline.start && f.CDATE <= this.timeline.cutoff)
      let avg = this.actData.filter(f=> f.CDATE >= this.timeline.start && f.CDATE <= this.timeline.cutoff).map(d=> {return d.CLOSED_WK})
      let noWeek = data.length
      let average = avg.reduce((a, b) => (a + b))/noWeek

      this.actual_barChart(data, this.scale.Primary, barWidth, average)
      this.Actual_lineChart(data, this.scale.Secondary,)
    },


    actual_barChart(_data, scale_, width_, avg_) {

      this.Actual_Chart
      .selectAll(`rect_`)
      .data(_data)
      .enter()
      .append('rect')
      .attr('x', d => this.scale.X(d.CDATE) + width_ + 1)
      .attr('y', d => scale_(d.CLOSED_WK))
      .attr('height', d => this.canvas.chart.height - scale_(d.CLOSED_WK))
      .attr('width', width_)
      .attr('fill', '#B5549B')
      .attr('opacity', 1)

      this.Actual_Chart
      .append('line')
      .attr('x1', this.scale.X(_data[0].CDATE)+5)
      .attr('y1', scale_(avg_))
      .attr('x2', this.scale.X(_data.find(f=> f.CDATE == this.timeline.cutoff).CDATE) + 10)
      .attr('y2', scale_(avg_))
      .attr('stroke', '#B5549B').attr('stroke-width', .3)

      this.Actual_Chart
      .append('text')
      .attr('x', 5).attr('y', scale_(avg_) - 4)
      .attr('fill','#B5549B').attr('font-size', 10)
      .text(`Actual Avg.: ${parseInt(avg_)}`)
    },


    Actual_lineChart(data_, scale_) {
      this.Xband = this.scale.X.bandwidth() / 2

      let lineFunc = d3.line()
      .x(d => this.scale.X(d.CDATE) + this.Xband)
      .y(d => scale_(d.CLOSED_PRO))

      let final = {date: 0, value: 0}
      let lineChart = this.Actual_Chart
      .append('g')
      .attr('id', `LINE_CHART_${this.chart_Count}`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      lineChart
      .append('path')
      .attr('d', lineFunc(data_))
      .style('stroke', '#B5549B').style('stroke-width', 1)
      .style('fill', 'none')

      lineChart
      .selectAll(`circle_`)
      .data(data_)
      .enter()
      .append('circle')
      .attr('cx', d => this.scale.X(d.CDATE) + this.Xband)
      .attr('cy', d=>{
        final.date = d.CDATE
        final.value = d.CLOSED_PRO
        return scale_(d.CLOSED_PRO)
      })
      .attr('r', 2.5)
      .style('stroke', '#B5549B').style('stroke-width', 1)
      .style('fill', '#fff')

      // Progress Actual
      this.Actual_Chart
      .append('text')
      .attr('x', this.scale.X(final.date) + this.Xband + 5)
      .attr('y', scale_(final.value) + 3)
      .attr('fill',this.status.vColor).attr('font-size', 11).attr('font-weight', 450)
      .text(`${final.value.toFixed(1)}%`)
    },

  }
}