import * as d3 from 'd3'

export default {

  methods: {

    draw_Information() {
      
      let detail = this.detail
      if(!this.Queries.SQL3) return

      // ---------------------------------------
      // Construction Stage ---> Global Function
      // ---------------------------------------
      let DetailBox = this.SkylineCharts
      .append('g')
      .attr('id', 'Detail_Group')
      .attr('transform', `translate(${detail.x}, ${detail.y})`)
      .attr('font-family', this.skyline.skyBox.font)
      .attr('opacity', 1)

      DetailBox
      .append('text')
      .attr('id', `SKY_NO`)
      .attr('x', 0).attr('y', 0)
      .attr('font-size', 15).attr('fill', '#000')
      .text('Overall Status')

      let ownerValue = ''

      if (this.FilterValues) {
        if (this.FilterValues.OWNER_ID) {
          if (this.FilterValues.OWNER_ID[1]) ownerValue = ' : ' + this.FilterValues.OWNER_ID[0] + ` (+ ${this.FilterValues.OWNER_ID.length - 1})`
          else ownerValue = ' : ' + this.FilterValues.OWNER_ID
        } else {
          ownerValue = 'Description'
        }
      } else {
        ownerValue = 'Description'
      }

      DetailBox
      .append('text')
      .attr('id', `OWNER_ID_VALUE`)
      .attr('x', 0).attr('y', 10)
      .attr('font-size', 10).attr('fill', '#ccc')
      .text(`System Group ${ownerValue}`)

      let x_ = 30
      let y_ = 60

      this.viewOver.subsys.forEach((d,i)=> {
        let fData = this.targetData.filter(f => d == 'Walkdown' ? f.CSTD_WALKDOWN !== undefined : f.CSTD_ACTUAL !== undefined)
        
        this.draw_OverallStatus(DetailBox, x_, y_, i, this.viewOver.color[i], d, fData)
        
        x_ += 120
      })

      // if (this.legends.targetStatus == 'NONE') {
      //   let name = this.refCol.find(f => f.col == this.legends.refStatus).text

      //   this.draw_OverallStatus(DetailBox, 90, y_, 2, 'LightGray', name, this.targetData.filter(f => f[this.legends.refStatus] <= this.timeline.cutoff))
      // }

      y_ += 180
      
      this.group.type.forEach((c,j) => {
        DetailBox
        .append('rect')
        .attr('id',`detail_group_${j}`)
        .attr('x', 30).attr('y', y_-2)
        .attr('width', 10).attr('height', 10)
        .attr('fill', c == 'ITRs' ? '#AEDFFF' : '#FFB2D2').attr('opacity', 1)
        .attr('stroke', '#BCBCBC').attr('stroke-opacity', 0.3).attr('stroke-width', 0.5)

        DetailBox
        .append('text')
        .attr('id',`group_title_${j}`)
        .attr('x', 43).attr('y', y_+7)
        .attr('font-size', detail.tSize).attr('fill', '#000')
        .text(c)

        y_ += 35
        detail.group.forEach((d,i)=> {
          if (d == c) {
            DetailBox
            .append('text')
            .attr('id',`detail_name_${i}`)
            .attr('x', 51).attr('y', y_)
            .attr('font-size', detail.tSize).attr('fill', '#000')
            .text(detail.codes[i].substr(0,1))
            
            DetailBox
            .append('rect')
            .attr('id',`detail_namebox_${i}`)
            .attr('x', 47).attr('y', y_-11)
            .attr('width', 15).attr('height', 15)
            .attr('fill', 'none').attr('stroke', '#ddd')

            DetailBox
            .append('line')
            .attr('x1', 35).attr('x2', 47)
            .attr('y1', y_-3).attr('y2', y_-3)
            .attr('stroke', '#ddd')
            
            this.group[`${this.detail.group[i]}`].push(y_)
            
            this.detail.item.name.push(10 + this.getNodeElValue(`#detail_name_${i}`, 'width') + 20)
            if(detail.type[i] == 'TEXT') this.draw_detailText(DetailBox, detail.codes[i], 20, y_, i)
            if(detail.type[i] == 'TRIM') {
              this.detail.wrapSize =  this.detail.width - this.detail.item.name[i] - 5
              this.draw_detailText(DetailBox, detail.codes[i], this.detail.item.name[i], y_, i, this.detail.type[i])
            }
            if(detail.type[i] == 'BAR') this.draw_detailBarChart(DetailBox, detail.codes[i], 101, y_, i)
            
            if(detail.type[i] == 'TRIM') y_ += 0
            y_ += detail.lineHeight
          }
        }) 

        DetailBox
        .append('line')
        .attr('x1', 35).attr('x2', 35)
        .attr('y1', y_-87).attr('y2', y_-detail.lineHeight-3)
        .attr('stroke', '#ddd')

        y_ += 15
      })

    },

    draw_OverallStatus(selection, x_, y_, i, c_, type_, fData) {
      selection
      .append('rect')
      .attr('id', `OVTUBE_TOT_${i}`)
      .attr('x', x_).attr('y', y_)
      .attr('width', 40).attr('height', 110)
      .attr('rx', 5).attr('ry', 5)
      .attr('fill', c_).attr('opacity', 0.2)

      selection
      .append('rect')
      .attr('id', `OVTUBE_ACT_${i}`)
      .attr('x', x_).attr('y', y_ + 110 - this.tubeScale(fData.length))
      .attr('width', 40).attr('height', this.tubeScale(fData.length))
      .attr('rx', 5).attr('ry', 5)
      .attr('fill', `url(#${this.localId}_${c_}_${type_})`)

      selection
      .append('text')
      .attr('x', x_ + 20).attr('y', y_ - 5)
      .attr('text-anchor', 'middle')
      .attr('font-size', this.detail.tSize).attr('fill', '#aaa')
      .style('cursor', 'pointer')
      .text(this.targetData.length - fData.length)
      .on('click', () => this.link_TOWD({type: type_ , col: 'REM'}))

      let prog = fData.length / this.targetData.length * 100
      let margin = 0
      prog > 60 ? margin = 5 : margin = -5

      selection
      .append('text')
      .attr('x', x_ + 20).attr('y', y_ + 110 - this.tubeScale(fData.length) + margin)
      .attr('text-anchor', 'middle').attr('alignment-baseline', prog > 60 ? 'hanging' : '')
      .attr('font-size', this.detail.tSize).attr('fill', '#aaa')
      .style('cursor', 'pointer')
      .text(fData.length)
      .on('click', () => this.link_TOWD({type: type_ , col: 'ACT'}))

      selection
      .append('text')
      .attr('transform',`translate(${x_ + 20}, ${y_ + 120})`)
      .attr('font-size', this.detail.tSize).attr('fill', '#333')
      .attr('text-anchor', 'middle')
      .text(type_)

      if (i == 1) {
        selection
        .append('path')
        .attr('d', `M ${50} ${y_+123} L ${50} ${y_+135} L ${x_+20} ${y_+135} L ${x_+20} ${y_+123}`)
        .attr('fill', 'none')
        .attr('stroke', '#ddd')
        .attr('stroke-width', 1)

        selection
        .append('rect')
        .attr('transform', `translate(${x_/2 + 10}, ${y_+127})`)
        .attr('width', 50)
        .attr('height', 17)
        .attr('fill', '#fff')
        .attr('stroke', '#ddd')
        .attr('stroke-width', 1)

        selection
        .append('text')
        .attr('transform', `translate(${x_/2 + 35}, ${y_+139})`)
        .attr('font-size', this.detail.tSize).attr('text-anchor', 'middle')
        .style('cursor', 'pointer')
        .text(this.targetData.length)
        .on('click', () => this.link_TOWD({type: type_ , col: 'TOT'}))
      }
    },

    draw_detailText(selection, code_, x_, y_, i, type_) {
      selection
      .append('text')
      .attr('id', `TEXT_${i}`)
      .attr('x', x_).attr('y', y_)
      .attr('font-size', this.detail.tSize)
      .attr("dy", '0em')
      .attr('fill', '#000').attr('text-anchor', 'start')
      .text('-')
      .call(this.trimText, `#TEXT_${i}`, type_) // call trimText
    },
    
    trimText(text_, id_, type_) {
      if(type_ !== 'TRIM') return

      let getText = text_.text()
      for(var i=0; i < getText.length; i++){
   
        text_.text(getText.substr(0,i))
        if(this.getNodeElValue(id_, 'width') > this.detail.wrapSize - 15) {
          text_.text(`${getText.substr(0,i)}...`)
          return
        }
      }
    },


    draw_detailBarChart(selection, code_, name_x, y_, i, sub_) {
      let total = 0
      let actual = 0
      let remain = 0

      if (sub_) {
        this.targetData.filter(f => f.SUBSYS_NO == sub_).forEach((d,i) => {
          total += d[`${code_}_TOT`]
          actual += d[`${code_}_ACT`]
          remain += d[`${code_}_REM`]
        })
      } else {
        this.targetData.forEach((d,i) => {
          total += d[`${code_}_TOT`]
          actual += d[`${code_}_ACT`]
          remain += d[`${code_}_REM`]
        })
      }

      selection // TOT
      .append('text')
      .attr('id', `DETAIL_TOT_${i}`)
      .attr('x', name_x).attr('y', y_)
      .attr('font-size', this.detail.tSize - 1)
      .attr('fill', this.detail.tColor[i]).attr('text-anchor', 'end')
      .text(total)
      .style('cursor', 'pointer')
      .on('click', () => this.link_Index({item: code_ , col: 'TOT'}))

      let val_X = 0 // this.getNodeElValue(`#DETAIL_TOT_${i}`, 'width')

      let pro_ = total == 0 ? 0 : (actual / total) * 100
      let actX = (pro_/100) * 100

      selection // ACT Background
      .append('rect')
      .attr('id', `DETAIL_BAR_BG_${i}`)
      .attr('x', name_x + val_X + 2).attr('y', y_ - 9.5)
      .attr('width', 100).attr('height', 12)
      .attr('fill', '#E0E0DF').attr('fill-opacity', .3)

      selection // ACT Bar Chart
      .append('rect')
      .attr('id', `DETAIL_BAR_ACT_${i}`)
      .attr('x', name_x + val_X + 2).attr('y', y_ - 9.5)
      .attr('width', actX).attr('height', 12)
      .attr('fill', this.detail.bColor[i])

      selection // ACT Value
      .append('text')
      .attr('id', `DETAIL_ACT_VALUE_${i}`)
      .attr('x', pro_ > 60 ? name_x + val_X + actX : name_x + val_X + actX + 4)
      .attr('y', y_)
      .attr('font-size', this.detail.tSize - 1)
      .attr('fill', (pro_ > 60) ? '#333': this.detail.tColor[i])
      .attr('text-anchor', pro_ > 60 ? 'end' : 'start')
      .text(actual)
      .style('cursor', 'pointer')
      .on('click', () =>  this.link_Index({item: code_ , col: 'ACT'}))

      selection // REM Value
      .append('text')
      .attr('id', `DETAIL_REM_${i}`)
      .attr('x', name_x + val_X + 100 + 4).attr('y', y_)
      .attr('font-size', this.detail.tSize - 1)
      .attr('fill', this.detail.tColor[i]).attr('text-anchor', 'start')
      .text(remain)
      .style('cursor', 'pointer')
      .on('click', () =>  this.link_Index({item: code_ , col: 'REM'}))
    },


    mouseOver_Detail(skyNo) {
      if(!this.Queries.SQL3) return

      let d = this.targetData.find(f=> f[this.skyline.refColumn] == skyNo)

      // Group Name
      // d3.select(`#SKY_NO`).transition().duration(200)
      // .text(skyNo)
      
      this.detail.codes.forEach((c,i)=> {
        d3.select(`#detail_name_${i}`)
        .attr('fill', d[`${c}_TOT`] == 0 ? '#EEEEEE' : '#000')

        d3.select(`#TEXT_${i}`)
        .text(d[`${c}`])
        .call(this.trimText, `#TEXT_${i}`, this.detail.type[i]) // call - trimText

        // TOT
        if(this.detail.type[i] == 'BAR') {
          d3.select(`#DETAIL_TOT_${i}`)
          .text(d[`${c}_TOT`])
          .attr('fill', d[`${c}_TOT`] == 0 ? '#EEEEEE' : this.detail.tColor[i])
        }
      })

      let x_ = 0
      let y_ = 240

      d3.select('#Detail_Group')
      .append('rect')
      .attr('x', x_).attr('y', y_-10)
      .attr('width', this.detail.width).attr('height', 240)
      .attr('fill', '#fff').attr('stroke', '#ddd')
      
      this.subsystem_Detail(x_+10, y_-5, skyNo, '', d, 13)
      y_ += 15

      this.viewSubsys.title.forEach((c,j) => {
        this.subsystem_Detail(x_+10, y_, c, this.viewSubsys.value[j], d, this.detail.tSize, j)

        y_ += 20
      })

      this.detail.codes.forEach((c,j) => {
        d3.select('#Detail_Group')
        .append('text')
        .attr('x', x_+10).attr('y', y_)
        .attr('font-size', this.detail.tSize)
        .attr('fill', '#333')
        .text(c.substr(1,1) == 'P' ? this.detail.group[j] + ' ' + this.detail.name[j] : this.detail.name[j] + ' ' + this.detail.group[j])

        this.draw_detailBarChart(d3.select('#Detail_Group'), c, x_ + 110, y_, j, skyNo)

        y_ += 20
      })

      this.detail.codes.forEach((c,i)=> {
        // Bar Chart
        if(this.detail.type[i] == 'BAR') {
          
          d3.select(`#DETAIL_TOT_${i}`).transition().duration(200)
          .text(d[`${c}_TOT`])

          let name_x = 70 // this.detail.item.name[i]
          let val_X = 0 // this.getNodeElValue(`#DETAIL_TOT_${i}`, 'width')
          let pro_ = d[`${c}_PRO`]
          let actX = (pro_/100) * 75

          // ACT Background
          d3.select(`#DETAIL_BAR_BG_${i}`).transition().duration(200)
          .attr('x', name_x + val_X + 2)
          .attr('fill-opacity', d[`${c}_TOT`] == 0 ? 0.05 : .3)

          // ACT Bar Chart
          d3.select(`#DETAIL_BAR_ACT_${i}`).transition().duration(200)
          .attr('x', name_x + val_X + 2)
          .attr('width', actX)

          // ACT Value
          d3.select(`#DETAIL_ACT_VALUE_${i}`).transition().duration(200)
          .attr('x', d[`${c}_PRO`] > 70 ? name_x + val_X + actX  :  name_x + val_X + actX + 4)
          .attr('text-anchor', d[`${c}_PRO`] > 70 ? 'end' : 'start')
          .attr('fill', ()=> {
            if(d[`${c}_TOT`] == 0) return '#EEEEEE' 
            else { 
              return this.detail.tColor[i]
            }
          })
          .text(d[`${c}_ACT`])

          // REM Value
          d3.select(`#DETAIL_REM_${i}`).transition().duration(200)
          .attr('x', name_x + val_X + 100 + 4)
          .attr('fill', d[`${c}_TOT`] == 0 ? '#EEEEEE' : this.detail.tColor[i])
          .text(d[`${c}_REM`])
        }
      })
    },

    subsystem_Detail(x_, y_, title_, value_, data_, tSize_, idx_) {
      if (value_ == '') {
        d3.select('#Detail_Group')
        .append('rect')
        .attr('x', x_-3).attr('y', y_-12)
        .attr('width', 87).attr('height', 16)
        .attr('fill', '#fff').attr('stroke', '#ddd')
      } else {
        d3.select('#Detail_Group')
        .append('text')
        .attr('id', `value_${idx_}`)
        .attr('transform', `translate(${x_ + 100}, ${y_})`)
        .attr('font-size', tSize_)
        .attr('fill', '#333')
        .text(data_[value_])
        .call(this.trimText2, `#value_${idx_}`, this.detail.width - (x_ + 110))
      }
      
      d3.select('#Detail_Group')
      .append('text')
      .attr('transform', `translate(${x_}, ${y_})`)
      .attr('font-size', tSize_)
      .attr('fill', '#333')
      .text(title_)

      if (value_ == 'FORECAST_DATE') {
        d3.select(`#value_${idx_}`)
        .attr('fill', data_['FC_MODIFIED'] == 'Y' ? '#ff0d00' : '#757575')
      }
    },

    trimText2 (text_, id_, size_) {
      if (this.getNodeElValue(id_, 'width') <= size_) return

      let getText = text_.text()
      for (var i=getText.length; i > 0; i--) {
   
        text_.text(getText.substr(0,i))
        if (this.getNodeElValue(id_, 'width') < size_) {
          text_.text(`${getText.substr(0,i)}...`)
          return
        }
      }
    },

  }
}
