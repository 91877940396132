<template>
  <div :id="localId" class="svg_wrapper"></div>
</template>

<script>
import mx_Core from './includes/mixins/zMixins'        
import * as d3 from 'd3'
export default {
  name: "j-chart-project-1",
  mixins: [mx_Core],
  props: {
    id: String,
    modeExport: Boolean,
    getSelectItems: String,  

    FilterValues: { type: Object, default: () => ({}) },
    ColumnProps: { type: Array, default: () => ([]) },
    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    Note: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    Queries: { type: Object, default: () => ({}) },
  },


  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
    // console.log('this.id:', this.localId)
  },

  mounted() {
    this.register(this.draw)
  },

  methods: {
    draw() {
      if (!this.ready2draw) {
        return
      }

      this.clear()
      this.drawCanvas()
      this.drawing() // <------ Drawing Start
      this.drawNotes()
      this.drawTexts()


      // for export pdf
      this.getItems = this.getSelectItems ? JSON.parse(this.getSelectItems) : {}

      if(this.getSelectItems){
        if(this.getItems) {
          //Duration
          if(this.getItems.start_X) this.start_X = this.getItems.start_X
          if(this.getItems.finish_X) this.finish_X = this.getItems.finish_X
          if(this.getItems.bar_Width) this.bar_Width = this.getItems.bar_Width
          if(this.getItems.start_X && this.getItems.finish_X && this.getItems.bar_Width) this.duration_Drag()
          //Chart Inforamtion
          if(this.getItems.chart_List){
            this.condition_Pdf = JSON.parse(JSON.stringify(this.getItems.chart_List[0])) 
          }
          //fcStatus
          if(this.getItems.fc_Status) this.fcStatus = this.getItems.fc_Status
          //Chart Gen Status   
          
          if(this.getItems.chart_Status == 'ON'){
            if(this.getItems.chart_Count != -1){
              this.condition_Pdf.forEach((d,i) =>{
                this.schedule.sDate = d.sDate
                this.schedule.fDate = d.fDate
                this.schedule.currResource = d.curve
                this.getItems.fc_Status == 'QTY'? this.quantity_Charts() : this.turnover_Charts()
              })
            }
          } else {
            this.clear_Charts()  
          } 
          //Help
          if(this.getItems.action=='HELP'){
            this.getItems.help_Status == 'RESOURCE'? this.help_Resource() : this.help_Duration()
          }

        } 
        
      }
    },

    drawing() {
      // this.set_Gradients()
      this.set_LocalValues()
      this.set_Gradients()
      this.draw_Header()

      this.drawNotes()
      this.drawTexts()
    },

  }
}
</script>



<!-- <style lang="scss" scoped>
  .svg_wrapper {
    width: 1300px;
    height: 170px;

    overflow: hidden;
  }
</style> -->