import Defs from '../../../../../../includes/primitives/colorDefs'
import * as d3 from 'd3'

export default {
  data: () => ({
    Area_Canvas:    null,
    Area_Chart:     null,

    //pdf
    selectedItems : null,
    getItems      : null,
    
    rawData:        null,
    Overall:        null,
    Discipline:     null,
    Turnover:       null,
    cutOff:         null,

    canvas:         null,
    timeline:       null,
    axis:           null,
    charts:         null,

    grid:           null,
    barWidth:       null,
    scale:          {primary: null, secondary: null},

    status: [
      {item: 'A', disc: 'OV', checked: true},
      {item: 'B', disc: 'OV', checked: true},
    ],

    current_Unit: 'OV',

    // 마지막으로 라디오버튼을 누른 항목 찾기(초기값은 A Punch, OV)
    latestChecked: {
      item: 'AP',
      disc: 'OV',
    },

  }),


  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },


  methods: {

    setDefaultValues(){
      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      this.Overall = JSON.parse(JSON.stringify(this.Queries.SQL1[0]))
      this.Discipline = JSON.parse(JSON.stringify(this.Queries.SQL2))
      this.Turnover = JSON.parse(JSON.stringify(this.Queries.SQL3))

      this.Discipline.forEach((d, i) => {
        d.ITEM = `${d.ITEM}P`
        i == 0 ? d.checked = true : d.checked = false
        d.maxCR_Wk = this.getMaxBound_v2(d3.max(this.rawData.filter(f=> f.ITEM == d.ITEM && f.DISC == d.DISC), c => c.CREATED_WK))
        d.maxCL_Wk = this.getMaxBound_v2(d3.max(this.rawData.filter(f=> f.ITEM == d.ITEM && f.DISC == d.DISC), c => c.CLOSED_WK))
        d.max_Wk = d.maxCR_Wk > d.maxCL_Wk ? d.maxCR_Wk : d.maxCL_Wk
        d.max_Cumul = this.getMaxBound_v2(d3.max(this.rawData.filter(f=> f.ITEM == d.ITEM && f.DISC == d.DISC), c => c.REMAIN_CUM))
      })
      // console.log(this.Discipline)

      let sql4 = JSON.parse(JSON.stringify(this.Queries.SQL4[0]))
      
      // Cnavas ---------------------------------------------------------------------------
      this.canvas = {
        width           : sql4['CANVAS_WIDTH'],
        height          : sql4['CANVAS_HEIGHT'],
        
        padding : {
          top           : sql4['PADDING_TOP'],
          right         : sql4['PADDING_RIGHT'],
          bottom        : sql4['PADDING_BOTTOM'],
          left          : sql4['PADDING_LEFT'],
        },
        chart : {
          width         : sql4['CANVAS_WIDTH'] - sql4['PADDING_LEFT'] - sql4['PADDING_RIGHT'],
          height        : sql4['CANVAS_HEIGHT'] - sql4['PADDING_TOP'] - sql4['PADDING_BOTTOM'],
        },
      }

      // Timeline ------------------------------------------------------------------------------------
      this.timeline = {
        length          : this.canvas.chart.width,
        startDate       : sql4['START_DATE'],
        endDate         : sql4['END_DATE'],
        weekEnd         : sql4['WEEKEND'],
        day             : [],
        week            : [],
        month           : [],
        year            : [],
        scale           : null,

        cutoff          : sql4['CUTOFF_DATE'],
      }


      // Grids
      this.grid = []
      let height = this.canvas.chart.height / 2
      for (let i = 0; i < 6; i++) {
        this.grid.push(height - ((height / 5) * i))
      } 
      
      this.init_gmx_TimelineValues(this.timeline, 'day')
      this.barWidth = this.canvas.chart.width / this.timeline.week.length
      
    },


    set_Gradients() {
      // set Gradient color for the Timeline
      Defs.setDefs_Gradients(this.svg, 'V', 'LinearA4', 'LightBlue', [.8, .5, .0, .5], this.localId)
      Defs.setDefs_Gradients(this.svg, 'V', 'LinearA4', 'Yellow', [.8, .5, .0, .5], this.localId)
      Defs.setDefs_Gradients(this.svg, 'V', 'LinearA4', 'YellowGreen', [.8, .5, .0, .5], this.localId)
    },

    set_selectedItems(){  
      this.selectedItems = { 
        ...this.selectedItems,
        latestChecked:this.latestChecked,
      }
    },    
  }
}