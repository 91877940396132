import gmx_zMixins         from '../../../../../../includes/Jmixins/zMixins'

// Locally declared common Data-Props & SVG Drawing Support

import lmx_watching             from './Watching'
import lmx_Events               from './Events'

// Charts 
import lmx_LocalValues          from './set_LocalValues'
import lmx_Forms                from './draw_Forms'
import lmx_draw_Legends         from './draw_Legends'
import lmx_draw_Timeline        from './draw_Timeline'

import lmx_draw_Header          from './draw_Header'
import lmx_draw_Summary         from './draw_Summary'
import lmx_draw_Skyline         from './draw_Skyline'
import lmx_draw_Charts          from './draw_Charts'

import lmx_redraw_Charts        from './redraw_Charts'
import lmx_redraw_Overall       from './redraw_Overall'


export default {
  mixins: [
    gmx_zMixins, 

    // Local Mixins
    lmx_watching,
    lmx_Events,

    lmx_LocalValues,
    lmx_Forms,
    lmx_draw_Legends,
    lmx_draw_Timeline,

    lmx_draw_Summary,
    lmx_draw_Header,
    lmx_draw_Skyline,
    lmx_draw_Charts,
    
    lmx_redraw_Charts,
    lmx_redraw_Overall,

  ],
}
