import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms(no_) {
      if(this.Area_Header) this.Area_Header.remove()
      
      let unit = this.rawData.find(f=> f.UNIT_NO == no_)
      
      this.Area_Header = this.svg
      .append('g')
      .attr('transform', 'translate(0, 0)')

      this.Area_Header
      .append('text')
      .attr('transform', `translate(20, 25)`)
      .style('font-family', 'roboto').style('font-size', 16).style('fill', '#000')
      .text(unit.UNIT)

      this.Area_Header
      .append('text')
      .attr('transform', `translate(20, 40)`)
      .attr('font-size', 13).attr('fill', '#757575')
      .text(`${unit.UNIT_NO}-${unit.UNIT_DESC}`)
      
      // Keyplan
      this.loadSvg(
        this.Area_Header, {
        x   : 0,
        y   : 50,
        vb: '1 1  1625  750',
        url : `/CRISP/SYSTEM/UNIT_SUMMARY/Keyplan_Modal.svg`
      }).then(() => {
        d3.select(`#MKEY-${no_}`).style('fill-opacity', 1) 
        d3.select(`#MKEY-${no_}`).style('opacity', 1) 
      })

      // load SVG
      this.innerSvg = this.svg.append('svg')
      if(this.Area_Header) this.Area_Header.moveToFront()

      this.loadSvg(
        this.innerSvg, {
        x   : 0,
        y   : 0,
        url : `/CRISP/SYSTEM/UNIT_SUMMARY/SVGs/${no_}.svg`
      }).then(() => {
        d3.selectAll('text').style('font-family', 'roboto')
        
        this.draw_Summary(unit)
      })
    
    },

  }
}