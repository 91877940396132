import moment from 'moment'
import * as d3 from 'd3'

export default {
  methods: {

    draw_Summary(unit) {

      let ballon = this.innerSvg
      .append('g')
      .attr('id', `Ballon_Group`)
      .attr('transform', `translate(0, 0.5)`)
      .attr('font-family', 'roboto').attr('opacity', 0)
      .style('cursor', 'default')

      ballon
      .append('path')
      .attr('transform', `translate(-7, -22)`)
      .attr('d', `M74.2,0.2h-72c-1.1,0-2,0.9-2,2v11c0,1.1,0.9,2,2,2H4l2.8,7l-0.2-7h67.6c1.1,0,2-0.9,2-2v-11 C76.2,1.1,75.3,0.2,74.2,0.2z`)
      .attr('fill', '#fff').attr('stroke', '#FAAA18').attr('stroke-width', 1).attr('stroke-opacity', .5)

      ballon
      .append('text')
      .attr('id', `Ballon_Text`)
      .attr('x', -3.5).attr('y', -10.5)
      .attr('font-size', 11).attr('fill', '#333').attr('font-weight', 430)
      .text(`21100-U-6102`)

      let summary = this.innerSvg
      .append('g')
      .attr('transform', `translate(20, 365)`)
      .attr('font-family', 'roboto').style('cursor', 'default')

      summary
      .append('text')
      .attr('x', 0).attr('y', 12)
      .attr('font-size', 12).attr('fill', '#333').attr('font-weight', 400)
      .text(`${unit.UNIT}`)

      summary
      .append('text')
      .attr('x', 0).attr('y', 23)
      .attr('font-size', 9).attr('fill', '#bcbcbc')
      .text(`${unit.UNIT_DESC}`)
      
      this.draw_System(summary, unit)
      this.draw_Turnover(this.innerSvg, unit, 'Turnover', 'SSMCC', 190, '#83D2F5')
      this.draw_Turnover(this.innerSvg, unit, 'Walkdown', 'WD', 210, '#FCE3EB')
    },


    draw_System(selection, unit) {

      let getColor = '#8BC248'
      let skyline = selection
      .append('g')
      .attr('transform', `translate(0.5, -3.5)`)
      .attr('font-family', 'roboto').attr('font-size', 9)

      let x = 0, count = 0
      this.timeline.week.forEach((w, i) => {

        let y = -8
        let dateFiltered = this.Systems.filter(f => f.UNIT == unit.UNIT && f.cDate == w.eDate)

        dateFiltered.forEach((d, i) => {
          let system = skyline
          .append('g')
          .attr('id', `SSYS_${d.SUBSYS_NO}`)
          .attr('class', `${x}_${y}`)
          .attr('transform', `translate(${x}, ${y})`)
          .attr('font-family', 'roboto').style('cursor', 'pointer')
          .on('mouseover', function() {
            let className = d3.select(this).attr('class').split('_').map(Number)
            let x_ = className[0] + 26
            let y_ = className[1] + 360 + 2
            d3.select(`#Ballon_Group`).moveToFront().attr('transform', `translate(${x_}, ${y_})`).attr('opacity', 1)
            d3.select(this).transition().duration(150).attr('opacity', .5)
            d3.select(`#Ballon_Text`).text(d.SUBSYS_NO)
          })
          .on('mouseout', function() {
            d3.select(`#Ballon_Group`).attr('opacity', 0)
            d3.select(this).transition().duration(150).attr('opacity', 1)
          })
          .on('click', ()=> {
            this.call_Subsystem(d.UNIT, d.SUBSYS_NO) // --> Event.js
          })

          system
          .append('rect')
          .attr('x', 0).attr('y', 0)
          .attr('height', 9).attr('opacity', 1)
          .attr('width', 14)
          .attr('stroke', '#bcbcbc').attr('stroke-width', 1).attr('stroke-opacity', .5)
          .attr('fill', '#fff')

          let TOT = d.A_TOT + d.B_TOT
          let ACT = d.A_ACT + d.B_ACT
          let PRO = ACT/TOT

          system
          .append('rect')
          .attr('x', 0).attr('y', 0)
          .attr('height', 9).attr('opacity', 1)
          .attr('width', ()=> {
            let len = 14
            if(ACT > 0) len = 14*PRO
            if(d.CSTD_ACTUAL) len =  14
            return len
          })
          .attr('stroke', 'none').attr('stroke-width', 1).attr('stroke-opacity', .5)
          .attr('fill', ()=> {
            let color = '#EFEFEF'
            if(ACT > 0) color = '#FEED57'
            if(d.CSTD_ACTUAL) color =  getColor
            return color
          })

          y -= 11
        })
        if(dateFiltered.length > 0) {
          x += 17
          count += 1
        }
      })

      let lineLen = (count*17) + 120
      if(lineLen<250) lineLen = 250

      skyline
      .append('line')
      .attr('x1', 0).attr('y1', 3.5)
      .attr('x2', lineLen).attr('y2', 3.5).attr('opacity', .4)
      .attr('stroke', '#757575').attr('stroke-width', 1)
      
      skyline
      .append('text')
      .attr('x', lineLen).attr('y', 0)
      .attr('font-size', 10).attr('fill', '#bcbcbc')
      .attr('text-anchor', 'end')
      .text('Days to turnover')

      skyline
      .append('text')
      .attr('x', lineLen).attr('y', 13)
      .attr('font-size', 11).attr('fill', '#757575')
      .attr('text-anchor', 'end')
      .text(unit.MAX_PLAN)

      let days = moment(unit.MAX_PLAN).diff(moment(new Date()),'days')
      
      skyline
      .append('text')
      .attr('x', lineLen).attr('y', -10)
      .attr('font-size', 15)
      .attr('fill', days>0 ? '#83D2F5' : '#EC407A')
      .attr('text-anchor', 'end')
      .text(`${days} Days`)

    },



    draw_Turnover(selection, d, title, code, y_, color_) {
      let ITRs = selection
      .append('g')
      .attr('transform', `translate(50, ${y_})`)
      .attr('font-family', 'roboto').attr('font-size', 10)

      ITRs
      .append('text')
      .attr('x', -30).attr('y', 0)
      .attr('fill', '#333').attr('font-size', 10)
      .text(title)
      
      // Total
      ITRs
      .append('text')
      .attr('x', 33).attr('y', 0).style('cursor', 'pointer')
      .attr('fill', '#757575').attr('text-anchor', 'end')
      .text(d[`${code}_TOT`])
      .call(this.call_SSMCC, {unit: d.UNIT, code: code, cols: 'TOT'}) // <---- Event.js

      ITRs
      .append('rect')
      .attr('x', 35).attr('y', -9).attr('opacity', .2)
      .attr('width', 70).attr('height', 10).attr('fill', '#E0E0DF')

      let PRO = (d[`${code}_PRO`]/100) * 70
      ITRs
      .append('rect')
      .attr('x', 35).attr('y', -9).attr('opacity', .7)
      .attr('width', PRO).attr('height', 10).attr('fill', color_)

      // Actual
      ITRs
      .append('text')
      .attr('y', 0).attr('fill', '#757575')
      .attr('x', d[`${code}_PRO`] < 60 ? 35 + PRO + 2 : 55 + PRO - 2)
      .attr('text-anchor', d[`${code}_PRO`] < 60 ? 'start' : 'end')
      .text(d[`${code}_ACT`])
      .style('cursor', 'pointer')
      .call(this.call_SSMCC, {unit: d.UNIT, code: code, cols: 'ACT'}) // <---- Event.js

      // Remain
      ITRs
      .append('text')
      .attr('x', 107).attr('y', 0)
      .attr('fill', '#757575').attr('text-anchor', 'start')
      .text(d[`${code}_REM`])
      .style('cursor', 'pointer')
      .call(this.call_SSMCC, {unit: d.UNIT, code: code, cols: 'REM'}) // <---- Event.js

    },
    
  }
}