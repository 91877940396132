import * as d3 from 'd3'

export default {
  methods: {

    draw_Header() {
      // Background of Header
      this.Area_Canvas = this.svg
      .append('g')
      .attr('transform',`translate(0, 0)`)
      .style('font-family', 'roboto').style('cursor', 'default')

      let defs = this.svg.append('defs')
      let headRect = defs.append('radialGradient')
      .attr('id', `GradientShadow`).attr('cx', .5).attr('cy', .7).attr('r', .3)
      headRect.append('stop').attr('stop-color', '#000000').attr('offset', '.06').attr('stop-opacity', 1)
      headRect.append('stop').attr('stop-color', '#E6E6E6').attr('offset', '1').attr('stop-opacity', 1)

      // Background
      let shadow = defs.append('linearGradient')
      .attr('id', `GradientBG`).attr('x1', 0).attr('x2', 0).attr('y1', 0).attr('y2', 1)
      shadow.append('stop').attr('stop-color', '#E0E0DF').attr('offset', '0').attr('stop-opacity', .5)
      shadow.append('stop').attr('stop-color', '#BDBCBC').attr('offset', '1').attr('stop-opacity', .5)

      this.Area_Canvas
      .append('ellipse')
      .attr('cx', 600).attr('cy', 205)
      .attr('rx', 580).attr('ry', 9)
      .attr('fill', 'url(#GradientShadow)').attr('stroke', '#E0E0DF').attr('stroke-width', 0)
      
      this.Area_Canvas
      .append('rect')
      .attr('x', 10).attr('y', 10.5)
      .attr('rx', 5).attr('ry', 5)
      .attr('width', this.canvas.width-20).attr('height', 200)
      .attr('fill', '#fff').attr('stroke', '#E0E0DF').attr('stroke-width', .5)

      this.Area_Canvas
      .append('rect')
      .attr('x', 12).attr('y', 12.5)
      .attr('rx', 4).attr('ry', 4)
      .attr('width', this.canvas.width-24).attr('height', 196)
      .attr('fill', 'url(#GradientBG)').attr('stroke', '#E0E0DF').attr('stroke-width', .5)

      // Title of Header
      let Header = this.Area_Canvas
      .append('g')
      .attr('transform',`translate(20.5, 20.5)`)

      Header
      .append('text')
      .attr('transform',`translate(${5}, 17)`)
      .attr('font-size', 22)
      .attr('fill', '#000').attr('text-anchor', 'start')
      .text(this.Overall.UNIT == 'OV' ? `S-Curve for Grassroots` : `S-Curve for ${this.Overall.UNIT}`)

      Header
      .append('text')
      .attr('transform',`translate(${5}, 30)`)
      .attr('font-size', 13).attr('fill', '#757575').attr('text-anchor', 'start')
      .text(this.Overall.UNIT_DESC == 'OV' ? 'MC & Pre-Commissioning Status by Discipline' : this.Overall.UNIT_DESC)

      // Layout Lines
      let line = [
        {x1: 0,      y1:56,  x2: 425,   y2: 56  },
        {x1: 215,    y1:60,  x2: 215,   y2: 180 },
        {x1: 430,    y1:0,   x2: 430,   y2: 180 },
        {x1: 850,    y1:0,   x2: 850,   y2: 180 },
      ]
      line.forEach(d=> {
        Header
        .append('line')
        .attr('x1', d.x1).attr('y1', d.y1).attr('x2', d.x2).attr('y2', d.y2).attr('stroke', '#bcbcbc').attr('stroke-width', 0.5)
      })
      

      this.draw_Timeline()
      this.draw_Grid()
      this.draw_Axis_Primary()
      this.draw_Axis_Secondary()

      this.header_Sheets()
      this.header_Discipline()
      this.last_Turnover()

      this.draw_Chart_Controller()
      this.draw_Legends()
    },

  }
}