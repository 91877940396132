import moment from 'moment'
export default {

  methods: {

    set_PlanValues(sch){
      
      this.db_Total = 0
      this.durationCurveData = this.get_durationCurveData(sch.sDate, sch.fDate, sch.max, sch.limite)
      // this.durationCurveData.forEach((d,i)=> {console.log(d.days, d.volume)}) // ----------------> Console

      this.rawData.forEach((d,i)=> {
        let getDuration = this.get_DurationDays(d[this.schedule.code])
        d.SDATE = moment(moment(d.CSTD_PLAN).add(-(getDuration + this.schedule.fDate - 1), 'days')).format('YYYY-MM-DD')
        d.FDATE = moment(moment(d.CSTD_PLAN).add(-this.schedule.fDate, 'days')).format('YYYY-MM-DD')
        d.BUDGET = []
      
        let getValues = []
        getValues = this.assign_RecourceCurve(this.schedule.currResource,getDuration, d[this.schedule.code])

        let nextDay = moment(moment(d.SDATE).format('YYYY-MM-DD 23:59:59')).toDate()
        for(let i=0; i<getDuration; i++) {
          let value_ = {
            cDate: moment(nextDay).format('YYYY-MM-DD'),
            value: getValues[i]
          }
          d.BUDGET.push(value_)
          nextDay = moment(nextDay).add(1, 'days').toDate()
        }
        this.db_Total += d[this.schedule.code]
      })
      

      // Daily cumulative -------------------------------------------------------
      this.timeline.day.forEach((time,t)=> {
        this.rawData.forEach((d,i)=> {
          let fValue = d.BUDGET.find(f=> f.cDate == time.cDate)
          if(fValue) time.value += fValue.value
        })
      })


      // Get Weekly cumulative --------------------------------------------------
      let chart_Total_Qty = 0
      let cd = this.condition_List.find(f=> f.count == this.chart_Count)

      this.timeline.week.forEach((wk,w)=> {
        let fromDate = moment(moment(wk.eDate).add(-6, 'days').format('YYYY-MM-DD')).toDate()
        let arrWeek = this.timeline.day.filter(f=> moment(f.cDate) >= fromDate && moment(f.cDate) <= wk.eDate).map(d=> {return d.value})
        wk.value = arrWeek.reduce((a, b) => (a + b))
        chart_Total_Qty += wk.value
        wk.cumul = chart_Total_Qty

        // Peak Time
        if(cd.pk_max < wk.value) {
          cd.pk_idx = w
          cd.pk_date = wk.eDate
          cd.pk_max = parseInt(wk.value)
        }
      })
      let weeks9 = this.timeline.week.filter((f,i)=> i >= (cd.pk_idx-this.peakTime) && i <= (cd.pk_idx+this.peakTime)).map(m=> {return m.value})
      cd.pk_avg = parseInt(weeks9.reduce((a, b) => (a + b))/(this.peakTime*2+1))
      // console.log(cd)


      // Week Cumulative Progress(%) --------------------------------------------
      this.timeline.week.forEach((wk,w)=> {
        wk.pro = (wk.cumul/chart_Total_Qty) * 100
      })
      console.log('TOTAL:', parseInt(chart_Total_Qty), '/', this.db_Total)
    },

    
  }
}