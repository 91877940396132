import * as d3 from 'd3'

export default {
  methods: {

    draw_Header() {

      let forms = this.Area_Canvas
      .append('g')
      .attr('transform', `translate(40, 60)`)
      .attr('font-family', 'roboto').style('cursor', 'default')

      forms
      .append('text')
      .attr('x', 0).attr('y', 0)
      .attr('font-size', 32).attr('fill', '#333')
      .attr('text-anchor', 'srart').attr('font-weight', 500)
      .text('Radial Chart for Grassroots')

      forms
      .append('text')
      .attr('x', 0).attr('y', 17)
      .attr('font-size', 11).attr('fill', '#757575')
      .attr('text-anchor', 'srart')
      .text('Radial Chart incorporates the sum of ITR A and ITR B.')

      forms
      .append('text')
      .attr('x', 0).attr('y', 30)
      .attr('font-size', 11).attr('fill', '#757575')
      .attr('text-anchor', 'srart')
      .text('Gantt Chart is based on the first and last dates of the sub-system turnover plan.')


      


      

      this.draw_Radial()
      this.draw_Summary()

      this.draw_Timeline()
      this.draw_GantteCharts()

    },

    




  }
}