export default {
  
  methods: {
    
    draw_Radial() {

      let pieCenter = this.Area_Canvas
      .append('g')
      .attr('transform', `translate(${this.pie.x}, ${this.pie.y})`)
      .attr('font-family', 'roboto')
      .style('cursor', 'default')

      let pieTitle = pieCenter
      .append('g')
      .attr('id', `pieTitle`)
      .attr('transform', `translate(0,0)`).attr('opacity', 1)

      pieTitle
      .append('text')
      .attr('x', 0).attr('y', 0)
      .attr('font-size', 20).attr('fill', '#333').attr('font-weight', 450)
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'alphabetic')
      .text('Grassroot')

      pieTitle
      .append('text')
      .attr('x', 0).attr('y', 12)
      .attr('font-size', 11).attr('fill', '#757575')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'alphabetic')
      .text('A + B ITRs Progress(%)')

      let unitStatus = pieCenter
      .append('g')
      .attr('id', `unitSTATUS`)
      .attr('transform', `translate(0,0)`).attr('opacity', 0).attr('font-size', 10)

      unitStatus
      .append('line')
      .attr('x1', -60).attr('y1', 0).attr('x2', 60).attr('y2', 0)
      .attr('stroke', '#bcbcbc').attr('stroke-width', 0.5)

      unitStatus
      .append('text')
      .attr('x', 0).attr('y', -55).attr('font-size', 12)
      .attr('fill', '#000').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(`A ITRs`)

      unitStatus
      .append('text')
      .attr('x', 0).attr('y', 58).attr('font-size', 12)
      .attr('fill', '#000').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(`B ITRs`)

      this.unit_Status(unitStatus, 'A', -38)
      this.unit_Status(unitStatus, 'B', 10)

      this.donut_Controller()
    },


    donut_Controller() {
      let pie = this.pie
      let space = 30
      let fa = space/2
      let sRa = 0.3
      let gap = 1
      let len = null

      let sumTotal = this.Gantt.map(d => d[`${this.ITR}_TOT`]).reduce((a, b) => (a + b))

      // Grids
      this.draw_Grids(sRa)


      this.Gantt.forEach((d, i) => {

        // let angle = (360-space) * (100/this.rawData.length/100)
        let angle = (360-space) * (d[`${this.ITR}_TOT`]/sumTotal)
        
        if(i == 0 || i == 1) angle += 1
        if(i == 2 || i == 3) angle += 1.5
        if(i == 43) angle += 5

        // Total -------------------------
        len = pie.ra
        this.draw_Donut(angle, fa, len, sRa, {
          gap:      gap,
          space:    space,
          bColor:   '#fff', 
          sColor:   '#bcbcbc', 
          sWidth:   .5,
          opacity:  [.1, .5],

          id:       'TOTAL',
          data:     d,
        })

        // Actual -------------------------
        len = (pie.ra * sRa) + ((pie.ra * (1 - sRa)) * (d[`${this.ITR}_PRO`]/100))
        this.draw_Donut(angle, fa, len, sRa, {
          gap:      gap,
          space:    space,
          bColor:   this.color21[i], 
          sColor:   this.color21[i], 
          sWidth:   1,
          opacity:  [1, 1],

          id:       'ACTUAL',
          data:     d,
        })

        // Text
        // this.draw_ArcsText(angle, sRa, fa, {
        //   data:     d.UNIT,
        //   gap:      gap,
        // })

        this.draw_pieText(angle, len, fa, {
          data:     d,
          gap:      gap,
        })
        
        fa = fa + angle
      })
    },


    
    
  }
}