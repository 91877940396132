import moment from 'moment'
import * as d3 from 'd3'

export default {
  methods: {

    draw_Timeline() {    

      let week_Timeline = this.timeline.week.filter(f=> f.eDate >=  new Date(this.timeline.start))
      let month_Timeline = this.timeline.month.filter(f=> f.eDate >=  new Date(this.timeline.start))

      this.timeline.scale = d3.scaleTime()
      .domain([
        week_Timeline[0].sDate, 
        week_Timeline[week_Timeline.length - 1].eDate
      ])
      .rangeRound([0, this.timeline.length])

      this.Area_Screen = this.svg
      .append('g')
      .attr('id', 'SCREEN')
      .attr('transform', `translate(${this.canvas.padding.left+.5}, ${this.canvas.padding.top+.5})`)
      .attr('font-family', 'roboto') // rage / roboto


      let Timeline = this.Area_Screen
      .append('g')
      .attr('transform', `translate(0, ${this.canvas.chart.height+.5})`)
      .style('font-family', 'roboto')

      Timeline
      .append('line')
      .attr('x1', 0).attr('y1', 0).attr('x2', this.canvas.chart.width).attr('y2', 0)
      .attr('stroke', '#bcbcbc').attr('storke-width', .3)

      // Week Text
      Timeline
      .append('g')
      .selectAll('text')
      .data(week_Timeline) // *** week
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate)).attr('y', 4)
      .style('font-size', 9).attr('fill', '#757575')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
      .attr('cursor', 'default')
      .text(d => d.name)

      Timeline
      .append('g')
      .selectAll('path')
      .data(week_Timeline) // *** week
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        if (i != week_Timeline.length-1) return `M${this.timeline.scale(d.eDate)+ 1}, 0 V 4` // *** week
      })
      .attr('stroke', '#bcbcbc')
      .attr('stroke-width', .5)

      // cutoff Week highlight
      let cutoff = moment(this.timeline.cutoff).format('YYYY-MM-DD')

      this.timeline.week.forEach(d => {
        if (moment(d.eDate).format('YYYY-MM-DD') == cutoff) {
          Timeline
          .append('rect')
          .attr('x',this.timeline.scale(d.sDate) + 1)
          .attr('y',.5)
          .attr('width', this.timeline.scale(d.eDate) - this.timeline.scale(d.sDate))
          .attr('height',14)
          .style('opacity', 0.3)
          .attr('fill','#00b0f0')
          .attr('stroke','#bcbcbc')
          .attr('stroke-width',0.5)               
        }
      })
      
      // Month Text
      Timeline
      .append('g')
      .selectAll('text')
      .data(month_Timeline) // *** week
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate)).attr('y', 15)
      .style('font-size', 9).attr('fill', '#757575')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
      .attr('cursor', 'default')
      .text(d => d.name)

      Timeline
      .append('g')
      .selectAll('path')
      .data(month_Timeline) // *** week
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        if (i != month_Timeline.length-1) return `M${this.timeline.scale(d.eDate)+ 1}, 0 V 22` // *** week
      })
      .attr('stroke', '#bcbcbc')
      .attr('stroke-width', .5)

      // Year Text & Timeline Seperator for the Year
      Timeline
      .append('g')
      .selectAll('text')
      .data(this.timeline.year)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate))
      .attr('y', 28)
      .style('font-size', 10)
      .attr('fill', '#757575')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
      .attr('cursor', 'default')
      .text(d => d.name)

      Timeline
      .append('g')
      .selectAll('path')
      .data(this.timeline.year)
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        if (i != this.timeline.year.length-1) return `M${this.timeline.scale(d.eDate) + 1}, 0 V 35`
      })
      .attr('stroke', '#bcbcbc')
      .attr('stroke-width', .5)
    }, 


    
    draw_Grid() {
      let sGrid = this.Area_Screen
      .append('g')
      .attr('transform', `translate(0, 0)`)
      
      this.grid.forEach(d=> {
        sGrid
        .append('line')
        .attr('x1', 0)
        .attr('x2', this.canvas.chart.width)
        .attr('y1', d)
        .attr('y2', d)
        .attr('stroke', '#bcbcbc')
        .attr('stroke-width', .5)
        .attr('opacity', .3)
      })
    },
    


    draw_Axis_Primary() {
      let maxPrimary = 7500

      this.scale.Primary = d3.scaleLinear()
      .domain([0, maxPrimary])
      .range([this.canvas.chart.height, 0])

      this.grid.forEach((d,i)=> {
        this.Area_Screen
        .append('line')
        .attr('x1', - 5)
        .attr('x2', 0)
        .attr('y1', d)
        .attr('y2', d)
        .attr('stroke', '#bcbcbc')
        .attr('stroke-width', .5)

        this.Area_Screen
        .append('text')
        .attr('id', `Axis_Primary_${i}`)
        .attr('transform', `translate(${-7}, ${d + 1})`)
        .attr('font-size', 9)
        .attr('fill', '#757575')
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .text((maxPrimary) * (i*2)/10)
      })

      this.Area_Screen
      .append('line')
      .attr('x1', 0).attr('y1', 0)
      .attr('x2', 0).attr('y2', this.canvas.chart.height)
      .attr('stroke', '#bcbcbc').attr('stroke-width', .5)

      this.Area_Screen
      .append('text')
      .attr('transform', `translate(${-45}, ${this.canvas.chart.height/2}) rotate(-90)`)
      .attr('font-size', 12).attr('fill', '#333')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('No of Checksheet (week)')
    },



    draw_Axis_Secondary() {

      this.scale.Secondary = d3.scaleLinear()
      .domain([0, 100])
      .range([this.canvas.chart.height, 0])
      
      this.grid.forEach((d,i)=> {
        this.Area_Screen
        .append('line')
        .attr('x1', this.canvas.chart.width)
        .attr('x2', this.canvas.chart.width + 5)
        .attr('y1', d)
        .attr('y2', d)
        .attr('stroke', '#bcbcbc')
        .attr('stroke-width', .5)

        this.Area_Screen
        .append('text')
        .attr('transform', `translate(${this.canvas.chart.width + 7}, ${d+1})`)
        .attr('font-size', 9)
        .attr('fill', '#757575')
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text(100 * (i*2)/10)
      })

      this.Area_Screen
      .append('line')
      .attr('x1', this.canvas.chart.width).attr('y1', 0)
      .attr('x2', this.canvas.chart.width).attr('y2', this.canvas.chart.height)
      .attr('stroke', '#bcbcbc').attr('stroke-width', .5)

      this.Area_Screen
      .append('text')
      .attr('transform', d => `translate(${this.canvas.chart.width + 45}, ${this.canvas.chart.height/2}) rotate(-90)`)
      .attr('font-size', 12).attr('fill', '#333')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('Progress % (Cumulative)')


      // Last Subsystem Turn-over
      let maxTO = 0
      this.rawData.forEach(d=> {
        if(moment(d.CSTD_PLAN) > moment(maxTO)) maxTO = (moment(d.CSTD_PLAN)).toDate()
      })
   
      this.Area_Screen
      .append('line')
      .attr('x1', this.timeline.scale(maxTO)).attr('y1', 0)
      .attr('x2', this.timeline.scale(maxTO)).attr('y2', this.canvas.chart.height)
      .attr('stroke', '#757575').attr('stroke-width', .3)

      this.Area_Screen
      .append('line')
      .attr('x1', this.timeline.scale(maxTO)).attr('y1', 0)
      .attr('x2', this.timeline.scale(maxTO)-20).attr('y2', -20)
      .attr('stroke', '#757575').attr('stroke-width', .2)

      this.Area_Screen
      .append('rect')
      .attr('x', this.timeline.scale(maxTO)-105).attr('y', -31)
      .attr('width', 85).attr('height', 13).attr('fill', '#fff')
      .attr('stroke', '#757575').attr('stroke-width', .2)

      this.Area_Screen
      .append('text')
      .attr('x', this.timeline.scale(maxTO)-24).attr('y', -22)
      .attr('font-size', 9).attr('fill', '#757575').attr('text-anchor', 'end')
      .text('Last T/O Plan Date')
    },
    



    

    
  }
}