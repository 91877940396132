import * as d3 from 'd3'

export default {
  methods: {

    call_Search(input=null) {
      
      let data_List = JSON.parse(JSON.stringify(this.rawData))
      this.search.results = []

      // apply search input ------------------------
      if(input != null) this.search.input = input
      input = input.toLowerCase()
      
      this.search.results = input ? data_List.filter(f => this.search.targets.some(col => f[this.skyline.refColumn].toLowerCase().includes(input))) : []
      this.filtering_Search(this.legends.sheetStatus) // ---> below
    },

    link_Skyline(code_) {

      // set for link key value   ex) let dataSource = {KEY1:d.PKG,KEY2:d.DISC...}
      let dataSource = {} // DO not Remark if possible 

      dataSource = {[`KEY1`]: code_}

      // set for request from A/G jsonProps
      let request_ = this.getRequestColumnProps('request', dataSource, this.ColumnProps)
      if(!request_) return

      // set for filterString 
      let filterString_ = ''

      // if A/G filterString is exist, set request_.iFilters.filterString + filterString_
      if(request_.iFilters.filterString) {
        request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
      } else {
        request_.iFilters.filterString = filterString_||''
      }

      // combine filterString and inputFilter &&& emit request-action
      request_ = {...request_,
        filters : {
          ...this.FilterValues,
          ...request_.filters,
        },
        iFilters: { 
          filterString : request_.iFilters.filterString||'',
          inputFilter : request_.iFilters.inputFilter,},
      }
      this.$emit('request-action', request_)
    },

    call_ITRs(selection, d) {
      selection
        .on('mouseover', function() {
          d3.select(this).transition().duration(50).style('fill', '#EC407A')
        })
        .on('mouseout', function() {
          d3.select(this).transition().duration(50).style('fill', '#757575')
        })
        .on('click', ()=> {
          let code_ = d.item.substr(0,1)
          // set for link key value   ex) let dataSource = {KEY1:d.PKG,KEY2:d.DISC...}
          let dataSource = {} // DO not Remark if possible 

          let reqText = (d.item == 'A' || d.item == 'B') ? `JSON${d.item}` : `JSONP`

          // set for request from A/G jsonProps
          let request_ = this.getRequestColumnProps(reqText, dataSource, this.ColumnProps)
          if(!request_) return
          
          // set for filterString 
          let filterString_ = ''

          if (d.type == 'TOT') {
            filterString_ += `[SCOPE] = 'Grassroots' AND [CATEGORY] = '${code_}'`
          } else if (d.type == 'ACT') {
            filterString_ += `[SCOPE] = 'Grassroots' AND [CATEGORY] = '${code_}' AND [STATUS] = 'C'`
          } else {
            filterString_ += `[SCOPE] = 'Grassroots' AND [CATEGORY] = '${code_}' AND [STATUS] <> 'C'`
          }

          if (d.disc) filterString_ += ` AND [DISC] = '${d.disc}'`

          // if A/G filterString is exist, set request_.iFilters.filterString + filterString_
          if(request_.iFilters.filterString) {
            request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
          } else {
            request_.iFilters.filterString = filterString_||''
          }

          // combine filterString and inputFilter &&& emit request-action
          request_ = {...request_,
            filters : {
              ...this.FilterValues,
              ...request_.filters,
            },
            iFilters: { 
              filterString : request_.iFilters.filterString||'',
              inputFilter : request_.iFilters.inputFilter,},
          }
          this.$emit('request-action', request_)
        })
    },

    filtering_Search() {

      let sheetStatus = this.sheetStatus

      this.rawData.forEach(d => {
        d.visibility = 'N'
        d.translucence = 'N'

      })

      // Filtering Sheet Status -----------------------------------------------------------------------------------
      this.rawData.forEach(d => {

        if (this.search.input == '') {
          if(sheetStatus == 'TOTAL' || sheetStatus == d[`A_STATUS`]) d.visibility = 'Y'
        } else {
          if(
              this.search.results.find(f => f[this.skyline.refColumn] == d[this.skyline.refColumn]) && 
              (sheetStatus == 'TOTAL' || sheetStatus == d[`A_STATUS`])
            ) d.visibility = 'Y'
        }

      })
      // this.reDraw_Sheet()
      
      // Visibility Execution ------------------------------------------------
      this.rawData.forEach(d => {
        if(d.visibility == 'Y') {
          d3.select(`#BOX_${d[this.skyline.refColumn]}`).transition().duration(750)
          .attr('opacity', 1).attr('cursor', 'pointer')
          d.hover = 'Y'
        } else {
          d3.select(`#BOX_${d[this.skyline.refColumn]}`).transition().duration(750)
          .attr('opacity', 0).attr('cursor', 'default')
          d.hover = 'N'
        }
      })

    },

    reDraw_Sheet() {
      let filteredSheet = this.rawData.filter(f=> f.visibility == 'Y')

      this.count_Sheet(filteredSheet)
      // console.log('Sheet Values: ', this.sheet.values) // ---

      this.sheet.styles.forEach(d => {
        d3.select(`#Sheet_text_${d.status}`)
        .text(`${d.text} (${this.sheet.values[d.status]})`)
      })
    },

  }
}