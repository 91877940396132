import * as d3 from 'd3'

export default {

  methods: {
    header_TrendsChart() {

      // Trend History -----------------------------------------------------------------------------------
      let trends = this.svg
      .append('g')
      .attr('transform',`translate(380, 15)`)
      .style('cursor', 'default')

      trends
      .append('text')
      .attr('x', 0).attr('y', 20).attr('font-size', 15)
      .attr('fill', this.status.mColor).attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Last 12 weeks Trends Chart')

      trends
      .append('text')
      .attr('x', 0).attr('y', 33).attr('font-size', 10.5)
      .attr('fill', this.status.sColor).attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Number of created and closed A checksheet by week')

      trends
      .append('rect')
      .attr('x', 0).attr('y', 44)
      .attr('width', 9).attr('height', 9)
      .attr('fill', this.status.sColor)

      trends
      .append('text')
      .attr('x', 11).attr('y', 50).attr('font-size', 9)
      .attr('fill', this.status.sColor).attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Created')

      trends
      .append('rect')
      .attr('x', 50).attr('y', 44)
      .attr('width', 9).attr('height', 9)
      .attr('fill', this.status.nColor)

      trends
      .append('text')
      .attr('x', 61).attr('y', 50).attr('font-size', 9)
      .attr('fill', this.status.nColor).attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Closed')

            
      
      // Summary 12 Weeks
      let summary = trends
      .append('g')
      .attr('transform',`translate(240, -25)`)

      let sum_Closed = 0, sum_Created = 0, j = 0, x_ = 300

      for(let i=11; i>=0; i--) {
        sum_Created += this.weekTrend[i].CREATED_WK
        sum_Closed += this.weekTrend[i].CLOSED_WK

        if(j==3 || j==7 || j==11 ) {
          summary
          .append('rect')
          .attr('x', x_-27.5).attr('y', 37.5)
          .attr('width', 55).attr('height', 16)
          .attr('stroke', this.status.vColor).attr('fill', 'none').attr('stroke-width', .3)

          summary
          .append('text')
          .attr('x', x_).attr('y', 50).attr('font-size', 11)
          .attr('fill', this.status.vColor).attr('text-anchor', 'middle')
          .text(`${(j+1)} weeks`)

          summary
          .append('text')
          .attr('x', x_-5).attr('y', 65).attr('font-size', 11)
          .attr('fill', this.status.sColor ).attr('text-anchor', 'end')
          .text(parseInt(sum_Created/(j+1)))

          summary
          .append('text')
          .attr('x', x_).attr('y', 65).attr('font-size', 11)
          .attr('fill', this.status.vColor).attr('text-anchor', 'middle')
          .text('/')

          summary
          .append('text')
          .attr('x', x_+5).attr('y', 65).attr('font-size', 11)
          .attr('fill', this.status.nColor ).attr('text-anchor', 'start')
          .text(parseInt(sum_Closed/(j+1)))
          x_ -= 70
        }
        j++
      }

      // Button for Forecasting
      let QuantityeBtn = trends
      .append('g')
      .attr('id', 'FORECAST')
      .attr('transform',`translate(480, 160)`)
      .attr('opacity', 1)
      .style('cursor', 'pointer')
      .on('mouseover', () => {
        d3.select(`#GENERATE`).transition().duration(150).attr('opacity', .5)
      })
      .on('mouseout', () => {
        d3.select(`#GENERATE`).transition().duration(150).attr('opacity', 1)
      })
      .on('click', ()=> {
                
        // for export pdf
        this.qty_Min = d3.select('#MIN_QTY').text()
        this.qty_Max = d3.select('#MAX_QTY').text()

        this.quantity_Charts()

        this.set_selectedItems({})

      })

      QuantityeBtn
      .append('rect') 
      .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3)
      .attr('width', 90).attr('height', 25).attr('fill', '#CDDC37').attr('stroke-width', 0)

      QuantityeBtn
      .append('text')
      .attr('x', 45).attr('y', 16).attr('font-size', 11).attr('fill', '#333').attr('text-anchor', 'middle')
      .text('QUANTITY')

      this.chart_Trend(trends)
      this.qty_Boundary(trends)
    },

    quantity_Charts(){

      if(this.fcStatus !== 'QTY') this.clear_Charts()
      // for export pdf
      this.fcStatus = 'QTY'

      // Initial Data
      this.set_LocalValues()

      this.chart_Count += 1
      if(this.chart_Count > 9) {
        this.popup_MaxChart()
        return
      }

      // Push Chart Condition Data
      this.condition_List.push({
        count:    this.chart_Count,
        duration: this.schedule.sDate - this.schedule.fDate,
        sDate:    this.schedule.sDate,
        fDate:    this.schedule.fDate,
        curve:    this.schedule.currResource,
        pk_idx:   0,
        pk_date:  0,
        pk_max:   0,
        pk_avg:   0,
        qty_min:  this.qty_Min,
        qty_max:  this.qty_Max,
      })

      this.set_ForecastValues()
      this.set_Axies(5000)
      this.redraw_ActualBarChart(5000)
      this.forecast_Controller()

      // if(this.chart_Count == 0) this.draw_ConditionHeader()
      // this.draw_Conditions()      
      
      // for export pdf
      this.chart_Status = 'ON'
    },

    chart_Trend(selection) {
      let chart = selection
      .append('g')
      .attr('transform',`translate(20, 20)`)

      let created = this.getMaxBound_v2(d3.max(this.weekTrend, w => w.CREATED_WK))
      let closed = this.getMaxBound_v2(d3.max(this.weekTrend, w => w.CLOSED_WK))
      let max = created > closed ? created : closed

      let height = 100
      let scale = d3.scaleLinear()
      .domain([0, max])
      .range([height, 0])

      chart
      .append('line')
      .attr('x1', -5.5).attr('y1', 50)
      .attr('x2', -5.5).attr('y2', height + 54)
      .attr('stroke', '#757575').attr('stroke-width', .5)

      chart
      .append('line')
      .attr('x1', -5.5).attr('y1', height + 53.5)
      .attr('x2', 420).attr('y2', height + 53.5)
      .attr('stroke', '#757575').attr('stroke-width', .5)

      let x_ = 5
      this.weekTrend.forEach((d,i)=> {

        chart
        .append('rect')
        .attr('id', `TREND_BAR_${i}`)
        .attr('x', x_).attr('y', scale(d.CREATED_WK) + 53)
        .attr('width', 10).attr('height', height - scale(d.CREATED_WK))
        .attr('fill', this.status.sColor)

        chart
        .append('text')
        .attr('id', `TREND_TEXT_${i}`)
        .attr('x', x_+5).attr('y', scale(d.CREATED_WK) + 50).attr('font-size', 8)
        .attr('fill', this.status.sColor).attr('text-anchor', 'middle')
        .text(d.CREATED_WK)

        chart
        .append('rect')
        .attr('id', `TREND_BAR_${i}`)
        .attr('x', x_+13).attr('y', scale(d.CLOSED_WK) + 53)
        .attr('width', 10).attr('height', height - scale(d.CLOSED_WK))
        .attr('fill', this.status.nColor)

        chart
        .append('text')
        .attr('id', `TREND_TEXT_${i}`)
        .attr('x', x_+17).attr('y', scale(d.CLOSED_WK) + 50).attr('font-size', 8)
        .attr('fill', this.status.nColor).attr('text-anchor', 'middle')
        .text(d.CLOSED_WK)

        chart
        .append('text')
        .attr('x', x_+10).attr('y', 163).attr('font-size', 9)
        .attr('fill', this.status.vColor).attr('text-anchor', 'middle')
        .text(`${12-i} wk`)

        x_ += 35
      })
    },

    qty_Boundary(selection) {
      selection
      .append('text')
      .attr('x', 515).attr('y', 117).attr('font-family', 'roboto')
      .attr('font-size', 11).attr('fill', '#333').attr('text-anchor', 'end')
      .text('MAX:')

      selection
      .append('rect')
      .attr('x', 520).attr('y', 100.5).attr('rx', 2).attr('ry', 2)
      .attr('width', 50).attr('height', 22)
      .attr('stroke','#757575').attr('stroke-width', .3).attr('fill', '#fff')

      selection
      .append("foreignObject")
      .attr('x', 520).attr('y', 100.5)
      .attr('width', 50).attr('height', 22)
      .append('xhtml:div')
      .append('div')
      .attr('id', 'MAX_QTY')
      .attr('contentEditable', true)
      .attr('style', `padding: 3px 5px; font-size: 12px; line-height: 16px; text-align: right;`)
      .text(this.qty_Max)
      .on('keydown', (_, i, a) => {
        this.register(() => { 
          let text = d3.select(a[i]).text()
          if(text.length > 5) d3.select(a[i]).text(text.substring(0, 5))
        },0) 
      })

      selection
      .append('text')
      .attr('x', 515).attr('y', 145).attr('font-family', 'roboto')
      .attr('font-size', 11).attr('fill', '#333').attr('text-anchor', 'end')
      .text('MIN:')

      selection
      .append('rect')
      .attr('x', 520).attr('y', 130.5).attr('rx', 2).attr('ry', 2)
      .attr('width', 50).attr('height', 22)
      .attr('stroke','#757575').attr('stroke-width', .3).attr('fill', '#fff')

      selection
      .append("foreignObject")
      .attr('x', 520).attr('y', 130.5)
      .attr('width', 50).attr('height', 22)
      .append('xhtml:div')
      .append('div')
      .attr('id', 'MIN_QTY')
      .attr('contentEditable', true)
      .attr('style', `padding: 3px 5px; font-size: 12px; line-height: 16px; text-align: right;`)
      .text(this.qty_Min)
      .on('keydown', (_, i, a) => {
        this.register(() => { 
          let text = d3.select(a[i]).text()
          if(text.length > 5) d3.select(a[i]).text(text.substring(0, 5))
        },0) 
      })
    },

  }
}
