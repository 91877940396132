<template>
  <div :id="localId" class="svg_wrapper"></div>
</template>

<script>
import mx_Core from './includes/mixins/zMixins'        
import * as d3 from 'd3'
export default {
  name: "j-chart-project-1",
  mixins: [mx_Core],
  props: {
    id: String,
    modeExport: Boolean,
    getSelectItems: String,  

    FilterValues: { type: Object, default: () => ({}) },
    ColumnProps: { type: Array, default: () => ([]) },
    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    Note: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    Queries: { type: Object, default: () => ({}) },
  },


  created() {
    this.localId = `${this.id || 'j-chart-custom'}_${this.safeId('')}`
    // console.log('this.id:', this.localId)
  },

  mounted() {
    this.register(this.draw)
  },

  methods: {
    draw() {
      if (!this.ready2draw) {
        return
      }

      this.clear()
      this.drawCanvas()
      this.drawing() // <------ Drawing Start
    },

    drawing() {
      this.set_LocalValues()
      this.draw_Forms(this.unit.count) // this.unit.count
      this.draw_Controller()

      // for export pdf
      this.getItems = this.getSelectItems ? JSON.parse(this.getSelectItems) : {}
      // console.log('this.getSelectItems',this.getSelectItems?'TRUE':'FALSE' )
      // console.log('this.getItems',this.getItems?'TRUE':'FALSE' )
      // console.log('this.slideX',this.getItems.slideX?'TRUE':'FALSE' )
      if(this.getSelectItems){
        //console.log('this.getItems', this.getItems)
        if(this.getItems) {
          if(this.getItems.slideX){
            d3.select(`#SLIDE_${this.localId}`)
            .attr('transform', `translate(${this.getItems.slideX}, 40)`)
          }
        }
      }
    },

  }
}
</script>



<!-- <style lang="scss" scoped>
  .svg_wrapper {
    width: 1300px;
    height: 230px;

    overflow: hidden;
  }
</style> -->