import * as d3 from 'd3'

export default {
  methods: {

    /* Jinkee
      draw_barChart() 함수에서 호출됨 -> draw_Summary.js
      {
        CP21_ID:   CP21_ID, 
        item:     SSMCC / WD / A / B / C / AP / BP / CP, 
        coltype:  TOT / ACT / REM, 
      }
    */
    call_Summary(selection, d) {
      selection
      .on('mouseover', function() {
        d3.select(this).transition().duration(50).style('fill', '#EC407A')
      })
      .on('mouseout', function() {
        d3.select(this).transition().duration(50).style('fill', '#333')
      })
      .on('click', ()=> {
        this.link_Action(d)
      })
    },


    // link_Action(grp_,scope_,code_,coltype_) {
    link_Action(link_) {
      // set for link key value   ex) let dataSource = {KEY1:d.PKG,KEY2:d.DISC...}
      let dataSource = {}
      // if delValue is 'Y', set dataSource
      // if(this.delValue.length > 0){
      //   this.delValue.forEach((d, i) => {
      //     dataSource = {...dataSource, [`KEY${i+1}`]: this.rawData[_style.idx][d]}
      //   })
      // }
      let reqtext = `JSON${(link_.item =='SSMCC'||link_.item =='WD') ? 'SSMCC' : link_.item}`  // JSONSSMCC , JSONWD , JSONSYS, JSONA, JSONAP (JSONB, JSONBP )

      // set for request from A/G jsonProps
      let request_ = this.getRequestColumnProps(reqtext, dataSource, this.ColumnProps)
      if(!request_) return

      // if filterCol is exist, set filterString_
      let filterString_ = ''

      let cp21_ = link_.CP21_ID
      let coltype_ = link_.coltype
      let code_ = link_.item
      
      // OVERALL SUMMARY SUBSYSTEM 
      if( code_ == 'SSMCC' || code_ == 'WD') {

        if( code_ == 'SSMCC' && coltype_ == 'TOT'){
          filterString_ += `[CP21_ID] LIKE '%${cp21_}%'`
        } else if ( code_ == 'SSMCC' && coltype_ == 'ACT'){
          filterString_ += `[CP21_ID] LIKE '%${cp21_}%' AND [CSTD_ACTUAL] IS NOT NULL`
        } else if ( code_ == 'SSMCC' && coltype_ == 'REM'){
          filterString_ += `[CP21_ID] LIKE '%${cp21_}%' AND [CSTD_ACTUAL] IS NULL`
        } else if ( code_ == 'WD' && coltype_ == 'TOT'){
          filterString_ += `[CP21_ID] LIKE '%${cp21_}%'`
        } else if ( code_ == 'WD' && coltype_ == 'ACT'){
          filterString_ += `[CP21_ID] LIKE '%${cp21_}%' AND [CSTD_WALKDOWN] IS NOT NULL`
        } else if ( code_ == 'WD' && coltype_ == 'REM'){
          filterString_ += `[CP21_ID] LIKE '%${cp21_}%' AND [CSTD_WALKDOWN] IS NULL`
        }
      // SHEET & PUNCH
      }else{        
        code_ = (link_.item == 'A'||link_.item == 'B')? link_.item : link_.item.substr(0,1)

        if(coltype_ == 'TOT'){
          filterString_ += cp21_ == 'OV'? `[CATEGORY] = '${code_}'` : `[CP21_ID] LIKE '%${cp21_}%' AND [CATEGORY] = '${code_}'`
        } else if(coltype_ == 'ACT'){
          filterString_ += cp21_ == 'OV'? `[CATEGORY] = '${code_}' AND [STATUS] = 'C'` :`[CP21_ID] LIKE '%${cp21_}%' AND [CATEGORY] = '${code_}' AND [STATUS] = 'C'`
        } else {
          filterString_ += cp21_ == 'OV'? `[CATEGORY] = '${code_}' AND [STATUS] <> 'C'`:`[CP21_ID] LIKE '%${cp21_}%' AND [CATEGORY] = '${code_}' AND [STATUS] <> 'C'`
        }
      }

      // if A/G iFilters is exist, (filterString_ + iFilters)
      if(request_.iFilters.filterString) {
        request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
      } else {
        request_.iFilters.filterString = filterString_||''
      }

      // combine filterString 
      request_ = {...request_,
        filters : {
          ...this.FilterValues,
          ...request_.filters,
        },
        iFilters: { 
          filterString : request_.iFilters.filterString||'',
          inputFilter : request_.iFilters.inputFilter,},
      }

      console.log('filterString_: ',filterString_)
      this.$emit('request-action', request_)
    },
  }
}