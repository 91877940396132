import * as d3 from 'd3'

export default {

  methods: {

    popup_MaxChart() {
      
      let Alert_Canvas = this.svg
      .append('g')
      .attr('id', 'ALERT_WINDOW')
      .attr('transform',`translate(0, 0)`)
      .attr('opacity', 1)

      Alert_Canvas
      .append('rect')
      .attr('x', 0).attr('y', 0)
      .attr('width', this.canvas.width).attr('height', this.canvas.height)
      .attr('fill', '#fff').attr('opacity', .75)


      let alertWidth = 400
      let alertHeight = 140
      
      let Alert_MaxChart = Alert_Canvas
      .append('g')
      .attr('transform',`translate(${(this.canvas.width/2)-(alertWidth/2)}, ${(this.canvas.height/2)-(alertHeight/2)})`)
      .attr('font-family', 'roboto')

      Alert_MaxChart
      .append('rect')
      .attr('x', 0).attr('y', 0).attr('rx', 10).attr('ry', 10)
      .attr('width', alertWidth).attr('height', alertHeight)
      .attr('stroke', '#757575').attr('stroke-width', .5).attr('stroke-opacity', .3)
      .attr('fill', '#FCE3EB').attr('opacity', 1)

      Alert_MaxChart
      .append('path')
      .attr('transform',`translate(20, 20) scale(2.5)`)
      .attr('d', this.icon.alert).attr('fill', '#E22767').attr('opacity', 1)

      Alert_MaxChart
      .append('text')
      .attr('x', 110).attr('y', 35).attr('font-size', 16).attr('fill', '#E22767')
      .text('Alert!')

      Alert_MaxChart
      .append('text')
      .attr('x', 110).attr('y', 60)
      .attr('font-size', 12).attr('fill', '#000')
      .text('You can no longer generate a chart.')

      Alert_MaxChart
      .append('text')
      .attr('x', 110).attr('y', 75)
      .attr('font-size', 12).attr('fill', '#000')
      .text('Press the Clear Charts button to generate again.!')

      let button = Alert_MaxChart
      .append('g')
      .attr('transform',`translate(290, 95.5)`)
      .attr('font-family', 'roboto').style('cursor', 'pointer').attr('opacity', 1)
      .on('mouseover', function() {
        d3.select(this).transition().duration(150).attr('opacity', .5)
      })
      .on('mouseout', function() {
        d3.select(this).transition().duration(150).attr('opacity', 1)
      })
      .on('click', ()=> {
        d3.select('#ALERT_WINDOW').remove()
      })

      button
      .append('rect')
      .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3)
      .attr('width', 70).attr('height', 25)
      .attr('stroke', '#757575').attr('stroke-width', .5)
      .attr('fill', '#bcbcbc').attr('opacity', 1)

      button
      .append('text')
      .attr('x', 25).attr('y', 17).attr('font-size', 15).attr('fill', '#fff')
      .text('OK')
    },

    help_Duration() {

      let Help_Duration = this.svg
      .append('g')
      .attr('id', 'HELP_DURATION')
      .attr('transform',`translate(0, ${this.canvas.padding.top - 70})`)
      .attr('opacity', 1)

      Help_Duration
      .append('rect')
      .attr('x', 0).attr('y',  - (this.canvas.padding.top-70))
      .attr('width', this.canvas.width).attr('height', this.canvas.padding.top - 70)
      .attr('fill', '#fff').attr('opacity', .5)

      this.loadSvg(
        Help_Duration, {
        x: 0,
        y: 0,
        url : `/CRISP/PLANNING/SVGs/HELP_DURATION.svg`
      }).then((svg) => {

        let button = Help_Duration
        .append('g')
        .attr('transform',`translate(1050, 560.5)`)
        .attr('font-family', 'roboto').style('cursor', 'pointer').attr('opacity', 1)
        .on('mouseover', function() {
          d3.select(this).transition().duration(150).attr('opacity', .5)
        })
        .on('mouseout', function() {
          d3.select(this).transition().duration(150).attr('opacity', 1)
        })
        .on('click', ()=> {
          d3.select('#HELP_DURATION').remove()
        })
  
        button
        .append('rect')
        .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3)
        .attr('width', 100).attr('height', 25)
        .attr('stroke', '#757575').attr('stroke-width', .5)
        .attr('fill', '#44A9DF').attr('opacity', 1)
  
        button
        .append('text')
        .attr('x', 30).attr('y', 17).attr('font-size', 15).attr('fill', '#fff')
        .text('Close')

      })

    },

    help_Resource() {

      let Help_Resource = this.svg
      .append('g')
      .attr('id', 'HELP_RESOURCE')
      .attr('transform',`translate(0, ${this.canvas.padding.top - 70})`)
      .attr('opacity', 1)

      Help_Resource
      .append('rect')
      .attr('x', 0).attr('y',  - (this.canvas.padding.top-70))
      .attr('width', this.canvas.width).attr('height', this.canvas.padding.top - 70)
      .attr('fill', '#fff').attr('opacity', .5)

      this.loadSvg(
        Help_Resource, {
        x: 0,
        y: 0,
        url : `/CRISP/PLANNING/SVGs/HELP_RESOURCE.svg`
      }).then((svg) => {

        let button = Help_Resource
        .append('g')
        .attr('transform',`translate(1050, 560.5)`)
        .attr('font-family', 'roboto').style('cursor', 'pointer').attr('opacity', 1)
        .on('mouseover', function() {
          d3.select(this).transition().duration(150).attr('opacity', .5)
        })
        .on('mouseout', function() {
          d3.select(this).transition().duration(150).attr('opacity', 1)
        })
        .on('click', ()=> {
          d3.select('#HELP_RESOURCE').remove()
        })
  
        button
        .append('rect')
        .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3)
        .attr('width', 100).attr('height', 25)
        .attr('stroke', '#757575').attr('stroke-width', .5)
        .attr('fill', '#44A9DF').attr('opacity', 1)
  
        button
        .append('text')
        .attr('x', 30).attr('y', 17).attr('font-size', 15).attr('fill', '#fff')
        .text('Close')
      })
    },

  }
}
