export default {
  data: () => ({

    formSvg: null,
    legendGroup: null,
    boxGroup: null,

    rawData:    [],
    Title: null,
    subTitle: null,

    timeline:   {},
    skyline:    {},
    legends:    {},
    sheet: {},
    detail:     {},
    filters: [],
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    set_UserValues(){
      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      let sql1 = this.Queries.SQL1[0] // Timeline
      let sql2 = this.Queries.SQL2[0] // Skyline
      let sql3 = null
      if (this.Queries.SQL3) sql3 = this.Queries.SQL3[0] //Legends
      let sql4 = null
      if (this.Queries.SQL4[0]) sql4 = this.Queries.SQL4[0] //Detail Style
 

      // Timeline -------------------------------------------------------------------------------
      this.timeline = {
        baseX           : sql1['X'],
        baseY           : sql1['Y'],
        length          : sql1['LENGTH'],
        cutoff          : sql1['CUTOFF'],
        startDate       : sql1['START'],
        endDate         : sql1['END'],
        refColumn       : sql1['REF_DATE'], // ***
        week            : [],
        month           : [],
        year            : [],
        scale           : null,
        height          : null,
        font            : sql1['FONT'],
      }
      this.timeline.styles = {
        month: {
          height    : sql1['MONTH_HEIGHT'],
          line      : sql1['MONTH_LINE'],
          sWidth    : sql1['MONTH_STROKE_WIDTH'],
          sColor    : sql1['MONTH_STROKE_COLOR'],
          tSize     : sql1['MONTH_TEXT_SIZE'],
          tColor    : sql1['MONTH_TEXT_COLOR'],
        },
        year: {
          height    : sql1['YEAR_HEIGHT'],
          line      : sql1['YEAR_LINE'],
          sWidth    : sql1['YEAR_STROKE_WIDTH'],
          sColor    : sql1['YEAR_STROKE_COLOR'],
          tSize     : sql1['YEAR_TEXT_SIZE'],
          tColor    : sql1['YEAR_TEXT_COLOR'],
       },
      }


      // Skyline Properties -------------------------------------------------------------------------------
      
      this.skyline = {
        titleMain     : sql2['TITLE_MAIN'],
        titleSub      : sql2['TITLE_SUB'],

        skyBox: {
          font        : sql2['BOX_FONT'],
          height      : sql2['BOX_HEIGHT'],
          gap         : sql2['BOX_GAP'],
          radius      : sql2['BOX_RADIUS'],
          tSize       : sql2['BOX_TEXT_SIZE'],
        },
        refColumn     : sql2['REF_COLUMN'], // ***
        scale         : sql2['BOX_HOVER_SCALE'],
      },

      this.search = {
        x             : sql2['SEARCH_X'],
        y             : sql2['SEARCH_Y'],
        visible       : sql2['SEARCH_VISIBLE'],
        note          : sql2['SEARCH_NOTE'],
        targets       : [''],
        input         : '',
        results       : [],
      },

      this.legends = {
        pkgStatus     : 'ALL',
        sheetStatus   : 'TOTAL',
        visible       : sql2['LEGEND_VISIBLE'],
        x             : sql2['LEGEND_X'],
        y             : sql2['LEGEND_Y'],
      }


      // Status Styles -------------------------------------------------------------------------------
      // this.set_Package()
      this.sheet = {
        ref_Status    : sql3['REF_STATUS'],
        prefix        : sql3['SHEET_PREFIX'],
        styles        : [],
        values        : [],
      }
    
      let sheetStatus = sql3['SHEET_STATUS'].replace(/\s/g, '').split('/')

      sheetStatus.forEach((d,i)=> {
        this.sheet.styles[i] = {
          text        :         sql3['SHEET_TITLE'].replace(/\s/g, ' ').split('/')[i],
          status      :         sql3['SHEET_STATUS'].replace(/\s/g, '').split('/')[i],
          type        :         sql3['SHEET_TYPE'].replace(/\s/g, '').split('/')[i],
          color       :         sql3['SHEET_COLOR'].replace(/\s/g, '').split('/')[i],
          tWeight     :         sql3['SHEET_FONT_WEIGHT'].replace(/\s/g, '').split('/')[i],
          tColor      :         sql3['SHEET_FONT_COLOR'].replace(/\s/g, '').split('/')[i],
          sWidth      : Number( sql3['SHEET_STROKE_WIDTH'].replace(/\s/g, '').split('/')[i]),
          sColor      :         sql3['SHEET_STROKE_COLOR'].replace(/\s/g, '').split('/')[i],
          opacity     : Number( sql3['SHEET_OPACITY'].replace(/\s/g, '').split('/')[i]),
        }
      })
      this.set_SkylineStatus() // ---> set_LocalValues.js


      // Detail infomation Box -------------------------------------------------------------------------------
      if(sql4) {
        this.detail = {
          visible:            sql4['DETAIL_VISIBLE'],
          onOff:              sql4['DETAIL_ONOFF'],
          x:                  sql4['DETAIL_X'],
          y:                  sql4['DETAIL_Y'],
          width:              sql4['DETAIL_WIDTH'],
          tSize:              sql4['DETAIL_TEXT_SIZE'],
          lineHeight:         sql4['DETAIL_LINEHEIGHT'],
          codes:              sql4['DETAIL_CODE'].replace(/\s/g, '').split('/'),
          name:               sql4['DETAIL_NAME'].replace(/\s/g, '').split('/'),
          type:               sql4['DETAIL_TYPE'].replace(/\s/g, '').split('/'),
          tColor:             sql4['DETAIL_VALUE_COLOR'].replace(/\s/g, '').split('/'),
          bColor:             sql4['DETAIL_BAR_COLOR'].replace(/\s/g, '').split('/'),
          wrapSize:           null,
          item: {
            name: [],
          },
          title: {
            width:            sql4['DETAIL_TITLE_WIDTH'],
            tColor:           sql4['DETAIL_TITLE_COLOR'],
          }
        }
        this.detail.height =  sql4['DETAIL_LINEHEIGHT'] * this.detail.codes.length + 20
      }
      this.set_SkylineData() // <--- set_LocalValues.js
    },


    


   


  }
}