import * as d3 from 'd3'
import { i } from 'mathjs'

export default {
  methods: {
    
    exe_BindGroup_On(d) {
      
      // Clicked Group 보다 하위 Group의 Y값 구하기
      let heightTotal = this.Gantt.item_Height * this.rawData.filter(f=> f.GROUP == d.name).length 
                      + this.Summary.height * this.rawData.filter(f=> f.GROUP == d.name && f.bind == 'open').length

      // Changing Canvas and _canvas_border_background__ Size 
      this.Canvas.CanvasHeight -= heightTotal
      d3.select(`.canvas`).attr('height', this.Canvas.CanvasHeight)
      d3.select(`._canvas_border_background__${this.localId}`).transition().duration(150).attr('height', this.Canvas.CanvasHeight)
      d3.selectAll(`.tLine`).attr('y2', this.Canvas.CanvasHeight - 100)

      this.MilestoneGroup.forEach((group, g)=> {

        // Click Group보다 아래의 Group들 처리
        if(d.idx < g) { 
          group.y -= heightTotal
          d3.select(`#MS_GROUP_${g}`).transition().duration(150).attr('transform', `translate(${this.Gantt.gx}, ${group.y})`)

          this.rawData.filter(f=> f.GROUP == group.name).forEach(item => {
            item.y -= heightTotal
            d3.select(`#ITEM_GROUP_${item.CODE}`).transition().duration(150).attr('transform', `translate(${this.Gantt.ix}, ${item.y})`)
            d3.select(`#SUMMARY_ITEM_${item.CODE}`).attr('transform', `translate(${this.Gantt.ix}, ${item.y})`).style('visibility', 'hidden')
          })
        }

        // Click Group과 같은 경우 처리
        if(d.idx == group.idx) { 
          d3.select(`#MS_GROUP_TXT_${g}`).attr('cursor', `default`)
          d3.select(`#ICONS_ALL_${g}`).transition().duration(150).attr('opacity', 0)
          setTimeout(() => {
            d3.select(`#BAR_GROUP_PROGRESS_${d.idx}`).transition().duration(150).style('visibility', 'visible')
            d3.select(`#MS_GROUP_${g}`).moveToFront()
          }, 100)
          let cumul_Y = this.Gantt.item_Height + 3
          this.rawData.filter(f=> f.GROUP == group.name).forEach((item, i) => {

            item.y -= cumul_Y
            d3.select(`#ITEM_GROUP_${item.CODE}`).transition().duration(150).attr('transform', `translate(${this.Gantt.ix}, ${item.y})`)
            d3.select(`#SUMMARY_ITEM_${item.CODE}`).attr('transform', `translate(${this.Gantt.ix}, ${item.y})`)

            item.bind == 'open' ? cumul_Y += this.Summary.height + this.Gantt.item_Height : cumul_Y += this.Gantt.item_Height
            setTimeout(() => {
              d3.select(`#SUMMARY_ITEM_${item.CODE}`).transition().duration(150).style('visibility', 'hidden')
              d3.select(`#MS_TABLE_${item.CODE}`).transition().duration(150).style('visibility', 'hidden')
              d3.select(`#BAR_ACT_${item.CODE}`).transition().duration(150).style('visibility', 'hidden')
              d3.select(`#BAR_TXT_${item.CODE}`).transition().duration(150).style('visibility', 'hidden')
              
              if(item.max == 'N') d3.select(`#BAR_DATE_${item.CODE}`).transition().duration(150).style('visibility', 'hidden')
            }, 50)
          })
          group.itemDisplay = 'hidden'
          d3.select(`#ICON_GROUP_${group.idx}`).attr('d', this.icons.plus).attr('fill', `url(#${this.localId}_LightBlue_)`)
        }
      })
      this.pdf_selectedItems() // -----  for PDF
    },


    exe_BindGroup_Off(d) {
      
      let heightTotal = this.Gantt.item_Height * this.rawData.filter(f=> f.GROUP == d.name).length 
                      + this.Summary.height * this.rawData.filter(f=> f.GROUP == d.name && f.bind == 'open').length
      
      // Changing Canvas and _canvas_border_background__ Size 
      this.Canvas.CanvasHeight += heightTotal
      d3.select(`.canvas`).attr('height', this.Canvas.CanvasHeight)
      d3.select(`._canvas_border_background__${this.localId}`).transition().duration(150).attr('height', this.Canvas.CanvasHeight)
      d3.selectAll(`.tLine`).attr('y2', this.Canvas.CanvasHeight - 100)

      this.MilestoneGroup.forEach((group, g)=> {

        // Click Group보다 아래의 Group들 처리
        if(d.idx < g) { 
          group.y += heightTotal
          d3.select(`#MS_GROUP_${g}`).transition().duration(150).attr('transform', `translate(${this.Gantt.gx}, ${group.y})`)

          this.rawData.filter(f=> f.GROUP == group.name).forEach(item => {
            item.y += heightTotal
            d3.select(`#ITEM_GROUP_${item.CODE}`).transition().duration(150).attr('transform', `translate(${this.Gantt.ix}, ${item.y})`)
            d3.select(`#SUMMARY_ITEM_${item.CODE}`).attr('transform', `translate(${this.Gantt.ix}, ${item.y})`)
          })
        }

        // Click Group과 같은 경우 처리
        if(d.idx == group.idx) { 
          d3.select(`#MS_GROUP_TXT_${g}`).attr('cursor', `pointer`)
          d3.select(`#ICONS_ALL_${g}`).transition().duration(150).attr('opacity', 1)
          d3.select(`#BAR_GROUP_PROGRESS_${d.idx}`).style('visibility', 'hidden')

          let cumul_Y = this.Gantt.item_Height + 3
          this.rawData.filter(f=> f.GROUP == group.name).forEach((item, i) => {

            item.y += cumul_Y
            d3.select(`#ITEM_GROUP_${item.CODE}`).transition().duration(150).attr('transform', `translate(${this.Gantt.ix}, ${item.y})`)
            d3.select(`#SUMMARY_ITEM_${item.CODE}`).attr('transform', `translate(${this.Gantt.ix}, ${item.y})`)

            item.bind == 'open' ? cumul_Y += this.Summary.height + this.Gantt.item_Height : cumul_Y += this.Gantt.item_Height
            setTimeout(() => {
              d3.select(`#SUMMARY_ITEM_${item.CODE}`).transition().duration(150).style('visibility', item.bind == 'open' ? 'visible' : 'hidden')
              d3.select(`#MS_TABLE_${item.CODE}`).transition().duration(150).style('visibility', 'visible')
              d3.select(`#BAR_DATE_${item.CODE}`).transition().duration(150).style('visibility', 'visible')
              d3.select(`#BAR_ACT_${item.CODE}`).transition().duration(150).style('visibility', 'visible')
              d3.select(`#BAR_TXT_${item.CODE}`).transition().duration(150).style('visibility', 'visible')
            }, 100)
          })
          group.itemDisplay = 'visible'
          d3.select(`#ICON_GROUP_${group.idx}`).attr('d', this.icons.minus).attr('fill', `url(#${this.localId}_LightGray_ICON)`)
        }
      })
      this.pdf_selectedItems() // ----- for PDF
    },


    exe_Bind_Items(expention, _group, _item) {
      
      let sumHeight = this.Summary.height

      // Changing Canvas and _canvas_border_background__ Size 
      expention == 'open' ? this.Canvas.CanvasHeight += sumHeight : this.Canvas.CanvasHeight -= sumHeight
      d3.select(`.canvas`).attr('height', this.Canvas.CanvasHeight)
      d3.select(`._canvas_border_background__${this.localId}`).transition().duration(150).attr('height', this.Canvas.CanvasHeight)
      d3.selectAll(`.tLine`).attr('y2', this.Canvas.CanvasHeight - 100)

      this.MilestoneGroup.forEach((group, g)=> {
        if(_group.idx < g) { 
          group.y = expention == 'open' ? group.y += sumHeight : group.y -= sumHeight

          d3.select(`#MS_GROUP_${g}`).transition().duration(150)
          .attr('transform', `translate(${this.Gantt.gx}, ${group.y})`)
        }

        this.rawData.filter(f=> f.GROUP == group.name).forEach(item => {
          if(_item.idx == item.idx) {
            item.bind = expention == 'open' ? 'open' : 'closed'
            d3.select(`#ITEM_ICON_${item.CODE}`).text(expention == 'open' ? '-' : '+')
            d3.select(`#SUMMARY_ITEM_${item.CODE}`).transition().duration(150).style('visibility', expention == 'open' ? 'visible' : 'hidden')
          } else if(_item.idx < item.idx) { 
            item.y = expention == 'open' ? item.y += sumHeight : item.y -= sumHeight

            d3.select(`#ITEM_GROUP_${item.CODE}`).transition().duration(150).attr('transform', `translate(${this.Gantt.ix}, ${item.y})`)
            d3.select(`#SUMMARY_ITEM_${item.CODE}`).transition().duration(150).attr('transform', `translate(${this.Gantt.ix}, ${item.y})`)
          }
        })
      })

      // Change Status of Group --------------------
      let getGroup = this.MilestoneGroup.find(f=> f.name == _group.name)

      let cnt_item_TOT = this.rawData.filter(f=> f.GROUP == _group.name).length
      let cnt_item_OPEN = this.rawData.filter(f=> f.GROUP == _group.name && f.bind == 'open').length
      let cnt_item_CLOSED = this.rawData.filter(f=> f.GROUP == _group.name && f.bind == 'closed').length

      // Group Status Change to OPEN
      if(cnt_item_TOT == cnt_item_OPEN) {
        getGroup.bind = 'open'
        d3.select(`#ICONS_ALL_${getGroup.idx}`).attr('points', this.icons.reduce)
      }

      // Group Status Change to CLOSED
      if(cnt_item_TOT == cnt_item_CLOSED) {
        getGroup.bind = 'closed'
        d3.select(`#ICONS_ALL_${getGroup.idx}`).attr('points', this.icons.expand)
      }
      this.pdf_selectedItems() // ----- for PDF
    },
  }
}