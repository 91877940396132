import moment from 'moment'
import * as d3 from 'd3'

export default {
  methods: {

    redraw_Charts(items) {
      // console.log(items)

      d3.select(`#CHECK_${this.filterStatus.idx}`).attr('opacity', 0)
      this.filterStatus = items
      d3.select(`#CHECK_${items.idx}`).attr('opacity', 1)
      
      this.reset_Charts(items)
      this.set_selectedItems(items)

    },


    reset_Charts(items) {

      // Reset Primary Axis
      let maxPrimary = items.max_Cumul
      this.scale.Primary = d3.scaleLinear()
      .domain([0, maxPrimary])
      .range([this.canvas.chart.height, 0])

      // Reset Secondary Axis
      let maxSecondary = items.max_Wk
      this.scale.Secondary = d3.scaleLinear()
      .domain([0, maxSecondary])
      .range([this.canvas.chart.height, 0])

      // Redraw Primary & Secondary Axis Value
      this.grid.forEach((d,i)=> {
        d3.selectAll(`#Axis_Secondary_${i}`).text((maxPrimary) * (i*2)/10)
        d3.selectAll(`#Axis_Primary_${i}`).text(maxSecondary * (i*2)/10)
      })

      let planData = this.rawData.filter(f=> f.DISC == items.DISC)
      let ActualData = planData.filter(f=>f.CDATE <= this.timeline.cutoff)

      let lineFunc = d3.line()
      .x(d => this.timeline.scale(moment(moment(d.CDATE).format('YYYY-MM-DD 23:59:59')).toDate())-(this.barWidth / 2))
      .y(d => this.scale.Primary(d.REMAIN_CUM))
      d3.select(`#LINE_CHART`).transition().duration(500)
      .attr('d', lineFunc(ActualData))
      .attr('stroke', this.barColor[`${items.ITEM}`])
      

      lineFunc = d3.line()
      .x(d => this.timeline.scale(moment(moment(d.CDATE).format('YYYY-MM-DD 23:59:59')).toDate())-(this.barWidth / 2))
      .y(d => this.scale.Primary(d.PL_REMAIN_CUM))
      d3.select(`#PL_LINE_CHART`).transition().duration(500).attr('d', lineFunc(planData))


      // Plan
      planData.forEach((d,i)=> {
        d3.select(`#BAR_CLOSED_PL_${i}`).transition().duration(500)
        .attr('y', this.scale.Secondary(d.PL_CLOSED_WK))
        .attr('height', this.canvas.chart.height - this.scale.Secondary(d.PL_CLOSED_WK))

        d3.select(`#PL_NODE_${i}`).transition().duration(500)
        .attr('cy', this.scale.Primary(d.PL_REMAIN_CUM))
      })

      // Actual
      ActualData.filter(f=>f.CDATE <= this.timeline.cutoff).forEach((d,i)=> {
        d3.select(`#BAR_CLOSED_${i}`).transition().duration(500)
        .attr('y', this.scale.Secondary(d.CLOSED_WK))
        .attr('height', this.canvas.chart.height - this.scale.Secondary(d.CLOSED_WK))
        .attr('fill', this.barColor[`${items.ITEM}`])

        d3.select(`#NODE_${i}`).transition().duration(500)
        .attr('cy', this.scale.Primary(d.REMAIN_CUM))
      })



      // Deviaition & Delta
      let data = this.rawData.find(f=> f.ITEM == items.ITEM && f.DISC == items.DISC && f.CDATE == this.timeline.cutoff)
      
      let devValue = parseInt(data.TOTAL)- parseInt(data.PL_TOTAL)
      d3.select(`#DEV_PLAN`)
      .text(`P6 Total: ${data.PL_TOTAL}`)
      d3.select(`#DEV_ACTUAL`).text(`Hexagon Total: ${data.TOTAL}`)
      d3.select(`#DEV_VALUE`).attr('fill', devValue < 0 ? '#EC407A' : '#44A9DF').text(`Delta: ${devValue}`)

      let deltaValue = parseInt(data.PL_REMAIN_CUM) - parseInt(data.REMAIN_CUM)
      d3.select(`#DELTA_VALUE`)
      .transition()
      .ease(d3.easeLinear)
      .duration(300)
      .tween("text", function() {
        const interpolator = d3.interpolateNumber(d3.select(this).text(), deltaValue)
        return function(t) {
          d3.select(this).text(Math.round(interpolator(t)))
        }
      })
      .attr('fill', deltaValue< 0 ? '#EC407A': '#44A9DF')

      d3.select(`#PLAN_VALUE`)
      .transition()
      .ease(d3.easeLinear)
      .duration(300)
      .tween("text", function() {
        const interpolator = d3.interpolateNumber(d3.select(this).text(), data.PL_REMAIN_CUM)
        return function(t) {
          d3.select(this).text(Math.round(interpolator(t)))
        }
      })

      d3.select(`#ACTUAL_VALUE`)
      .transition()
      .ease(d3.easeLinear)
      .duration(300)
      .tween("text", function() {
        const interpolator = d3.interpolateNumber(d3.select(this).text(), data.REMAIN_CUM)
        return function(t) {
          d3.select(this).text(Math.round(interpolator(t)))
        }
      })

      d3.select(`#DELTA_ICON`)
      .transition()
      .ease(d3.easeLinear)
      .duration(300)
      .attr('fill', deltaValue < 0 ? '#EC407A': '#44A9DF')
      .attr('transform', deltaValue < 0 ? `translate(165, 8) rotate(0)` : `translate(177, 21) rotate(180)`)


      // // Redraw Last Remain Value -------------------------------------------------------------------------------------------------
      // d3.select(`#LAST_VALUE`).transition().duration(500)
      // .attr('y', this.scale.Primary(ActualData.REMAIN_CUM) + 3.5)
      // .tween("text", function() {
      //   const interpolator = d3.interpolateNumber(d3.select(this).text(), ActualData.REMAIN_CUM)
      //   return function(t) {
      //     d3.select(this).text(Math.round(interpolator(t)))
      //   }
      // })
      // d3.select(`#PL_LAST_VALUE`).transition().duration(500)
      // .attr('y', this.scale.Primary(planData.at(-1).PL_REMAIN_CUM) + 3.5)
      // .tween("text", function() {
      //   const interpolator = d3.interpolateNumber(d3.select(this).text(), planData.at(-1).PL_REMAIN_CUM)
      //   return function(t) {
      //     d3.select(this).text(Math.round(interpolator(t)))
      //   }
      // })

    },
  }
}