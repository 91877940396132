import * as d3 from 'd3'
export default {
  
  methods: {

    draw_Radar() {

      let fCht_Radar = this.Area_Canvas
      .append('g')
      .attr('transform', `translate(0.5, 610.5)`)
      .style('font-family', 'roboto').attr('cursor', 'default')

      fCht_Radar
      .append('text')
      .attr('transform',`translate(240, 12)`)
      .attr('font-size', 15).attr('text-anchor', 'start').attr('fill', '#333').attr('text-anchor', 'middle')
      .text('Existing Site')

      fCht_Radar
      .append('text')
      .attr('transform',`translate(240, 26)`)
      .attr('font-size', 11).attr('text-anchor', 'start').attr('fill', '#bcbcbc').attr('text-anchor', 'middle')
      .text('Radar Chart for Overall')


      // Radar Chart - Global Functional Chart
      this.J_radars.series.tColor = ['#333','#333','#F9A3C1','#bcbcbc','#bcbcbc','#bcbcbc','#4CAE4E','#4CAE4E']
      this.J_radars.axes.display = 'N'
      this.J_radars.area.fColor = `url(#${this.localId}_LightBlue_H)`
      this.J_radars.area.sColor = '#44A9DF'
      this.draw_gmx_RadarChart(fCht_Radar, {
        x: 240,
        y: 200,
        size: 250,
        code: 'NAME',
        data: this.Radar.ES,
      })

      fCht_Radar
      .append('text')
      .attr('transform',`translate(1040, 12)`)
      .attr('font-size', 15).attr('text-anchor', 'start').attr('fill', '#333').attr('text-anchor', 'middle')
      .text('Grassroots')

      fCht_Radar
      .append('text')
      .attr('transform',`translate(1040, 26)`)
      .attr('font-size', 11).attr('text-anchor', 'start').attr('fill', '#bcbcbc').attr('text-anchor', 'middle')
      .text('Radar Chart for Overall')

      // Radar Chart - Global Functional Chart
      this.J_radars.series.tColor = ['#333','#333','#F9A3C1','#bcbcbc','#bcbcbc','#bcbcbc','#4CAE4E','#4CAE4E']
      this.J_radars.area.fColor = `url(#${this.localId}_Yellow_H)`
      this.J_radars.area.sColor = '#FFDA00'
      this.J_radars.node.sColor = '#FFDA00'
      this.J_radars.node.fColor = '#FFDA00'
      this.draw_gmx_RadarChart(fCht_Radar, {
        x: 1040,
        y: 200,
        size: 250,
        code: 'NAME',
        data: this.Radar.GR,
      })

    },

  }
}