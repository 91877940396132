import moment from 'moment'
import * as d3 from 'd3'

export default {
  methods: {

    draw_Timeline() {    

      this.Area_Screen = this.svg
      .append('g')
      .attr('id', 'SCREEN')
      .attr('transform', `translate(${this.canvas.padding.left+.5}, ${this.canvas.padding.top+.5})`)
      .attr('font-family', 'roboto') // rage / roboto

      let Timeline = this.Area_Screen
      .append('g')
      .attr('transform', `translate(0, ${this.canvas.chart.height+.5})`)
      .style('font-family', 'roboto').attr('cursor', 'default')

      Timeline
      .append('line')
      .attr('x1', 0).attr('y1', 0).attr('x2', this.canvas.chart.width).attr('y2', 0)
      .attr('stroke', '#bcbcbc').attr('storke-width', .3)

      // Day Text & Timeline Seperator for the Day
      Timeline
      .append('g')
      .selectAll('text')
      .data(this.timeline.week) // *** week
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate)).attr('y', 4)
      .style('font-size', 9).attr('fill', '#757575')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
      .text(d => d.name)

      Timeline
      .append('g')
      .selectAll('path')
      .data(this.timeline.week) // *** week
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        if (i != this.timeline.week.length-1) return `M${this.timeline.scale(d.eDate)+ 1}, 0 V 4` // *** week
      })
      .attr('stroke', '#bcbcbc').attr('stroke-width', .5)


      // cutoff month highlight
      let cutoff = moment(this.timeline.cutoff).format('YYYY-MM-DD')

      this.timeline.week.forEach(d => {
        if (moment(d.eDate).format('YYYY-MM-DD') == cutoff) {
          Timeline
          .append('rect')
          .attr('x',this.timeline.scale(d.sDate) + 1)
          .attr('y',.5)
          .attr('width', this.timeline.scale(d.eDate) - this.timeline.scale(d.sDate))
          .attr('height',14)
          .style('opacity', 0.3)
          .attr('fill','#00b0f0')
          .attr('stroke','#bcbcbc')
          .attr('stroke-width',0.5)               
        }
      })


      // Month Text & Timeline Seperator for the Month
      Timeline
      .append('g')
      .selectAll('text')
      .data(this.timeline.month) // *** week
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate)).attr('y', 15)
      .style('font-size', 9).attr('fill', '#757575')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
      .text(d => d.name)

      Timeline
      .append('g')
      .selectAll('path')
      .data(this.timeline.month) // *** week
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        if (i != this.timeline.month.length-1) return `M${this.timeline.scale(d.eDate)+ 1}, 0 V 22` // *** week
      })
      .attr('stroke', '#bcbcbc').attr('stroke-width', .5)

      // Year Text & Timeline Seperator for the Year
      Timeline
      .append('g')
      .selectAll('text')
      .data(this.timeline.year)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate))
      .attr('y', 28)
      .style('font-size', 10).attr('fill', '#757575')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
      .text(d => d.name)

      Timeline
      .append('g')
      .selectAll('path')
      .data(this.timeline.year)
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        if (i != this.timeline.year.length-1) return `M${this.timeline.scale(d.eDate) + 1}, 0 V 35`
      })
      .attr('stroke', '#bcbcbc').attr('stroke-width', .5)
    }, 


    draw_Grid() {
      let sGrid = this.Area_Screen
      .append('g')
      .attr('transform', `translate(0, 0)`)
      
      this.grid.forEach(d=> {
        sGrid
        .append('line')
        .attr('x1', 0).attr('x2', this.canvas.chart.width)
        .attr('y1', d).attr('y2', d)
        .attr('stroke', '#bcbcbc')
        .attr('stroke-width', .5)
        .attr('opacity', .3)

        sGrid
        .append('line')
        .attr('x1', 0).attr('x2', this.canvas.chart.width)
        .attr('y1',d + this.canvas.chart.height/2).attr('y2', d + this.canvas.chart.height/2)
        .attr('stroke', '#bcbcbc')
        .attr('stroke-width', .5)
        .attr('opacity', .3)
      })
    },
    

    draw_Axis_Primary() {

      let maxPrimary = d3.max(this.Discipline.filter(f=> f.checked == true), c => c.max_Cumul)

      this.scale.Primary = d3.scaleLinear()
      .domain([0, maxPrimary])
      .range([this.canvas.chart.height/2, 0])

      this.grid.forEach((d,i)=> {
        this.Area_Screen
        .append('line')
        .attr('x1', - 5)
        .attr('x2', 0)
        .attr('y1', d)
        .attr('y2', d)
        .attr('stroke', '#bcbcbc')
        .attr('stroke-width', .5)

        this.Area_Screen
        .append('text')
        .attr('class', `Axis_Primary_${i}`)
        .attr('transform', `translate(${-7}, ${d + 1})`)
        .attr('font-size', 9)
        .attr('fill', '#757575')
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .text((maxPrimary) * (i*2)/10)

        this.Area_Screen
        .append('line')
        .attr('x1', - 5)
        .attr('x2', 0)
        .attr('y1', d + this.canvas.chart.height/2)
        .attr('y2', d + this.canvas.chart.height/2)
        .attr('stroke', '#bcbcbc')
        .attr('stroke-width', .5)

        if(i>0) {
          this.Area_Screen
          .append('text')
          .attr('class', `Axis_Primary_${i}`)
          .attr('transform', `translate(${-7}, ${this.canvas.chart.height/2 + (this.canvas.chart.height/2-d)})`)
          .attr('font-size', 9)
          .attr('fill', '#757575')
          .attr('text-anchor', 'end')
          .attr('font-oblique', 'end')
          .attr('alignment-baseline', 'middle')
          .text((maxPrimary) * (i*2)/10)
        }
      })

      this.Area_Screen
      .append('line')
      .attr('x1', 0).attr('y1', 0)
      .attr('x2', 0).attr('y2', this.canvas.chart.height)
      .attr('stroke', '#bcbcbc').attr('stroke-width', .5)

      this.Area_Screen
      .append('text')
      .attr('transform', `translate(${-45}, ${this.canvas.chart.height/2}) rotate(-90) skewX(-15)`)
      .attr('font-size', 12).attr('fill', '#333')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('Outstanding No. of Punch')
    },


    draw_Axis_Secondary() {

      let maxSecondary = d3.max(this.Discipline.filter(f=> f.checked == true), c => c.max_Wk)

      this.scale.Secondary = d3.scaleLinear()
      .domain([0, maxSecondary])
      .range([this.canvas.chart.height/2, 0])
      
      this.grid.forEach((d,i)=> {
        this.Area_Screen
        .append('line')
        .attr('x1', this.canvas.chart.width)
        .attr('x2', this.canvas.chart.width + 5)
        .attr('y1', d)
        .attr('y2', d)
        .attr('stroke', '#bcbcbc')
        .attr('stroke-width', .5)

        this.Area_Screen
        .append('text')
        .attr('class', `Axis_Secondary_${i}`)
        .attr('transform', `translate(${this.canvas.chart.width + 7}, ${d + 1})`)
        .attr('font-size', 9)
        .attr('fill', '#757575')
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text(((maxSecondary) * (i*2)/10))

        this.Area_Screen
        .append('line')
        .attr('x1', this.canvas.chart.width)
        .attr('x2', this.canvas.chart.width + 5)
        .attr('y1', d + this.canvas.chart.height/2)
        .attr('y2', d + this.canvas.chart.height/2)
        .attr('stroke', '#bcbcbc')
        .attr('stroke-width', .5)

        if(i>0) {
          this.Area_Screen
          .append('text')
          .attr('class', `Axis_Secondary_${i}`)
          .attr('transform', `translate(${this.canvas.chart.width + 7}, ${this.canvas.chart.height/2 + (this.canvas.chart.height/2-d)})`)
          .attr('font-size', 9)
          .attr('fill', '#757575')
          .attr('text-anchor', 'start')
          .attr('alignment-baseline', 'middle')
          .text(((maxSecondary) * (i*2)/10))
        }
      })

      this.Area_Screen
      .append('line')
      .attr('x1', this.canvas.chart.width).attr('y1', 0)
      .attr('x2', this.canvas.chart.width).attr('y2', this.canvas.chart.height)
      .attr('stroke', '#bcbcbc').attr('stroke-width', .5)

      this.Area_Screen
      .append('text')
      .attr('transform', d => `translate(${this.canvas.chart.width + 45}, ${this.canvas.chart.height*0.25}) rotate(-90) skewX(-15)`)
      .attr('font-size', 12).attr('fill', '#333')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('Created Punch(Week)')

      this.Area_Screen
      .append('text')
      .attr('transform', d => `translate(${this.canvas.chart.width + 45}, ${(this.canvas.chart.height*0.75)}) rotate(-90) skewX(-15)`)
      .attr('font-size', 12).attr('fill', '#333')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('Closed Punch (Week)')
    },
    
    
  }
}