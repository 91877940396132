import * as d3 from 'd3'

export default {
  methods: {

    call_Search(input=null) {
      let data_List = JSON.parse(JSON.stringify(this.rawData))
      this.search.results = []

      // apply search input ------------------------
      if(input != null) this.search.input = input
      input = input.toLowerCase()
      
      this.search.results = input ? data_List.filter(f => this.search.targets.some(col => f[this.skyline.refColumn].toLowerCase().includes(input))) : []
      this.searchStatus = this.search.results.length > 0 ? 'ON':'OFF'
      this.filtering_Search(this.legends.sheetStatus) // ---> below
      
    },


    link_Skyline(code_) {

      // set for link key value   ex) let dataSource = {KEY1:d.PKG,KEY2:d.DISC...}
      let dataSource = {} // DO not Remark if possible 

      dataSource = {[`KEY1`]: code_}

      // set for request from A/G jsonProps
      let request_ = this.getRequestColumnProps('request', dataSource, this.ColumnProps)
      if(!request_) return

      // set for filterString 
      let filterString_ = ''

      // if A/G filterString is exist, set request_.iFilters.filterString + filterString_
      if(request_.iFilters.filterString) {
        request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
      } else {
        request_.iFilters.filterString = filterString_||''
      }

      // combine filterString and inputFilter &&& emit request-action
      request_ = {...request_,
        filters : {
          ...this.FilterValues,
          ...request_.filters,
        },
        iFilters: { 
          filterString : request_.iFilters.filterString||'',
          inputFilter : request_.iFilters.inputFilter,},
      }
      this.$emit('request-action', request_)
    },

    link_Subsystem(d) {
      // set for link key value   ex) let dataSource = {KEY1:d.PKG,KEY2:d.DISC...}
      let dataSource = {} // DO not Remark if possible 

      // set for request from A/G jsonProps
      let request_ = this.getRequestColumnProps('JSONPLAN', dataSource, this.ColumnProps)
      if(!request_) return

      // set for filterString 
      let filterString_ = ''

      filterString_ += `[SCOPE] = 'Existing Site' AND [CSTD_PLAN] >= '${d.sDate}' AND [CSTD_PLAN] <= '${d.eDate}'`

      // if A/G filterString is exist, set request_.iFilters.filterString + filterString_
      if(request_.iFilters.filterString) {
        request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
      } else {
        request_.iFilters.filterString = filterString_||''
      }

      // combine filterString and inputFilter &&& emit request-action
      request_ = {...request_,
        filters : {
          ...this.FilterValues,
          ...request_.filters,
        },
        iFilters: { 
          filterString : request_.iFilters.filterString||'',
          inputFilter : request_.iFilters.inputFilter,},
      }
      this.$emit('request-action', request_)
    },

    filtering_Search() {

      let pkgStatus = this.legends.pkgStatus
      let sheetStatus = this.legends.sheetStatus
      let punchStatus = this.legends.punchStatus
      //console.log(sheetStatus, this.legends.layerStatus)
      this.rawData.forEach(d => {
        d.visibility = 'N'
        d.translucence = 'N'

        pkgStatus == 'ALL' ? d.extra = 'ALL' : d.extra = d[this.package.refColumn]
      })

      // Filtering Sheet Status -----------------------------------------------------------------------------------
      let sheert_ = this.sheet.styles.find(f=> f.status == sheetStatus)

      this.rawData.filter(f=> f.extra == pkgStatus).forEach(d => {
        switch(sheert_.type) {
          case 'LAYER':
            if (this.search.input == '') {
              if(sheetStatus == 'TOTAL' || d[this.legends.layerStatus] == sheetStatus) d.visibility = 'Y'
            } else {
              if(
                this.search.results.find(f => f[this.skyline.refColumn] == d[this.skyline.refColumn]) && 
                (sheetStatus == 'TOTAL' || d[this.legends.layerStatus] == sheetStatus)
              ) d.visibility = 'Y'
            }
          break
          
          default:
            if (this.search.input == '') {
              if(sheetStatus == 'TOTAL' || sheetStatus.split('-')[0] == d[`${this.sheet.prefix}_STATUS`].substring(0,sheetStatus.split('-')[0].length)) d.visibility = 'Y'
            } else {
              if(
                  this.search.results.find(f => f[this.skyline.refColumn] == d[this.skyline.refColumn]) && 
                  (sheetStatus == 'TOTAL' || sheetStatus == d[`${this.sheet.prefix}_STATUS`])
                ) d.visibility = 'Y'
            }
          break
        }
      })
      // this.reDraw_Sheet()

      // Filtering Punch Status -----------------------------------------------------------------------------------

      let filteredSheet = this.rawData.filter(f=> f.visibility == 'Y')
      this.count_Punch(filteredSheet)

      filteredSheet.forEach(d=> {
        d.translucence = 'Y' 
        // d.translucence = 'N'
      })

      this.punch.styles.find(f=> f.status == this.legends.punchStatus).filtered.forEach (d=> {
        d.translucence = 'N'
      })

      // Visibility Execution ------------------------------------------------
      this.rawData.forEach((d,i) => {
        d3.select(`#BOX_${i}`)
        .attr('visibility', d.visibility == 'Y' ? 'vidsible' : 'hidden')
        .attr('opacity', d.translucence == 'Y' ? 0.2 : 1)
      })

      if (this.search.input || !(pkgStatus == 'ALL' && sheetStatus == 'TOTAL' && punchStatus == 'NONE'))  {
        d3.selectAll('.block_group_count')
        .attr('visibility', 'hidden')
      } else {
        d3.selectAll('.block_group_count')
        .attr('visibility', 'vidsible')
      }

      this.reDraw_Punch()

      // for export pdf
      this.set_selectedItems()
      
    },

    reDraw_Sheet() {
      let filteredSheet = this.rawData.filter(f=> f.visibility == 'Y')

      this.count_Sheet(filteredSheet)
      // console.log('Sheet Values: ', this.sheet.values) // ---

      this.sheet.styles.forEach(d => {
        d3.select(`#Sheet_text_${d.status}`)
        .text(`${d.text} (${this.sheet.values[d.status]})`)
      })
    },


    reDraw_Punch() {
      this.punch.styles.forEach(d => {
        d3.select(`#${this.punch.prefix}_text_${d.status}`)
        .text(d.status == 'NONE' ? `${d.text}` : `${d.text} (${this.punch.values[d.status]})`)
      })
    },

  }
}