import * as d3 from 'd3'

export default {
  methods: {

    redraw_Charts(unit, idx, sysNo) {

      let chartBase = this.colHeight-60
      let code =   ['A',       'B',       'C',       'AP',      'BP',      'CP'      ]
      let items = ['A', 'B']
      let system = this.Systems.find(f=> f.SUBSYS_NO == sysNo)

      // SUMMARY Table ------------------------------------------------------------------------------
      code.forEach(d=> {

        let TOT = system[`${d}_TOT`]
        let PRO = system[`${d}_PRO`]

        d3.select(`#ITEM_${idx}_${d}_NAME`).transition().duration(300)
        .attr('opacity', TOT == 0 ? 0.2 : 1)
        
        d3.select(`#ITEM_${idx}_${d}_TOT`).transition()
        .ease(d3.easeLinear).duration(300)
        .attr('opacity', TOT == 0 ? 0.2 : 1)
        .tween("text", function() {
          const interpolator = d3.interpolateNumber(d3.select(this).text(), system[`${d}_TOT`])
          return function(t) {
            d3.select(this).text(Math.round(interpolator(t)))
          }
        })
        d3.select(`#ITEM_${idx}_${d}_TOT`)
        .call(this.call_Summary, {unit: unit.UNIT, sSys: sysNo, item: d, cols: 'TOT'}) // ---> Event.js

        
        d3.select(`#ITEM_${idx}_${d}_ACT`).transition()
        .ease(d3.easeLinear).duration(300)
        .attr('opacity', TOT == 0 ? 0.2 : 1)
        .attr('x', PRO < 70 ? 45 + PRO + 2 : 45 + PRO - 2)
        .attr('text-anchor', PRO < 70 ? 'start' : 'end')
        .tween("text", function() {
          const interpolator = d3.interpolateNumber(d3.select(this).text(), system[`${d}_ACT`])
          return function(t) {
            d3.select(this).text(Math.round(interpolator(t)))
          }
        })
        d3.select(`#ITEM_${idx}_${d}_ACT`)
        .call(this.call_Summary, {unit: unit.UNIT, sSys: sysNo, item: d, cols: 'ACT'}) // ---> Event.js


        d3.select(`#ITEM_${idx}_${d}_REM`).transition()
        .ease(d3.easeLinear).duration(300)
        .attr('opacity', TOT == 0 ? 0.2 : 1)
        .tween("text", function() {
          const interpolator = d3.interpolateNumber(d3.select(this).text(), system[`${d}_REM`])
          return function(t) {
            d3.select(this).text(Math.round(interpolator(t)))
          }
        })
        d3.select(`#ITEM_${idx}_${d}_REM`)
        .call(this.call_Summary, {unit: unit.UNIT, sSys: sysNo, item: d, cols: 'REM'}) // ---> Event.js


        d3.select(`#ITEM_${idx}_${d}_PRO`).transition().duration(300)
        .attr('width', PRO)
      })

      // Rundown Chart ------------------------------------------------------------------------------
      items.forEach(item=> {

        let data = this.Rundown.filter(f=> f.SUBSYS_NO == sysNo && f.ITEM == item)
        d3.select(`#SYS_${idx}`).text(system.SUBSYS_NO).attr('fill', '#EC407A')
        
        d3.select(`#NoData_${idx}_${item}`).attr('opacity', 0)
        d3.select( `#Remain_${idx}_${item}`).attr('opacity', 1)

        // Chart Data가 없는경우 (TOTAL = 0)
        if(system[`${item}_TOT`] == 0) {

          this.Grids.forEach((d,i)=> {
            d3.select(`#Primary_${idx}_${item}_${i}`).text('')
            d3.select(`#Secondary_${idx}_${item}_${i}`).text('')
          })

          d3.select(`#Line_${idx}_${item}`).attr('opacity', 0)
          // Bar Chart 
          this.Rundown.filter(f=> f.SUBSYS_NO == '20600-C-0101' && f.ITEM == 'A').forEach((d,i)=> {
            d3.select(`#Bar_${idx}_${item}_${i}`).attr('opacity', 0)
            d3.select(`#Line_${idx}_${item}_${i}`).attr('opacity', 0)
          })

          d3.select(`#NoData_${idx}_${item}`).attr('opacity', 1)
          d3.select( `#Remain_${idx}_${item}`).attr('opacity', 0)

          return
        }

        // Axies --------------------------------------------------
        // Primary 
        let maxPrimary = this.getMaxBound_v2(system[`${item}_TOT`])
        this.scalePrimary = d3.scaleLinear()
        .domain([0, maxPrimary])
        .range([chartBase, 0])

        this.Grids.forEach((d,i)=> {
          d3.select(`#Primary_${idx}_${item}_${i}`).transition()
          .ease(d3.easeLinear).duration(300)
          .attr('opacity', 1)
          .tween("text", function() {
            const interpolator = d3.interpolateNumber(d3.select(this).text(), maxPrimary * (i*2)/10)
            return function(t) {
              d3.select(this).text(Math.round(interpolator(t)))
            }
          })
        })
 
        // Secondary  --------------------------------------------------
        let maxSecondary = this.getMaxBound_v2(d3.max(data, c => c.CLOSED_WK))
        this.scaleSecondary = d3.scaleLinear()
        .domain([0, maxSecondary])
        .range([chartBase, 0])
        
        this.Grids.forEach((d,i)=> {
          d3.select(`#Secondary_${idx}_${item}_${i}`).transition()
          .ease(d3.easeLinear).duration(300)
          .attr('opacity', 1)
          .tween("text", function() {
            const interpolator = d3.interpolateNumber(d3.select(this).text(), maxSecondary * (i*2)/10)
            return function(t) {
              d3.select(this).text(Math.round(interpolator(t)))
            }
          })
        })

        // Bar Chart --------------------------------------------------
        data.forEach((d,i)=> {
          d3.select(`#Bar_${idx}_${item}_${i}`).transition().duration(300)
          .attr('y', this.scaleSecondary(d.CLOSED_WK))
          .attr('height', chartBase - this.scaleSecondary(d.CLOSED_WK))
          .attr('opacity', .5)
        })

        // Line Chart --------------------------------------------------
        let last_Date = null
        let last_Cum = null
        let lineFunc = d3.line()
        .x(d => this.scaleX(d.CDATE) + 5)
        .y(d => this.scalePrimary(d.REMAIN_CUM))

        d3.select(`#Line_${idx}_${item}`).transition().duration(300)
        .attr('d', lineFunc(data)).attr('opacity', 1)

        data.forEach((d,i)=> {
          d3.select(`#Line_${idx}_${item}_${i}`).transition().duration(300)
          .attr('cx',this.scaleX(d.CDATE) + 5)
          .attr('cy',this.scalePrimary(d.REMAIN_CUM))
          .attr('opacity', 1)
          last_Date = this.scaleX(d.CDATE)
          last_Cum = this.scalePrimary(d.REMAIN_CUM)
        })

        // Outstanding --------------------------------------------------
        d3.select(`#Remain_${idx}_${item}`).transition()
        .ease(d3.easeLinear).duration(300)
        .attr('opacity', 1)
        .attr('x', last_Date + 10)
        .attr('y', last_Cum + 3)
        .tween("text", function() {
          const interpolator = d3.interpolateNumber(d3.select(this).text(), system[`${item}_REM`])
          return function(t) {
            d3.select(this).text(Math.round(interpolator(t)))
          }
        })

      })
      
    },

  }
}