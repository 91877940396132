import moment from 'moment'
import * as d3 from 'd3'

export default {
  methods: {

    draw_Chart_Controller() {

      this.Area_Chart = this.svg
      .append('g')
      .attr('id', `Area_Chart`)
      .attr('transform', `translate(${this.canvas.padding.left+.5}, ${this.canvas.padding.top+.5})`)
      .attr('font-family', 'roboto') // rage / roboto

      // Set bandwidth of the Bar Chart
      let gap = 1
      let count = this.Discipline.filter(f=> f.checked == true).length
      let width = this.barWidth - (gap * count) - 1
      let x = -(this.barWidth - 2)
      width = width / count
      
      this.Discipline.filter(f=> f.checked == true).forEach((d,i)=> {

        let data = this.rawData.filter(f=> f.ITEM == d.ITEM && f.DISC == d.DISC)

        this.draw_BarChart({
          data:     data,
          item:     d.ITEM,
          disc:     d.DISC,
          scale:    this.scale.Primary,
          x:        x,
          width:    width,
          color:    this.barColors[i],
          opacity:  .5
        })
        x += width + 1

        this.draw_LineChart({
          data:     this.rawData.filter(f=> f.ITEM == d.ITEM && f.DISC == d.DISC), 
          item:     d.ITEM,
          disc:     d.DISC,
          scale:    this.scale.Secondary, 
          color:    this.barColors[i],
          opacity:  .8,
          x:        this.barWidth / 2,
        })
      })
    },

    draw_BarChart(cht) {
      // console.log(cht.data)
      let BarChart = this.Area_Chart
      .append('g')
      .attr('id', `BAR_${cht.data[0].ITEM}_${cht.data[0].DISC}`)
      .attr('transform', `translate(0,0)`)
      .attr('opacity', cht.opacity)

      BarChart
      .selectAll(`rect_`)
      .data(cht.data)
      .enter()
      .append('rect')
      .attr('id', (d,i)=> `BAR_${i}`)
      .attr('x', d => {
        return this.timeline.scale(moment(moment(d.CDATE).format('YYYY-MM-DD 23:59:59')).toDate()) + cht.x
      })
      .attr('y', d => cht.scale(d.CLOSED_WK))
      .attr('height', d => this.canvas.chart.height - cht.scale(d.CLOSED_WK))
      .attr('width', cht.width)
      .attr('fill', cht.color)
    },

    draw_LineChart(cht) {

      let lineFunc = d3.line()
      .x(d => this.timeline.scale(moment(moment(d.CDATE).format('YYYY-MM-DD 23:59:59')).toDate())-cht.x)
      .y(d => cht.scale(d.REMAIN_CUM))

      let final = {date: 0, value: 0}
      let lineChart = this.Area_Chart
      .append('g')
      .attr('id', `LINE_${cht.data[0].ITEM}_${cht.data[0].DISC}`)
      .attr('transform', `translate(0,0)`)
      .attr('opacity', cht.opacity)

      lineChart
      .append('path')
      .attr('d', lineFunc(cht.data))
      .style('stroke', cht.color).style('stroke-width', 1)
      .style('fill', 'none')

      lineChart
      .selectAll(`circle_`)
      .data(cht.data)
      .enter()
      .append('circle')
      .attr('cx', d => this.timeline.scale(moment(moment(d.CDATE).format('YYYY-MM-DD 23:59:59')).toDate())-cht.x)
      .attr('cy', d=>{
        final.date = d.CDATE
        final.value = d.REMAIN_CUM
        return cht.scale(d.REMAIN_CUM)
      })
      .attr('r', 2)
      .style('stroke', cht.color).style('stroke-width', 1)
      .style('fill', '#fff')

      // Text Remain
      let last = cht.data.at(-1)
      lineChart
      .append('text')
      .attr('x', this.timeline.scale(moment(moment(last.CDATE).format('YYYY-MM-DD 23:59:59')).toDate())-cht.x + 4)
      .attr('y', cht.scale(last.REMAIN_CUM) + 2.5)
      .attr('font-size', 10).attr('fill', '#333').attr('text-anchor', 'start')
      .text(last.REMAIN_CUM)
    },

    last_Turnover() {
      
      let Turnover = this.Area_Canvas
      .append('g')
      .attr('transform', `translate(${this.canvas.padding.left+.5}, ${this.canvas.padding.top+.5})`)
      .attr('font-family', 'roboto') // rage / roboto

      let getDate = ''
      if (this.current_Unit == 'OV') {
        getDate = d3.max(this.Turnover, c => c.PLAN_MAX)
      } else {
        getDate = this.Turnover.find(f=> f.UNIT == this.current_Unit).PLAN_MAX
      }
      let last_Turnover = moment(moment(getDate).format('YYYY-MM-DD 23:59:59')).toDate()

      Turnover
      .append('line')
      .attr('x1', this.timeline.scale(last_Turnover)).attr('y1', 0)
      .attr('x2', this.timeline.scale(last_Turnover)).attr('y2', this.canvas.chart.height)
      .attr('stroke', '#757575').attr('stroke-width', .3)

      Turnover
      .append('line')
      .attr('x1', this.timeline.scale(last_Turnover)).attr('y1', 0)
      .attr('x2', this.timeline.scale(last_Turnover)-20).attr('y2', -20)
      .attr('stroke', '#757575').attr('stroke-width', .2)

      Turnover
      .append('rect')
      .attr('x', this.timeline.scale(last_Turnover)-105).attr('y', -31)
      .attr('width', 85).attr('height', 13).attr('fill', '#fff')
      .attr('stroke', '#757575').attr('stroke-width', .2)

      Turnover
      .append('text')
      .attr('x', this.timeline.scale(last_Turnover)-24).attr('y', -22)
      .attr('font-size', 9).attr('fill', '#757575').attr('text-anchor', 'end')
      .text('Last T/O Plan Date')
    }
  }
}