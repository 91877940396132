import * as d3 from 'd3'

export default {
  methods: {

    draw_Summary() {

      let ballon = this.Area_Canvas
      .append('g')
      .attr('id', `Ballon_Group`)
      .attr('transform', `translate(0, 0.5)`)
      .attr('font-family', 'roboto').attr('opacity', 0)
      .style('cursor', 'default')

      ballon
      .append('path')
      .attr('transform', `translate(-7, -22)`)
      .attr('d', `M74.2,0.2h-72c-1.1,0-2,0.9-2,2v11c0,1.1,0.9,2,2,2H4l2.8,7l-0.2-7h67.6c1.1,0,2-0.9,2-2v-11 C76.2,1.1,75.3,0.2,74.2,0.2z`)
      .attr('fill', '#fff').attr('stroke', '#bf0032').attr('stroke-width', 1).attr('stroke-opacity', .5)

      ballon
      .append('text')
      .attr('id', `Ballon_Text`)
      .attr('x', -3.5).attr('y', -10.5)
      .attr('font-size', 11).attr('fill', '#333').attr('font-weight', 430)
      .text(`21100-U-6102`)


      let summary = this.Area_Canvas
      .append('g')
      .attr('transform', `translate(50.5, 875.5)`)
      .attr('font-family', 'roboto').style('cursor', 'default')

      let x = 0, y = 0
      let step_Y = [150,130,180,160], sNo = 0
      
      this.rawData.forEach((d,i)=> {

        let unit = summary
        .append('g')
        .attr('transform', `translate(${x}, ${y})`)
        .attr('font-size', 9)

        unit
        .append('text')
        .attr('x', 0).attr('y', 9)
        .attr('font-size', 12).attr('fill', '#333').attr('font-weight', 430)
        .text(`${d.UNIT}`)
        .style('cursor', 'pointer').attr('opacity', 1)
        .on('mouseover', function() {
          d3.select(this).transition().duration(150).attr('fill', 'red')
        })
        .on('mouseout', function() {
          d3.select(this).transition().duration(150).attr('fill', '#000')
        })
        .on('click', ()=> {
          this.call_Unit(d.UNIT, d.UNIT_NO) // --> Event.js
        })

        unit
        .append('text')
        .attr('x', 0).attr('y', 20)
        .attr('font-size', 9).attr('fill', '#bcbcbc').attr('font-weight', 430)
        .text(`${d.UNIT_DESC}`)
        
        this.draw_System(unit, d, i, x, y)

        this.draw_Sheet(unit, d, 'A', 35, '#83D2F5')
        this.draw_Sheet(unit, d, 'B', 50, '#F7BACF')

        x += 230
        if((i % 5) == 0) {
          y  = y + parseInt(step_Y[sNo])
          sNo += 1
          x = 0
        }
      })
      
    },



    draw_System(selection, d_, idx_, gX, gY) {
      let getColor = this.color21[idx_]
      let sys = selection
      .append('g')
      .attr('transform', `translate(0, -3.5)`)
      .attr('font-family', 'roboto').attr('font-size', 9)

      sys
      .append('line')
      .attr('x1', 0).attr('y1', 0)
      .attr('x2', 170).attr('y2', 0).attr('opacity', .2)
      .attr('stroke', '#bcbcbc').attr('stroke-width', 1)

      sys
      .append('text')
      .attr('x', 170).attr('y', -2)
      .attr('font-size', 12).attr('fill', getColor)
      .attr('text-anchor', 'end').style('opacity', 1).style('cursor', 'pointer')
      .text(this.system.filter(f=> f.UNIT == d_.UNIT).length)
      .on('mouseover', function() {
        d3.select(this).transition().duration(100).style('opacity', .5).attr('fill', 'red')
      })
      .on('mouseout', function(){
        d3.select(this).transition().duration(100).style('opacity', 1).attr('fill', getColor)
      })
      .on('click', () => { 
        this.call_List(d_.UNIT)
      })

      let x = 0, y = -10

      this.system.filter(f=> f.UNIT == d_.UNIT).forEach((d,i)=> {
        sys
        .append('rect')
        .attr('id', `SSYS_${d.SUBSYS_NO}`)
        .attr('class', `${gX}_${gY}`)
        .attr('x', x).attr('y', y).attr('opacity', 1)
        .attr('width', 10).attr('height', 10)
        .attr('stroke', '#fff').attr('stroke-width', 1).style('cursor', 'pointer')
        .attr('fill', ()=> {
          let color = '#EFEFEF'
          if(d.CSTD_WALKDOWN !== undefined) color = '#bcbcbc'
          if(d.CSTD_ACTUAL !== undefined) color =  this.color21[idx_]
          return color
        })
        .on('mouseover', function() {
          d3.select(this).transition().duration(150).attr('opacity', .3)
          let className = d3.select(this).attr('class').split('_').map(Number)
          let x_ = className[0] + parseInt(d3.select(this).attr('x')) + 50 + 5
          let y_ = className[1] + parseInt(d3.select(this).attr('y')) + 875
          d3.select(`#Ballon_Group`).moveToFront().attr('transform', `translate(${x_}, ${y_})`).attr('opacity', 1)
          d3.select(`#Ballon_Text`).text(d.SUBSYS_NO)
        })
        .on('mouseout', function() {
          d3.select(`#Ballon_Group`).attr('opacity', 0)
          d3.select(this).transition().duration(150).attr('opacity', 1)
        })
        .on('click', ()=> {
          this.call_Subsystem(d.UNIT, d.SUBSYS_NO) // --> Event.js
        })
  
        x += 10
        if((i+1) % 10 == 0) {
          y -= 10  
          x = 0
        }
      })
    },



    draw_Sheet(selection, d, code, y_, color_) {
      let ITRs = selection
      .append('g')
      .attr('transform', `translate(0, ${y_})`)
      .attr('font-family', 'roboto').attr('font-size', 9)

      ITRs
      .append('text')
      .attr('x', 0).attr('y', 0)
      .attr('fill', '#333')
      .text(`${code} ITR`)
      
      // Total
      ITRs
      .append('text')
      .attr('x', 53).attr('y', 0)
      .attr('fill', '#757575').attr('text-anchor', 'end')
      .text(d[`${code}_TOT`])
      .style('cursor', 'pointer')
      .on('mouseover', function() {
        d3.select(this).transition().duration(150).attr('fill', 'red')
      })
      .on('mouseout', function() {
        d3.select(this).transition().duration(150).attr('fill', '#757575')
      })
      .on('click', ()=> {
        this.call_Sheet(d.UNIT_NO, code, 'TOT') // --> Event.js
      })

      ITRs
      .append('rect')
      .attr('x', 55).attr('y', -8).attr('opacity', .2)
      .attr('width', 70).attr('height', 8).attr('fill', '#E0E0DF')

      let PRO = (d[`${code}_PRO`]/100) * 70
      ITRs
      .append('rect')
      .attr('x', 55).attr('y', -8).attr('opacity', .7)
      .attr('width', PRO).attr('height', 8).attr('fill', color_)

      // Actual
      ITRs
      .append('text')
      .attr('y', 0).attr('fill', '#757575')
      .attr('x', d[`${code}_PRO`] < 60 ? 55 + PRO + 2 : 55 + PRO - 2)
      .attr('text-anchor', d[`${code}_PRO`] < 60 ? 'start' : 'end')
      .text(d[`${code}_ACT`])
      .style('cursor', 'pointer')
      .on('mouseover', function() {
        d3.select(this).transition().duration(150).attr('fill', 'red')
      })
      .on('mouseout', function() {
        d3.select(this).transition().duration(150).attr('fill', '#757575')
      })
      .on('click', ()=> {
        this.call_Sheet(d.UNIT_NO, code, 'ACT') // --> Event.js
      })

      // Remain
      ITRs
      .append('text')
      .attr('x', 127).attr('y', 0)
      .attr('fill', '#757575').attr('text-anchor', 'start')
      .text(d[`${code}_REM`])
      .style('cursor', 'pointer')
      .on('mouseover', function() {
        d3.select(this).transition().duration(150).attr('fill', 'red')
      })
      .on('mouseout', function() {
        d3.select(this).transition().duration(150).attr('fill', '#757575')
      })
      .on('click', ()=> {
        this.call_Sheet(d.UNIT_NO, code, 'REM') // --> Event.js
      })

    },
    
  }
}