import * as d3 from 'd3'

export default {
  methods: {

    /* Jinkee
      draw_barChart() 함수에서 호출됨 -> draw_Summary.js
      {
        scope:    Grassroots / Existing Site, 
        item:     SSMCC / WD / A / B / C / AP / BP / CP, 
        coltype:  TOT / ACT / REM, 
      }
    */
    call_Summary(selection, d) {
      selection
      .on('mouseover', function() {
        d3.select(this).transition().duration(50).style('fill', '#EC407A')
      })
      .on('mouseout', function() {
        d3.select(this).transition().duration(50).style('fill', '#333')
      })
      .on('click', ()=> {
        this.link_Action(d)
      })
    },



    /* Jinkee
      draw_TrendChart() / redraw_TrendChart() 두 함수에서 호출됨 -> draw_Trends.js
      {
        scope:  Grassroots / Existing Site, 
        item:   SSMCC / WD / A / B / C / AP / BP / CP, 
        date:   selected date, 
      }
    */
    call_TrendCharts(selection, d) {
      selection
      .on('mouseover', function() {
        d3.select(this).transition().duration(50).style('fill', '#EC407A')
      })
      .on('mouseout', function() {
        d3.select(this).transition().duration(50).style('fill', '#757575')
      })
      .on('click', ()=> {
// SSMCC ------------------------------------------------------------------------------------------------- 
        if (this.turnover == 'Y') {
          let dataSource = {}

          let request_ = this.getRequestColumnProps('request2', dataSource, this.ColumnProps)

          if(!request_) return

          let scope_ = d.scope
          let date_ = d.date

          let filterString_ = `[CSTD_ACTUAL] > DATEADD(WW,-1,'${date_}') AND [CSTD_ACTUAL] <= DATEADD(WW,0,'${date_}') AND `
          
          filterString_ += `[SCOPE] = '${scope_}'`

          if(request_.iFilters.filterString) {
            request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
          } else {
            request_.iFilters.filterString = filterString_||''
          }

          request_ = {...request_,
            filters : {
              ...this.FilterValues,
              ...request_.filters,
            },
            iFilters: { 
              filterString : request_.iFilters.filterString||'',
              inputFilter : request_.iFilters.inputFilter,},
          }
          this.$emit('request-action', request_)
        } else {
// Punch -------------------------------------------------------------------------------------------------
          if (d.item == 'AP' || d.item == 'BP' || d.item == 'CP') {
            let dataSource = {} 

            let request_ = this.getRequestColumnProps('request3', dataSource, this.ColumnProps)

            if(!request_) return

            let scope_ = d.scope
            let code_ = d.item.substr(0,1)
            let date_ = d.date

            let filterString_ = `[CLOSE_DATE] > DATEADD(WW,-1,'${date_}') AND [CLOSE_DATE] <= DATEADD(WW,0,'${date_}') AND `
            
            filterString_ += scope_ == 'OV'? `[CATEGORY] = '${code_}' AND [STATUS] = 'C'` :`[SCOPE] = '${scope_}' AND [CATEGORY] = '${code_}' AND [STATUS] = 'C'`

            if(request_.iFilters.filterString) {
              request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
            } else {
              request_.iFilters.filterString = filterString_||''
            }

            request_ = {...request_,
              filters : {
                ...this.FilterValues,
                ...request_.filters,
              },
              iFilters: { 
                filterString : request_.iFilters.filterString||'',
                inputFilter : request_.iFilters.inputFilter,},
            }
            this.$emit('request-action', request_)
// ITRs -------------------------------------------------------------------------------------------------
          } else {
            let dataSource = {}

            let request_ = this.getRequestColumnProps('request1', dataSource, this.ColumnProps)

            if(!request_) return

            let scope_ = d.scope
            let code_ = d.item
            let date_ = d.date

            let filterString_ = `[CLOSE_DATE] > DATEADD(WW,-1,'${date_}') AND [CLOSE_DATE] <= DATEADD(WW,0,'${date_}') AND `
            
            filterString_ += scope_ == 'OV'? `[CATEGORY] = '${code_}' AND [STATUS] = 'C'` :`[SCOPE] = '${scope_}' AND [CATEGORY] = '${code_}' AND [STATUS] = 'C'`

            if(request_.iFilters.filterString) {
              request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
            } else {
              request_.iFilters.filterString = filterString_||''
            }

            request_ = {...request_,
              filters : {
                ...this.FilterValues,
                ...request_.filters,
              },
              iFilters: { 
                filterString : request_.iFilters.filterString||'',
                inputFilter : request_.iFilters.inputFilter,},
            }
            this.$emit('request-action', request_)
          }
        }
      })
    },



    /* Jinkee
      draw_WeekPerformance() / redraw_WeekPerformance() 두 함수에서 호출됨 -> draw_Weeks.js
      {
        scope:  Grassroots / Existing Site, 
        item:   SSMCC / WD / A / B / C / AP / BP / CP, 
        date:   selected date, 
        type:   week / day,
      }
    */
    call_WeekPerformance(selection, d) {
      selection
      .on('mouseover', function() {
        d3.select(this).transition().duration(50).style('fill', '#EC407A')
      })
      .on('mouseout', function() {
        d3.select(this).transition().duration(50).style('fill', '#333')
      })
      .on('click', ()=> {
// SSMCC ------------------------------------------------------------------------------------------------- 
        if (d.item == 'SSMCC') {
          let dataSource = {}

          let request_ = this.getRequestColumnProps('request2', dataSource, this.ColumnProps)

          if(!request_) return

          let scope_ = d.scope
          let date_ = d.date

          let filterString_ = d.type == 'week' ? 
              ` [CSTD_ACTUAL] > DATEADD(WW,-1,'${date_}') AND [CSTD_ACTUAL] <= DATEADD(WW,0,'${date_}') AND ` : 
              ` [CSTD_ACTUAL] = '${date_}' AND `
          
          filterString_ += `[SCOPE] = '${scope_}'`

          if(request_.iFilters.filterString) {
            request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
          } else {
            request_.iFilters.filterString = filterString_||''
          }

          request_ = {...request_,
            filters : {
              ...this.FilterValues,
              ...request_.filters,
            },
            iFilters: { 
              filterString : request_.iFilters.filterString||'',
              inputFilter : request_.iFilters.inputFilter,},
          }
          this.$emit('request-action', request_)
        } 
// Punch -------------------------------------------------------------------------------------------------
        else if (d.item == 'AP' || d.item == 'BP' || d.item == 'CP') {
          let dataSource = {} 

          let request_ = this.getRequestColumnProps('request3', dataSource, this.ColumnProps)

          if(!request_) return

          let scope_ = d.scope
          let code_ = d.item.substr(0,1)
          let date_ = d.date

          let filterString_ = d.type == 'week' ? 
              ` [CLOSE_DATE] > DATEADD(WW,-1,'${date_}') AND [CLOSE_DATE] <= DATEADD(WW,0,'${date_}') AND ` : 
              ` [CLOSE_DATE] = '${date_}' AND `
          
          filterString_ += scope_ == 'OV'? `[CATEGORY] = '${code_}' AND [STATUS] = 'C'` :`[SCOPE] = '${scope_}' AND [CATEGORY] = '${code_}' AND [STATUS] = 'C'`

          if(request_.iFilters.filterString) {
            request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
          } else {
            request_.iFilters.filterString = filterString_||''
          }

          request_ = {...request_,
            filters : {
              ...this.FilterValues,
              ...request_.filters,
            },
            iFilters: { 
              filterString : request_.iFilters.filterString||'',
              inputFilter : request_.iFilters.inputFilter,},
          }
          this.$emit('request-action', request_)
// ITRs -------------------------------------------------------------------------------------------------
        } else {
          let dataSource = {}

          let request_ = this.getRequestColumnProps('request1', dataSource, this.ColumnProps)

          if(!request_) return

          let scope_ = d.scope
          let code_ = d.item
          let date_ = d.date

          let filterString_ = d.type == 'week' ? 
              ` [CLOSE_DATE] > DATEADD(WW,-1,'${date_}') AND [CLOSE_DATE] <= DATEADD(WW,0,'${date_}') AND ` : 
              ` [CLOSE_DATE] = '${date_}' AND `
          
          filterString_ += scope_ == 'OV'? `[CATEGORY] = '${code_}' AND [STATUS] = 'C'` :`[SCOPE] = '${scope_}' AND [CATEGORY] = '${code_}' AND [STATUS] = 'C'`

          if(request_.iFilters.filterString) {
            request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
          } else {
            request_.iFilters.filterString = filterString_||''
          }

          request_ = {...request_,
            filters : {
              ...this.FilterValues,
              ...request_.filters,
            },
            iFilters: { 
              filterString : request_.iFilters.filterString||'',
              inputFilter : request_.iFilters.inputFilter,},
          }
          this.$emit('request-action', request_)
        }
      })
    },



    // link_Action(grp_,scope_,code_,coltype_) {
    link_Action(link_) {
      // set for link key value   ex) let dataSource = {KEY1:d.PKG,KEY2:d.DISC...}
      let dataSource = {}
      // if delValue is 'Y', set dataSource
      // if(this.delValue.length > 0){
      //   this.delValue.forEach((d, i) => {
      //     dataSource = {...dataSource, [`KEY${i+1}`]: this.rawData[_style.idx][d]}
      //   })
      // }
      let reqtext = `JSON${(link_.item =='SSMCC'||link_.item =='WD') ? 'SSMCC' : link_.item}`  // JSONSSMCC , JSONWD , JSONSYS, JSONA, JSONAP (JSONB, JSONBP, JSONC, JSONCP)

      // set for request from A/G jsonProps
      let request_ = this.getRequestColumnProps(reqtext, dataSource, this.ColumnProps)
      if(!request_) return

      // if filterCol is exist, set filterString_
      let filterString_ = ''

      let scope_ = link_.scope
      let coltype_ = link_.coltype
      let code_ = link_.item

      // OVERALL SUMMARY SUBSYSTEM 
      if( code_ == 'SSMCC' || code_ == 'WD') {

        if( code_ == 'SSMCC' && coltype_ == 'TOT'){
          filterString_ += `[SCOPE] = '${scope_}'`
        } else if ( code_ == 'SSMCC' && coltype_ == 'ACT'){
          filterString_ += `[SCOPE] = '${scope_}' AND [CSTD_ACTUAL] IS NOT NULL`
        } else if ( code_ == 'SSMCC' && coltype_ == 'REM'){
          filterString_ += `[SCOPE] = '${scope_}' AND [CSTD_ACTUAL] IS NULL`
        } else if ( code_ == 'WD' && coltype_ == 'TOT'){
          filterString_ += `[SCOPE] = '${scope_}'`
        } else if ( code_ == 'WD' && coltype_ == 'ACT'){
          filterString_ += `[SCOPE] = '${scope_}' AND [CSTD_WALKDOWN] IS NOT NULL`
        } else if ( code_ == 'WD' && coltype_ == 'REM'){
          filterString_ += `[SCOPE] = '${scope_}' AND [CSTD_WALKDOWN] IS NULL`
        }
      // SHEET & PUNCH
      }else{        
        code_ = (link_.item == 'A' || link_.item == 'B' || link_.item == 'C')? link_.item : link_.item.substr(0,1)

        if(coltype_ == 'TOT'){
          filterString_ += scope_ == 'OV'? `[CATEGORY] = '${code_}'` : `[SCOPE] = '${scope_}' AND [CATEGORY] = '${code_}'`
        } else if(coltype_ == 'ACT'){
          filterString_ += scope_ == 'OV'? `[CATEGORY] = '${code_}' AND [STATUS] = 'C'` :`[SCOPE] = '${scope_}' AND [CATEGORY] = '${code_}' AND [STATUS] = 'C'`
        } else {
          filterString_ += scope_ == 'OV'? `[CATEGORY] = '${code_}' AND [STATUS] <> 'C'`:`[SCOPE] = '${scope_}' AND [CATEGORY] = '${code_}' AND [STATUS] <> 'C'`
        }
      }

      // if A/G iFilters is exist, (filterString_ + iFilters)
      if(request_.iFilters.filterString) {
        request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
      } else {
        request_.iFilters.filterString = filterString_||''
      }

      // combine filterString 
      request_ = {...request_,
        filters : {
          ...this.FilterValues,
          ...request_.filters,
        },
        iFilters: { 
          filterString : request_.iFilters.filterString||'',
          inputFilter : request_.iFilters.inputFilter,},
      }
      this.$emit('request-action', request_)
    },
  }
}