import * as d3 from 'd3'

export default {
  methods: {

    draw_Timeline() {    

      this.timeline.scale = d3.scaleTime()
      .domain([
        this.timeline.week[0].sDate, 
        this.timeline.week[this.timeline.week.length - 1].eDate
      ])
      .rangeRound([0, this.timeline.length])

      this.Area_Gantt = this.svg
      .append('g')
      .attr('id', 'SCREEN')
      .attr('transform', `translate(${this.timeline.x}, ${this.timeline.y})`)
      .attr('font-family', 'roboto') // rage / roboto


      let Timeline = this.Area_Gantt
      .append('g')
      .attr('transform', `translate(0, 0.5)`)
      .style('font-family', 'roboto')

      Timeline
      .append('rect')
      .attr('x', 0).attr('y', 0)
      .attr('width', this.timeline.length).attr('height', 16)
      .attr('fill', '#bcbcbc')
      .attr('stroke', '#bcbcbc').attr('storke-width', .3)


      // Month Text & Timeline Seperator for the Month
      Timeline
      .append('g')
      .selectAll('text')
      .data(this.timeline.month) // *** week
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate)).attr('y', 3)
      .style('font-size', 9).attr('fill', '#fff')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
      .attr('cursor', 'default')
      .text(d => d.name)

      Timeline
      .append('g')
      .selectAll('path')
      .data(this.timeline.month) // *** week
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        if (i != this.timeline.month.length-1) return `M${this.timeline.scale(d.eDate)+ 0}, 5 V 10` // *** week
      })
      .attr('stroke', '#fff').attr('stroke-width', 1).attr('stroke-opacity', .5)

      // Year Text & Timeline Seperator for the Year
      Timeline
      .append('g')
      .selectAll('text')
      .data(this.timeline.year)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate))
      .attr('y', -12)
      .style('font-size', 12)
      .attr('fill', '#757575')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
      .attr('cursor', 'default')
      .text(d => d.name)

      Timeline
      .append('g')
      .selectAll('path')
      .data(this.timeline.year)
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        if (i != this.timeline.year.length-1) return `M${this.timeline.scale(d.eDate) + 1}, 0 V -15`
      })
      .attr('stroke', '#bcbcbc')
      .attr('stroke-width', .5)
    }, 
    
  }
}