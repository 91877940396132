import * as d3 from 'd3'

export default {

  methods: {

    draw_Legends() {
      let Legends = this.Area_Chart
      .append('g')
      .attr('transform', `translate(.5, ${-30.5})`)
      .style('font-family', 'roboto').style('cursor', 'default')

      let x = 0
      let selectedData = this.Discipline.filter(f=> f.checked == true)

      selectedData.forEach((d,i)=> {

        let lgItem = Legends
        .append('g')
        .attr('id' ,`LEGEND_${d.ITEM}_${d.DISC}`)
        .attr('transform', `translate(${x}, 0)`)
        .attr('cursor', 'pointer').attr('opacity', 1)
        .on('mouseover', function() {
          d3.select(this).attr('opacity', .5)
          selectedData.forEach(op=> {
            d3.select(`#BAR_${op.ITEM}_${op.DISC}`).moveToFront()
            d3.select(`#BAR_${op.ITEM}_${op.DISC}`).transition().duration(300).attr('opacity', d.ITEM == op.ITEM && d.DISC == op.DISC ? 1 : .1)
            d3.select(`#LINE_${op.ITEM}_${op.DISC}`).moveToFront()
            d3.select(`#LINE_${op.ITEM}_${op.DISC}`).transition().duration(300).attr('opacity', d.ITEM == op.ITEM && d.DISC == op.DISC ? 1 : .1)
          })

        })
        .on('mouseout', function() {
          d3.select(this).attr('opacity', 1)
          selectedData.forEach(op=> {
            d3.select(`#BAR_${op.ITEM}_${op.DISC}`).transition().duration(300).attr('opacity', .5)
            d3.select(`#LINE_${op.ITEM}_${op.DISC}`).transition().duration(300).attr('opacity', .8)
          })
        })
        .on('click', ()=> {
        })

        lgItem
        .append('rect')
        .attr('x', 0).attr('y', 0)
        .attr('width', d.DISC == 'OV' ? 6 : 3)
        .attr('height', 20)
        .attr('fill', this.barColors[i])

        lgItem
        .append('text')
        .attr('x', 8).attr('y', 8)
        .attr('font-size', 10).attr('fill', '#333')
        .text(d.DISC == 'OV' ? `${d.ITEM} ITRs` : d.DISC_LONG.substr(2))

        lgItem
        .append('text')
        .attr('x', 8).attr('y', 19)
        .attr('font-size', 10).attr('fill', this.barColors[i])
        .text(`${d.PRO}%`)

        x += this.getNodeElValue(`#LEGEND_${d.ITEM}_${d.DISC}`, 'width') + 10
      })
    },
    
  }
}