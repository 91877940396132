import icon from '../../../../../../includes/primitives/icons'
export default {
  
  methods: {

    draw_Title(selection, d, x_) {
      let X = this.tables.x

      selection
      .append('text')
      .attr('x', 0).attr('y',0)
      .attr('font-size', 13).style('fill', '#000')
      .text(d.UNIT)
      .attr('cursor', 'pointer')
      //.on('click', () => { this.unit_Link({scope: this.unit.title, unit: d.UNIT}) })
      .on('click', () => { this.link_Action({scope: d.SCOPE, unit: d.UNIT, unit_No: d.UNIT_NO, code: 'UNIT', coltype: 'UNIT'}) })

      selection
      .append('text')
      .attr('x', 0).attr('y',12)
      .attr('font-size', 9).attr('fill', '#bcbcbc')
      .style('font-family', 'roboto')
      .text('Turnover Plan vs Actual')

      selection // Actual
      .append('text')
      .attr('x', X[1]).attr('y', 37).attr('fill', '#757575').attr('text-anchor', 'end').attr('font-size', 9)
      .text(`Total`)

      selection // Actual
      .append('text')
      .attr('x', X[2]+2).attr('y', 37).attr('fill', '#757575').attr('text-anchor', 'end').attr('font-size', 9)
      .text(`Closed`)

      selection // Actual
      .append('text')
      .attr('x', X[3]+4).attr('y', 37).attr('fill', '#757575').attr('text-anchor', 'end').attr('font-size', 9)
      .text(`Remain`)

      let dev = d.SSMCC_ACT - d.SSMCC_PLN
      let iColor = '', iName = ''

      if(dev > 0) {
        iColor = '#44A9DF'
        iName = icon.arrow.up
      } else if (dev < 0) {
        iColor = '#EC407A'
        iName = icon.arrow.down
      } else {
        iColor = '#bcbcbc'
        iName = icon.arrow.zero
      }

      selection // Delta
      .append('text')
      .attr('x', 115).attr('y', 12.5)
      .attr('fill', iColor)
      .attr('font-weight', 450)
      .attr('text-anchor', 'start')
      .attr('font-size', 13)
      .text(dev)

      selection // Delta
      .append('path')
      .attr('transform', `translate(100.5, -1.5)`)
      .attr('fill', iColor)
      .attr('d', iName)
    },

    draw_SSMCC(selection, d, no_, x_, y_) {

      let X = this.tables.x
      let system = selection
      .append('g')
      .attr('id', `SSMCC_${no_}`)
      .attr('transform', `translate(${0}, ${y_})`)
      .attr('font-size', 10)

      system
      .append('rect')
      .attr('x', 0).attr('y', 0)
      .attr('rx', 3).attr('ry', 3)
      .attr('fill', this.tables.bColor[0]).attr('opacity', this.tables.opacity[0])
      .attr('width', this.tables.length).attr('height', 40)

      system // SSMCC
      .append('text')
      .attr('x', X[0]).attr('y', 14).attr('fill', '#000').text(this.sheet.name[0])
      system // Total
      .append('text')
      .attr('x', X[1]).attr('y', 14).attr('fill', '#757575').attr('text-anchor', 'end')
      .text(d.SSMCC_TOT)
      .attr('cursor', 'pointer')       
      .on('click', () => { this.link_Action({unit:d.UNIT, code:this.sheet.code[0], coltype:'TOT'}) })

      system // Actual
      .append('text')
      .attr('x', X[2]).attr('y', 14).attr('fill', '#757575').attr('text-anchor', 'end')
      .text(d.SSMCC_ACT)
      .attr('cursor', 'pointer')  
      .on('click', () => { this.link_Action({unit:d.UNIT, code:this.sheet.code[0], coltype:'ACT'}) })

      system // Remain
      .append('text')
      .attr('x', X[3]).attr('y', 14).attr('fill', '#757575').attr('text-anchor', 'end')
      .text(d.SSMCC_REM)
      .attr('cursor', 'pointer')  
      .on('click', () => { this.link_Action({unit:d.UNIT, code:this.sheet.code[0], coltype:'REM'}) })

      system // Walkdown
      .append('text')
      .attr('x', X[0]).attr('y', 33).attr('fill', '#000').text(this.sheet.name[1])
      system // Total
      .append('text')
      .attr('x', X[1]).attr('y', 33).attr('fill', '#757575').attr('text-anchor', 'end')
      .text(d.SSMCC_TOT)
      .attr('cursor', 'pointer')       
      .on('click', () => { this.link_Action({unit:d.UNIT, code:this.sheet.code[1], coltype:'TOT'}) })

      system // Actual
      .append('text')
      .attr('x', X[2]).attr('y', 33).attr('fill', '#757575').attr('text-anchor', 'end')
      .text(d.SSMCC_WD)
      .attr('cursor', 'pointer')   
      .on('click', () => { this.link_Action({unit:d.UNIT, code:this.sheet.code[1], coltype:'ACT'}) })

      system // Remain
      .append('text')
      .attr('x', X[3]).attr('y', 33).attr('fill', '#757575').attr('text-anchor', 'end')
      .text(d.SSMCC_TOT - d.SSMCC_WD)
      .attr('cursor', 'pointer')   
      .on('click', () => { this.link_Action({unit:d.UNIT, code:this.sheet.code[1], coltype:'REM'}) })

      let pro = d.SSMCC_PRO
      system
      .append('rect')
      .attr('x', 0).attr('y', 41).attr('rx', 1).attr('ry', 1).attr('opacity', 0.2)
      .attr('fill', '#E0E0DF').attr('height', 12).attr('width', this.tables.length)

      system
      .append('rect')
      .attr('x', 0).attr('y', 41).attr('rx', 1).attr('ry', 1).attr('opacity', this.tables.opacity[0])
      .attr('fill', this.tables.bColor[0]).attr('height', 12).attr('width', this.tables.length * (pro/100))

      system // Walkdown
      .append('text')
      .attr('fill', '#757575')
      .attr('x',  pro < 75 ? this.tables.length * (pro/100) + 2 : this.tables.length * (pro/100) - 2).attr('y', 50.5)
      .attr('text-anchor', pro < 75 ? 'start' : 'end')
      .text(`${pro.toFixed(1)}%`)
    },
  


    draw_Sheet(selection, d) {
      let X = this.tables.x

      let system = selection
      .append('g')
      .attr('id', `${d.code}_${d.no}`)
      .attr('transform', `translate(${0}, ${d.y})`)
      .attr('font-size', 10)

      system
      .append('rect')
      .attr('x', 0).attr('y', 0)
      .attr('rx', 3).attr('ry', 3)
      .attr('fill', d.bColor)
      .attr('opacity', d.opacity)
      .attr('width', this.tables.length).attr('height', 20)

      system // Name
      .append('text')
      .attr('x', X[0]).attr('y', 14).attr('fill', '#000').text(d.name)

      system // Total
      .append('text')
      .attr('x', X[1]).attr('y', 14).attr('fill', '#757575').attr('text-anchor', 'end')
      .text(d.data[`${d.code}_TOT`])
      .attr('cursor', 'pointer') 
      .on('click', () => { this.link_Action({unit:d.data.UNIT, code:d.code, coltype:'TOT'}) })

      system // Actual
      .append('text')
      .attr('x', X[2]).attr('y', 14).attr('fill', '#757575').attr('text-anchor', 'end')
      .text(d.data[`${d.code}_ACT`])
      .attr('cursor', 'pointer')  
      .on('click', () => { this.link_Action({unit:d.data.UNIT, code:d.code, coltype:'ACT'}) })

      system // Remain
      .append('text')
      .attr('x', X[3]).attr('y', 14).attr('fill', '#757575').attr('text-anchor', 'end')
      .text(d.data[`${d.code}_REM`])
      .attr('cursor', 'pointer')   
      .on('click', () => { this.link_Action({unit:d.data.UNIT, code:d.code, coltype:'REM'}) })

      system
      .append('rect')
      .attr('x', 0).attr('y', 21).attr('rx', 1).attr('ry', 1).attr('opacity', 0.2).attr('height', 12)
      .attr('fill', '#E0E0DF').attr('width', this.tables.length)

      system
      .append('rect')
      .attr('x', 0).attr('y', 21).attr('rx', 1).attr('ry', 1).attr('height', 12)
      .attr('fill', d.bColor).attr('opacity', d.opacity)
      .attr('width', this.tables.length * (d.data[`${d.code}_PRO`]/100))

      system
      .append('text')
      .attr('x', d.data[`${d.code}_PRO`] < 75 ? this.tables.length * (d.data[`${d.code}_PRO`]/100) + 2 : this.tables.length * (d.data[`${d.code}_PRO`]/100) - 2)
      .attr('y', 30.5).attr('fill', '#757575')
      .attr('text-anchor', d.data[`${d.code}_PRO`] < 75 ? 'start' : 'end')
      .text(`${d.data[`${d.code}_PRO`].toFixed(1)}%`)
    },

    
  }
}