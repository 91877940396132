export default {

  methods: {

    set_Package(){
      let sql3 = null
      if (this.Queries.SQL3) sql3 = this.Queries.SQL3[0] //Legends
      
      this.package = {
        visible       : sql3['PKG_VISIBLE'],
        refColumn     : sql3['PKG_REF_COLUMN'],
        text          : sql3['PKG_NAME'].replace(/\s/g, '').split('/'),
        status        : sql3['PKG_STATUS'].replace(/\s/g, '').split('/'),
        values        : [],
      }
      if (this.package.visible !== 'Y') this.legends.y -= 25
    },


    set_Checksheet(){
      let sql3 = null
      if (this.Queries.SQL3) sql3 = this.Queries.SQL3[0] //Legends

      this.sheet = {
        prefix        : sql3['SHEET_PREFIX'],
        styles        : [],
        values        : [],
      }
    
      let sheetStatus = sql3['SHEET_STATUS'].replace(/\s/g, '').split('/')

      sheetStatus.forEach((d,i)=> {
        this.sheet.styles[i] = {
          text        :         sql3['SHEET_TITLE'].replace(/\s/g, ' ').split('/')[i],
          status      :         sql3['SHEET_STATUS'].replace(/\s/g, '').split('/')[i],
          type        :         sql3['SHEET_TYPE'].replace(/\s/g, '').split('/')[i],
          color       :         sql3['SHEET_COLOR'].replace(/\s/g, '').split('/')[i],
          tWeight     :         sql3['SHEET_FONT_WEIGHT'].replace(/\s/g, '').split('/')[i],
          tColor      :         sql3['SHEET_FONT_COLOR'].replace(/\s/g, '').split('/')[i],
          sWidth      : Number( sql3['SHEET_STROKE_WIDTH'].replace(/\s/g, '').split('/')[i]),
          sColor      :         sql3['SHEET_STROKE_COLOR'].replace(/\s/g, '').split('/')[i],
          opacity     : Number( sql3['SHEET_OPACITY'].replace(/\s/g, '').split('/')[i]),
        }
      })
      this.set_SkylineStatus(this.sheet.prefix) // ---> below
    },

    
    set_Punch(){
      let sql3 = null
      if (this.Queries.SQL3) sql3 = this.Queries.SQL3[0] //Legends
      
      this.punch = {
        prefix        : sql3['PUNCH_PREFIX'],
        styles        : [],
        values        : [],
      }
      let punchStatus = sql3[`PUNCH_STATUS`].replace(/\s/g, '').split('/')

      punchStatus.forEach((d,i)=> {
        this.punch.styles[i] = {
          text        : sql3[`PUNCH_NAME`].trim().split('/')[i],
          status      : sql3[`PUNCH_STATUS`].replace(/\s/g, '').split('/')[i],
        }
      })
    },
    

    set_SkylineStatus(prefix_) {
      // Setting for the Status styles in skyline 
      this.sheet.styles.forEach((s,i) => {

        this.rawData.filter(f => f[`${prefix_}_STATUS`] == s.status).forEach(d => {
          d.type     = s.type
          d.tSize    = this.skyline.skyBox.tSize //
          d.tColor   = s.tColor
          d.tWeight  = s.tWeight
          d.sWidth   = s.sWidth
          d.sColor   = s.sColor
          d.bColor   = s.color
          d.opacity  = s.opacity
          d.radius   = this.skyline.skyBox.radius
        }) 
      })
    },
  }
}