import * as d3 from 'd3'

export default {

  methods: {

    header_Resource() {
      let curves = {
        LINEAR: 'M0,8.8c0,0,11.1,0,13.1,0s13.7,0,13.7,0',
        LEFT:   'M26.7,14.9c0,0-10.4,0.5-14.3-6.2 C6.7-1.1,5,0.2,3.4,0.9c-2.3,1-3,13.3-3.1,14',
        CENTER: 'M26.4,14.5c0,0-3.7,0.5-6.9-6.5 C16,0.5,14,0.4,13.2,0.4c-0.8,0-2.8,0.1-6.3,7.6C3.7,15,0,14.5,0,14.5',
        RIGHT:  'M0,14.9c0,0,10.4,0.5,14.3-6.2 c5.7-9.8,7.3-8.6,9-7.8c2.3,1,3,13.3,3.1,14',

        name: ['LINEAR', 'LEFT', 'CENTER', 'RIGHT']
      }
      
      let resCurve = this.Area_Canvas
      .append('g')
      .attr('class', 'RESOURCE')
      .attr('transform',`translate(970, 110)`)
      .style('cursor', 'default')

      resCurve
      .append('text')
      .attr('x', 0).attr('y', 20).attr('font-size', 15)
      .attr('fill', '#fff').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Distribution Curve')

      resCurve
      .append('text')
      .attr('x', 0).attr('y', 33).attr('font-size', 10.5)
      .attr('fill', this.status.sColor).attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Number of closed A checksheet by week')

      resCurve
      .append('path')
      .attr('transform',`translate(125, 13) scale(1.2)`)
      .attr('d', this.icon.info).attr('fill', '#FFDA00')
      .style('cursor', 'pointer')
      .on('mouseover', function() {
        d3.select(this).transition().duration(150).attr('opacity', .5)
      })
      .on('mouseout', function() {
        d3.select(this).transition().duration(150).attr('opacity', 1)
      })
      .on('click', ()=> {
        this.help_Resource()
        this.help_Status = 'RESOURCE'
        // for export pdf
        this.set_selectedItems({action:'HELP',help_Status : this.help_Status})
      })

      let x_ = 10
      curves.name.forEach((d,i)=> {
        let curveItem = resCurve
        .append('g')
        .attr('id', `CURVE_${d}`)
        .attr('transform',`translate(${x_}, 60.5)`)
        .attr('opacity', this.schedule.currResource == d ? 1 : .3)
        .style('cursor', 'pointer')
        .on('mouseover', () => {
          d3.select(`#CURVE_${d}`).transition().duration(150).attr('opacity', 1)
        })
        .on('mouseout', () => {
          d3.select(`#CURVE_${d}`).transition().duration(150)
          .attr('opacity', this.schedule.currResource == d ? 1 : .3)
        })
        .on('click', ()=> {
          d3.select(`#CURVE_${this.schedule.currResource}`).transition().duration(150).attr('opacity', .3)
          this.schedule.currResource = d
        })

        curveItem
        .append('rect') 
        .attr('x', -1.5).attr('y', -10).attr('rx', 5).attr('ry', 5)
        .attr('width', 30).attr('height', 30)
        .attr('fill', 'url(#GradientBG)')
        .attr('stroke', '#757575').attr('stroke-width', .5)

        curveItem
        .append('path') 
        .attr('d', curves[d])
        .attr('fill', 'none').attr('opacity', 1)
        .attr('stroke', '#333').attr('stroke-width', 1.5)

        resCurve
        .append('text')
        .attr('x', x_+ 15).attr('y', 90).attr('font-size', 8.5)
        .attr('fill', this.status.sColor).attr('text-anchor', 'middle')
        .text(d)

        x_ += 45
      })

      let forecast = this.Area_Canvas
      .append('g')
      .attr('transform',`translate(840, 110)`)
      .style('cursor', 'default')

      // Buttons -------------------------------------------------------------------------------------------------------
      let ClearBtn = forecast
      .append('g')
      .attr('id', 'CLEAR_CHART')
      .attr('transform',`translate(350, 45)`)
      .attr('opacity', 1)
      .style('cursor', 'pointer')
      .on('mouseover', () => {
        d3.select(`#CLEAR_CHART`).transition().duration(150).attr('opacity', .5)
      })
      .on('mouseout', () => {
        d3.select(`#CLEAR_CHART`).transition().duration(150).attr('opacity', 1)
      })
      .on('click', ()=> {
        this.clear_Charts()
        // for export pdf
        this.set_selectedItems({})
      })

      ClearBtn
      .append('rect') 
      .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3)
      .attr('width', 90).attr('height', 16).attr('fill', '#989B9B').attr('stroke-width', 0)

      ClearBtn
      .append('text')
      .attr('x', 45).attr('y', 11).attr('font-size', 10).attr('fill', '#fff').attr('text-anchor', 'middle')
      .text('CLEAR CHARTS')


      let GenerateBtn = forecast
      .append('g')
      .attr('id', 'GENERATE')
      .attr('transform',`translate(350, 65)`)
      .attr('opacity', 1)
      .style('cursor', 'pointer')
      .on('mouseover', () => {
        d3.select(`#GENERATE`).transition().duration(150).attr('opacity', .5)
      })
      .on('mouseout', () => {
        d3.select(`#GENERATE`).transition().duration(150).attr('opacity', 1)
      })
      .on('click', ()=> {
        
        // for export pdf
        this.turnover_Charts()
        
        this.set_selectedItems({})

      })

      GenerateBtn
      .append('rect') 
      .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3)
      .attr('width', 90).attr('height', 25).attr('fill', '#757575').attr('stroke-width', 0)

      GenerateBtn
      .append('text')
      .attr('x', 45).attr('y', 16).attr('font-size', 11).attr('fill', '#fff').attr('text-anchor', 'middle')
      .text('TURN-OVER')

    },

    turnover_Charts(){

      if(this.fcStatus !== 'TO') this.clear_Charts()
      // for export pdf
      this.fcStatus = 'TO'

      // Initial Data
      this.set_LocalValues()

      this.chart_Count += 1
      if(this.chart_Count > 9) {
        this.popup_MaxChart()
        return
      }

      // Push Chart Condition Data
      this.condition_List.push({
        count:    this.chart_Count,
        duration: this.schedule.sDate - this.schedule.fDate,
        sDate:    this.schedule.sDate,
        fDate:    this.schedule.fDate,
        curve:    this.schedule.currResource,
        pk_idx:   0,
        pk_date:  0,
        pk_max:   0,
        pk_avg:   0,
      })

      this.redraw_ActualBarChart(7500)
      this.set_PlanValues(this.schedule)
      this.set_Axies(7500)
      
      this.plan_Controller()

      if(this.chart_Count == 0) this.draw_ConditionHeader()
      this.draw_Conditions()

      // for export pdf
      this.chart_Status = 'ON'    
    },

    clear_Charts() {
      if(this.chart_Count == -1) return

      d3.select('#CONDITION_BOX').remove()

      this.condition_List.forEach(d => {
        d3.select(`#Chart_Area_${d.count}`).remove()
        d3.select(`#CHART_CONDITION_${d.count}`).remove()
      })
      this.condition_List = []
      this.chart_Count = -1

      // for export pdf
      this.chart_Status = 'OFF'
    }

  }
}
