import Defs from '../../../../../../includes/primitives/colorDefs'
import * as d3 from 'd3'
import moment from 'moment'

export default {


  methods: {

    set_ChartValues(){

      // To preprocess the status values in an icicle chart based on a cutoff
      this.msList.forEach( m =>{
        let startIndex = this.rawData.filter(f => m.MS_NO == f.MS_NO).findIndex(f => f.STATUS == 'togo')
        let finishIndex = this.rawData.filter(f => m.MS_NO == f.MS_NO).findIndex(f => f.STATUS == 'completed' && new Date(f.CUTOFF) <= new Date(this.icicle.cutoff))
        if (finishIndex == -1) finishIndex = 100

        this.rawData.filter(f => m.MS_NO == f.MS_NO).forEach( (d, i) => {
          if (i == startIndex) d.icon = 'start'
          if (i > startIndex && i < finishIndex) d.icon = 'togo'
          if (i == finishIndex) d.icon = 'completed'
          if (i > finishIndex) d.icon = ''
          if (d.icon == 'togo' && d.CUTOFF == this.icicle.cutoff) d.icon = 'ongoing'
          if (new Date(d.CUTOFF) > new Date(this.icicle.cutoff)) d.icon = ''
        })
        m.checked = 'Y'
        m.PLAN = this.rawData.find(f => f.MS_NO == m.MS_NO).FORECAST
        m.FORECAST = this.rawData.find(f => f.MS_NO == m.MS_NO && f.CUTOFF == this.icicle.cutoff).FORECAST
        m.DELTA = moment(m.FORECAST).diff(moment(m.PLAN), "days")

        if(m.DELTA < 0) m.sch = 'AHEAD' 
        if(m.DELTA  == 0) m.sch = 'ON'
        if(m.DELTA  > 0) m.sch = 'BEHIND'
        
        let actual = this.rawData.find(f => f.MS_NO == m.MS_NO && f.STATUS =='completed')
        if(actual) m.sch = 'COMPLETED'

        m.IDX = m.MS_NO.substr(3)
      })

      this.options.schedule.code.forEach(d=> {
        this.options.schedule.count.
        push(this.msList.filter(f=> f.MS_NO.substr(0, 2) == this.options.milestone.cStatus && f.sch == d).length)
      })
    },



    
    set_Gradients() {
      // set Gradient color for the Timeline
      this.icon = {
        stroke: '#757575',
        colors:[
          '#757575', '#BDBCBC', '#E0E0DF', '#44A9DF', '#83D2F5', '#B4E3FA', '#FAAA18', '#FFDA00', '#4CAE4E', '#8BC248', '#CDDC37', '#EC407A', '#F7BACF',
          '#757575', '#BDBCBC', '#E0E0DF', '#44A9DF', '#83D2F5', '#B4E3FA', '#FAAA18', '#FFDA00', '#4CAE4E', '#8BC248', '#CDDC37', '#EC407A', '#F7BACF',
          '#757575', '#BDBCBC', '#E0E0DF', '#44A9DF', '#83D2F5', '#B4E3FA', '#FAAA18', '#FFDA00', '#4CAE4E', '#8BC248', '#CDDC37', '#EC407A', '#F7BACF',
          '#757575', '#BDBCBC', '#E0E0DF', '#44A9DF', '#83D2F5', '#B4E3FA', '#FAAA18', '#FFDA00', '#4CAE4E', '#8BC248', '#CDDC37', '#EC407A', '#F7BACF'
        ],
        start:  'M-5-7H5c1.1,0,2,0.9,2,2V5c0,1.1-0.9,2-2,2H-5c-1.1,0-2-0.9-2-2V-5C-7-6.1-6.1-7-5-7z',
        togo:'M0-4.4c2.4,0,4.4,2,4.4,4.4C4.3,2.4,2.4,4.4,0,4.4S-4.4,2.4-4.4,0S-2.4-4.4,0-4.4z',
        ongoing:   'M-7.1-1.4l5.7-5.7c0.8-0.8,2-0.8,2.8,0l5.7,5.7c0.8,0.8,0.8,2,0,2.8L1.4,7.1 c-0.8,0.8-2,0.8-2.8,0l-5.7-5.7C-7.9,0.6-7.9-0.6-7.1-1.4z',
        completed: 'M4.4,6.6c-0.1,0-0.3,0-0.4-0.1L1.1,4.8c-0.7-0.4-1.5-0.4-2.2,0L-4,6.5  c-0.1,0.1-0.3,0.1-0.4,0.1c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.2-0.4-0.2-0.7l0.7-3.3c0.2-0.7,0-1.5-0.6-2L-7.7-2  C-8-2.3-8.1-2.8-7.8-3.2c0.1-0.2,0.3-0.2,0.5-0.3l3.4-0.3c0.8,0,1.5-0.5,1.8-1.2l1.4-3.1C-0.6-8.5-0.3-8.6,0-8.6s0.6,0.2,0.8,0.5  L2.1-5c0.3,0.7,1,1.2,1.8,1.3l3.4,0.3c0.3,0,0.6,0.3,0.7,0.6c0.1,0.3,0,0.7-0.2,0.9L5.2,0.3c-0.6,0.5-0.9,1.3-0.7,2l0.7,3.3  c0.1,0.2,0,0.5-0.2,0.7C4.9,6.5,4.7,6.6,4.4,6.6L4.4,6.6z',
        checked: 'M3.7,4.6L3.7,4.6L1.3,2.4L0,3.6L3.7,7L10,1.2L8.7,0L3.7,4.6z'
      }

      Defs.setDefs_Gradients(this.svg, 'plan', 'LinearA4', 'LightBlue', [.5, .8, .5, 0], this.localId)
      Defs.setDefs_Gradients(this.svg, 'actual', 'LinearA4', 'LightBlue', [.8, .5, 0, .5], this.localId)
    },
  }

}