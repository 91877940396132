import * as d3 from 'd3'

export default {
  methods: {

    call_IcicleChart(selection, m) {
      let self = this

      selection
      .on('mouseover', () => {
        selection.transition().duration(50)
        .style('opacity', .7)

        selection.moveToFront()

        d3.select(`#highlight_${m.MS_NO}`)
        .style('visibility', 'visible')

        // Detailed Information
        d3.select('#Detail_Group').transition().duration(300).attr('opacity', 1)
        self.mouseOver_Detail(m.MS_NO) // ---> Detailed Information
      })

      .on('mouseout', () => {
        selection
        .transition().duration(50)
        .style('opacity', 1)

        d3.select(`#highlight_${m.MS_NO}`)
        .style('visibility', 'hidden')

        // Detailed Information
        d3.select('#Detail_Group').transition().delay(500).duration(300)
        .attr('opacity', self.detail.onOff == 'ON' ? 1 : 0)
      })
    },



    call_MilestoneList(selection, d) {
      selection
      .on('click', ()=> {
        d3.select(`#checked_${d.MS_NO}`)
        .style('visibility', () => {
          if(d.checked == 'Y') {
            d.checked = 'N'
            d3.select(`#icicle_${d.MS_NO}`).style('visibility', 'hidden')
            return 'hidden'
          } else {
            d.checked = 'Y'
            d3.select(`#icicle_${d.MS_NO}`).style('visibility', 'visible')
            return 'visible'
          }
        })
        
        //pdf
        let cnt = this.selectedList.find(f=> f.MS_NO == d.MS_NO)
        if(cnt){
          this.selectedList.filter(f=> f.MS_NO == d.MS_NO).forEach(p =>{
            p.checked = d.checked == 'N'?'Y':'N'
          })
        } else {
          this.selectedList.push({MS_NO:d.MS_NO,checked:d.checked == 'N'?'Y':'N'})
        }

        this.set_selectedItems()
      })
      .on('mouseover', () => {
        d3.select(`#icicle_${d.MS_NO}`).moveToFront()
        
        selection.transition().duration(150)
        .style('opacity', .7)
        highlight('over', d.MS_NO) // --->

        // Detailed Information
        d3.select('#Detail_Group').transition().duration(300).attr('opacity', 1)
        self.mouseOver_Detail(d.MS_NO) // ---> Detailed Information
      })
      .on('mouseout', () => {
        selection.transition().duration(150)
        .style('opacity', 1)
        highlight('out', d.MS_NO) // --->

        // Detailed Information
        d3.select('#Detail_Group').transition().delay(500).duration(300)
        .attr('opacity', self.detail.onOff == 'ON' ? 1 : 0)
      })

      let self = this
      let highlight = function(event, no) {
        self.msList.forEach( d => {
          if ( event == 'over') {
            d3.select(`#icicle_${d.MS_NO}`).transition().duration(100)
            .style('opacity', d.MS_NO == no ? 1 : .2)

          } else if ( event == 'out') {
            d3.select(`#icicle_${d.MS_NO}`).transition().duration(100)
            .style('opacity', 1)
          }
        })
      }
    },
    


    call_SelectAll(selection) {
      selection
      .on('mouseover', () => {
        selection
        .transition().duration(50).style('opacity', .7)
      })
      .on('mouseout', () => {
        selection
        .transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {

        if(this.msList.checkStatus == 'select') {
          selectAll()
          this.msList.checkStatus = 'unselect'
          d3.select('#slelectAll')
          .attr('fill', '#B5549B')
          .text('Unselect all')
        } else {
          unselectAll()
          this.msList.checkStatus = 'select'
          d3.select('#slelectAll')
          .attr('fill', '#44A9DF')
          .text('Select all')
        }
        //for pdf
        this.set_selectedItems()
        
      })

      let self = this
      let selectAll = function() {
        self.msList.forEach( d => {
          d.checked = 'Y'
          d3.select(`#checked_${d.MS_NO}`)
          .style('visibility', 'visible')
          d3.select(`#icicle_${d.MS_NO}`)
          .style('visibility', 'visible')
        })
      }

      let unselectAll = function() {
        self.msList.forEach( d => {
          d.checked = 'N'
          d3.select(`#checked_${d.MS_NO}`)
          .style('visibility', 'hidden')
          d3.select(`#icicle_${d.MS_NO}`)
          .style('visibility', 'hidden')
        })
      }
    },
  }
}