import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js'
import * as d3 from 'd3'

export default {
  methods: {
    load_FBXModel() {
      // FBX 파일 로드
      this.fbxLoader = new FBXLoader()

      this.createLoadingBar()

      this.fbxLoader.load(`${this.RESOURCE}/3D/fbx/${this.fbxFile}`, (object) => {
        this.fbxModel = object
        
        this.fbxModel.scale.set(1, 1, 1) // 필요에 따라 스케일 조정
        this.scene.add(this.fbxModel)

        // 모든 자식 요소에 대해 receiveShadow 설정
        this.fbxModel.traverse((child) => {
          if (child.isMesh) {
            this.originTransform[child.uuid] = {
              position: child.position.clone(),
              rotation: child.rotation.clone(),
            }

            if (child.material) {
              if (Array.isArray(child.material)) {
                child.material.forEach(material => {
                  material.envMap = this.texture
                  material.reflectivity = 0.3 // 반사율 조정 (0.0 ~ 1.0)
                  material.envMapIntensity = 0.1 // 환경 맵 강도 조정
                  material.needsUpdate = true
                })
              } else {
                child.material.envMap = this.texture
                child.material.reflectivity = 0.3 // 반사율 조정 (0.0 ~ 1.0)
                child.material.envMapIntensity = 0.1 // 환경 맵 강도 조정
                child.material.needsUpdate = true
              }
            }
            
            child.castShadow = true
            child.receiveShadow = true
            // // 환경 맵 적용한 재질 설정
            // child.material.envMap = this.texture
            // child.material.reflectivity = 1 // 반사율 조정 (0.0 ~ 1.0)
            // child.material.envMapIntensity = 1 // 환경 맵 강도 조정 (0.0 ~ 1.0)
            // child.material.needsUpdate = true
          }
        })

        // 모든 객체에 대해 버튼을 생성합니다.
        this.fbxModel.traverse((child) => {
          if (child.name) {
            this.originPosition[child.name] = child.position.clone()
          }
          // this.originalColors[child.uuid] = child.material.color.clone() // 원래 색상 저장
          // child.material.forEach((d, i) => {
          //   this.originalColors[child.uuid] = d.color.clone()
          // })
          // this.createButtonForObject(child, buttonCount++)
        })

        requestAnimationFrame(this.checkRenderComplete)
      }, (xhr) => {
        d3.select('#progress_gauge').attr('width', 200 * (xhr.loaded / xhr.total))
        if (xhr.loaded == xhr.total) {
          console.log('The FBX file has been successfully loaded.')
          d3.select('#progress_text').text('Rendering...')
        }
      }, (error) => {
        console.log('An error occurred while loading the FBX file: ', error)
      })
    },

    createButtonForObject(object, index) {
      const button = document.createElement('button')
      button.innerText = object.name || 'Unnamed Object'
      button.style.position = 'absolute'
      button.style.top = `${20 + index * 30}px`
      button.style.left = '93%'
      this.$refs.threeContainer.appendChild(button)

      button.addEventListener('click', () => {
            object.traverse((child) => {
                if (child.isMesh) {
                    child.material.color.set(Math.random() * 0xffffff) // 임의의 색상으로 변경
                    // child.material.forEach((d, i) => {
                    //   console.log(d)
                    //   d.color.set(Math.random() * 0xffffff)
                    // })
                }
            })
        })
    },

    createLoadingBar() {
      let loadingBar = d3.select(this.$refs.threeContainer).append('svg')
      .attr('id', 'svg_loading')
      .attr('transform', `translate(0, ${-this.SceneHeight})`)
      .attr('width', 1).attr('height', 1)

      loadingBar
      .append('text')
      .attr('id', 'progress_text')
      .attr('x', this.SceneWidth / 2).attr('y', this.SceneHeight / 2 - 22)
      .attr('text-anchor', 'middle').attr('font-size', 12)
      .text('Loading FBX file...')

      loadingBar
      .append('rect')
      .attr('x', this.SceneWidth / 2 - 100).attr('y', this.SceneHeight / 2 - 10)
      .attr('width', 200).attr('height', 20)
      .attr('fill', 'none').attr('stroke', '#333')
      .attr('stroke-width', 0.1)

      loadingBar
      .append('rect')
      .attr('id', 'progress_gauge')
      .attr('x', this.SceneWidth / 2 - 100).attr('y', this.SceneHeight / 2 - 10)
      .attr('width', 0).attr('height', 20)
      .attr('fill', 'lightgreen').attr('stroke', 'none')
    },

    checkRenderComplete() {
      this.renderer.render(this.scene, this.camera)

      d3.select('#svg_loading').remove()
    }
  }
}