import * as d3 from 'd3'

export default {
  methods: {

    header_Discipline() {

      this.disc_Sheets({
        x:      445,
        item:   'A',
        title:  'Discipline',
        cols:   'DISC',
        ref:    'DISC',
        interval: -60,
      })
      
    },


    disc_Sheets(st) {

      let Area_Discipline = this.Area_Canvas
      .append('g')
      .attr('transform',`translate(${st.x}, 10)`)
      .style('cursor', 'default')

      let barChart = Area_Discipline
      .append('g')
      .attr('transform', `translate(-5, 0)`)
      .style('font-family', 'roboto')

      barChart
      .append('text')
      .attr('transform',`translate(10, 25)`)
      .attr('font-size', 11).attr('fill', '#000').attr('text-anchor', 'start')
      .text(st.title)

      barChart
      .append('text')
      .attr('transform',`translate(10, 35)`)
      .attr('font-size', 9).attr('fill', '#BDBCBC').attr('text-anchor', 'start')
      .text('ITRs Status by Discipline')

      let colX = [0, 180+st.interval, 220+st.interval, 260+st.interval, 265+st.interval]

      let disc_Sheet = Area_Discipline
      .append('g')
      .attr('transform',`translate(30.5, 60)`)
      .style('font-family', 'roboto')
      
      let line = [
        {x1: 265+st.interval, y1:-15, x2: 265+st.interval},
        {x1: 315+st.interval, y1:-15, x2: 315+st.interval},
        {x1: 365+st.interval, y1:-15, x2: 365+st.interval},
      ]
      let eLine = this.DiscType.filter(f=> f.ITEM == st.item).length * 18 -15

      line.forEach(d=> {
        disc_Sheet
        .append('line')
        .attr('x1', d.x1).attr('y1', d.y1).attr('x2', d.x2).attr('y2', eLine).attr('stroke', '#bcbcbc').attr('stroke-width', 0.5)
      })

      disc_Sheet.append('text').attr('x', 263+st.interval).attr('y', -17).attr('font-size', 7).attr('text-anchor', 'middle').attr('fill', '#757575').text('0%')
      disc_Sheet.append('text').attr('x', 313+st.interval).attr('y', -17).attr('font-size', 7).attr('text-anchor', 'middle').attr('fill', '#757575').text('50%')
      disc_Sheet.append('text').attr('x', 363+st.interval).attr('y', -17).attr('font-size', 7).attr('text-anchor', 'middle').attr('fill', '#757575').text('100%')

      this.DiscType.filter(f=> f.ITEM == st.item).forEach((d,i) => {
        d.checked = false

        // Check Box
        let check = disc_Sheet
        .append('g')
        .attr('id', `CHECK_ITEM_${d.idx}`)
        .attr('transform',`translate(0, 0)`)
        .attr('cursor', 'pointer').attr('opacity', 1)
        .on('mouseover', function() {
          d3.select(this).attr('opacity', .7)
        })
        .on('mouseout', function() {
          d3.select(this).attr('opacity', 1)
        })
        .on('click', ()=> {
          this.redraw_Charts(d)
        })

        check
        .append('circle')
        .attr('transform',`translate(${colX[0]-10}, ${i*18 - 3})`)
        .attr('r', 4.5).attr('opacity', 1)
        .attr('fill', 'none').attr('stroke', '#333').attr('stroke-width', 1).attr('stroke-opacity', 0.5)

        check
        .append('circle')
        .attr('id', `CHECK_${d.idx}`)
        .attr('transform',`translate(${colX[0]-10}, ${i*18 - 3})`)
        .attr('r', 2.5).attr('opacity', 0)
        .attr('fill', '#333').attr('stroke-width', 0)

        // Dicsipline Title
        check
        .append('text')
        .attr('x', colX[0]).attr('y', i*18)
        .attr('font-size', 9).attr('text-anchor', 'start').attr('fill', '#333')
        .text(d.DISC_SHORT) // d.DISC_LONG.substr(2)

        // Total
        disc_Sheet
        .append('text')
        .attr('x', colX[1]).attr('y', i*18).attr('font-size', 9).attr('text-anchor', 'end').attr('fill', '#333')
        .attr('cursor', 'pointer')
        .text(d.TOT)
        .call(this.call_Sheet_List, {item: d.ITEM, type: 'TOT', key: d.DISC})

        // Actual
        disc_Sheet
        .append('text')
        .attr('x', colX[2]).attr('y', i*18).attr('font-size', 9).attr('text-anchor', 'end').attr('fill', '#333')
        .attr('cursor', 'pointer')
        .text(d.ACT)
        .call(this.call_Sheet_List, {item: d.ITEM, type: 'ACT', key: d.DISC})

        // Remain
        disc_Sheet
        .append('text')
        .attr('x', colX[3]).attr('y', i*18).attr('font-size', 9).attr('text-anchor', 'end').attr('fill', '#333')
        .attr('cursor', 'pointer')
        .text(d.REM)
        .call(this.call_Sheet_List, {item: d.ITEM, type: 'REM', key: d.DISC})

        // background
        disc_Sheet
        .append('rect')
        .attr('x', colX[4]).attr('y', i*18-9).attr('width', 100).attr('height', 12)
        .attr('fill', '#bcbcbc').attr('opacity', .2)

        disc_Sheet
        .append('rect')
        .attr('x', colX[4]).attr('y', i*18-9).attr('width', d.PRO).attr('height', 12)
        .attr('fill', this.barColor[`${d.ITEM}`]).attr('opacity', .5)

        // Progress
        disc_Sheet
        .append('text')
        .attr('x', d.PRO > 60 ? colX[4] + d.PRO - 2 : colX[4] + d.PRO + 2)
        .attr('y', i*18).attr('font-size', 9)
        .attr('text-anchor', d.PRO > 60 ? 'end' : 'start')
        .attr('fill',  d.PRO > 60 ? '#fff' : '#333')
        .text(d.PRO+'%')
      })

    },

  }
}