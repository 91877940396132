import * as d3 from 'd3'

export default {

  methods: {
    
    header_Duration() {
      let schedule = this.svg
      
      .append('g')
      .attr('class', 'SCHEDULE')
      .attr('transform',`translate(970, 10)`)
      .style('cursor', 'default')

      schedule
      .append('text')
      .attr('x', 0).attr('y', 20).attr('font-size', 15)
      .attr('fill', '#fff').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('MC Duration Setting')

      schedule
      .append('text')
      .attr('x', 0).attr('y', 33).attr('font-size', 10.5)
      .attr('fill', this.status.sColor).attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Setting duration based on subystem volume')

      schedule
      .append('path')
      .attr('transform',`translate(140, 13) scale(1.2)`)
      .attr('d', this.icon.info).attr('fill', '#FFDA00')
      .style('cursor', 'pointer')
      .on('mouseover', function() {
        d3.select(this).transition().duration(150).attr('opacity', .5)
      })
      .on('mouseout', function() {
        d3.select(this).transition().duration(150).attr('opacity', 1)
      })
      .on('click', ()=> {
        this.help_Duration()

        this.help_Status = 'DURATION'
        // for export pdf
        this.set_selectedItems({action:'HELP',help_Status : this.help_Status})
      })

      let durationArea = schedule
      .append('g')
      .attr('class', 'SCHEDULE')
      .attr('transform',`translate(100, 70.5)`)

      durationArea
      .append('rect') 
      .attr('x', 0).attr('y', 2)
      .attr('width', 200).attr('height', 16)
      .attr('fill', '#E0E0DF').attr('opacity', .3)
      .attr('stroke', '#757575').attr('stroke-width', .2)

      let this_ = this
      let startX = 90, finishX = 190, barWidth = 100
      
      let durationBar = durationArea
      .append('rect')
      .attr('id', 'DURATION')
      .attr('x', 90).attr('y', 5)
      .attr('width', 100).attr('height', 10)
      .attr('fill', '#fff').attr('opacity', 1)
      .style('cursor', 'pointer')
      let barHandler = d3.drag()
      .on('drag', function() {

        // barWidth = barWidth - (d3.event.x - startX)

        if (d3.event.x < barWidth/2) d3.event.x = barWidth/2
        if (d3.event.x > 200-(barWidth/2)) d3.event.x = 200-(barWidth/2)

        d3.select(`#Start_Handle`).attr('cx', d3.event.x-(barWidth/2))
        d3.select(`#Finish_Handle`).attr('cx', d3.event.x+(barWidth/2))
        d3.select(`#DURATION`).attr('x', startX)
        d3.select(`#sTEXT`).attr('x', d3.event.x-(barWidth/2)).text(`-${parseInt(200-d3.event.x+(barWidth/2))}`)
        d3.select(`#fTEXT`).attr('x', d3.event.x+(barWidth/2)).text(parseInt(200-d3.event.x-(barWidth/2)) == 0 ? parseInt(200-d3.event.x-(barWidth/2)) : `-${parseInt(200-d3.event.x-(barWidth/2))}`)

        this_.schedule.sDate = parseInt(200 - d3.event.x + barWidth/2)
        this_.schedule.fDate = parseInt(200 - d3.event.x - barWidth/2)

        startX = d3.event.x - barWidth/2
        finishX = d3.event.x + barWidth/2
        // console.log(this_.schedule.sDate, this_.schedule.fDate)
        
        // for export pdf
        this_.set_durationItems(startX,finishX,barWidth,this_.schedule.sDate,this_.schedule.fDate)
        this_.set_selectedItems({})

      })
      barHandler(durationBar)

      durationArea
      .append('text')
      .attr('id', 'sTEXT')
      .attr('x', 90).attr('y', 28).attr('font-size', 10)
      .attr('fill', this.status.vColor).attr('text-anchor', 'middle').text(-110)

      durationArea
      .append('text')
      .attr('id', 'fTEXT')
      .attr('x', 190).attr('y', 28).attr('font-size', 10)
      .attr('fill', this.status.vColor).attr('text-anchor', 'middle').text(-10)

      durationArea
      .append('text')
      .attr('x', 200).attr('y', -12).attr('font-size', 10)
      .attr('fill', '#EC407A').attr('text-anchor', 'middle').text('T/O')

      durationArea
      .append('path')
      .attr('transform',`translate(205, 0)scale(.75)`)
      .attr('d', 'M-8 -1 l-5 -10 h10 z')
      .attr('fill', '#EC407A')

      durationArea
      .append('text')
      .attr('id', 'X')
      .attr('x', 100).attr('y', -5).attr('font-size', 11)
      .attr('fill', this.status.vColor).attr('text-anchor', 'middle').text('Max Duration: 100 days')

      // Start Handle ---------------------------------------------
      let startBar = durationArea
      .append('circle')
      .attr('id', `Start_Handle`)
      .attr('cx', startX).attr('cy', 10.5).attr('r', 3)
      .attr('fill', '#757575').attr('stroke-width', 0)
      .style('cursor', 'w-resize')

      let startHandler = d3.drag()
      .on('drag', function() {

        if (d3.event.x < 0) d3.event.x = 0
        if (d3.event.x > finishX-30) d3.event.x = finishX-30
        
        barWidth = barWidth - (d3.event.x - startX)

        d3.select(`#X`).text(`Max Duration: ${parseInt(barWidth)} days`)
        d3.select(`#Start_Handle`).attr('cx', d3.event.x)
        d3.select(`#DURATION`).attr('x', d3.event.x).attr('width', barWidth)
        d3.select(`#sTEXT`).attr('x', d3.event.x).text(`-${parseInt(200-d3.event.x)}`)

        this_.schedule.sDate = 200 - d3.event.x
        startX = d3.event.x
        // console.log(this_.schedule.sDate, this_.schedule.fDate)
        
        // for export pdf
        this_.set_durationItems(startX,finishX,barWidth,this_.schedule.sDate,this_.schedule.fDate)
        this_.set_selectedItems({})
      })
      startHandler(startBar)


      // Finish Handle ---------------------------------------------
      let finishBar = durationArea
      .append('circle')
      .attr('id', `Finish_Handle`)
      .attr('cx', finishX).attr('cy', 10.5).attr('r', 3)
      .attr('fill', '#757575').attr('stroke-width', 0)
      .style('cursor', 'w-resize')

      let finishHandler = d3.drag()
      .on('drag', function() {

        if (d3.event.x < startX+30) d3.event.x = startX+30
        if (d3.event.x > 200) d3.event.x = 200

        barWidth = barWidth + (d3.event.x - finishX)

        d3.select(`#X`).text(`Max Duration: ${parseInt(barWidth)} days`)

        d3.select(`#Finish_Handle`).attr('cx', d3.event.x)
        d3.select(`#DURATION`).attr('width', barWidth)
        d3.select(`#fTEXT`).attr('x', d3.event.x).text(parseInt(200-d3.event.x) == 0 ? parseInt(200-d3.event.x) : `-${parseInt(200-d3.event.x)}`)
        this_.schedule.fDate = 200 - d3.event.x
        
        finishX = d3.event.x
        // console.log(this_.schedule.sDate, this_.schedule.fDate)
        
        // for export pdf
        this_.set_durationItems(startX,finishX,barWidth,this_.schedule.sDate,this_.schedule.fDate)
        this_.set_selectedItems({})
      })
      finishHandler(finishBar)
      
      // for export pdf
      this.duration_Drag()
    },

    set_durationItems(s_,f_,b_,sDate_,fDate_){
      // for export pdf
      this.start_X = s_
      this.finish_X = f_
      this.bar_Width = b_
      this.schedule.sDate = sDate_? sDate_ : this.schedule.sDate
      this.schedule.fDate = fDate_? fDate_ : this.schedule.fDate
    },

    duration_Drag(){

      d3.select(`#Start_Handle`).attr('cx', this.start_X)
      d3.select(`#Finish_Handle`).attr('cx', this.finish_X)
      d3.select(`#DURATION`).attr('x', this.start_X).attr('width', this.bar_Width)
      d3.select(`#sTEXT`).attr('x', this.start_X).text(`-${parseInt(200-this.start_X)}`)
      d3.select(`#fTEXT`).attr('x', this.finish_X).text(parseInt(200-this.finish_X) == 0 ? parseInt(200-this.finish_X) : `-${parseInt(200-this.finish_X)}`)

      this.schedule.sDate = 200 - this.start_X
      this.schedule.fDate = 200 - this.finish_X
    }
    
  }
}
