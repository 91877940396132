export default {
  data: () => ({
    stg_Canvas:   null,
    stg_Slide:    null,
    rawData:      [],

    slideX:       10,
    unit:         {},
    tables:       {},
    sheet:        {},

    paging:       0,

    // pdf
    getItems:       null,
    selectedItems:  null,
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    set_LocalValues(){

      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      let sql1 = JSON.parse(JSON.stringify(this.Queries.SQL1[0]))
    
      this.unit = {
        count:      1,
        tblNo:      sql1['TABLE_PAGE_NO'],
        title:      sql1['TABLE_NAME'],
        fSize:      16,
        fColor:     '#000',
      }

      this.tables = {
        length:       0,
        bColor:       sql1['TABLE_COLOR'].replace(/\s/g, '').split('/'),
        opacity:      sql1['TABLE_OPACITY'].replace(/\s/g, '').split('/').map(Number),
        gap:          30,
        left:         5,
        right:        5.3,
        width:        [40, 35, 35, 35],
        align:        ['start', 'end', 'end', 'end'],
        x:            [],
      }
      this.sheet = {
        name:       sql1['SHEET_NAME'].replace(/\s/g, ' ').split('/'),
        code:       sql1['SHEET_CODE'].replace(/\s/g, '').split('/'),
      }

      let x_ = this.tables.left
      this.tables.width.forEach((d,i)=> {
        if(this.tables.align[i] == 'start') this.tables.x[i] = x_
        if(this.tables.align[i] == 'end') this.tables.x[i] = x_ + d
        x_ += d
      })
      this.tables.length = x_ + this.tables.right

      this.paging = parseInt(this.rawData.length/this.unit.tblNo) + 1
      // console.log(this.paging)
    },

    set_selectedItems() {
      this.selectedItems = {
        ...this.selectedItems,
        slideX: this.slideX
      }
    }

  }
}