<template>
  <div :id="localId" class="svg_wrapper"></div>
</template>

<script>
import mx_Core from './includes/mixins/zMixins'        
import * as d3 from 'd3'
export default {
  name: "j-chart-project-1",
  mixins: [mx_Core],
  props: {
    id: String,
    modeExport: Boolean,
    getSelectItems: String,  

    FilterValues: { type: Object, default: () => ({}) },
    ColumnProps: { type: Array, default: () => ([]) },
    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    Note: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    Queries: { type: Object, default: () => ({}) },
  },


  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
    // console.log('this.id:', this.localId)
  },

  mounted() {
    this.register(this.draw)
  },

  methods: {
    draw() {
      if (!this.ready2draw) {
        return
      }

      this.clear()
      this.drawCanvas()
      this.drawing() // <------ Drawing Start
    },

    drawing() {
      this.set_LocalValues()
      this.set_ChartValues()
      this.set_Gradients()
      this.draw_Forms()
      this.draw_Timeline()
      this.draw_Grids()

      // for export pdf
      this.getItems = this.getSelectItems ? JSON.parse(this.getSelectItems) : {}

      if(this.getSelectItems){
        if(this.getItems) {

          if(this.getItems.sel_Status) this.msList.checkStatus = this.getItems.sel_Status

          if(this.getItems.milestone) this.options.milestone = this.getItems.milestone
          if(this.getItems.schedule) this.options.schedule = this.getItems.schedule

          this.draw_Legends()    
          this.icicle_Controller()

          if(this.getItems.filterStatus=='ON'){
            if(this.getItems.milestone.cStatus){
              this.options.milestone.code.forEach((d,i) => {
                d3.select(`#MS_Item_${d}`).style('opacity', 0.8) 
                d3.select(`#MS_Item_text_${d}`).attr('font-weight', 400).attr('fill', '#757575')
                d3.select(`#MS_Item_in_${d}`).attr('fill', '#fff')
                d3.select(`#OMS_Item_Out_${d}`).attr('stroke', '#bcbcbc')
              })
              d3.select(`#MS_Item${this.options.milestone.cStatus}`).style('opacity', 1) 
              d3.select(`MS_Item_text_${this.options.milestone.cStatus}`).attr('font-weight', 600).attr('fill', '#000')
              d3.select(`#MS_Item_in_${this.options.milestone.cStatus}`).attr('fill', '#83D2F5')
              d3.select(`#MS_Item_Out_${this.options.milestone.cStatus}`).attr('stroke', '#83D2F5')

              this.icicle_Controller('milestone')
            } 
            if(this.getItems.sc_cStatus){              
              this.options.schedule.code.forEach((d,i) => {
                console.log('cnt',this.options.schedule.count[i])
                d3.select(`#SCHEDULE_${d}`).attr('opacity', 0.8) 
                d3.select(`#SCHEDULE_text_${d}`).attr('font-weight', 400).attr('fill', '#757575')
                .text(`${this.options.schedule.name[i]}(${this.options.schedule.count[i]})`)
                d3.select(`#SCHEDULE_in_${d}`).attr('fill', '#fff')
                d3.select(`#SCHEDULE_out_${d}`).attr('stroke', '#bcbcbc')
              })
              d3.select(`#SCHEDULE_${this.options.schedule.cStatus}`).attr('opacity', 1) 
              d3.select(`#SCHEDULE_text_${this.options.schedule.cStatus}`).attr('font-weight', 600).attr('fill', '#000')
              d3.select(`#SCHEDULE_in_${this.options.schedule.cStatus}`).attr('fill', '#83D2F5')
              d3.select(`#SCHEDULE_out_${this.options.schedule.cStatus}`).attr('stroke', '#83D2F5')

              this.icicle_Controller('schedule')
            } 

            if(this.getItems.sel_Status){
              if(this.msList.checkStatus == 'select') {
                this.msList.forEach( d => {
                  d.checked = 'Y'
                  d3.select(`#checked_${d.MS_NO}`).style('visibility', 'visible')
                  d3.select(`#icicle_${d.MS_NO}`).style('visibility', 'visible')
                })
                this.msList.checkStatus = 'unselect'
                d3.select('#slelectAll').attr('fill', '#B5549B').text('Unselect all')
              } else {
                this.msList.forEach( d => {
                  d.checked = 'N'
                  d3.select(`#checked_${d.MS_NO}`).style('visibility', 'hidden')
                  d3.select(`#icicle_${d.MS_NO}`).style('visibility', 'hidden')
                })
                this.msList.checkStatus = 'select'
                d3.select('#slelectAll').attr('fill', '#44A9DF').text('Select all')
              }
            } 
            
            if(this.getItems.selectedList){
              this.getItems.selectedList.forEach( d => {
                if(d.checked == 'Y') {
                  d3.select(`#checked_${d.MS_NO}`).style('visibility', 'hidden')
                  d3.select(`#icicle_${d.MS_NO}`).style('visibility', 'hidden')
                } else {
                  d3.select(`#checked_${d.MS_NO}`).style('visibility', 'visible')
                  d3.select(`#icicle_${d.MS_NO}`).style('visibility', 'visible')
                }
              })
            }
          }

        }
      } else {
        this.draw_Legends()
        this.icicle_Controller()
      }
      this.draw_Information()
      this.drawNotes()
      this.drawTexts()
    },

  }
}
</script>



<style lang="scss" scoped>
  .svg_wrapper {
    width: 1300px;
    height: 1000px;

    overflow: hidden;
  }
</style>