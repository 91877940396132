import * as d3 from 'd3'
import moment from 'moment'

export default {

  methods: {

    set_ChartValues(){
      
      // ### call the global Function for Skyline Values ###
      this.init_gmx_SkylineValues_Week(
        this.rawData, 
        {
          refColumn:    this.timeline.refColumn,
          startDate:    this.timeline.startDate,
          endDate:      this.timeline.endDate,
          weekEnd:      'friday',
          skyBox_Width: this.get_gmx_MatchTimeline('friday', 1), // *** week
          skyBox_Gap:   1 // *** week
        }
      )

      this.rawData.forEach(d => {
        let date__  = this.get_gmx_TimelineCDate_Week(d[this.timeline.refColumn]) // *** week
        d.cDate     = date__.eDate
        d.sDate     = date__.sDate
        d.date      = d3.timeParse('%Y-%m-%d')(d[this.timeline.refColumn])
        d.hover     = 'Y'
      })

      this.skyline.styles.forEach(s => {
        this.rawData.filter(f => f[this.skyline.refStatus] == s.status).forEach(d => {
          d.tStyle   = s.tStyle
          d.bColor   = s.bColor
          d.opacity  = s.opacity
        })
      })


      // Get Max Value of the Bar Chart
      const Primary = 0
      const Secoundary = 1

      // Primary
      if(this.axis.maxValue[Primary]){
        this.maxPrimary = this.getMaxBound_v2(this.axis.maxValue[Primary])
        // console.log('MAX1')
      } else {
        this.charts.filter(f=> f.axis == 'PRIMARY').forEach(d=> {

          let getMax = d3.max(this.chartData, c => c[d.code])
          if( getMax > this.maxPrimary) this.maxPrimary = getMax
        })
        this.maxPrimary = this.getMaxBound_v2(this.maxPrimary)
      }
      // console.log('maxPrimary', this.maxPrimary)

      // Secoundary
      if(this.axis.maxValue[Secoundary]){
        this.maxSecoundary = this.getMaxBound_v2(this.axis.maxValue[Secoundary])
      } else {
        this.charts.filter(f=> f.axis == 'SECOUNDARY').forEach(d=> {
          let getMax = d3.max(this.chartData, c => c[d.code])
          if( getMax > this.maxSecoundary) this.maxSecoundary = getMax
        })
        this.maxSecoundary = this.getMaxBound_v2(this.maxSecoundary)
      }
      // console.log('maxSecoundary', this.maxSecoundary)

      this.scale.Primary = d3.scaleLinear()
      .domain([0, this.maxPrimary])
      .range([this.canvas.chart.height, 0])

      this.scale.Secondary = d3.scaleLinear()
      .domain([0, this.maxSecoundary])
      .range([this.canvas.chart.height, 0])


      // Grid
      this.grid = []
      for (let i = 0; i < 6; i++) {
        this.grid.push(this.canvas.chart.height - ((this.canvas.chart.height / 5) * i))
      }
    },
  }
}