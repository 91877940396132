import * as d3 from 'd3'

export default {
  methods: {
    /*
      Link_Actions()
    */

    link_Action(link_) {
      // set for link key value   ex) let dataSource = {KEY1:d.PKG,KEY2:d.DISC...}
      let dataSource = {}

      if(!!link_.unit) dataSource = {
        KEY1: link_.scope,
        KEY2: link_.unit,
        KEY3: link_.unit_No,
      }

      // SSMCC, WD, MC, SC, A, AP, B, BP, UNIT
      let reqtext = `JSON${(link_.code =='SSMCC'||link_.code =='WD')? 'SSMCC':link_.code}`

      // set for request from A/G jsonProps
      let request_ = this.getRequestColumnProps(reqtext, dataSource, this.ColumnProps)
      if(!request_) return

      // if filterCol is exist, set filterString_
      let filterString_ = ''

      let scope_ = link_.scope == 'GR'? 'Grassroots' : link_.scope == 'ES'? 'Existing Site' : 'Grassroots'
      let coltype_ = link_.coltype
      let code_ = link_.code
      
      // OVERALL SUMMARY SUBSYSTEM 
      if( code_ == 'SSMCC' || code_ == 'WD') {
        if( coltype_ == 'TOT'){
          filterString_ += `[SCOPE] = '${scope_}' AND [SSMCC_TOT] = '${coltype_}'`
        } else if ( code_ == 'SSMCC' & coltype_ == 'ACT'){
          filterString_ += `[SCOPE] = '${scope_}' AND [SSMCC_ACT] = '${coltype_}'`
        } else if ( code_ == 'SSMCC' & coltype_ == 'REM'){
          filterString_ += `[SCOPE] = '${scope_}' AND [SSMCC_ACT] = ''`
        } else if ( code_ == 'WD' & coltype_ == 'ACT'){
          filterString_ += `[SCOPE] = '${scope_}' AND [SSMCC_WD] = '${code_}'`
        } else if ( code_ == 'WD' & coltype_ == 'REM'){
          filterString_ += `[SCOPE] = '${scope_}' AND [SSMCC_WD] = ''`
        }
      } else if(code_ == 'UNIT'){
        filterString_ += `[SCOPE] = '${scope_}'`
      // SHEET & PUNCH
      } else {        
        code_ = (link_.code == 'A'||link_.code == 'B')? link_.code : link_.code.substr(0,1)
        if(coltype_ == 'TOT'){
          filterString_ += `[SCOPE] = '${scope_}' AND [CATEGORY] = '${code_}'`
        } else if(coltype_ == 'ACT'){
          filterString_ += `[SCOPE] = '${scope_}' AND [CATEGORY] = '${code_}' AND [STATUS] = 'C'`
        } else {
          filterString_ += `[SCOPE] = '${scope_}' AND [CATEGORY] = '${code_}' AND [STATUS] <> 'C'`
        }
      }

      // if A/G iFilters is exist, (filterString_ + iFilters)
      if(request_.iFilters.filterString) {
        request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
      } else {
        request_.iFilters.filterString = filterString_||''
      }

      // combine filterString 
      request_ = {...request_,
        filters : {
          ...this.FilterValues,
          ...request_.filters,
        },
        iFilters: { 
          filterString : request_.iFilters.filterString||'',
          inputFilter : request_.iFilters.inputFilter,},
      }
      this.$emit('request-action', request_)
    },
  }
}