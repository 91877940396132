export default {
  
  methods: {

    draw_Overall(selection) {
      this.coorX += this.overall.length

      selection
      .append('rect')
      .attr('x', 0).attr('y', 0)
      .attr('rx', 5).attr('ry', 5)
      .attr('fill', this.scope.bgColor[0]).attr('opacity', 1)
      .attr('width', this.overall.length).attr('height', this.header.height)

      selection // Grassroots
      .append('text')
      .attr('x', 10).attr('y', 25).attr('fill', '#333').attr('font-weight', 500).attr('font-size', 18)
      .text(this.scope.name)

      selection
      .append('text')
      .attr('x', 11).attr('y', 37).attr('fill', '#757575').attr('font-size', 11)
      .text('Status of MC & Pre-Commissioning')

      let Overall_Charts = selection
      .append('g')
      .attr('id', `OVERALL_${this.localId}`)
      .attr('transform', `translate(100, 75)`)

      let y_ = 0
      this.overall.name.forEach((d,i)=> {
        // OVERALL 
        Overall_Charts // TITLE
        .append('text')
        .attr('transform', `translate(${this.overall.x[0]}, ${y_})`)
        .attr('fill', '#333').attr('text-anchor', this.overall.align[0]).attr('font-size', 10)
        .text(d)

        Overall_Charts // TOTAL
        .append('text')
        .attr('id', `OVERALL_TOT_${this.localId}_${i}`)  // 함수로 뺄 경우 사용할 id
        .attr('transform', `translate(${this.overall.x[1] - 4}, ${y_})`)
        .attr('fill', '#333').attr('text-anchor', this.overall.align[1]).attr('font-size', 10)
        .text(this.rawData[`${this.overall.code[i]}_TOT`])
        .attr('cursor', 'pointer')
        .on('click', () => { this.link_Action({scope:this.scope.code[0], code:this.overall.code[i], coltype:'TOT'}) })

        Overall_Charts // BAR CHART - Background
        .append('rect')
        .attr('x', this.overall.x[2]).attr('y', y_-10.5)
        .attr('width', 100).attr('height', 14).attr('fill', '#bcbcbc').attr('opacity', .3)
        .attr('stroke-width', 0)
        
        // Actual Bar Chart
        let PRO = this.rawData[`${this.overall.code[i]}_PRO`]

        Overall_Charts 
        .append('rect')
        .attr('x', this.overall.x[2]).attr('y', y_-10.5)
        .attr('width', PRO).attr('height', 14).attr('stroke-width', 0)
        .attr('fill', `url(#${this.localId}_${this.scope.bColor[0]}_H)`)
        
        // ACTUAL
        Overall_Charts
        .append('text')
        .attr('y', y_)
        .attr('x', PRO < 60 ? this.overall.x[2]+PRO + 2 : this.overall.x[2]+PRO - 2)
        .attr('fill', PRO < 60 ? '#333' : '#333')
        .attr('text-anchor', PRO < 60 ? 'start' : 'end')
        .attr('font-size', 10)
        .text(this.rawData[`${this.overall.code[i]}_ACT`])
        .attr('cursor', 'pointer')
        // .on('click', () => { this.link_Action('OVS','OV',this.overall.code[i],'ACT') })
        .on('click', () => { this.link_Action({scope:this.scope.code[0], code:this.overall.code[i], coltype:'ACT'}) })

        // REMAIN
        Overall_Charts 
        .append('text')
        .attr('transform', `translate(${this.overall.x[3] + 2}, ${y_})`)
        .attr('fill', '#333').attr('text-anchor', this.overall.align[3]).attr('font-size', 10)
        .text(this.rawData[`${this.overall.code[i]}_REM`])
        .attr('cursor', 'pointer') 
        // .on('click', () => { this.link_Action('OVS','OV',this.overall.code[i],'REM') })
        .on('click', () => { this.link_Action({scope:this.scope.code[0], code:this.overall.code[i], coltype:'REM'}) })

        y_ += 25
      })
      
    },


   
  }
}