import * as d3 from 'd3'
import __C from '../../../../../../includes/primitives/_constant_crisp'
export default {
  data: () => ({
    RESOURCE: `${__C.HOST_NAME_RESOURCE}/resources`,

    innerSvg:       null,
    Area_Header:    null,

    rawData:        null,
    Systems:        null,

    turnover:       {}, 
    sheets:         {}, 

  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    set_LocalValues(){
      
      if(this.getSelectItems) {
        this.pageOptions = JSON.parse(this.getSelectItems)
      }
      
      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      this.Systems = JSON.parse(JSON.stringify(this.Queries.SQL1))

      this.rawData.forEach(d => {
        d.WD_TOT = d.SSMCC_TOT
        d.WD_ACT = d.SSMCC_WD
        d.WD_REM = d.SSMCC_TOT - d.SSMCC_WD
        d.WD_PRO= (d.SSMCC_WD/d.SSMCC_TOT) *100
      })


      // Timeline ------------------------------------------------------------------------------------
      this.timeline = {
        week            : [],
        month           : [],
        year            : [],
        scale           : null,
      }
      this.init_gmx_SkylineValues_Week(
        this.Systems, 
        {
          startDate:    '2023-09-01',
          endDate:      '2024-12-27',
          weekEnd:      'friday',
          skyBox_Width: 10,
          skyBox_Gap:   1 // *** week
        }
      )
      this.Systems.forEach(d => {
        if(d.CSTD_PLAN >= '2023-09-01') d.cDate = this.get_gmx_TimelineCDate_Week(d.CSTD_PLAN).eDate
        else d.cDate = this.get_gmx_TimelineCDate_Week('2023-09-01').eDate
      })

      // console.log(this.Systems)
    },


    loadSvg(selection, data) {
      return d3.xml(`${this.RESOURCE}${data.url}`).then(source => {
        let svg_ = source.documentElement
        selection.node().append(svg_)

        svg_ = d3.select(svg_)
        .attr('x', data.x)
        .attr('y', data.y)
        .attr('viewBox', data.vb)
        return new Promise(resolve => {
          resolve(svg_)
        })
      })
    },


    set_Gradients() {

    },
  }
}