import * as d3 from 'd3'

export default {
  
  methods: {
    draw_Timeline() {
      this.timeline.plan = this.init_gmx_TimelineValues_v1(this.timeline.plan)
      this.timeline.actual = this.init_gmx_TimelineValues_v1(this.timeline.actual)

      this.sTimeline = this.svg
      .append('g')
      .attr('transform', `translate(${this.icicle.x}, ${this.icicle.y})`)
      .attr('id', 'Timelines')
      .attr('font-family', 'roboto')

      this.draw_Timeline_Plan()
      this.draw_Timeline_Actual()
    },

    draw_Timeline_Plan() {
      
      let Timeline_Plan = this.sTimeline
      .append('g')
      .attr('transform', `translate(${this.icicle.padding.left}, 0)`)
      .attr('id', 'plan_timeline')

      Timeline_Plan
      .append('rect')
      .attr('x', -this.icicle.padding.left).attr('y', 0).attr('width', this.icicle.padding.left-2).attr('height', 25)
      .attr('fill', `url(#${this.localId}_LightBlue_plan)`).attr('opacity', .5)

      Timeline_Plan
      .append('rect')
      .attr('x', 0).attr('y', 0).attr('width', this.timeline.plan.width).attr('height', 25)
      .attr('fill', `url(#${this.localId}_LightBlue_plan)`).attr('opacity', .5)

      Timeline_Plan
      .append('text')
      .attr('x', -37).attr('y', 35).style('font-size', 10)
      .style('fill', '#000').attr('font-weight', 450)
      .text('PLAN -')


      // Timeline Text using the scale function
      // Year
      Timeline_Plan
      .append('g')
      .selectAll('text')
      .data(this.timeline.plan.values.year)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.plan.scale(d.midDate))
      .attr('y', 14).style('font-size', this.styles.plan.year)
      .style('fill', '#000').attr('font-weight', 450)
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(d => d.name)

      // Month
      Timeline_Plan
      .append('g')
      .selectAll('text')
      .data(this.timeline.plan.values.month)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.plan.scale(d.midDate))
      .attr('y', 35).style('font-size', this.styles.plan.month).style('fill', '#333')
      .attr('text-anchor', 'middle').attr('alignment-baseline_1', 'middle')
      .text(d => d.name.toUpperCase())

      // Timeline Seperator
      Timeline_Plan
      .append('g')
      .selectAll('path')
      .data(this.timeline.plan.values.month)
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        if (this.timeline.plan.values.month.length-1 > i) {
          if (d.name == 'Dec')return `M${this.timeline.plan.scale(d.endDate)}, 0 V35`
          else return `M${this.timeline.plan.scale(d.endDate)}, 25 V35`
        }
      })
      .attr('stroke', d => d.name == 'Dec' ? '#fff' : '#757575')
      .attr('stroke-width', d => d.name == 'Dec' ? 2 : 0.3)
    },

    

    draw_Timeline_Actual() {

      let Timeline_Actual = this.sTimeline
      .append('g')
      .attr('transform', `translate(0, ${this.styles.actual.topMargin})`)
      .attr('id', 'actual_timeline')

      Timeline_Actual
      .append('rect')
      .attr('x', 0).attr('y',0).attr('width', 25).attr('height', this.timeline.actual.width)
      .attr('fill', `url(#${this.localId}_LightBlue_actual)`).attr('opacity', .5)

      // Timeline Text using the scale function
      // Year
      Timeline_Actual
      .append('g')
      .selectAll('text')
      .data(this.timeline.actual.values.year)
      .enter()
      .append('text')
      .attr('transform', d => `translate(14, ${this.timeline.actual.scale(d.midDate)})rotate(-90)`)
      .style('font-size', this.styles.actual.year).style('fill', '#000').attr('font-weight', 450)
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(d => d.name)

      // Month
      Timeline_Actual
      .append('g')
      .selectAll('text')
      .data(this.timeline.actual.values.month)
      .enter()
      .append('text')
      .attr('transform', d => `translate(35, ${this.timeline.actual.scale(d.midDate)})rotate(-90)`)
      .style('font-size', this.styles.actual.month).style('fill', '#333')
      .attr('text-anchor', 'middle').attr('alignment-baseline_1', 'middle')
      .text(d => d.name.toUpperCase())

      // Timeline Seperator
      this.timeline.actual.values.month.forEach( d => {
        if (d.name == 'Dec') {
          Timeline_Actual
          .append('line')
          .attr('x1', 0).attr('y1', this.timeline.actual.scale(d.endDate))
          .attr('x2', 25).attr('y2', this.timeline.actual.scale(d.endDate))
          .attr('stroke', '#fff')
          .attr('stroke-width', 2 )
        }
      })
    },

  }
}