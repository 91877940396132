import { ApplicationService } from '../services'
import Loading from '@/mixins/loading.mixin'
import __C from '../primitives/_constant_'
import __M from 'moment'
import * as d3 from 'd3'
import { mapState, mapMutations } from 'vuex'
import { set } from 'core-js/core/dict'
export default {
  mixins: [
    Loading
  ],
  data: () => ({
    appService: null
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),
  },
  created() {
    this.appService = new ApplicationService()
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [
      'setSelectItems'
    ]),
    // @@params ### ---------------------------
    // @type    pdf | png
    // @info    { name: String, width: Number, height: Number }
    // @action  Function(type):Promise | Object
    async createExport(type, info, action, items=null, idx=null) {
      // to get the debugging string ------------------------------
      // console.log('token:', this.account.token)
      // console.log('type:', type)
      // console.log('info for dimention, not the included url string:', JSON.stringify(info))
      // ----------------------------------------------------------
      this.loading = true
      if(typeof action == 'string') var action__ = action
      else action__ = await action(type)
      // ----------------------------------------------------------
      // console.log('action:', action__)
      // console.log('items:', JSON.stringify(items))
      // console.log(`URL :    http://localhost:8080/#/export/${this.account.token}/${action__}/${JSON.stringify(items)}`)
      // ----------------------------------------------------------
      if(this.dimention && this.dimention.isreal) {
        info.width = this.dimention.width
        info.height = this.dimention.height
      }

      let idx_ = idx ? await idx(type) : null
      let items_ = items ? JSON.stringify(items) : null

      let dpi_ = 96
      let width_ = type == 'pdf' ? Number((info.width / dpi_).toFixed(2)) : Number(info.width)
      let height_ = type == 'pdf' ? Number((info.height / dpi_).toFixed(2)) : info.height
      let scale_ = type == 'pdf' ? 1 : 1
        
      let params = new URLSearchParams()
      params.append('action', action__)
      params.append('create', type)
      params.append('width', type == 'pdf' ? (width_ < height_ ? width_ : height_) : width_)
      params.append('height', type == 'pdf' ? (width_ < height_ ? height_ : width_) : height_)
      params.append('scale', scale_)
      params.append('orientation', width_ > height_ ? 'Landscape' : 'Portrait')
      params.append('items', items_)
      params.append('idx', idx_)

      return new Promise((resolve) => {
        this.appService.createExport(params, (res) => {
          this.download(type, (info.name || '').replace(/\s/g, '_'), res)
          resolve(res)
          this.loading = false
        })
      })
    },
    
    async createExcel(exportInfo, fValues, iFilters) {
      this.loading = true

      let params = new URLSearchParams()
      params.append('exportInfo', JSON.stringify(exportInfo))
      params.append('fValues', JSON.stringify(fValues))
      params.append('iFilters', JSON.stringify(iFilters))

      let fileName_ = []
      if(exportInfo.useColValue && exportInfo.fileColName && exportInfo.fileColName.length > 0) {
        exportInfo.fileColName.forEach(colName => {
          if(fValues[colName]) fileName_.push(fValues[colName])
        })
      } else {
        fileName_.push(exportInfo.fileName)
      }

      let fileName__ = fileName_.join('-')

      if(exportInfo.appendDate) fileName__ += '-' + __M(new Date()).format('YYYY_MM_DD')

      return await new Promise(async (resolve, reject) => {
        this.appService.createExcel(params, (res) => {
          try {
            this.download('xlsx', fileName__, res)
            resolve(res)

          } catch (err) {
            reject(err)
          }

          this.loading = false
        })
      })
    },
    download(type, filename, data) {
      if(!data || typeof data != 'string') {
        console.log('[USER: #DEBUG] Cannot download empty data.',data)
        return null
      }

      let binary_ = atob(data)
      let len_ = binary_.length
      let buffer_ = new ArrayBuffer(len_)
      let view_ = new Uint8Array(buffer_)

      for (var i = 0; i < len_; i++) {
        view_[i] = binary_.charCodeAt(i);
      }

      // create the blob object with content-type "application/pdf"
      let mimeXlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      let mimePdf = 'application/pdf'
      let mimeImage = 'image/png'

      let blob = new Blob([view_], { type: type == 'xlsx' ? mimeXlsx : (type == 'pdf' ? mimePdf : mimeImage) });

      let url = URL.createObjectURL(blob);
      let link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${filename}.${type}`)
      document.body.appendChild(link)
      link.click()
      let defalut = ''
      this.setSelectItems(defalut)
    },
  }
}