import moment from 'moment'
import * as d3 from 'd3'

export default {
  methods: {

    forecast_Controller() {

      let barGap = 1
      let barWidth = (this.scale.X.bandwidth()/2)-(barGap/2)
   
      // Call Chart
      let data = this.timeline.week.filter(f=> f.eDate >= new Date(this.timeline.cutoff))
      let lastDate = data[data.findIndex(f=> f.value == 0)]
      if(lastDate) lastDate = lastDate.eDate

      this.forecast_barChart(data, this.scale.Primary, barWidth)
      this.forecast_lineChart(data, this.scale.Secondary)
      
      if(lastDate) this.sheetCompleteDate2(this.Area_Charts, lastDate)
    },


    forecast_barChart(_data, scale_, width_) {
      let chart = this.canvas.chart

      this.Area_Charts
      .selectAll(`rect_`)
      .data(_data)
      .enter()
      .append('rect')
      .attr('x', d => this.scale.X(d.eDate) + 0)
      .attr('y', d => scale_(d.value))
      .attr('height', d => chart.height - scale_(d.value))
      .attr('width', width_)
      .attr('fill', this.colors[this.chart_Count])
      .attr('opacity', .4)
    },


    forecast_lineChart(data_, scale_, no_) {
      this.Xband = this.scale.X.bandwidth() / 2

      let lineFunc = d3.line()
      .x(d => this.scale.X(d.eDate) + this.Xband)
      .y(d => scale_(d.pro))

      let this_ = this
      let lineChart = this.Area_Charts
      .append('g')
      .attr('id', `LINE_CHART_${this.chart_Count}`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')
  
      // Draw Chart Tag
      lineChart
      .append('path')
      .attr('d', lineFunc(data_))
      .style('stroke', this.colors[this.chart_Count])
      .style("stroke-dasharray", (2,2))
      .style('stroke-width', 2)
      .attr('opacity', .4)
      .style('fill', 'none')

      lineChart
      .selectAll(`circle_${no_}`)
      .data(data_)
      .enter()
      .append('circle')
      .attr('cx', d => this.scale.X(d.eDate) + this.Xband)
      .attr('cy', d=> scale_(d.pro))
      .attr('r', 1.5)
      .style('stroke', this.colors[this.chart_Count])
      .style('stroke-width', 2)
      .attr('stroke-opacity', .4)
      .style('fill', this.colors[this.chart_Count])


      let from = 10 + (this.chart_Count*2)
      let tag = {
        x1: this.scale.X(data_[from].eDate)+this.Xband,
        y1: scale_(data_[from].pro),
        x2: (this.scale.X(data_[from].eDate)+this.Xband) - 50,
        y2: scale_(data_[from].pro) - 30.5,
      }

      lineChart
      .append('line')
      .attr('x1', tag.x1).attr('y1', tag.y1)
      .attr('x2', tag.x2).attr('y2', tag.y2)
      .attr('stroke', this.colors[this.chart_Count]).attr('stroke-width', .5)

      lineChart
      .append('line')
      .attr('x1', tag.x2).attr('y1', tag.y2)
      .attr('x2', tag.x2-50).attr('y2', tag.y2)
      .attr('stroke', this.colors[this.chart_Count]).attr('stroke-width', .5)

      lineChart
      .append('rect')
      .attr('x', tag.x2-50 - 12).attr('y', tag.y2 - 6)
      .attr('width', 12).attr('height', 12)
      .attr('fill', this.colors[this.chart_Count])
      .attr('stroke', '#bcbcbc').attr('stroke-width', .5)

      lineChart
      .append('text')
      .attr('x', tag.x2-50 - 6).attr('y', tag.y2 +1)
      .attr('font-size', 10).attr('fill', '#333')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(this.chart_Count)
    },


    sheetCompleteDate2(selection, lastDate){
      selection
      .append('line')
      .attr('x1', this.scale.X(lastDate))
      .attr('x2', this.scale.X(lastDate))
      .attr('y1', 0)
      .attr('y2', this.canvas.chart.height)
      .attr('stroke', this.colors[this.chart_Count]).attr('stroke-width', .5)

      let x_ = this.scale.X(lastDate)
      let y_ = this.canvas.chart.height - 100 - (this.chart_Count*50)
      selection
      .append('polygon')
      .attr('points', `${x_},${y_} ${x_+10},${y_-13} ${x_+90},${y_-13} ${x_+90},${y_+13} ${x_+10},${y_+13}` )
      .attr('fill', '#fff')
      .attr('stroke', this.colors[this.chart_Count]).attr('stroke-width', .5)

      selection
      .append('text')
      .attr('x', x_ + 85).attr('y', y_ - 3)
      .attr('text-anchor', 'end')
      .attr('font-size', 10).attr('fill', this.colors[this.chart_Count])
      .text(`To be completed`)
      
      selection
      .append('text')
      .attr('x', x_ + 85).attr('y', y_ + 8)
      .attr('text-anchor', 'end')
      .attr('font-size', 10).attr('fill', this.colors[this.chart_Count])
      .text(moment(lastDate).add(-7, 'days').format('YYYY-MM-DD'))

      // console.log(this.timeline.week)
    }
  }
}