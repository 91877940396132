export default {
  
  methods: {

    // SHEET SUMMARY
    draw_Summary(selection, gX, item_, idx) {
      let barSummary = selection
      .append('g')
      .attr('transform', `translate(${gX}, 30)`)
      .style('font-family', 'roboto').attr('cursor', 'default')   
      
      barSummary
      .append('rect')
      .attr('x', 0).attr('y', 0).attr('rx', 5).attr('ry', 5)
      .attr('fill', this.sheets.bgColor[idx]).attr('opacity', .9)
      .attr('width', 215).attr('height', 60)

      barSummary
      .append('rect')
      .attr('x', 0).attr('y', 65).attr('rx', 5).attr('ry', 5)
      .attr('fill', this.sheets.bgColor[idx]).attr('opacity', .6)
      .attr('width', 215).attr('height', 50)

      barSummary
      .append('rect')
      .attr('x', 0).attr('y', 120).attr('rx', 5).attr('ry', 5)
      .attr('fill', this.sheets.bgColor[idx]).attr('opacity', .3)
      .attr('width', 215).attr('height', 50)

      // TITLE
      barSummary 
      .append('text')
      .attr('x', 10).attr('y', 20).attr('fill', '#000')
      .attr('font-size', 12).text(this.sheets.mTitle[idx])

      // TITLE - SUB
      barSummary 
      .append('text')
      .attr('x', 10).attr('y', 32).attr('fill', '#757575').attr('font-size', 9)
      .text(this.sheets.sTitle[idx])

      // TITLE - PRO
      barSummary 
      .append('text')
      .attr('x', 190).attr('y', 52).attr('fill', '#333')
      .attr('text-anchor', 'end').attr('font-size', 16)
      .text(item_ == 'MC' ? this.grassroots.MC_PRO.toFixed(1) + '%' : this.grassroots.SC_PRO.toFixed(1) + '%')
      
      this.draw_barSheet(barSummary, idx, item_, 65)
      this.draw_barPunch(barSummary, idx, item_, 120)

      this.coorX += 215 + 10
    },


    // SHEET CHART
    draw_barSheet(selection, idx, item_, y_) {
      let data_ = this.grassroots
      let sht = (item_ == 'MC') ? 'A' : 'B'
      let x_ = [70, 170]
      let len = 100

      let barChart = selection
      .append('g')
      .attr('transform', `translate(0, ${y_})`)
      .style('font-family', 'roboto').attr('cursor', 'default')   

      barChart // TITLE
      .append('text')
      .attr('x', 10).attr('y', 18).attr('fill', '#333')
      .attr('font-size', 10).text(`${sht} ITRs`)

      barChart // Total
      .append('text')
      .attr('x', x_[0]-2).attr('y', 39).attr('fill', '#757575')
      .attr('font-size', 10).attr('text-anchor','end')
      .text(data_[`${sht}_TOT`])
      .attr('cursor', 'pointer')
      .on('click', () => { this.link_Action({scope:'GR', code:sht, coltype:'TOT'}) })

      barChart // BAR CHART - Background
      .append('rect')
      .attr('x', x_[0]).attr('y', 30)
      .attr('width', len).attr('height', 12).attr('fill', '#bcbcbc').attr('opacity', .2)
        
      // BAR CHART - Actual Bar Chart
      let PRO = data_[`${sht}_PRO`]
      barChart
      .append('rect')
      .attr('x', x_[0]).attr('y', 30)
      .attr('width', len * (PRO/100)).attr('height', 12).attr('fill', this.sheets.bgColor[idx])

      barChart // Actual Value
      .append('text')
      .attr('y', 39).attr('font-size', 10)
      .attr('x', PRO < 50 ? x_[0] + len * (PRO/100) + 1 : x_[0] + len * (PRO/100) - 1)
      .attr('fill', PRO < 50 ? '#757575' : '#757575')
      .attr('text-anchor', PRO < 50 ? 'start' : 'end')
      .text(data_[`${sht}_ACT`])
      .attr('cursor', 'pointer')      
      .on('click', () => { this.link_Action({scope:'GR', code:sht, coltype:'ACT'}) })

      barChart // Remain
      .append('text')
      .attr('x', x_[1]+2).attr('y', 39).attr('fill', '#757575')
      .attr('font-size', 10).attr('text-anchor','start')
      .text(data_[`${sht}_REM`])
      .attr('cursor', 'pointer')     
      .on('click', () => { this.link_Action({scope:'GR', code:sht, coltype:'REM'}) })
    },

    // SHEET CHART
    draw_barPunch(selection, idx, item_, y_) {
      let data_ = this.grassroots
      let sht = (item_ == 'MC') ? 'AP' : 'BP'
      let x_ = [70, 170]
      let len = 100

      let barChart = selection
      .append('g')
      .attr('id', `SHEETDET_${this.localId}`)
      .attr('transform', `translate(0, ${y_})`)

      barChart // TITLE
      .append('text')
      .attr('x', 10).attr('y', 18).attr('fill', '#333')
      .attr('font-size', 10).text(`Punch ${sht.charAt(0)}`)

      barChart // Total
      .append('text')
      .attr('x', x_[0]-2).attr('y', 39).attr('fill', '#757575')
      .attr('font-size', 10).attr('text-anchor','end')
      .text(data_[`${sht}_TOT`])
      .attr('cursor', 'pointer')
      .on('click', () => { this.link_Action({scope:'GR', code:sht, coltype:'TOT'}) })

      barChart // BAR CHART - Background
      .append('rect')
      .attr('x', x_[0]).attr('y', 30)
      .attr('width', len).attr('height', 12).attr('fill', '#bcbcbc').attr('opacity', .2)
      
      // BAR CHART - Actual Bar Chart
      let PRO = data_[`${sht}_PRO`]
      barChart
      .append('rect')
      .attr('x', x_[0]).attr('y', 30)
      .attr('width', len * (PRO/100)).attr('height', 12).attr('fill', this.sheets.bgColor[idx])

      barChart // Actual Value
      .append('text')
      .attr('y', 39).attr('font-size', 10)
      .attr('x', PRO < 50 ? x_[0] + len * (PRO/100) + 1 : x_[0] + len * (PRO/100) - 1)
      .attr('fill', PRO < 50 ? '#757575' : '#757575')
      .attr('text-anchor', PRO < 50 ? 'start' : 'end')
      .text(data_[`${sht}_ACT`])
      .attr('cursor', 'pointer')      
      .on('click', () => { this.link_Action({scope:'GR', code:sht, coltype:'ACT'}) })

      barChart // Remain
      .append('text')
      .attr('x', x_[1]+2).attr('y', 39).attr('fill', '#757575')
      .attr('font-size', 10).attr('text-anchor','start')
      .text(data_[`${sht}_REM`])
      .attr('cursor', 'pointer')     
      .on('click', () => { this.link_Action({scope:'GR', code:sht, coltype:'REM'}) })
    }
  }
}