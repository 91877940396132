import gmx_zMixins         from '../../../../../../includes/Jmixins/zMixins'

// Locally declared common Data-Props & SVG Drawing Support

import lmx_watching             from './watching'

// Charts 
import lmx_LocalValues         from './set_LocalValues'
import lmx_ChartValues          from './set_ChartValues'

import lmx_drawForms            from './draw_Forms'
import lmx_Axis                 from './draw_Axis'
import lmx_Charts               from './draw_Charts'
import lmx_Detail               from './draw_Detail'
import lmx_Legends              from './draw_Legends'

import lmx_events               from './Events'

export default {
  
  mixins: [
    gmx_zMixins, 
    
    // Local Mixins
    lmx_watching,
    lmx_LocalValues,
    lmx_ChartValues,
    lmx_events,

    lmx_drawForms,
    lmx_Axis,
    lmx_Charts,
    lmx_Detail,
    lmx_Legends,
    
  ],

}
