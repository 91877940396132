import gmx_zMixins         from '../../../../../../includes/Jmixins/zMixins'

// Locally declared common Data-Props & SVG Drawing Support

import lmx_watching             from './Watching'

// Charts 
import lmx_LocalValues          from './set_LocalValues'
import lmx_Header               from './draw_Header'

import lmx_Events               from './Events'
import lmx_Radial               from './draw_Radial.js'
import lmx_RadialChart          from './draw_RadialChart'
import lmx_Subsystems           from './draw_Subsystems'
import lmx_Timeline             from './draw_Timeline'
import lmx_GanttChart           from './draw_GanttChart'

export default {
  mixins: [
    gmx_zMixins, 

    // Local Mixins
    lmx_watching,
    lmx_LocalValues,
    lmx_Header,
    lmx_Events,

    lmx_Radial,
    lmx_RadialChart,
    lmx_Subsystems,
    lmx_Timeline,
    lmx_GanttChart,
    
  ],
}
