import * as d3 from 'd3'

export default {

  methods: {

    draw_Options() {
      if (this.search.visible == 'Y') this.option_Search()
    },


    option_Search() {

      // ---------------------------------------
      // Search Controller ---> Global Function
      // ---------------------------------------

      let SearchBox = this.SkylineCharts
      let data__ = {
        x         : this.search.x,
        y         : this.search.y,
        width     : 200,
        height    : 60.5,
        label     : 'Search',
        labelWidth: 40,
        targets: [
          { text: this.search.note },
        ],
        callFunc: this.call_Search, // --->  in Event.js
      }
      this.drawSearchToolFree(SearchBox, data__) //---> Global function
    }, 

  }
}
