import * as d3 from 'd3'
export default {
  
  methods: {

    draw_Trends() {
      let items = {
        x:      [20, 65, 110, 170, 230, 270, 310],
        width:  [40, 40, 40, 55, 35 ,35, 35],
        code:   ['A', 'B', 'C', 'SSMCC', 'AP', 'BP', 'CP'],
        name:   ['A ITRs', 'B ITRs', 'C ITRs', 'Turnover', 'Pn. A', 'Pn. B', 'Pn. C'],
      } 

      let Trends = this.Area_Canvas
      .append('g')
      .attr('transform', `translate(470.5, 600.5)`)
      .style('font-family', 'roboto').attr('cursor', 'default')

      let this_ = this
      items.name.forEach((d,i)=> {

        let btn = Trends
        .append('g')
        .attr('transform', `translate(${items.x[i] - (items.width[i]/2)}, 0)`)
        .attr('cursor', 'pointer').attr('opacity', 1)
        .on('mouseover', function() {
          d3.select(this).transition().duration(150)
          .attr('cursor', this_.selectedTrend == items.code[i] ? 'default' : 'pointer')
          .attr('opacity', this_.selectedTrend == items.code[i] ? 1 : .3)
        })
        .on('mouseout', function() {
          d3.select(this).transition().duration(150).attr('opacity', 1)
        })
        .on('click', ()=> {
          d3.select(`#btn_BOX_${this.selectedTrend}`).attr('fill', '#fff')
          d3.select(`#btn_TXT_${this.selectedTrend}`).attr('fill', '#333')

          this.selectedTrend = items.code[i]
          d3.select(`#btn_BOX_${this.selectedTrend}`).attr('fill', '#bcbcbc')
          d3.select(`#btn_TXT_${this.selectedTrend}`).attr('fill', '#fff')

          // Redraw Trend Chart
          this.redraw_TrendChart('Existing Site',this.selectedTrend)
          this.redraw_TrendChart('Grassroots',this.selectedTrend)

          // Redraw Week Performance
          this.redraw_WeekPerformance('Existing Site',this.selectedTrend)
          this.redraw_WeekPerformance('Grassroots',this.selectedTrend)

          // for export pdf
          this.set_selectedItems()
        })

        btn
        .append('rect')
        .attr('id', `btn_BOX_${items.code[i]}`)
        .attr('x', 0).attr('y', 0)
        .attr('width', items.width[i]).attr('height', 16)
        .attr('stroke', '#bcbcbc').attr('stroke-width', .5)
        .attr('fill', this.selectedTrend == items.code[i] ? '#bcbcbc' : '#fff')

        btn
        .append('text')
        .attr('id', `btn_TXT_${items.code[i]}`)
        .attr('transform',`translate(${items.width[i]/2}, 9)`)
        .attr('font-size', 11).attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .attr('fill', this.selectedTrend == items.code[i] ? '#fff' : '#333')
        .text(d)
      })
      
      this.draw_TrendChart(Trends, {
        y:        45,
        scope:    'Existing Site',
        item:     this.selectedTrend,
        bColor:   '#83D2F5',
      })

      this.draw_TrendChart(Trends, {
        y:        240,
        scope:    'Grassroots',
        item:     this.selectedTrend,
        bColor:   '#FEED57',
      })
    },


    draw_TrendChart(selection, s) {
      let TrendChart = selection
      .append('g')
      .attr('transform', `translate(0, ${s.y})`)
      .style('font-family', 'roboto').attr('cursor', 'default')

      TrendChart
      .append('text')
      .attr('transform',`translate(0, 0)`)
      .attr('font-size', 13).attr('text-anchor', 'start')
      .attr('fill', '#333').attr('alignment-baseline', 'middle')
      .text(s.scope)

      TrendChart
      .append('text')
      .attr('transform',`translate(0, 12)`)
      .attr('font-size', 10).attr('text-anchor', 'start')
      .attr('fill', '#bcbcbc').attr('alignment-baseline', 'middle')
      .text('Last 12 Weeks Trends')

      // x & y Axis
      let cht = this.trendChart

      let chart = TrendChart
      .append('g')
      .attr('transform', `translate(0, 25)`)
      .style('font-family', 'roboto').attr('cursor', 'default')

      chart
      .append('line')
      .attr('x1', 0).attr('y1', 0).attr('x2', 0).attr('y2', cht.height)
      .attr('stroke', '#bcbcbc').attr('stroke-width', 0.5)

      chart
      .append('line')
      .attr('x1', 0).attr('y1', cht.height).attr('x2', cht.width).attr('y2', cht.height)
      .attr('stroke', '#bcbcbc').attr('stroke-width', 0.5)

       // Grid
       let grid = []
       for (let i = 0; i < 6; i++) {
         grid.push(cht.height - ((cht.height / 5) * i))
       }

       grid.forEach(d=> {
        chart
        .append('line')
        .attr('x1', 0).attr('x2', cht.width)
        .attr('y1', d).attr('y2', d)
        .attr('stroke', '#bcbcbc')
        .attr('stroke-width', .5).attr('opacity', .3)
      })

      // Scale Y
      let chtData = this.Trend.filter(f=> f.SCOPE == s.scope && f.ITEM == s.item)
      let maxValue = this.getMaxBound_v2(d3.max(chtData, c => c.CLOSED_WK))

      let scaleY = d3.scaleLinear()
      .domain([0, maxValue])
      .range([cht.height, 0])

      // for pdf(Turnover)
      let chtData_TO = this.Trend.filter(f=> f.SCOPE == s.scope && f.ITEM == 'A')
      let maxValue_TO = this.getMaxBound_v2(d3.max(chtData_TO, c => c.TO_ACT_WK))
      
      let scaleY_TO = d3.scaleLinear()
      .domain([0, maxValue_TO])
      .range([cht.height, 0])
      
      // Draw Bar Chart
      // for pdf(Turnover)
      if (this.selectedTrend == 'SSMCC') {
        chtData_TO.forEach((d,i)=> {
          chart
          .append('rect')
          .attr('id', `bar_RECT_${d.SCOPE.substr(0,1)}_${i}`)
          .attr('x', i*25 + 20).attr('y', scaleY_TO(d.TO_ACT_WK))
          .attr('width', 10)
          .attr('height', cht.height - scaleY_TO(d.TO_ACT_WK))
          .attr('fill', s.bColor)
          
          chart
          .append('text')
          .attr('id', `bar_TXT_${d.SCOPE.substr(0,1)}_${i}`)
          .attr('x', i*25 + 20 + 5).attr('y', scaleY_TO(d.TO_ACT_WK) - 2)
          .attr('font-size', 9).attr('text-anchor', 'middle').attr('fill', '#757575')
          .text(d.TO_ACT_WK)
          .attr('cursor', 'pointer')
          .call(this.call_TrendCharts, {scope: d.SCOPE, item: d.ITEM, date: d.CDATE})
        })
      } else {
        chtData.forEach((d,i)=> {
          chart
          .append('rect')
          .attr('id', `bar_RECT_${d.SCOPE.substr(0,1)}_${i}`)
          .attr('x', i*25 + 20).attr('y', scaleY(d.CLOSED_WK))
          .attr('width', 10)
          .attr('height', cht.height - scaleY(d.CLOSED_WK))
          .attr('fill', s.bColor)

          chart
          .append('text')
          .attr('id', `bar_TXT_${d.SCOPE.substr(0,1)}_${i}`)
          .attr('x', i*25 + 20 + 5).attr('y', scaleY(d.CLOSED_WK) - 2)
          .attr('font-size', 9).attr('text-anchor', 'middle').attr('fill', '#757575')
          .text(d.CLOSED_WK)
          .attr('cursor', 'pointer')
          .call(this.call_TrendCharts, {scope: d.SCOPE, item: d.ITEM, date: d.CDATE})
        })
      }
    },



    redraw_TrendChart(scope, item) {
      
      let code = null, chtData = null

      if(item == 'SSMCC') {
        code = 'TO_ACT_WK'
        chtData = this.Trend.filter(f=> f.SCOPE == scope && f.ITEM == 'A')
        this.turnover = 'Y' // turnover 확인용
      } else {
        code = 'CLOSED_WK'
        chtData = this.Trend.filter(f=> f.SCOPE == scope && f.ITEM == item)
        this.turnover = null // turnover 확인용
      }

      let maxValue = this.getMaxBound_v2(d3.max(chtData, c => c[code]))

      let scaleY = d3.scaleLinear()
      .domain([0, maxValue])
      .range([this.trendChart.height, 0])
      
      chtData.forEach((d,i)=> {
        d3.select(`#bar_RECT_${scope.substr(0,1)}_${i}`).transition().duration(500)
        .attr('y', scaleY(d[code]))
        .attr('height', this.trendChart.height - scaleY(d[code]))

        d3.select(`#bar_TXT_${scope.substr(0,1)}_${i}`)
        .call(this.call_TrendCharts, {scope: d.SCOPE, item: d.ITEM, date: d.CDATE})
        
        d3.select(`#bar_TXT_${scope.substr(0,1)}_${i}`).transition().duration(500)
        .attr('y', scaleY(d[code]) - 2)
        
        .tween("text", function() {
          const interpolator = d3.interpolateNumber(d3.select(`#bar_TXT_${scope.substr(0,1)}_${i}`).text(), d[code])
          return function(t) {
            d3.select(`#bar_TXT_${scope.substr(0,1)}_${i}`).text(Math.round(interpolator(t)))
          }
        })
      })
      
    },
   
  }
}