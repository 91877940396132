import * as d3 from 'd3'

export default {
  methods: {

    draw_Header() {

      this.formSvg = this.svg
      
      this.formSvg
      .append('text')
      .attr('transform', `translate(${this.timeline.baseX}, 40)`)
      .style('font-family', 'roboto').style('font-size', 32).style('fill', 'skyblue')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(this.skyline.titleMain)

      this.formSvg
      .append('text')
      .attr('transform', `translate(${this.timeline.baseX}, 65)`)
      .style('font-size', 12)
      .style('fill', '#757575')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(this.skyline.titleSub)
    },
  }
}