import * as d3 from 'd3'

export default {

  methods: {
    
    draw_Timeline() {    

      let Timeline = this.Area_Canvas
      .append('g')
      .attr('transform', `translate(${this.timeline.x + this.timeline.column -0.5}, ${this.timeline.y})`)

      Timeline
      .append('rect') 
      .attr('x', 0).attr('y', 0).attr('width', this.timeline.length).attr('height', 3)
      .attr('fill', `url(#${this.localId}_LightGray_)`).attr('opacity', .99)

      Timeline
      .append('rect')
      .attr('x', 0).attr('y', 3).attr('width', this.timeline.length).attr('height', this.timeline.height)
      .attr('fill', `url(#${this.localId}_LightGray_)`).attr('opacity', .5)

      Timeline
      .append('rect') 
      .attr('x', 0).attr('y', this.timeline.height + 1).attr('width', this.timeline.length).attr('height', 4)
      .attr('fill', `url(#${this.localId}_LightGray_)`)

      
      // Month Text & Timeline Seperator for the Month
      Timeline
      .append('g')
      .selectAll('text')
      .data(this.timeline.month)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate))
      .attr('y', 25)
      .style('font-size', 9)
      .attr('fill', '#757575')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'hanging')
      .attr('cursor', 'default')
      .text(d => d.name.toUpperCase())

      Timeline
      .append('g')
      .selectAll('line')
      .data(this.timeline.month)
      .enter()
      .append('line')
      .attr('x1', d => this.timeline.scale(d.eDate)).attr('y1', 24)
      .attr('x2', d => this.timeline.scale(d.eDate)).attr('y2', 35)
      .attr('stroke', '#bcbcbc').attr('oapcity', .1)
      .attr('stroke-width', (d,i) => (i != this.timeline.month.length-1) ? 1 : 0)

      Timeline
      .append('g')
      .selectAll('line')
      .data(this.timeline.month)
      .enter()
      .append('line')
      .attr('class', 'tLine')
      .attr('x1', d => this.timeline.scale(d.eDate)).attr('y1', 40)
      .attr('x2', d => this.timeline.scale(d.eDate)).attr('y2', 1400)
      .attr('stroke', '#f5f5f5').attr('oapcity', .1)
      .attr('stroke-width', 1)


      // Year Text & Timeline Seperator for the Year
      Timeline
      .append('g')
      .selectAll('text')
      .data(this.timeline.year)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate))
      .attr('y', 8)
      .style('font-size', 11)
      .attr('fill', '#333')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'hanging')
      .attr('cursor', 'default')
      .text(d => d.name)

      Timeline
      .append('g')
      .selectAll('path')
      .data(this.timeline.year)
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        if (i != this.timeline.year.length-1) return `M${this.timeline.scale(d.eDate)}, ${2} V${19}`
      })
      .attr('stroke', '#bcbcbc').attr('oapcity', .1)
      .attr('stroke-width', 1)
    }, 
  }
}
