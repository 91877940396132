import * as d3 from 'd3'

export default {
  methods: {
    /*
      call_IcicleChart(selection, m)
      call_MilestoneList(selection, d)
      call_SelectAll(selection)
    */

    call_IcicleChart(selection, m) {
      selection
      .on('mouseover', () => {
        selection
        .transition().duration(50)
        .style('opacity', .7)
  
        selection.moveToFront()

        d3.select(`#highlight_${m.MS}`)
        .style('visibility', 'visible')
      })

      .on('mouseout', () => {
        selection
        .transition().duration(50)
        .style('opacity', 1)

        d3.select(`#highlight_${m.MS}`)
        .style('visibility', 'hidden')
      })
    },



    call_MilestoneList(selection, d) {
      selection
      .on('click', ()=> {
        d3.select(`#checked_${d.MS}`)
        .style('visibility', () => {
          if(d.checked == 'Y') {
            d.checked = 'N'
            d3.select(`#icicle_${d.MS}`).style('visibility', 'hidden')
            return 'hidden'
          } else {
            d.checked = 'Y'
            d3.select(`#icicle_${d.MS}`).style('visibility', 'visible')
            return 'visible'
          }
        })
      })
      .on('mouseover', () => {
        selection.transition().duration(150)
        .style('opacity', .7)
        highlight('over', d.MS) // --->
      })
      .on('mouseout', () => {
        selection.transition().duration(150)
        .style('opacity', 1)
        highlight('out', d.MS) // --->
      })

      let self = this
      let highlight = function(event, no) {
        self.msList.forEach( d => {
          if ( event == 'over') {
            d3.select(`#icicle_${d.MS}`).transition().duration(100)
            .style('opacity', d.MS == no ? 1 : .2)

          } else if ( event == 'out') {
            d3.select(`#icicle_${d.MS}`).transition().duration(100)
            .style('opacity', 1)
          }
        })
      }
    },
    


    call_SelectAll(selection) {
      selection
      .on('mouseover', () => {
        selection
        .transition().duration(50).style('opacity', .7)
      })
      .on('mouseout', () => {
        selection
        .transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        if(this.msList.checkStatus == 'select') {
          selectAll()
          this.msList.checkStatus = 'unselect'
          d3.select('#slelectAll')
          .attr('fill', '#B5549B')
          .text('Unselect all')
        } else {
          unselectAll()
          this.msList.checkStatus = 'select'
          d3.select('#slelectAll')
          .attr('fill', '#44A9DF')
          .text('Select all')
        }
      })

      let self = this
      let selectAll = function() {
        self.msList.forEach( d => {
          d.checked = 'Y'
          d3.select(`#checked_${d.MS}`)
          .style('visibility', 'visible')
          d3.select(`#icicle_${d.MS}`)
          .style('visibility', 'visible')
        })
      }

      let unselectAll = function() {
        self.msList.forEach( d => {
          d.checked = 'N'
          d3.select(`#checked_${d.MS}`)
          .style('visibility', 'hidden')
          d3.select(`#icicle_${d.MS}`)
          .style('visibility', 'hidden')
        })
      }
    },
  }
}