import * as d3 from 'd3'

export default {
  methods: {
    
    draw_GanttCharts() {

      let Gantt = this.Area_Canvas
      .append('g')
      .attr('transform', `translate(${this.timeline.x}, ${this.timeline.y + 60})`)
      .style('font-family', 'roboto').attr('cursor', 'default')

      this.MilestoneGroup.forEach((group,g)=> {

        let milestoneGroup = Gantt
        .append('g')
        .attr('id', `MS_GROUP_${g}`)
        .attr('transform', `translate(${this.Gantt.gx}, ${group.y})`)

        // Milestone Group - Expansion Icon ----------------------------------------------------------------------------------------
        let expansionIcon = milestoneGroup
        .append('g')
        .attr('id', `MS_GROUP_${g}`)
        .attr('transform', `translate(0, -7)`)
        .attr('cursor', 'pointer').attr('opacity', 1)
        .on('click', ()=> {
          if(group.itemDisplay == 'visible') {
            this.exe_BindGroup_On(group)
          } else {
            this.exe_BindGroup_Off(group)
          }
        })

        expansionIcon
        .append('rect')
        .attr('transform', `translate(0, 0)`)
        .attr('width', 11).attr('height', 11).attr('fill', `#fff`)
        expansionIcon
        .append('path')
        .attr('id',`ICON_GROUP_${g}`)
        .attr('transform', `translate(0, 0) scale(1)`)
        .attr('d', this.icons.minus)
        .attr('fill', `url(#${this.localId}_LightGray_ICON)`)

        // Milestone Group - Description 
        milestoneGroup
        .append('text')
        .attr('id',`MS_GROUP_TXT_${g}`)
        .attr('x', 15).attr('y', 0)
        .attr('font-size', 13).attr('fill', '#333')
        .attr('font-weight', 420).attr('alignment-baseline', 'middle')
        .attr('cursor', 'pointer')
        .text(group.name)
        .on('click', ()=> {
          if(group.itemDisplay == 'hidden') return
          if(group.bind == 'closed') {
            this.exe_Expansion_All(group)
          } else {
            this.exe_Reduced_All(group)
          }
        })

        let width = this.getNodeElValue(`#MS_GROUP_TXT_${g}`, 'width')
        milestoneGroup
        .append('path')
        .attr('id',`ICONS_ALL_${g}`)
        .attr('transform', `translate(${width + 18}, -7) scale(1)`)
        .attr('d', this.icons.expand)
        .attr('fill', `#bcbcbc`).attr('opacity', 1)

        // Group Progress bar
        let sDate = group.min == undefined ? 0 : this.timeline.scale(new Date(group.min)) - 18
        let fDate = group.max == undefined ? 0 : this.timeline.scale(new Date(group.max))
        let lenPro = fDate - sDate

        let GroupPro = milestoneGroup
        .append('g')
        .attr('id', `BAR_GROUP_PROGRESS_${g}`)
        .attr('transform', `translate(${this.timeline.column - this.Gantt.gx + sDate}, ${-4})`)
        .style('visibility', 'hidden')

        GroupPro
        .append('rect')
        .attr('x', 0).attr('y', 0)
        .attr('width', lenPro * (group.AB_PRO/100)).attr('height', 6)
        .attr('fill', `url(#${this.localId}_LightBlue_)`)
        .attr('opacity', 1)

        GroupPro
        .append('text')
        .attr('x', lenPro * (group.AB_PRO/100) + 2).attr('y', 6)
        .attr('font-size', 9).attr('fill', '#333')
        .attr('alignment-baseline', 'start')
        .text(group.AB_PRO.toFixed(1) + '%')




        
        // Milestone Item Group ------------------------------------------------------------------------------------
        this.rawData.filter(f=> f.GROUP == group.name).forEach((item,i)=> {

          let milestoneItem = Gantt
          .append('g')
          .attr('id', `ITEM_GROUP_${item.CODE}`)
          .attr('transform', `translate(${this.Gantt.ix}, ${item.y})`)
          .attr('cursor', 'pointer').style('visibility', 'visible')
          .on('mouseover', function() {
            d3.select(`#ITEM_BOX_${item.CODE}`).transition().duration(150).style('opacity', .1)
            d3.select(`#ITEM_TXT_${item.CODE}`).transition().duration(150).attr('fill', '#EC407A')
            d3.select(`#ITEM_ICON_${item.CODE}`).transition().duration(150).attr('fill', '#EC407A')
          })
          .on('mouseout', function() {
            d3.select(`#ITEM_BOX_${item.CODE}`).transition().duration(150).style('opacity', .1)
            d3.select(`#ITEM_TXT_${item.CODE}`).transition().duration(150).attr('fill', '#333')
            d3.select(`#ITEM_ICON_${item.CODE}`).transition().duration(150).attr('fill', '#bcbcbc')
          })
          .on('click', ()=> {
            if(item.bind == 'closed') {
              this.exe_Bind_Items('open', group, item)
            } else {
              this.exe_Bind_Items('closed', group, item)
            }
            this.rawData.forEach(d=> {
              d3.select(`#ITEM_NAME_${d.CODE}`).attr('fill', d.bind == 'open' ? '#44A9DF' : '#333')
            })
          })


           // Milestone Item - Table 
          let table = milestoneItem
          .append('g')
          .attr('id', `MS_TABLE_${item.CODE}`)
          .attr('transform', `translate(0, 0)`)
          .style('font-family', 'roboto')
          .style('visibility', group.itemDisplay == 'visible' ? 'visible' : 'hidden')

          table
          .append('rect')
          .attr('id', `ITEM_BOX_${item.CODE}`)
          .attr('x', -5).attr('y', - 11.5)
          .attr('width', this.timeline.length + this.timeline.column - this.Gantt.ix).attr('height', 20)
          .attr('fill', '#E0E0DF').attr('opacity', .1)

          table
          .append('text')
          .attr('id', `ITEM_ICON_${item.CODE}`)
          .attr('x', -3).attr('y', 0)
          .attr('font-size', 10.5).attr('fill', '#bcbcbc').attr('alignment-baseline', 'middle')
          .text('+')

          table
          .append('text')
          .attr('id', `ITEM_TXT_${item.CODE}`)
          .attr('x', 5).attr('y', .5).attr('font-weight', 400)
          .attr('font-size', 10).attr('fill', '#000').attr('alignment-baseline', 'middle')
          .text(item.DESC)

          table
          .append('text')
          .attr('x', 320).attr('y', 0).attr('alignment-baseline', 'middle')
          .attr('font-size', 10).attr('fill', '#333')
          .text(item.MS_DATE)


          // Milestone Item - Gantt Bar Charts 
          let ganttChart = milestoneItem
          .append('g')
          .attr('transform', `translate(${this.timeline.column - this.Gantt.ix}, 0)`)

          let sDate = item.MIN_PLAN == undefined ? 0 : this.timeline.scale(new Date(item.MIN_PLAN)) - 18
          let fDate = item.MAX_PLAN == undefined ? 0 : this.timeline.scale(new Date(item.MAX_PLAN))
          let msDate = this.timeline.scale(new Date(item.MS_DATE))
          let lenPro = fDate - sDate
          let arrDate = item.MS_DATE.split('-').map(Number)

          if(item.MIN_PLAN) {
            ganttChart
            .append('rect')
            .attr('id', `BAR_BG_${item.CODE}`)
            .attr('x', sDate).attr('y', - 6)
            .attr('width', lenPro).attr('height', 10)
            .attr('fill', `url(#${this.localId}_LightGray_ICON)`)
            .attr('opacity', .5)

            ganttChart
            .append('rect')
            .attr('id', `BAR_ACT_${item.CODE}`)
            .attr('x', sDate).attr('y', - 4)
            .attr('width', lenPro * (item.AB_PRO/100)).attr('height', 6)
            .attr('fill', `url(#${this.localId}_LightBlue_)`)
            .attr('opacity', .5).style('visibility', group.itemDisplay == 'visible' ? 'visible' : 'hidden')

            ganttChart
            .append('text')
            .attr('id', `BAR_TXT_${item.CODE}`)
            .attr('x', sDate + lenPro * (item.AB_PRO/100) + 2).attr('y', 2)
            .attr('font-size', 8).attr('fill', '#757575')
            .attr('alignment-baseline', 'start').style('visibility', group.itemDisplay == 'visible' ? 'visible' : 'hidden')
            .text(item.AB_PRO.toFixed(1) + '%')
          }

          // Milestone Date
          ganttChart
          .append('path')
          .attr('id',`ICON_MS_DATE_${g}`)
          .attr('transform', `translate(${msDate-4}, -12) scale(.7)`)
          .attr('d', this.icons.ms_date)
          .attr('fill', '#EC407A')

          ganttChart
          .append('text')
          .attr('id', `BAR_DATE_${item.CODE}`)
          .attr('x', msDate+5).attr('y', -4)
          .attr('font-size', 9).attr('fill', '#44A9DF').attr('alignment-baseline', 'start')
          .style('visibility', ()=> {
            if(item.max == 'N') {
              if(group.itemDisplay == 'visible') return 'visible'; else return 'hidden'
            } else return 'visible'
          })
          .text(`${arrDate[2]}/${arrDate[1]}`)


          // Milestone Item - Summary ------------------------------------------
          this.draw_Summary_Item(Gantt, item)


        })

      })
    },

  }
}
