import * as d3 from 'd3'

export default {
  methods: {

    load_Layout() {
      this.setZoomScale(1600, 1132, 1, 1.4)

      this.innerSvg = this.svg.append('svg')
      this.setPriority()

      this.innerSvg
      .append('image')
      .attr('id','lev1_background')
      .attr('transform', `translate(${this.zoom.out.x}, ${this.zoom.out.y}) scale(${this.zoom.out.scale})`)
      .attr('xlink:href', `${this.RESOURCE}/CRISP/SYSTEM/UNIT_SUMMARY/PLAN_1600.jpg`)
      .style('opacity', 0)
      
      d3.select('#lev1_background')
      .transition().duration(500)
      .attr('transform',`translate(${this.zoom.in.x}, ${this.zoom.in.y}) scale(${this.zoom.in.scale})`)
      .style('opacity', 1)

      // After loading the SVG, perform initialization
      this.loadSvg(
        this.innerSvg, {
        x   : 0,
        y   : 0,
        url : `/CRISP/SYSTEM/UNIT_SUMMARY/Unit_Layout.svg`
      }).then(() => {
        d3.selectAll('text').style('font-family', 'roboto')

        this.rawData.filter(f=> f.SCOPE == 'Grassroots' && f.SCOPE !== 'NA').forEach((d,i) => {
          
          let pnt =  this.innerSvg.select(`#PNT-${d.UNIT_NO}`)
          d.x = pnt.attr('cx')
          d.y = pnt.attr('cy')

          d3.select(`#NO`).style('opacity', 0)
          d3.select(`#SUM`).style('opacity', 0)
          d3.select(`#PNT-${d.UNIT_NO}`).style('opacity', 0)
          d3.select(`#BOX-${d.UNIT_NO}`).style('opacity', 0)
          d3.select(`#TAG-${d.UNIT_NO}`).attr('transform', `translate(${d.x}, ${d.y}) scale(0)`).style('opacity', 1)

          d3.select(`#BTN-${d.UNIT_NO}`)
          .attr('cursor', 'pointer')
          .style('opacity', 0)
          .on('mouseover', () => {
            d3.select(`#KEY-${d.UNIT_NO}`).transition().duration(300).style('opacity', 1)
            d3.select(`#BOX-${d.UNIT_NO}`).transition().duration(300).style('opacity', 1)
            d3.select(`#TAG-${d.UNIT_NO}`).transition().duration(300).attr('transform', `translate(${0}, ${0}) scale(1)`).style('opacity', 1)
            this.load_UnitSummary(d,i)
          })
          .on('mouseout', () => {
            d3.select(`#KEY-${d.UNIT_NO}`).transition().duration(300).style('opacity', 0)
            d3.select(`#BOX-${d.UNIT_NO}`).transition().duration(300).style('opacity', 0)
            d3.select(`#TAG-${d.UNIT_NO}`).transition().duration(300).attr('transform', `translate(${d.x}, ${d.y}) scale(0)`).style('opacity', 0)
            if(this.stg_Summary) this.stg_Summary.remove()
          })
          .on('click', () => { 
            this.link_Action({scope:d.SCOPE, code:'UNIT', unit:d.UNIT, unit_No: d.UNIT_NO}) 
            // d3.select(`#KEY-${d.UNIT_NO}`).transition().duration(300).style('opacity', 0)
          })
        })
        this.load_ListUnit()
        this.drawNotes()
        this.drawTexts()
      })
    },

    load_ListUnit() {
      let AreaListGroup = this.innerSvg
      .append('g')
      .attr('id', 'Area_list_Group')
      .attr('transform', `translate(20, 320)`)

      this.rawData.filter(f=> f.SCOPE == 'Grassroots' && f.SCOPE !== 'NA').forEach((d,i) => {

        // Title List of Area
        let AreaList = AreaListGroup
        .append('g')
        .attr('id', `Area_list_${i}`)
        .attr('transform', `translate(0, ${(i * 32)})`)
        .style('cursor', 'pointer')
        .on('mouseover', () => {
          d3.select(`#KEY-${d.UNIT_NO}`).transition().duration(300).style('opacity', 1)
          d3.select(`#Area_list_${i}`).attr('opacity', 0.4)
          d3.select(`#BOX-${d.UNIT_NO}`).transition().duration(300).style('opacity', 1)
          d3.select(`#TAG-${d.UNIT_NO}`).transition().duration(300).attr('transform', `translate(${0}, ${0}) scale(1)`).style('opacity', 1)
          this.load_UnitSummary(d,i)
        })
        .on('mouseout', () => {
          d3.select(`#KEY-${d.UNIT_NO}`).transition().duration(300).style('opacity', 0)
          d3.select(`#Area_list_${i}`).attr('opacity', 1)
          d3.select(`#BOX-${d.UNIT_NO}`).transition().duration(300).style('opacity', 0)
          d3.select(`#TAG-${d.UNIT_NO}`).transition().duration(300).attr('transform', `translate(${d.x}, ${d.y}) scale(0)`).style('opacity', 0)
          if(this.stg_Summary) this.stg_Summary.remove()
        })
        .on('click', () => { 
          this.link_Action({scope:d.SCOPE, code:'UNIT', unit:d.UNIT, unit_No: d.UNIT_NO}) 
        })

        AreaList
        .append("rect")
        .attr('x', 12).attr('y', -8.5)
        .attr('width', 5).attr('height', 20)
        .style('fill', 'url(#PHOTO)')

        AreaList
        .append("text")
        .attr('x', 20).attr('y', 0).attr('font-size', 11)
        .style('fill', '#000' ).text(`${d.UNIT}`)

        AreaList
        .append("text")
        .attr('x', 20).attr('y', 11).attr('font-size', 10)
        .style('fill', '#757575' ).text(`${d.UNIT_NO}-${d.UNIT_DESC}`)
      })
    },
    
  }
}