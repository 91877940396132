import * as d3 from 'd3'

export default {
  methods: {

    draw_Header() {

      this.formSvg = this.svg
      .append('g')
      .attr('transform', `translate(50, 50)`)

      // this.formSvg
      // .append('text')
      // .attr('transform', `translate(${this.timeline.baseX}, 40)`)
      // .style('font-family', 'roboto').style('font-size', 28).style('fill', 'skyblue')
      // .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      // .text(this.currentUnit == 'OV' ? 'Subsystem Turnover Skyline Chart of Grassroots' : `Subsystem Turnover Skyline Chart of ${this.currentUnit}`)

      // this.formSvg
      // .append('text')
      // .attr('transform', `translate(${this.timeline.baseX}, 60)`)
      // .style('font-size', 13)
      // .style('fill', '#bcbcbc')
      // .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      // .text('Weekly timeline of subsystems turnover planned with A & B ITRs status')

      // this.draw_Status()
    },

    draw_Status() {

      this.formSvg
      .append('rect')
      .attr('width', 400)
      .attr('height', 400)
      .attr('stroke', '#333')
      .attr('stroke-width', 0.3)
      .attr('stroke-opacity', 0.6)
      .attr('fill', 'none')

    },
  }
}