import Defs from '../../../../../../includes/primitives/colorDefs'
import * as d3 from 'd3'

export default {

  methods: {

    set_SkylineData() {
      // ### call the global Function for Skyline Values ###
      this.init_gmx_SkylineValues_Month(
        this.rawData, 
        {
          refColumn:    this.timeline.refColumn,
          skyBox_Width: this.skyline.skyBox.width,
          skyBox_Gap:   this.skyline.skyBox.gap,
          startDate:    this.timeline.startDate,
          endDate:      this.timeline.endDate,
        }
      )

      this.rawData.forEach(d => {
        let date__  = this.get_gmx_TimelineCDate_Month(d[this.timeline.refColumn])
        d.cDate     = date__.eDate
        d.sDate     = date__.sDate
        d.date      = d3.timeParse('%Y-%m-%d')(d[this.timeline.refColumn])
      })
      
      this.timeline.height = this.timeline.styles.month.height + this.timeline.styles.year.height
    },



    set_SkylineStatus() {
      // Setting for the Status styles in skyline 
      this.sheet.styles.forEach((s,i) => {

        this.rawData.filter(f => f[this.sheet.ref_Status] == s.status).forEach(d => {
          d.type     = s.type
          d.tSize    = this.skyline.skyBox.tSize //
          d.tColor   = s.tColor
          d.tWeight  = s.tWeight
          d.sWidth   = s.sWidth
          d.sColor   = s.sColor
          d.bColor   = s.color
          d.opacity  = s.opacity
          d.radius   = this.skyline.skyBox.radius
        }) 
      })
    },



    count_Package(data_){
      
      this.package.status.forEach((d,i)=> {
        if (d == 'ALL') this.package.values[d] = data_.length
        else this.package.values[d] = data_.filter(f=> f[this.package.refColumn] == d).length
      })
    },

    
    
    count_Sheet(data_){

      let prefix = this.sheet.prefix
      
      this.sheet.styles.forEach((d,i)=> {
        let first_name = d.status.split('-')[0]
        let first_len = first_name.length

        switch(d.type) {
          case 'LAYER':
            this.sheet.values[d.status] = data_.filter(f=> f[d.status] == 'Y').length
          break
          case 'REMAIN':
            this.sheet.values[d.status] = data_.filter(f=> f[`${prefix}_STATUS`] == d.status).length
          break
          default:
            if (d.status == 'TOTAL') this.sheet.values.TOTAL = data_.length
            else this.sheet.values[d.status] = data_.filter(f=> f[`${prefix}_STATUS`].substring(0,first_len) == first_name).length
          break
        }
      })
    },



    set_Styles() {
      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `shadowTimeline`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#3d3d3d').attr('offset', '0').attr('stop-opacity', 0.5)
      lightBlue.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 0.3)

      Defs.setDefs_Gradients(this.svg, 'TL', 'LinearA4', 'LightGray', [ 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 'plan', 'LinearA4', 'LightBlue', [.5, .8, .5, 0], this.localId)
    },
  }
}