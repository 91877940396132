import * as d3 from 'd3'

export default {

  methods: {
    header_HexagonStatus() {

      let current = [
        {title: 'Total',        value: 0},
        {title: 'Actual',       value: 0},
        {title: 'Outstanding',  value: 0},
      ]
      current[0].value = this.rawData.length
      current[1].value = this.rawData.filter(f=> f.CSTD_ACTUAL !== undefined ).length
      current[2].value = current[0].value - current[1].value

      let subsystem = this.svg
      .append('g')
      .attr('transform',`translate(20.5, 10.5)`)
      .attr('font-family', 'roboto')
      .style('cursor', 'default')

      subsystem
      .append('text')
      .attr('x', 0).attr('y', 80).attr('font-size', 15)
      .attr('fill', '#fff').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Subsystem')

      subsystem
      .append('text')
      .attr('x', 0).attr('y', 93).attr('font-size', 10.5)
      .attr('fill', this.status.sColor).attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Turn-over Status of Subsystem')

      let y_= 110
      current.forEach((d,i)=> {

        let shtItem = subsystem
        .append('g')
        .attr('transform',`translate(0, ${y_})`)

        shtItem
        .append('text')
        .attr('x', 110).attr('y', 20).attr('font-size', 13)
        .attr('fill', this.status.nColor).attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(d.title)

        shtItem
        .append('text')
        .attr('x', 160).attr('y', 20).attr('font-size', 14).attr('font-weight', 400)
        .attr('fill', this.status.vColor).attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(d.value)

        y_ += 25
      })


      // Checksheet Status -----------------------------------------------------------------------------------
      current = [
        {title: 'Total',        value: 0},
        {title: 'Actual',       value: 0},
        {title: 'Outstanding',  value: 0},
      ]
      current[0].value = this.rawData.map(d=> {return d.A_TOT}).reduce((a, b) => (a + b))
      current[1].value = this.rawData.map(d=> {return d.A_ACT}).reduce((a, b) => (a + b))
      current[2].value = current[0].value - current[1].value

      this.timeNow.TOT = current[0].value
      this.timeNow.ACT = current[1].value
      this.timeNow.REM = current[2].value
      this.timeNow.PRO = (current[1].value/current[0].value)*100

      let checksheet = subsystem

      .append('g')
      .attr('transform',`translate(180, 0)`)

      checksheet
      .append('text')
      .attr('x', 0).attr('y', 80).attr('font-size', 15)
      .attr('fill', this.status.mColor).attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('A Checksheet')

      checksheet
      .append('text')
      .attr('x', 0).attr('y', 93).attr('font-size', 10.5)
      .attr('fill', this.status.sColor).attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Status of A checksheet')

      y_ = 110
      current.forEach((d,i)=> {

        let volItem = checksheet
        .append('g')
        .attr('transform',`translate(0, ${y_})`)

        volItem
        .append('text')
        .attr('x', 100).attr('y', 20).attr('font-size', 13)
        .attr('fill', this.status.nColor).attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(current[i].title)

        volItem
        .append('text')
        .attr('x', 160).attr('y', 20).attr('font-size', 14).attr('font-weight', 400)
        .attr('fill', this.status.vColor).attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(current[i].value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))

        y_ += 25
      })
    },

  }
}
