import * as d3 from 'd3'

export default {
  methods: {

    reset_Charts() {

      // Reset Primary Axis
      let maxPrimary = this.getMaxBound_v2(d3.max(this.Discipline.filter(f=> f.checked == true), c => c.maxValue))
      this.scale.Primary = d3.scaleLinear()
      .domain([0, maxPrimary])
      .range([this.canvas.chart.height, 0])

      this.grid.forEach((d,i)=> {
        d3.select(`#Axis_Primary_${i}`).text((maxPrimary) * (i*2)/10)
      })


      // Reset BarChart
      if(this.Area_Chart) this.Area_Chart.remove()
      this.draw_Chart_Controller()
      this.draw_Legends()
      
      //for pdf
      this.set_selectedItems()
    },


  }
}