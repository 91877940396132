import gmx_zMixins         from '../../../../../../includes/Jmixins/zMixins'

// Locally declared common Data-Props & SVG Drawing Support

import lmx_watching             from './watching'

// Charts 
import lmx_UserValues           from './set_UserValues'
import lmx_LocalValues          from './set_LocalValues'

import lmx_Timeline             from './draw_Timeline'
import lmx_Skyline              from './draw_Skyline'
import lmx_Legends              from './draw_Legends'
import lmx_Options              from './draw_Options'
import lmx_Detail               from './draw_Detail'

import lmx_events               from './Events'

export default {
  
  mixins: [
    gmx_zMixins, 
    
    // Local Mixins
    lmx_watching,
    lmx_UserValues,
    lmx_LocalValues,
    lmx_events,

    lmx_Timeline,
    lmx_Skyline,
    lmx_Legends,
    lmx_Detail,
    
    lmx_Options,
  ],

}
