import color from '../../../../../../includes/primitives/Color_Data'
import __C from '../../../../../../includes/primitives/_constant_crisp'
import * as d3 from 'd3'
export default {
  data: () => ({
    RESOURCE: `${__C.HOST_NAME_RESOURCE}/resources`,
    innerSvg:       null,
    Area_Canvas:    null,
    Area_Gantt:     null,

    rawData:        [],
    system:         null,
    Gantt:          null,

    pie: {x: 365, y: 465, ra: 275, spin: -90},

    grid:           null,
    legends:        null, 
    color21:        color.color21.bright,

    ITR:            'AB',
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    set_LocalValues(){
      
      if(this.getSelectItems) {
        this.pageOptions = JSON.parse(this.getSelectItems)
      }
      
      let mainSQL = JSON.parse(JSON.stringify(this.DataItems))
      this.system = JSON.parse(JSON.stringify(this.Queries.SQL1))
      this.Gantt = JSON.parse(JSON.stringify(this.Queries.SQL2))
      let sql3 = this.Queries.SQL3[0]

      this.Gantt.forEach(d=> {

        // Sorting by Sequence
        this.rawData.push(mainSQL.find(f=> f.UNIT == d.UNIT))

        d.A_TOT = this.rawData.find(f=> f.UNIT == d.UNIT).A_TOT
        d.A_ACT = this.rawData.find(f=> f.UNIT == d.UNIT).A_ACT
        d.A_REM = this.rawData.find(f=> f.UNIT == d.UNIT).A_REM
        d.A_PRO = this.rawData.find(f=> f.UNIT == d.UNIT).A_PRO

        d.B_TOT = this.rawData.find(f=> f.UNIT == d.UNIT).B_TOT
        d.B_ACT = this.rawData.find(f=> f.UNIT == d.UNIT).B_ACT
        d.B_REM = this.rawData.find(f=> f.UNIT == d.UNIT).B_REM
        d.B_PRO = this.rawData.find(f=> f.UNIT == d.UNIT).B_PRO

        d.AB_TOT = this.rawData.find(f=> f.UNIT == d.UNIT).A_TOT + this.rawData.find(f=> f.UNIT == d.UNIT).B_TOT
        d.AB_ACT = this.rawData.find(f=> f.UNIT == d.UNIT).A_ACT + this.rawData.find(f=> f.UNIT == d.UNIT).B_ACT
        d.AB_PRO = (d.AB_ACT / d.AB_TOT) * 100
      })
      // console.log(this.Gantt)

      // Timeline --------------------
      this.timeline = {
        x               : 730,
        y               : 130,
        length          : 430,
        
        startDate       : sql3['START_DATE'],
        endDate         : sql3['END_DATE'],
        day             : [],
        week            : [],
        month           : [],
        year            : [],
        scale           : null,
      }
      
      this.init_gmx_TimelineValues(this.timeline, '')
    },




    loadSvg(selection, data) {
      return d3.xml(`${this.RESOURCE}${data.url}`).then(source => {
        let svg_ = source.documentElement
        selection.node().append(svg_)

        svg_ = d3.select(svg_)
        .attr('x', data.x)
        .attr('y', data.y)
        .attr('viewBox', data.vb)
        return new Promise(resolve => {
          resolve(svg_)
        })
      })
    },



    set_Defs() {
      var defs = this.Area_Canvas.append('defs')

      var filter = defs.append('filter')
      .attr('id', 'dropshadow')

      filter.append('feGaussianBlur')
      .attr('in', 'SourceAlpha')
      .attr('stdDeviation', 1)
      .attr('result', 'blurOut')
      filter.append('feOffset')
      .attr('in', 'blurOut')
      .attr('dx', .3)
      .attr('dy', .3)
      .attr('result', 'offsetBlur')

      var feMerge = filter.append('feMerge')

      feMerge.append('feMergeNode')
      .attr('in', 'offsetBlur')
      feMerge.append('feMergeNode')
      .attr('in', 'SourceGraphic')
    }

  }
}