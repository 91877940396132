import __C from '../../../../../../includes/primitives/_constant_crisp'
import path from '../../../../../../includes/primitives/icons'
import * as d3 from 'd3'

export default {
  data: () => ({
    RESOURCE: `${__C.HOST_NAME_RESOURCE}/resources`,
    arrow:              path.arrow,
    icon:               path.icons,
    sHeader:            null,
    Area_Canvas:        null,
    Area_Screen:        null,
    Area_Charts:        null,
    Actual_Chart:       null,

    //pdf
    selectedItems:      null,
    getItems:           null,

    rawData:            [],
    actData:            [],
    weekTrend:          [],
    cutOff:             null,

    canvas:             null,
    timeline:           {},
    grid:               [],
    scale: {
      primary:          null, 
      secondary:        null
    },
    
    status: {
      mColor:           '#fff',
      sColor:           '#9DEEEB',
      nColor:           '#B7FFFD', // #B7FFFD
      vColor:           '#095350', // #005757
    },

    schedule: {
      sDate:            110,
      fDate:            10,
      max:              3000,
      limite:           5000,
      code:             'A_TOT',
      currResource:     'CENTER',
    },

    start_X:            90, 
    finish_X:           190,
    bar_Width:          100,
    sche_Code:          'A_TOT',
    help_Status:        'RESOURCE',
    chart_Status:       'OFF',
    trend_Status:       'CLOSED_WK',
    act_Status:         'OFF',
    colors:[
                        '#83D2F5', '#F7BACF', '#FFDA00', '#8BC248', '#44A9DF', '#FAAA18', '#CECECE', '#F35E90', '#8BC248', '#B5549B',
                        '#83D2F5', '#F7BACF', '#FFDA00', '#8BC248', '#44A9DF', '#FAAA18', '#CECECE', '#F35E90', '#8BC248', '#B5549B',
    ],

    peakTime:           null,
    conditionColumn:    [],
    condition_List:     [],
    durationCurveData:  [],
    durationValues:     [],
    condition_Pdf:      [],

    chart_Count:        -1,
    db_Total:           0,
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    set_LocalValues(){
      
      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      this.actData = JSON.parse(JSON.stringify(this.Queries.SQL1))
      this.weekTrend = JSON.parse(JSON.stringify(this.Queries.SQL2))
      let sql3 = JSON.parse(JSON.stringify(this.Queries.SQL3[0]))
      
      // Cnavas ---------------------------------------------------------------------------
      this.canvas = {
        width           : sql3['CANVAS_WIDTH'],
        height          : sql3['CANVAS_HEIGHT'],
        
        padding : {
          top           : sql3['PADDING_TOP'],
          right         : sql3['PADDING_RIGHT'],
          bottom        : sql3['PADDING_BOTTOM'],
          left          : sql3['PADDING_LEFT'],
        },
        chart : {
          width         : sql3['CANVAS_WIDTH'] - sql3['PADDING_LEFT'] - sql3['PADDING_RIGHT'],
          height        : sql3['CANVAS_HEIGHT'] - sql3['PADDING_TOP'] - sql3['PADDING_BOTTOM'],
        },
      }

      // Timeline ------------------------------------------------------------------------------------
      this.timeline = {
        start           : sql3['TIMELINE_START'],
        cutoff          : sql3['CUTOFF'],
        length          : this.canvas.chart.width,
        
        startDate       : sql3['START_DATE'],
        endDate         : sql3['END_DATE'],
        weekEnd         : sql3['WEEKEND'],
        day             : [],
        week            : [],
        month           : [],
        year            : [],
        scale           : null,
      }

      // PeakTime
      this.peakTime = sql3['PEAK_DURATION']

      this.conditionColumn = [
        {name: '#',         width: 20,    code: 'count',    },
        {name: 'Duration',  width: 40,    code: 'duration', },
        {name: 'Start',     width: 38,    code: 'sDate',    },
        {name: 'Finish',    width: 38,    code: 'fDate',    },
        {name: 'Curve',     width: 50,    code: 'curve',    },
        {name: 'Date',      width: 60,    code: 'pk_date',  },
        {name: 'Max.',      width: 45,    code: 'pk_max',  },
        {name: 'Avg.',      width: 45,    code: 'pk_avg',  },
      ]
      let cumul = 0
      this.conditionColumn.forEach((d,i)=> {
        cumul += d.width
        d.x = cumul - (d.width/2)
      })


      // Grids
      this.grid = []
      for (let i = 0; i < 6; i++) {
        this.grid.push(this.canvas.chart.height - ((this.canvas.chart.height / 5) * i))
      }

      this.init_gmx_TimelineValues(this.timeline, 'day')
    },


    loadSvg(selection, data) {
      return d3.xml(`${this.RESOURCE}${data.url}`).then(source => {
        let svg_ = source.documentElement
        selection.node().append(svg_)

        svg_ = d3.select(svg_)
        .attr('x', data.x)
        .attr('y', data.y)

        return new Promise(resolve => {
          resolve(svg_)
        })
      })
    },


    set_Gradients() {
    },

    set_selectedItems(val_){  
      this.selectedItems = { 
        ...this.selectedItems,
        chart_Status: this.chart_Status,        
        chart_Count : this.chart_Count,
        act_Status  : this.act_Status,
        sche_Code   : this.schedule.code,
        trend_Status: this.trend_Status,
        start_X     : this.start_X,
        finish_X    : this.finish_X,
        bar_Width   : this.bar_Width,
        sDate       : this.schedule.sDate,
        fDate       : this.schedule.fDate,
      }
      if(val_.action == 'HELP') {
        this.selectedItems = { 
          ...this.selectedItems,
          action      : 'HELP',
          help_Status : this.help_Status,
        }
      }
      if(this.chart_Status == 'ON' && this.chart_Count != -1){
        this.selectedItems = { 
          ...this.selectedItems,
          chart_List : [this.condition_List]
        }

      } 
    },
  }
}