<template>
  <div ref="threeContainer" class="three-container" :style="threeContainerStyle"></div>
</template>

<script>
import * as d3 from 'd3'
import _data from './includes/primitives/data.js'
import mx_Core from './includes/mixins/zMixins'
import mx_Core2 from './includes/mixins/zMixins2'
import { WEBGL } from './includes/mixins/webGL.js'
import { EventBus } from './includes/mixins/EventBus'

export default {
  name: "ThreeComponent",
  mixins: [mx_Core, mx_Core2],
  data() {
    return {
      scene: null,
      camera: null,
      renderer: null,
      fbxModel: null,
      fbxLoader: null,
    }
  },

  computed: {
    threeContainerStyle() {
      return {
        top: `${_data.Canvas.CanvasHeight - this.SceneHeight}px`,
        left: `${_data.Canvas.CanvasWidth - this.SceneWidth}px`,
        position: 'absolute',
        zIndex: 3
      };
    }
  },

  mounted() {
    this.createThreeScene()
    this.resetColorsListener = this.resetColors.bind(this)
    this.hideObjectsListener = this.hideObjects.bind(this)
    this.unhideObjectsListener = this.unhideObjects.bind(this)
    this.changeObjColorListener = (receiveObj) => {
      this.changeObjColor(receiveObj)
    }
    this.moveObjListener = (obj, d) => {
      this.moveObj(obj, d)
    }
    this.resetPositionListener = this.resetPosition.bind(this)

    EventBus.$on('callReset', this.resetColorsListener)
    EventBus.$on('callHide', this.hideObjectsListener)
    EventBus.$on('callUnhide', this.unhideObjectsListener)
    EventBus.$on('changeObj', this.changeObjColorListener)
    EventBus.$on('moveObj', this.moveObjListener)
    EventBus.$on('resetPos', this.resetPositionListener)
  },

  beforeDestroy() {
    EventBus.$off('callReset', this.resetColorsListener)
    EventBus.$off('callHide', this.hideObjectsListener)
    EventBus.$off('callUnhide', this.unhideObjectsListener)
    EventBus.$off('changeObj', this.changeObjColorListener)
    EventBus.$off('moveObj', this.moveObjListener)
    EventBus.$off('resetPos', this.resetPositionListener)

    if (this.fbxModel) {
      this.disposeFBXModel(this.fbxModel)
    }
  },

  methods: {
    createThreeScene() {
      if (WEBGL.isWebGLAvailable()) {
        this.init_Scene()
        this.animate()
      } else {
        const warning = WEBGL.getWebGLErrorMessage()
        this.$refs.threeContainer.appendChild(warning)
      }
    },
  },
}
</script>

<style scoped>
</style>
