import * as d3 from 'd3'

export default {
  methods: {

    header_Sheets() {
      let Overall = this.Area_Canvas
      .append('g')
      .attr('transform',`translate(20, 90)`)
      .style('font-family', 'roboto').style('cursor', 'default')

      let data = this.DiscType.find(f=> f.ITEM == 'OV')

      let Bsheet = Overall
      .append('g')
      .attr('id', `CHECK_ITEM_${data.idx}`)
      .attr('transform',`translate(0, 0)`)
      .attr('cursor', 'pointer').attr('opacity', 1)
      .on('mouseover', function() {
        d3.select(this).attr('opacity', .7)
      })
      .on('mouseout', function() {
        d3.select(this).attr('opacity', 1)
      })
      .on('click', ()=> {
        this.redraw_Charts(data)
      })
      
      Bsheet
      .append('text')
      .attr('transform',`translate(25, 38)`)
      .attr('font-size', 10).attr('fill', '#333').attr('text-anchor', 'start')
      .text(`B ITRs`)

      Bsheet
      .append('circle')
      .attr('transform',`translate(16, 34)`)
      .attr('r', 5).attr('opacity', 1)
      .attr('fill', 'none').attr('stroke', '#333').attr('stroke-width', 1).attr('stroke-opacity', 0.5)

      Bsheet
      .append('circle')
      .attr('id', `CHECK_${data.idx}`)
      .attr('transform',`translate(16, 34)`)
      .attr('r', 3).attr('opacity', 1)
      .attr('fill', '#333').attr('stroke-width', 0)

      this.sheet_Summary(Overall, {
        x: 10,
        main: 'B ITRs',
        sub: 'Overall Status of B ITRs',
        sht: 'B',
        color: this.barColor.OV,
      })
    },



    sheet_Summary(selection, d) {

      let title = selection
      .append('g')
      .attr('transform',`translate(${d.x}, 0)`)
      .style('font-family', 'roboto').style('cursor', 'default')

      title
      .append('text')
      .attr('transform',`translate(0, 5)`).attr('font-size', 11).attr('fill', '#000').attr('text-anchor', 'start')
      .text(d.main)

      title
      .append('text')
      .attr('transform',`translate(0, 15)`).attr('font-size', 9).attr('fill', '#BDBCBC').attr('text-anchor', 'start')
      .text(d.sub)


      let sheets = selection
      .append('g')
      .attr('transform',`translate(${d.x+25}, ${40})`)

      sheets
      .append('text')
      .attr('transform',`translate(38, 17)`)
      .attr('font-size', 10).attr('fill', '#000').attr('text-anchor', 'end')
      .attr('cursor', 'pointer')
      .text(this.Overall.B_TOT)
      .call(this.call_Sheet_List, {item: d.sht, type: 'TOT'})
      
      let len = 150
      let PRO = (this.Overall.B_PRO/100) * len
      sheets
      .append('rect')
      .attr('x', 40).attr('y', 6.5).attr('width', len).attr('height', 14)
      .attr('fill', '#bcbcbc').attr('opacity', .3)

      sheets
      .append('rect')
      .attr('x', 40).attr('y', 6.5)
      .attr('width', PRO).attr('height', 14)
      .attr('fill', d.color).attr('opacity', 1)

      sheets
      .append('text')
      .attr('x',  PRO > 60 ? 38 + PRO : 42 + PRO)
      .attr('y', 17)
      .attr('font-size', 10).attr('fill', '#000')
      .attr('text-anchor', PRO > 60 ? 'end' : 'start')
      .attr('cursor', 'pointer')
      .text(this.Overall.B_ACT)
      .call(this.call_Sheet_List, {item: d.sht, type: 'ACT'})

      sheets
      .append('text')
      .attr('x', 38 + len + 3)
      .attr('y', 17)
      .attr('font-size', 10).attr('fill', '#000').attr('text-anchor', 'start')
      .attr('cursor', 'pointer')
      .text(this.Overall.B_REM)
      .call(this.call_Sheet_List, {item: d.sht, type: 'REM'})
    },

  }
}