import Defs from '../../../../../../includes/primitives/colorDefs'
export default {
  data: () => ({
    stg_Canvas:   null,

    rawData:      null,
    summary:      {},

    header:       {},
    scope:        {},
    overall:      {},
    sheet:        {},
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    set_LocalValues(){

      this.rawData = JSON.parse(JSON.stringify(this.DataItems[0]))
      let sql1 = JSON.parse(JSON.stringify(this.Queries.SQL1[0]))

      this.rawData.WD_TOT = this.rawData.SSMCC_TOT
      this.rawData.WD_ACT = this.rawData.SSMCC_WD
      this.rawData.WD_REM = this.rawData.SSMCC_TOT - this.rawData.SSMCC_WD
      this.rawData.WD_PRO = (this.rawData.SSMCC_WD / this.rawData.SSMCC_TOT) * 100

      this.rawData.MC_TOT = this.rawData.A_TOT + this.rawData.AP_TOT
      this.rawData.MC_ACT = this.rawData.A_ACT + this.rawData.AP_ACT
      this.rawData.MC_PRO = (this.rawData.MC_ACT / this.rawData.MC_TOT) * 100

      this.rawData.SC_TOT = this.rawData.B_TOT + this.rawData.BP_TOT
      this.rawData.SC_ACT = this.rawData.B_ACT + this.rawData.BP_ACT
      this.rawData.SC_PRO = (this.rawData.SC_ACT / this.rawData.SC_TOT) * 100


      // Header 
      this.header = {
        x:            [],
        height:       sql1['HEADER_HEIGHT'],
        col:          sql1['HEADER_COLUMN'].replace(/\s/g, '').split('/').map(Number),
      }
      let x = 0
      this.header.col.forEach((d,i)=> {
        this.header.x[i] = x
        x += d + 10
      })

      // OVERALL ---------------------------------------------------------------------------------
      this.overall = {
        x:            [],
        length:       sql1['OVERALL_LENGTH'],
        name:         sql1['OVERALL_NAME'].replace(/\s/g, ' ').split('/'),
        code:         sql1['OVERALL_CODE'].replace(/\s/g, '').split('/'),
        align:        sql1['OVERALL_ALIGN'].replace(/\s/g, '').split('/'),
        width:        sql1['OVERALL_COL_WIDTH'].replace(/\s/g, '').split('/').map(Number),
      }
      let x_ = 0
      this.overall.align.forEach((d,i)=> {
        if(d == 'start') this.overall.x[i] = x_
        if(d == 'end') this.overall.x[i] = x_ + this.overall.width[i]
        x_ +=  this.overall.width[i]
      })
      // console.log(this.overall.x)



      // Bar Chart 
      this.scope = {
        name:         sql1['SCOPE_NAME'],
        code:         sql1['SCOPE_CODE'].replace(/\s/g, '').split('/'),
        title:        sql1['SCOPE_TITLE'].replace(/\s/g, ' ').split('/'),
        bgColor:      sql1['SCOPE_COLOR'].replace(/\s/g, '').split('/'),
        bColor:       sql1['SCOPE_BAR_COLOR'].replace(/\s/g, '').split('/'),
        opacity:      sql1['SCOPE_OPACITY'].replace(/\s/g, '').split('/').map(Number),
      }
      

      // SHEET SUMMARY ----------------------------------------------------------------------------------
      this.sheet = {
        length:       sql1['SHEET_LENGTH'],
        mTitle:       sql1['SHEET_TITLE'].replace(/\s/g, ' ').split('/'),
        sTitle:       sql1['SHEET_SUB_TITLE'].replace(/\s/g, ' ').split('/'),

      }
    },

    set_Gradients() {
      // set Gradient color for the Timeline
      
      Defs.setDefs_Gradients(this.svg, 'H', 'LinearA4', 'Pink', [.5, .8, .5, -1], this.localId)
      Defs.setDefs_Gradients(this.svg, 'H', 'LinearA4', 'LightBlue', [.5, 1, .7, -.1], this.localId)
      Defs.setDefs_Gradients(this.svg, 'H', 'LinearA4', 'Yellow', [.5, .8, .5, 0], this.localId)
      Defs.setDefs_Gradients(this.svg, 'H', 'LinearA4', 'YellowGreen', [.5, .8, .5, 0], this.localId)

      Defs.setDefs_Gradients(this.svg, 'V', 'LinearA4', 'Pink', [.8, .5, .0, .5], this.localId)
      Defs.setDefs_Gradients(this.svg, 'V', 'LinearA4', 'LightBlue', [.8, .5, .0, .5], this.localId)
      Defs.setDefs_Gradients(this.svg, 'V', 'LinearA4', 'Yellow', [.8, .5, .0, .5], this.localId)
      Defs.setDefs_Gradients(this.svg, 'V', 'LinearA4', 'YellowGreen', [.8, .5, .0, .5], this.localId)
    },

  }
}