import * as d3 from 'd3'

export default {
  methods: {

    draw_Header() {
      
      // this.pdf_redrawing('GANTT_GROUP') // for exporting PDF
      
      this.Area_Canvas = this.svg
      .append('g')
      .attr('id', `GANTT_GROUP`)

      
      let Header = this.Area_Canvas
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .style('font-family', 'roboto').attr('cursor', 'default')

      Header
      .append('text')
      .attr('x', 30).attr('y', 40)
      .attr('font-size', 24).attr('fill', '#44A9DF').attr('font-weight', 400)
      .text('Gantt Chart of CP21 Milestones')

      Header
      .append('text')
      .attr('x', 32).attr('y', 55)
      .attr('font-size', 12).attr('fill', '#757575')
      .text('Contractor Handover Milestone with Mapping Subsystem')
      
      this.draw_Legends(Header)
      this.draw_HeaderTable(Header)
    },


    draw_Legends(selection) {
      let ms_legends = selection
      .append('g')
      .attr('transform', `translate(${this.timeline.x}, ${this.timeline.y - 15})`)
      .style('font-family', 'roboto').attr('cursor', 'default')

      ms_legends
      .append('rect')
      .attr('x', 0).attr('y', 0)
      .attr('width', 30).attr('height', 10)
      .attr('fill', `url(#${this.localId}_LightGray_ICON)`)
      .attr('opacity', .5)
      
      ms_legends
      .append('text')
      .attr('x', 35).attr('y', 8)
      .attr('font-size', 10).attr('fill', '#333')
      .text('Subsystem Turnover Date (First Date-10 ~ Last Date)')


      ms_legends
      .append('rect')
      .attr('x', 290).attr('y', 2)
      .attr('width', 30).attr('height', 6)
      .attr('fill', `url(#${this.localId}_LightBlue_)`)
      .attr('opacity', .5)

      ms_legends
      .append('text')
      .attr('x', 325).attr('y', 8)
      .attr('font-size', 10).attr('fill', '#333')
      .text('A + B ITRs Progress')


      ms_legends
      .append('path')
      .attr('transform', `translate(${430}, 0) scale(1)`)
      .attr('d', this.icons.ms_date)
      .attr('fill', '#44A9DF')

      ms_legends
      .append('text')
      .attr('x', 445).attr('y', 8)
      .attr('font-size', 10).attr('fill', '#333')
      .text('Milestone Forecast Date')





      // let Legend  = {
      //   name: ['Expand group', 'Collapse group', 'Expand all item', 'Collapse all item'],
      //   icon: ['plus', 'minus', 'expand', 'reduce'],
      //   color: [`url(#${this.localId}_LightBlue_`, `url(#${this.localId}_LightGray_ICON`, '#bcbcbc', '#bcbcbc']
      // }

      // let legends = selection
      // .append('g')
      // .attr('transform', `translate(${this.timeline.x}, ${this.timeline.y - 35})`)
      // .style('font-family', 'roboto').attr('cursor', 'default')

      // Legend.name.forEach((d,i)=> {
      //   legends
      //   .append('path')
      //   .attr('transform', `translate(${i*100}, 0) scale(1)`)
      //   .attr('d', this.icons[Legend.icon[i]])
      //   .attr('fill', Legend.color[i])

      //   legends
      //   .append('text')
      //   .attr('x', i*100 + 15).attr('y', 9)
      //   .attr('font-size', 10).attr('fill', '#757575').attr('font-weight', 450)
      //   .text(d)
      // })

      // legends
      // .append('text')
      // .attr('x', 403).attr('y', 9)
      // .attr('font-size', 10).attr('fill', '#333').attr('font-weight', 450)
      // .text('+')

      // legends
      // .append('text')
      // .attr('x', 403 + 10).attr('y', 9)
      // .attr('font-size', 10).attr('fill', '#757575').attr('font-weight', 450)
      // .text('Expand item')

      // legends
      // .append('text')
      // .attr('x', 480).attr('y', 9)
      // .attr('font-size', 10).attr('fill', '#333').attr('font-weight', 450)
      // .text('-')

      // legends
      // .append('text')
      // .attr('x', 480 + 10).attr('y', 9)
      // .attr('font-size', 10).attr('fill', '#757575').attr('font-weight', 450)
      // .text('Collapse item')
    },



    draw_HeaderTable(selection) {

      let columns = selection
      .append('g')
      .attr('transform', `translate(${this.timeline.x}, ${this.timeline.y})`)

      columns
      .append('path') // shadow
      .attr('d', `M 30, ${this.timeline.height + 2} H ${this.timeline.length+this.timeline.column - 30} L ${this.timeline.length + this.timeline.column},${this.timeline.height + 8} H0 Z`)
      .attr('fill', `url(#shadowTimeline)`) 

      columns
      .append('rect') 
      .attr('x', 0).attr('y', 0).attr('width', this.timeline.column).attr('height', 3)
      .attr('fill', `url(#${this.localId}_LightGray_)`).attr('opacity', .99)

      columns
      .append('rect')
      .attr('x', 0).attr('y', 3).attr('width', this.timeline.column).attr('height', this.timeline.height)
      .attr('fill', `url(#${this.localId}_LightGray_)`).attr('opacity', .5)

      // Time Now
      columns
      .append('rect')
      .attr('x', 0).attr('y', 2).attr('width', this.timeline.column + this.timeline.scale(new Date(this.timeline.cutoff)))
      .attr('height', this.timeline.height)
      .attr('fill', `url(#${this.localId}_LightBlue_)`).attr('opacity', .5)

      columns
      .append('rect') 
      .attr('x', 0).attr('y', this.timeline.height + 1).attr('width', this.timeline.column).attr('height', 4)
      .attr('fill', `url(#${this.localId}_LightGray_)`)

      
      let tableHeader = columns
      .append('g')
      .attr('transform', `translate(20, 24)`)
      .style('font-family', 'roboto').attr('cursor', 'default')

      tableHeader
      .append('text')
      .attr('x', 0).attr('y', 0)
      .attr('font-size', 12).attr('fill', '#333').attr('font-weight', 450)
      .text('Milestone Description')

      tableHeader
      .append('text')
      .attr('x', 348).attr('y', -7).attr('font-weight', 420)
      .attr('font-size', 11).attr('fill', '#333').attr('text-anchor', 'middle')
      .text('Forecast')

      tableHeader
      .append('text')
      .attr('x', 348).attr('y', +6).attr('font-weight', 420)
      .attr('font-size', 11).attr('fill', '#333').attr('text-anchor', 'middle')
      .text('Date')
    }

    
  }
}
