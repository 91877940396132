import * as d3 from 'd3'

export default {

  methods: {

    draw_Legends() {
      this.legendGroup = this.svg
      .append('g')
      .attr('id', 'Legend')
      .attr('transform', `translate(25, 50)`)

      this.legendGroup
      .append('text')
      .attr('transform', `translate(0, 35)`)
      .style('font-size', 12).style('fill', '#000').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(`Legends`)

      this.count_Sheet() // ---> below
      this.legend_Sheet() // ---> below
      this.legend_Chart() // ---> below
    },


    legend_Sheet() {
      let x_ = 0

      this.sheetGroup = this.legendGroup
      .append('g')
      .attr('id', `Sheet_Group`)
      .attr('transform', `translate(18, 55)`)

      this.skyline.styles.forEach((s, i) => {
        
        let legendItem = this.sheetGroup
        .append('g')
        .attr('id', `Sheet_${s.status}`)
        .attr('transform', `translate(${x_}, 0)`)
        .attr('font-family', 'roboto')
        .attr('opacity', this.sheetStatus == s.status ? 1 : 0.8)
        .style('cursor', 'pointer')
        .on('mouseover', () => { 
          d3.select(`#Sheet_${s.status}`).attr('opacity', 0.5) 
        })
        .on('mouseout', () => { 
          d3.select(`#Sheet_${s.status}`).attr('opacity', this.sheetStatus == s.status ? 1 : 0.8) 
        })
        .on('click', ()=> {
          d3.select(`#Sheet_${this.sheetStatus}`).attr('opacity', 0.8)
          d3.select(`#Sheet_text_${this.sheetStatus}`).attr('font-weight', 400).attr('fill', '#000')

          this.sheetStatus = s.status
          d3.select(`#Sheet_${s.status}`).style('opacity', 1)
          d3.select(`#Sheet_text_${s.status}`).attr('font-weight', 600).attr('fill', '#000')

          this.filtering_Search() // ---> Event.js
          // for pdf
          this.filterStatus = 'ON'
        })
        
        legendItem
        .append('rect')
        .attr('id', `Sheet_box_${s.status}`)
        .attr('transform', `translate(-5, -5)`)
        .attr('rx', 2).attr('ry', 2)
        .attr('width', 10).style('height', 10)
        .attr('stroke', '#bcbcbc')
        .attr('stroke-width', s.sWidth)
        .attr('fill', s.bColor)

        legendItem
        .append('text')
        .attr('id', `Sheet_text_${s.status}`)
        .attr('transform', `translate(9, 1)`)
        .attr('font-size', 9)
        .attr('font-weight', this.sheetStatus == s.status ? 600 : 400)
        .attr('fill', '#000')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(`${s.name} (${s.value})`)

        x_ += this.getNodeElValue(`#Sheet_${s.status}`, 'width') + 12
      })
      
    },


    legend_Chart() {
      let x_ = 0

      this.sheetGroup = this.legendGroup
      .append('g')
      .attr('id', `Chart_Group`)
      .attr('transform', `translate(8, 75)`)

      this.charts.forEach((s, i) => {
        
        let legendItem = this.sheetGroup
        .append('g')
        .attr('id', `Sheet_${s.status}`)
        .attr('transform', `translate(${x_}, 0)`)
        .attr('font-family', 'roboto')
        .attr('opacity', this.sheetStatus == s.status ? 1 : 0.8)
        .style('cursor', 'default')
        
        legendItem
        .append('line')
        .attr('x1', 0).attr('y1', 0)
        .attr('x2', 20).attr('y1', 0)
        .attr('stroke', s.sColor)
        .attr('stroke-width', 1)

        legendItem
        .append('circle')
        .attr('cx', 10).attr('cy', 0).attr('r', 3)
        .attr('stroke', s.sColor).attr('stroke-width', 1.5).attr('fill', '#fff')

        legendItem
        .append('text')
        .attr('id', `Sheet_text_${s.status}`)
        .attr('transform', `translate(22, 1)`)
        .attr('font-size', 9)
        .attr('font-weight', this.sheetStatus == s.status ? 600 : 400)
        .attr('fill', '#000')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(s.name)

        x_ += this.getNodeElValue(`#Sheet_${s.status}`, 'width') + 12
      })
      
    },

    count_Sheet(){
      this.skyline.styles.forEach((s,i)=> {
        if (s.status == 'TOTAL') s.value = this.rawData.length
        else s.value = this.rawData.filter(f=> f[`A_STATUS`] == s.status).length
      })
    },

    

    
  }
}