import * as d3 from 'd3'

export default {
  methods: {

    header_Sheets() {
      let Overall = this.Area_Canvas
      .append('g')
      .attr('transform',`translate(20, 90)`)
      .style('font-family', 'roboto').style('cursor', 'default')

      // A Check Box for Overall --------------------------------------------
      let Asheet = Overall
      .append('g')
      .attr('transform',`translate(0, 0)`)
      .attr('cursor', 'pointer').attr('opacity', 1)
      .on('mouseover', function() {
        d3.select(this).attr('opacity', .7)
      })

      .on('mouseout', function() {
        d3.select(this).attr('opacity', 1)
      })
      .on('click', ()=> {
        this.reset_Discipline('AP', 'OV')
      })
      Asheet
      .append('text')
      .attr('transform',`translate(25, 38)`)
      .attr('font-size', 10).attr('fill', '#000').attr('text-anchor', 'start')
      .text(`Punch A`)

      Asheet
      .append('circle')
      .attr('transform',`translate(16, 34)`)
      .attr('r', 5).attr('opacity', 1)
      .attr('fill', 'none').attr('stroke', '#333').attr('stroke-width', 1).attr('stroke-opacity', 0.5)

      Asheet
      .append('circle')
      .attr('id', `DISC_AP_OV`)
      .attr('transform',`translate(16, 34)`)
      .attr('r', 3).attr('opacity', this.Discipline[0].ITEM == 'AP' ? 1 : 0)
      .attr('fill', '#333').attr('stroke-width', 0)


      // B Check Box for Overall --------------------------------------------
      let Bsheet = Overall
      .append('g')
      .attr('id', `CHECK_GROUP_B_OV`)
      .attr('transform',`translate(220, 0)`)
      .attr('cursor', 'pointer').attr('opacity', 1)
      .on('mouseover', function() {
        d3.select(this).attr('opacity', .7)
      })
      .on('mouseout', function() {
        d3.select(this).attr('opacity', 1)
      })
      .on('click', ()=> {
        this.reset_Discipline('BP', 'OV')
      })
      Bsheet
      .append('text')
      .attr('transform',`translate(25, 38)`)
      .attr('font-size', 10).attr('fill', '#333').attr('text-anchor', 'start')
      .text(`Punch B`)

      Bsheet
      .append('circle')
      .attr('transform',`translate(16, 34)`)
      .attr('r', 5).attr('opacity', 1)
      .attr('fill', 'none').attr('stroke', '#333').attr('stroke-width', 1).attr('stroke-opacity', 0.5)

      Bsheet
      .append('circle')
      .attr('id', `DISC_BP_OV`)
      .attr('transform',`translate(16, 34)`)
      .attr('r', 3).attr('opacity',  this.Discipline[0].ITEM == 'BP' ? 1 : 0)
      .attr('fill', '#333').attr('stroke-width', 0)


      this.sheet_Summary(Overall, {
        x: 10,
        main: 'Punch A',
        sub: 'Status of Punch A',
        sht: 'AP',
        bColor: '#83D2F5',
      })

      this.sheet_Summary(Overall, {
        x: 225,
        main: 'Punch B',
        sub: 'Status of Punch B',
        sht: 'BP',
        bColor: '#d0d01e',
      })
    },


    sheet_Summary(selection, d) {

      let sheets = selection
      .append('g')
      .attr('transform',`translate(${d.x}, 0)`)
      .style('font-family', 'roboto').style('cursor', 'default')

      sheets
      .append('text')
      .attr('transform',`translate(0, 5)`).attr('font-size', 11).attr('fill', '#000').attr('text-anchor', 'start')
      .text(d.main)

      sheets
      .append('text')
      .attr('transform',`translate(0, 15)`).attr('font-size', 9).attr('fill', '#BDBCBC').attr('text-anchor', 'start')
      .text(d.sub)

      this.sheet_BarChart(selection, {x: d.x, y: 40, sht: d.sht, color: d.bColor})
    },


    sheet_BarChart(selection, d) {
      
      let sheets = selection
      .append('g')
      .attr('transform',`translate(${d.x+25}, ${d.y})`)

      sheets
      .append('text')
      .attr('class', `DISC_${d.sht}_${'OV'}_ITEM`)
      .attr('transform',`translate(38, 17)`)
      .attr('font-size', 10).attr('fill', '#333').attr('text-anchor', 'end')
      .attr('cursor', 'pointer')
      .text(this.Overall[`${d.sht}_TOT`])
      .call(this.call_Punch, {item: d.sht, type: 'TOT'})
      
      sheets
      .append('rect')
      .attr('x', 40).attr('y', 7.5).attr('width', 100).attr('height', 12)
      .attr('fill', '#bcbcbc').attr('opacity', .3)

      sheets
      .append('rect')
      .attr('x', 40).attr('y', 7.5)
      .attr('width', this.Overall[`${d.sht}_PRO`]).attr('height', 12)
      .attr('fill', d.color).attr('opacity', 1)

      let PRO = this.Overall[`${d.sht}_PRO`]
      sheets
      .append('text')
      .attr('class', `DISC_${d.sht}_${'OV'}_ITEM`)
      .attr('x',  PRO > 60 ? 38 + PRO : 42 + PRO)
      .attr('y', 17)
      .attr('font-size', 10).attr('fill', '#333')
      .attr('text-anchor', PRO > 60 ? 'end' : 'start')
      .attr('cursor', 'pointer')
      .text(this.Overall[`${d.sht}_ACT`])
      .call(this.call_Punch, {item: d.sht, type: 'ACT'})

      sheets
      .append('text')
      .attr('class', `DISC_${d.sht}_${'OV'}_ITEM`)
      .attr('transform',`translate(142, 17)`)
      .attr('font-size', 10).attr('fill', '#333').attr('text-anchor', 'start')
      .attr('cursor', 'pointer')
      .text(this.Overall[`${d.sht}_REM`])
      .call(this.call_Punch, {item: d.sht, type: 'REM'})
    },

  }
}