export default {
  
  methods: {

    draw_MilestoneTable(_data) {

      this.sTable = this.svg
      .append('g')
      .attr('id', 'Legends')
      .attr('transform', `translate(${this.msTable.x}, ${this.icicle.y - 4.5})`)
      .attr('font-size', 12).attr('fill', '#333').attr('font-weight', 500)

      // Draw header of the MilestoneTable
      this.sTable
      .append('text')
      .attr('id', 'slelectAll')
      .attr('transform', `translate(0, 0)`)
      .attr('fill', '#B5549B').attr('font-weight', 400)
      .attr('font-size', 10.5).attr("text-anchor", "start")
      .style('cursor', 'pointer')
      .text('Unselect all')
      .call(this.call_SelectAll)

      this.sTable.append('line')
      .attr('x1', 0).attr('y1', 6)
      .attr('x2', this.msTable.length).attr('y2', 6)
      .attr('stroke', '#757575').attr('stroke-width', .5)

      this.sTable.append('line')
      .attr('x1', 0).attr('y1', 24)
      .attr('x2', this.msTable.length).attr('y2', 24)
      .attr('stroke', '#757575').attr('stroke-width', .5)

      this.msTable.col.name.forEach((d,i)=> {
        this.sTable
        .append('text')
        .attr('transform', `translate(${this.msTable.col.x[i]}, 18)`)
        .attr('text-anchor', this.msTable.col.align[i]).attr('font-size', 10)
        .text(d)
      })
      

      // Draw data of the MilestoneTable
      let y_ = 42
      _data.forEach((d,i) => {

        let milestoneItem = this.sTable
        .append('g')
        .attr('id', `milestone_${d.MS_NO}`)
        .attr('transform', `translate(0, ${y_ + (i*18)})`)
        .style('opacity', 1).style('cursor', 'pointer')
        .call( this.call_MilestoneList, d) // --->
        
        milestoneItem
        .append('path')
        .attr('id', `checked_${d.MS_NO}`)
        .attr('transform', `translate(${0}, ${-7})`)
        .attr('d', this.icon.checked).attr('fill', '#bcbcbc')
        .attr('opacity', 1)

        milestoneItem
        .append('rect')
        .attr('x', 15).attr('y', -10).attr('width', 14.5).attr('height', 14)
        .attr('rx', 2).attr('ry', 2)
        .attr('fill', this.icon.colors[i])
        .attr('stroke', '#bcbcbc').attr('stroke-width', .5)

        this.msTable.col.code.forEach((m,j)=> {
          milestoneItem
          .append('text')
          .attr('transform', ()=> {
            if(j==0) return `translate(22, 0)`
            else return `translate(${this.msTable.col.x[j]}, 0)`
          })
          .attr('fill', ()=> {
            if(m == 'DELTA') {
              if(d[m]>0) return '#EC407A'
              if(d[m]<0) return '#44A9DF'
              if(d[m]==0) return '#bcbcbc'
            } else {
              return '#333'
            }
            
          })
          .attr('text-anchor', this.msTable.col.align[j])
          .attr('font-size', 10)
          .text(d[m])

          milestoneItem
          .append('rect')
          .attr('id',  `highlight_${d[m]}`)
          .attr('x', 34).attr('y', -11)
          .attr('width', this.msTable.length - this.msTable.col.width[0]).attr('height', 15)
          .attr('fill', '#44A9DF').attr('opacity', 0.15)
          .style('visibility', 'hidden')
        })
      })

    },
    
  }
}