import gmx_zMixins         from '../../../../../../includes/Jmixins/zMixins'

// Locally declared common Data-Props & SVG Drawing Support

import lmx_Events                     from './Events'
import lmx_Watching                   from './Watching'
import lmx_Controller                 from './func_Controller'

import lmx_LocalValues                from './set_LocalValues'
import lmx_ChartValues                from './set_ChartValues'
import lmx_draw_Forms                 from './draw_Forms'
import lmx_draw_Timelines             from './draw_Timelines'
import lmx_draw_MilestoneTable        from './draw_Table'
import lmx_draw_Charts                from './draw_Charts'
import lmx_draw_Detail                from './draw_Detail'



export default {
  
  mixins: [
    gmx_zMixins, 

    // Local Mixins
    lmx_Events,
    lmx_Watching,
    lmx_Controller,

    lmx_LocalValues,
    lmx_ChartValues,
    lmx_draw_Forms,
    lmx_draw_Timelines,
    lmx_draw_MilestoneTable,
    lmx_draw_Charts,
    lmx_draw_Detail,
  ],

}
