import * as d3 from 'd3'
import moment from 'moment'

export default {

  methods: {
    
    draw_Timeline() {    

      let timelineHeight = 40
      let Timeline = this.SkylineCharts
      .append('g')
      .attr('transform', `translate(${this.timeline.baseX}, ${this.timeline.baseY})`)
      .attr('cursor', 'default').attr('text-anchor', 'middle')

      /////////// draw the timeline background without the text
      Timeline
      .append('path') // shadow
      .attr('d', `M50,${timelineHeight + 6} H ${this.timeline.length-50} L ${this.timeline.length},${timelineHeight + 12} H0 Z`)
      .attr('fill', `url(#shadowTimeline)`) 

      Timeline
      .append('rect') 
      .attr('x', 0).attr('y', 0).attr('width', this.timeline.length).attr('height', 2)
      .attr('fill', `url(#${this.localId}_LightGray_TL)`).attr('opacity', .99)

      Timeline
      .append('rect')
      .attr('x', 0).attr('y', 2).attr('width', this.timeline.length).attr('height', timelineHeight)
      .attr('fill', `url(#${this.localId}_LightGray_TL)`).attr('opacity', .5)

      Timeline
      .append('rect') 
      .attr('x', 0).attr('y', timelineHeight + 1).attr('width', this.timeline.length).attr('height', 6)
      .attr('fill', `url(#${this.localId}_LightGray_TL)`)


       // Month Text 
       Timeline
       .append('g')
       .selectAll('text')
       .data(this.timeline.week)
       .enter()
       .append('text')
       .attr('x', d => this.timeline.scale(d.mDate))
       .attr('y', 11)
       .style('font-size', 9).attr('fill', '#757575')
       .text(d => d.name)
 
       Timeline
       .append('g')
       .selectAll('path')
       .data(this.timeline.week)
       .enter()
       .append('path')
       .attr('d', (d, i) => {
         if (i != this.timeline.week.length-1) return `M${this.timeline.scale(d.eDate)}, 2 V 11`
       })
       .attr('stroke', '#bcbcbc')
       .attr('stroke-width', .5)

      // cutoff Week highlight
      let cutoff = moment(this.timeline.cutoff).format('YYYY-MM-DD')

      this.timeline.week.forEach(d => {
        if (moment(d.eDate).format('YYYY-MM-DD') == cutoff) {
          Timeline
          .append('rect')
          .attr('x',this.timeline.scale(d.sDate) + 1)
          .attr('y',.5)
          .attr('width', this.timeline.scale(d.eDate) - this.timeline.scale(d.sDate))
          .attr('height',14)
          .style('opacity', 0.3)
          .attr('fill','#00b0f0')
          .attr('stroke','#bcbcbc')
          .attr('stroke-width',0.5)               
        }
      })

      // Month Text 
      Timeline
      .append('g')
      .selectAll('text')
      .data(this.timeline.month)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate))
      .attr('y', 22)
      .style('font-size', 9).attr('fill', '#333')
      .text(d => d.name.toUpperCase())

      Timeline
      .append('g')
      .selectAll('path')
      .data(this.timeline.month)
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        if (i != this.timeline.month.length-1) return `M${this.timeline.scale(d.eDate)}, 11 V 20`
      })
      .attr('stroke', '#bcbcbc')
      .attr('stroke-width', .5)


      // cutoff month highlight
      // let cutoff = moment(this.timeline.cutoff).format('YYYY-MM')

      // this.timeline.month.forEach(d => {
      //   if (moment(d.eDate).format('YYYY-MM') == cutoff) {
      //     Timeline
      //     .append('rect')
      //     .attr('x',this.timeline.scale(d.sDate) + 1)
      //     .attr('y',2.5)
      //     .attr('width', this.timeline.scale(d.eDate) - this.timeline.scale(d.sDate) - this.skyline.skyBox.gap)
      //     .attr('height',this.timeline.styles.month.height - 5)
      //     .style('opacity', 0.3)
      //     .attr('fill','#00b0f0')
      //     .attr('stroke','#bcbcbc')
      //     .attr('stroke-width',0.5)               
      //   }
      // })

      // Year Text & Timeline Seperator for the Year
      Timeline
      .append('g')
      .selectAll('text')
      .data(this.timeline.year)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate))
      .attr('y', 37)
      .style('font-size', 12)
      .attr('fill', '#333').attr('font-weight', 500)
      .text(d => d.name)

      Timeline
      .append('g')
      .selectAll('path')
      .data(this.timeline.year)
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        if (i != this.timeline.year.length-1) return `M${this.timeline.scale(d.eDate)}, 20 V 38`
      })
      .attr('stroke', '#bcbcbc')
      .attr('stroke-width', .5)
    }, 
  }
}
