import moment from 'moment'
import * as d3 from 'd3'

export default {
  methods: {

    draw_ConditionHeader(){

      let Condition = this.Area_Screen
      .append('g')
      .attr('id', `CONDITION_BOX`)
      .attr('transform', `translate(5, 5)`)
      .attr('font-family', 'roboto')
      .attr('opacity', 1)

      Condition
      .append('rect')
      .attr('x', 0).attr('y', 0)
      .attr('rx', 0).attr('ry', 0)
      .attr('width', 350).attr('height', 40)
      .attr('fill', 'url(#GradientBG)').attr('stroke', '#E0E0DF').attr('stroke-width', .5)

      Condition
      .append('text')
      .attr('transform',`translate(5, 15)`)
      .attr('font-size', 14)
      .attr('fill', this.status.mColor).attr('text-anchor', 'start')
      .text('Chart Information')

      this.conditionColumn.forEach((d,i)=> {
        Condition
        .append('text')
        .attr('x', d.x).attr('y', 33)
        .attr('font-size', 10)
        .attr('fill', this.status.mColor).attr('text-anchor', 'middle')
        .text(d.name)
      })
      Condition
      .append('text')
      .attr('x', this.conditionColumn[6].x-3).attr('y', 20)
      .attr('font-size', 10)
      .attr('fill', this.status.mColor).attr('text-anchor', 'middle')
      .text('Peak Time')

      Condition
      .append('line')
      .attr('x1', this.conditionColumn[5].x).attr('y1', 20).attr('x2', this.conditionColumn[5].x+20).attr('y2', 20)
      .attr('stroke', '#fff').attr('stroke-width', .5)
      Condition
      .append('line')
      .attr('x1', this.conditionColumn[5].x).attr('y1', 20).attr('x2', this.conditionColumn[5].x).attr('y2', 25)
      .attr('stroke', '#fff').attr('stroke-width', .5)

      Condition
      .append('line')
      .attr('x1', this.conditionColumn[7].x).attr('y1', 20).attr('x2', this.conditionColumn[7].x-20).attr('y2', 20)
      .attr('stroke', '#fff').attr('stroke-width', .5)
      Condition
      .append('line')
      .attr('x1', this.conditionColumn[7].x).attr('y1', 20).attr('x2', this.conditionColumn[7].x).attr('y2', 25)
      .attr('stroke', '#fff').attr('stroke-width', .5)
    },


    draw_Conditions(){
      let this_ = this
      let y = 39
      let len = this.condition_List.length
      y = y + (len * 20)

      let currCondition = this.condition_List.find(f=> f.count == this.chart_Count)
      this.condition_List.find(f=> f.count == this.chart_Count).y = y

      let Condition_Item = this.Area_Screen
      .append('g')
      .attr('id', `CHART_CONDITION_${this.chart_Count}`)
      .attr('transform', `translate(5, ${y})`)
      .attr('opacity', 1).style('cursor', 'pointer')
      .on('mouseover', function() {
        let getIdx = d3.select(this).attr('id').slice(-1)
        this_.condition_List.forEach(d=> {
          d3.select(`#Chart_Area_${d.count}`).transition().duration(300).attr('opacity', d.count == getIdx ? 1 : 0)
          d3.select(`#CONDITION_BOX_${d.count}`).transition().duration(150).attr('fill', d.count == getIdx ? '#EBEBEB' : '#fff')
        })
      })
      .on('mouseout', function() {
        this_.condition_List.forEach(d=> {
          d3.select(`#Chart_Area_${d.count}`).transition().duration(300).attr('opacity', 1)
          d3.select(`#CONDITION_BOX_${d.count}`).transition().duration(150).attr('fill', '#fff')
        })
      })

      Condition_Item
      .append('rect')
      .attr('id', `CONDITION_BOX_${this.chart_Count}`)
      .attr('x', 0).attr('y', -12)
      .attr('width', 350).attr('height', 18).attr('fill', '#fff')
      .attr('stroke', '#bcbcbc').attr('stroke-width', .2)

      this.conditionColumn.forEach((d,i)=> {
        Condition_Item
        .append('text')
        .attr('id', `CHART_IDX_${this.chart_Count}_${i}`)
        .attr('x', d.x)
        .attr('y', 0)
        .attr('font-size', 10).attr('opacity', .75)
        .attr('fill', this.status.vColor).attr('text-anchor', 'middle')
        .text(()=> {
          if(d.code == 'pk_date') return moment(currCondition[d.code]).format('YYYY-MM-DD')
          else if(d.code == 'sDate' || d.code == 'fDate') return `-${currCondition[d.code]}`
          else return currCondition[d.code]
        })

        // Close Window Icon
        let this_ = this
        let closeIcon = Condition_Item
        .append('g')
        .attr('id', `CLOSE_ICON_${this.chart_Count}`)
        .attr('transform', `translate(335, -6)`)
        .style('cursor', 'pointer').attr('opacity', 1)
        .on('click', function() {
          let getIdx = d3.select(this).attr('id').slice(-1)
          d3.select(`#CHART_CONDITION_${getIdx}`).remove()
          d3.select(`#Chart_Area_${getIdx}`).remove()

          this_.condition_List.filter(f=> f.count > getIdx).forEach(d=> {
            d.y = d.y - 20
            d3.select(`#CHART_CONDITION_${d.count}`).transition().duration(500)
            .attr('transform', `translate(5, ${d.y})`)
          })

          let slice = this_.condition_List.findIndex(f=> f.count == getIdx)
          this_.condition_List.splice(slice, 1)

          // console.log('Current List:', this_.condition_List)
        })

        closeIcon
        .append('rect')
        .attr('x1', 0).attr('y1', 0).attr('width', 8).attr('height', 8).attr('fill', '#fff').attr('opacity', 0)
        closeIcon
        .append('line')
        .attr('x1', 0).attr('y1', 0).attr('x2', 6).attr('y2', 6).attr('stroke', '#EC407A') .attr('stroke-width', .2)
        closeIcon
        .append('line')
        .attr('x1', 6).attr('y1', 0).attr('x2', 0).attr('y2', 6).attr('stroke', '#EC407A') .attr('stroke-width', .2)
      })

      // console.log(this.condition_List)
    },

  }
}