
import Defs from '../../../../../../includes/primitives/colorDefs'
import * as d3 from 'd3'
export default {
  data: () => ({

    innerSvg:       null,
    Area_Canvas:    null,

    rawData:        null,
    Systems:        null,
    Rundown:        null,

    turnover:       {}, 

    cols:           [520, 730, 1000],
    scaleX:         null,
    scaleY:         null,
    Grids:          [],
    colHeight:      190,

    dataDate:       '',
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    set_LocalValues(){
      
      if(this.getSelectItems) {
        this.pageOptions = JSON.parse(this.getSelectItems)
      }
      
      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      this.Systems = JSON.parse(JSON.stringify(this.Queries.SQL1))
      this.Rundown = JSON.parse(JSON.stringify(this.Queries.SQL2))

      // Timeline ------------------------------------------------------------------------------------
      this.timeline = {
        week            : [],
        month           : [],
        year            : [],
        scale           : null,
      }
      this.init_gmx_SkylineValues_Week(
        this.Systems, 
        {
          startDate:    '2023-09-01',
          endDate:      '2024-12-27',
          weekEnd:      'friday',
          skyBox_Width: 10,
          skyBox_Gap:   1 // *** week
        }
      )

      this.rawData.forEach(d=> {
        d.AB_TOT = d.A_TOT + d.B_TOT
        d.AB_ACT = d.A_ACT + d.B_ACT
        d.AB_REM = d.A_REM + d.B_REM
        d.AB_PRO = (d.AB_ACT / d.AB_TOT) * 100

        this.Systems.filter(f=> f.UNIT == d.UNIT).forEach(d => {
          d.cDate = (d.CSTD_PLAN >= '2023-09-01' && d.CSTD_PLAN <= '2024-12-27') ? this.get_gmx_TimelineCDate_Week(d.CSTD_PLAN).eDate : null
        })
        let lastDate = d3.max(this.Systems.filter(f=> f.UNIT == d.UNIT && f.CSTD_PLAN), c => c.cDate)

        this.Systems.filter(f=> f.UNIT == d.UNIT && f.cDate == null).forEach(s => {
          s.cDate = lastDate
        })
      })
      
      this.Systems.forEach(d=> {
        d.AB_TOT = d.A_TOT + d.B_TOT
        d.AB_ACT = d.A_ACT + d.B_ACT
        d.AB_REM = d.A_REM + d.B_REM
        d.AB_PRO = (d.AB_ACT / d.AB_TOT) * 100
      })

      // Grids
      let chtH = this.colHeight - 60
      this.Grids = []
      for (let i = 0; i < 6; i++) {
        this.Grids.push(chtH - ((chtH / 5) * i))
      } 
    },




    set_Gradients() {
      let lightBlue = this.svg
      .append('defs')
      Defs.setDefs_Gradients(this.svg, '', 'LinearA4', 'LightGray', [ 1.3, .5, -.2, .5], this.localId)
      Defs.setDefs_Gradients(this.svg, 'ICON', 'LinearA4', 'LightGray', [ 0.5, 1.0, 0.5, -.5], this.localId)
      Defs.setDefs_Gradients(this.svg, '', 'LinearA4', 'LightBlue', [.5, 1, .5, -.5], this.localId)
    },
  }
}