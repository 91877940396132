export default {
  
  methods: {
    draw_ES_Radial() {

      let pieCenter = this.Area_Canvas
      .append('g')
      .attr('transform', `translate(${300}, ${this.pie.y})`)
      .attr('font-family', 'roboto')
      .style('cursor', 'default')

      let pieTitle = pieCenter
      .append('g')
      .attr('id', `pieTitle`)
      .attr('transform', `translate(0,0)`).attr('opacity', 1)

      pieTitle
      .append('text')
      .attr('x', 0).attr('y', 0)
      .attr('font-size', 20).attr('fill', '#bcbcbc').attr('font-weight', 450)
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'alphabetic')
      .text('Existing Site')

      pieTitle
      .append('text')
      .attr('x', 0).attr('y', 40)
      .attr('font-size', 36).attr('fill', '#757575')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'alphabetic')
      .text('TBD')


    },

    
  }
}