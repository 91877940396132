import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms() {
      if(this.stage_Header) this.stage_Header.remove()

      this.stage_Header = this.svg
      .append('g')
      .attr('id', 'common_header_area')
      .attr('transform', 'translate(0, 0)')

      this.loadSvg(
        this.stage_Header, {
        x   : 20,
        y   : 20,
        url : '/CRISP/LOGOs/logo_text.svg'
      })

      this.stage_Header
      .append('text')
      .attr('transform', `translate(20, 90)`)
      .attr('font-size', 14).attr('fill', '#44A9DF')
      .text(`Grassroots's Unit Summary for System Completion`)

      this.loadSvg(
        this.stage_Header, {
        x   : 15,
        y   : 100,
        url : `/CRISP/SYSTEM/UNIT_SUMMARY/Keyplan.svg`
      }).then(() => {
        this.rawData.filter(f=> f.SCOPE == 'Grassroots' && f.SCOPE !== 'NA').forEach(d=> {
          d3.select(`#KEY-${d.UNIT_NO}`).style('opacity', 0)
        })
      })

      this.stage_Header
      .append('text')
      .attr('transform', `translate(90, 290)`)
      .style('font-family', 'roboto').style('font-size', 12).style('fill', '#000')
      .text('KEYPLAN')

      this.draw_Summary(this.stage_Header, 850, 'MC', 0)
      this.draw_Summary(this.stage_Header, 1075, 'SC', 1)

      this.draw_BarCharts(this.stage_Header, 1300, 'WD', 0)
      this.draw_BarCharts(this.stage_Header, 1410, 'SSMCC', 1)
    },

  }
}