<template>
  <div>
      <v-dialog 
        attach="#viewer_3d_content" 
        v-model="opened" 
        :persistent="persistent" 
        :hide-overlay="!overlay" 
        :width="getDlWidth" 
      >
      <v-card>
        <v-card-title class="eqPopupWrapper">
          <div class="popup_header">
            <div class="popup_header_item1">EQUIPMENT OVERVIEW</div>
            <div class="popup_header_item2">DRAG TO MOVE</div>
          </div>
          
          <div v-ripple class="popup_close">
            <button class="button_close" title="Close" @click="onClose">
              <i class="mdi mdi-close"></i>
            </button>
          </div>
          <div class="popup_contents_wrap">
            
            <div class="popup_contents" v-if="senderHeavyLift">
              <div class="popup_contents_box">
              <fieldset>
                <legend>Tag Information</legend>
                <div class="popup_contents_box_info">
                  <div class="contents_wrap">
                    <div class="contents_title">TAG No.</div>
                    <div class="contents_title">Component No.</div>
                    <div class="contents_title">TAG Description</div>
                    <div class="contents_title">Type</div>
                    <div class="contents_title">Dimention</div>
                    <div class="contents_title">Manufacture</div>
                    <div class="contents_title">Design of Lifting Lug</div>
                    <div class="contents_title">Drawing</div>
                    <div class="contents_title">Weight Unit(ton)</div>
                    <div class="contents_title">Weight ATT(ton)</div>
                    <div class="contents_title">Weight Drawing(ton)</div>
                    <div class="contents_title">Main Crain</div>
                    <div class="contents_title">Tali Crain</div>
                    <div class="contents_title">Transport Company</div>
                    <div class="contents_title">Transportation</div>
                    <div style="height: 1.2rem;"></div>
                    <div class="contents_title"></div>
                    <div class="contents_title">PO</div>
                    <div class="contents_title">Delivery ETA</div>
                    <div class="contents_title">Lifting Plan</div>
                  </div>

                  <div class="contents_wrap">
                    <div class="contents_text tag">{{ d.TAG_NO }}</div>
                    <div class="contents_text">{{ d.TAG_NO_COMPONENT }}</div>
                    <div class="contents_text descr" :title="d.TAG_DESCR">{{ d.TAG_DESCR }}</div>
                    <div class="contents_text">{{ d.TAG_TYPE }}</div>
                    <div class="contents_text">{{ d.DIMENSION_M }}</div>
                    <div class="contents_text">{{ d.MANUFACTURE }}</div>
                    <div class="contents_text">{{ d.DESIGN_OF_LIFTING_LUG }}</div>
                    <div class="contents_text">{{ d.DRAWING }}</div>
                    <div class="contents_text">{{ d.WEIGHT_UNIT }}</div>
                    <div class="contents_text">{{ d.WEIGHT_ATT }}</div>
                    <div class="contents_text">{{ d.WEIGHT_DWG }}</div>
                    <div class="contents_text">{{ d.CRANE_MAIN }}</div>
                    <div class="contents_text">{{ d.CRANE_TAIL }}</div>
                    <div class="contents_text">{{ d.TRANSPORT_COMPANY }}</div>
                    <div class="contents_text">{{ d.TRANSPORT }}</div>

                    <div style="height: 1rem;"></div>

                    <div class="contents_date_wrap">
                      <div class="contents_date">
                        <div class="contents_date_text">Plan</div>
                        <div class="contents_date_text">Forecast</div>
                        <div class="contents_date_text">Actual</div>
                      </div>
                      <div class="contents_date">
                        <div class="contents_date_text">{{ d.PO_ISSUE_PLAN}}</div>
                        <div class="contents_date_text">{{ d.PO_ISSUE_FORECAST }}</div>
                        <div class="contents_date_text">{{ d.PO_ISSUE_ACTUAL }}</div>
                      </div>
                      <div class="contents_date">
                        <div class="contents_date_text">{{ d.DELIVERY_PLAN }}</div>
                        <div class="contents_date_text">{{ d.DELIVERY_FORECAST }}</div>
                        <div class="contents_date_text">{{ d.DELIVERY_ACTUAL }}</div>
                      </div>
                      <div class="contents_date">
                        <div class="contents_date_text">{{ d.LIFTING_PLAN }}</div>
                        <div class="contents_date_text">{{ d.LIFTING_FORECAST }}</div>
                        <div class="contents_date_text">{{ d.LIFTING_ACTUAL }}</div>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </fieldset>
              </div>
            </div>

            <div class="popup_contents" v-else>
              <div class="popup_contents_box">
                <fieldset>
                  <legend>Status</legend>
                  <div class="popup_contents_box_inner">
                    <div class="status_image">
                      <img :src="require('@/assets/img/time.png')" />
                    </div>
                    <div class="status_box">
                      <div class="status_box_item">
                        <div class="status_box_item_1">ROS - PO Issue</div>
                        <div class="status_box_item_2">{{ d.ROS_PO_DELTA }}</div>
                        <div class="status_box_item_3">days</div>
                      </div>
                      <div class="status_box_item">
                        <div class="status_box_item_1">ROS - Delivery</div>
                        <div class="status_box_item_2">{{ d.ROS_DELIVERY_DELTA }}</div>
                        <div class="status_box_item_3">days</div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div class="popup_contents_box">
              <fieldset>
                <legend>Tag Information</legend>
                <div class="popup_contents_box_info">
                  <div class="contents_wrap">
                    <div v-if="senderVendorMap">
                      <div class="contents_title">MR No.</div>
                      <div class="contents_title">TAG No.</div>
                      <div class="contents_title">Item Desc.</div>
                      <div class="contents_title">PO No.</div>
                    </div>
                    <div v-else>
                      <div class="contents_title">TAG No.</div>
                      <div class="contents_title">TAG Description</div>
                      <div class="contents_title">MR No.</div>
                      <div class="contents_title">MR Description</div>
                    </div>
                    <div style="height: 1.2rem;"></div>
                    <div class="contents_title"></div>
                    <div class="contents_title">MR</div>
                    <div class="contents_title">LOA</div>
                    <div class="contents_title">PO</div>
                    <div class="contents_title">Delivery</div>
                    <div class="contents_title">Installation</div>
                    <div class="contents_title">ROS</div>
                  </div>

                  <div class="contents_wrap">
                    <div v-if="senderVendorMap">
                      <div class="contents_text mr">{{ d.MR_NO == '' ? 'N/A' : d.MR_NO }}</div>
                      <div class="contents_text tag">{{ d.TAG_NO }}</div>
                      <div class="contents_text descr" :title="d.DESCRIPTION">{{ d.DESCRIPTION }}</div>
                      <div class="contents_text descr" :title="d.PO_NO">{{ d.PO_NO }}</div>
                    </div>
                    <div v-else>
                      <div class="contents_text tag">{{ d.TAG }}</div>
                      <div class="contents_text descr" :title="d.TAG_DESCR">{{ d.TAG_DESCR }}</div>
                      <div class="contents_text mr">{{ d.MR_NO == '' ? 'N/A' : d.MR_NO }}</div>
                      <div class="contents_text descr" :title="d.MR_DESCR">{{ d.MR_DESCR == '' ? 'N/A' : d.MR_DESCR }}</div>
                    </div>

                    <div style="height: 1rem;"></div>

                    <div class="contents_date_wrap">
                      <div class="contents_date">
                        <div class="contents_date_text">Plan</div>
                        <div class="contents_date_text">Forecast</div>
                        <div class="contents_date_text">Actual</div>
                      </div>
                      <div class="contents_date">
                        <div class="contents_date_text">{{ d.MR_PLAN }}</div>
                        <div class="contents_date_text">{{ d.MR_FORECAST }}</div>
                        <div class="contents_date_text">{{ d.MR_ACTUAL }}</div>
                      </div>
                      <div class="contents_date">
                        <div class="contents_date_text">{{ d.LOA_PLAN }}</div>
                        <div class="contents_date_text">{{ d.LOA_FORECAST }}</div>
                        <div class="contents_date_text">{{ d.LOA_ACTUAL }}</div>
                      </div>
                      <div class="contents_date">
                        <div class="contents_date_text">{{ d.PO_PLAN }}</div>
                        <div class="contents_date_text">{{ d.PO_FORECAST }}</div>
                        <div class="contents_date_text">{{ d.PO_ACTUAL }}</div>
                      </div>
                      <div class="contents_date">
                        <div class="contents_date_text">{{ d.DELIVERY_PLAN }}</div>
                        <div class="contents_date_text">{{ d.DELIVERY_FORECAST }}</div>
                        <div class="contents_date_text">{{ d.DELIVERY_ACTUAL }}</div>
                      </div>
                      <div class="contents_date">
                        <div class="contents_date_text">{{ d.INSTALL_PLAN }}</div>
                        <div class="contents_date_text">{{ d.INSTALL_FORECAST }}</div>
                        <div class="contents_date_text">{{ d.INSTALL_ACTUAL }}</div>
                      </div>
                      <div class="contents_date">
                        <div class="contents_date_text">{{ d.RAS_ROS }}</div>
                        <!-- <div class="contents_date_text">{{ d.RAS_ROS }}</div>
                        <div class="contents_date_text">{{ d.RAS_ROS }}</div> -->
                      </div>
                    </div>
                  </div>
                  
                </div>
              </fieldset>
              </div>
            </div>
            <div class="popup_preview">
              <fieldset :style="senderHeavyLift ? 'height: 384px !important;' : 'height: 345px !important;'">
                <legend>Object view</legend>
                <div id="preview_box" class="preview_box">
                </div>
              </fieldset>

              
              <div class="preview_info" v-if="!senderHeavyLift">
                <div class="preview_info_wrap">
                  <div class="preview_info_title">CWA Code</div>
                  <div class="preview_info_text" :title="cwaCode">{{ cwaCode }}</div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!senderHeavyLift" class="detailModalText" @click="requestAction">Procurement Analysis</div>
          <div v-if="!senderHeavyLift" class="detailModalText" @click="requestActionToDoc">Drawing Register</div>
          
        </v-card-title>
      </v-card>
    </v-dialog>

    
    <j-modal-slide-component 
      v-model="maOpened"
      :component="maComponent"
      :filters="maFilters"
      :target="maTarget"
    />
  </div>
  
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { CommunicationService, FetchStreamService, FileService } from "@/services"
import '@/assets/stylus/ui/component/_jEquipmentPopup.styl'

import __C from '@/primitives/_constant_'
import QueryLibService from '../services/database.support.service'

import __M from 'moment'

import * as d3 from 'd3'

export default {
  name: 'j-modal-equipment-popup',
  props: {
    params: {
      type: Object,
      default: () => ({
        open: false,
        queryid: 0,
        filters: {},
        data: {
          sender: '',
          persistent: false,
          overlay: true,
        },
        action: {}
      })
    },
    value: null,
  },
  data: () => ({
    queryLibService: null,
    d: {},
    src: '',
    recentComment: null,
    recentFile: null,
    eqImgPopupOpen: false,
    maOpened: false,
    maComponent: '',
    maFilters: {},
    maTarget: null,
    cwaCode: ''
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),
    ...mapState(__C.STORE_NAMESPACE.APP_SERVICE, [ 'commentOpened', 'popupImgOpened' ]),
    
    opened: {
      get() { return this.value },
      set(val) { this.$emit('input', val) },
    },
    poNo() { return this.params.filters.PO_NO },
    senderVendorMap() { return this.params.data && this.params.data.sender == 'Vendor Map' },
    senderHeavyLift() { return this.params.action && this.params.action.target == 'hleqpopup' },
    getDlWidth(){
      if (this.params.action && this.params.action.target == 'hleqpopup') return 730
      else return 710
    },
    persistent() { return this.params.data && this.params.data.persistent },
    overlay() { if(!this.params.data) return true; else return this.params.data.overlay; },
  },
  watch: {
    opened(val) {
      this.recentComment = null
      this.recentFile = null
      // console.log(val)//true로 확인
      if(!val) return
      this.getDataItems()
    },
    'params.filters': {
      handler(val) {
        this.getDataItems()
      },
      deep: true
    },  
  },
  created() {
    this.queryLibService = new QueryLibService()

    this.communicationService = new CommunicationService()
    this.fetchStreamService = new FetchStreamService()
    this.fileService = new FileService()
  },
  mounted() {
    // d3.select('#jOverlayImgaWrapper').on('click', this.onImgPopupClose)
    // d3.select('#eq_photo_select').on('click', this.onImgPopupClose)

    // d3.select('.v-overlay').on('click', this.clickTest)
    // d3.select('.v-dialog__content--active').on('click', this.clickTest2)
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [ 'setCommentOpened', 'setCommentTitle', 'setImgPopupOpened' ]),
    openComment() {
      this.setCommentTitle(this.params.filters.TAG)
      this.setCommentOpened(true)
      // this.onClose()
    },
    onClose() {
      this.opened = false
    },
    onImgPopupClose() {
      this.eqImgPopupOpen = false
    },
    onImgPopupOpen() {
      let data = {
        token : this.account.token,
        path: this.recentFile.path
      }
      let target = 'eq_photo_select'
      this.fetchStreamService.selectPhotoFile(data)
      .then(response => response.blob())
      .then(blob => {
        let blobURL = URL.createObjectURL(blob)
        const img = document.getElementById(`${target}`)
        img.src = blobURL

        setTimeout(() => { this.setViewDimention() } ,150)
      })


      this.eqImgPopupOpen = true
      // this.setImgPopupOpened({
      //   open: true,
      //   imgPath: this.recentFile.path,
      //   dimention: this.recentFile.dimention
      // })
    },
    requestAction(){
      if (!this.d.MR_NO) return

      // window.document.documentElement.querySelector('.v-overlay').style.zIndex = 0;
      // window.document.documentElement.querySelector('.v-dialog__content v-dialog__content--active').style.zIndex = 0;


      this.maComponent = 'ServiceDashboard'
      this.maTarget = {
        code: 'Modal',
        type: '',
        id: 152,
        no: '',
      }
      
      this.maFilters = {
        filters: { MR_NO: this.d.MR_NO },
      }

      
      this.maOpened = true

      setTimeout(() => {
        this.onClose()  
      }, 500)
      


    },
    requestActionToDoc(){

      if (!this.d.TAG) return

      this.maComponent = 'ServiceDatatable'
      this.maTarget = {
        code: 'Modal',
        type: '',
        id: 385,
        no: '',
      }
      
      this.maFilters = {
        filters: { TAG_NO: this.d.TAG },
      }
      
      this.maOpened = true
      
      setTimeout(() => {
        this.onClose()  
      }, 500)

    },
    setViewDimention() {
      
      let photoViewHeight = 870
      let dimention = this.recentFile.dimention.split(',')

      if(!dimention || dimention.length === 0) return 
      
      let w_ = Number(dimention[0])
      let h_ = Number(dimention[1])
      let inW_ = 0
      let inH_ = 0

      if(h_ <= photoViewHeight) {
        inW_ = w_
        inH_ = h_
      } else {
        let ratio_ = photoViewHeight / h_
        inW_ = Math.round(w_ * ratio_)
        inH_ = photoViewHeight
      }
      d3.select('.photo_viewer')
      .transition()
      .duration(750)
      .style('width', `${inW_}px`)
      .style('height', `${inH_}px`)

      d3.select('.photo').transition().duration(500).style('opacity', 1)
    },
    getDataItems() {
      let filters_ = this.params.filters || {}

      if (this.senderHeavyLift) {
        this.queryLibService.getSqlQueryResult({ 
          idx: 0, 
          name: 'Equipment Popup Heavy LIftt Skyline',
          filters: `TAG_NO = '${filters_.TAG_NO}'`
        }).then(result => {
          this.d = result ? result[0] : {}

          this.src = `${__C.HOST_NAME}/#/pub_3d_viewer/token/${this.d.TAG_NO}/{"d":"eq","w":330,"h":360}`

          let prevBox = document.getElementById('preview_box')
          prevBox.innerHTML = ''

          let iframe = document.createElement('iframe')
          iframe.style.width = '328px'
          iframe.style.height = '360px'
          iframe.style.border = 'none'
          iframe.frameborder = 0
          iframe.scrolling = 'no'
          iframe.src = this.src

          prevBox.appendChild(iframe)  
        })
      }else {
        // console.log(filters_, this.params) ---EQ Popup queryid 6인지9인지 구별해야해!

        //ID이거나 Name이거나 ...    !!Room Popup 때문에 filters에 LV4 넣었음!! => For Tortue, Not China1
        this.queryLibService.getSqlQueryResult({ 
          idx: this.params.queryid, 
          name: this.senderVendorMap ? 'China1 Vendor Map PO Items' : 'Equipment Popup',
          filters: this.senderVendorMap ? `TAG_NO = '${filters_.TAG}'` : `TAG = '${filters_.TAG}'`
        }).then(result => {
          this.d = result ? result[0] : {}
          this.src = `${__C.HOST_NAME}/#/pub_3d_viewer/token/${this.d.TAG}/{"d":"eq","w":330,"h":320}`
          // console.log(this.src)

          let prevBox = document.getElementById('preview_box')
          prevBox.innerHTML = ''

          let iframe = document.createElement('iframe')
          iframe.style.width = '328px'
          iframe.style.height = '298px'
          iframe.style.border = 'none'
          iframe.frameborder = 0
          iframe.scrolling = 'no'
          iframe.src = this.src

          prevBox.appendChild(iframe)
          
        })


        
        this.queryLibService.getSqlQueryResult({ 
          idx: this.params.queryid, 
          name: 'Equipment Popup CWA Code',
          filters: `TAG_NO = '${filters_.TAG}'`
        }).then(result => {
          let res = result.map(d => d.CWA_CODE)
          this.cwaCode = res.join(', ')
          
        })

      }

      
    },
    getPhotoFile() {
      let data = {
        token : this.account.token,
        path: this.recentFile.path
      }
      this.fetchStreamService.selectPhotoFile(data)
      .then(response => response.blob())
      .then(blob => {
        let blobURL = URL.createObjectURL(blob)
        const img = document.getElementById(`epuipment_photo`)
        img.src = blobURL
      })
    },
  }
}


</script>
