import * as d3 from 'd3'
export default {
  methods: {

    // Resource Curves 
    assign_RecourceCurve(currResource, duration, qty){

      let Values = []
      switch (currResource) {
        case 'LINEAR':
          Values = this.get_Linear_Distribution(duration, qty)
        break
        case 'LEFT':
          Values = this.get_Left_Distribution(duration, qty)
        break
        case 'CENTER':
          Values = this.get_Center_Distribution(duration, qty)
        break
        case 'RIGHT':
          Values = this.get_Right_Distribution(duration, qty)
        break
      } 
      return Values
    },


    // Linear Distribution Curve 
    get_Linear_Distribution(period_, volume_){
      let planValue = []
      let result = 0
      for(let i=0; i<period_; i++) {
        planValue[i] = volume_ / period_
        result += planValue[i]
      }
      if(volume_==0) planValue = [0]
      if(period_ == 1) planValue = [volume_]
      // console.log(period_, planValue)
      return planValue
    },


    // Center Distribution Curve 
    get_Center_Distribution(period_, volume_){
      let triValue = []
      let triRatio = []
      let planValue = []
      let duration = period_ + 1
      let triTotal = 0

      for(let i=0; i<duration; i++) {
        triValue[i] = ((10)/2)*Math.sin((2*Math.PI/duration)*((i+1)-(duration/4)))+((10)/2)
        triTotal += triValue[i]
      }

      let result = 0
      for(let i=0; i<duration - 1; i++) {
        triRatio[i] = triValue[i] / triTotal
        planValue[i] = volume_ * triRatio[i]
        result += planValue[i]
      }
      if(volume_==0) planValue = [0]
      if(period_ == 1) planValue = [volume_]
      // console.log(period_, result)
      return planValue
    },
 

    // Right Distribution Curve 
    get_Right_Distribution(period_, volume_) {
      if(period_ == 0) return
      let calInteg = 0
      let planValue = []
      let xScale = d3.scaleLinear()
      .domain([0, period_]).range([0, 1]);

      function integrand(x) { return Math.pow(x, 4) * (1 - x) }
      for(let x = 0; x < period_; x++) { calInteg += integrand(xScale(x)) * (1 / period_) }
      function integrand2(x) { return (Math.pow(x, 4) * (1 - x)) / calInteg }

      let result = 0
      for(let x = 0; x < period_; x++) {
        planValue[x] = (integrand2(xScale(x)) / period_) * volume_
        result += (integrand2(xScale(x)) / period_) * volume_
      }
      if(volume_==0) planValue = [0]
      if(period_ == 1) planValue = [volume_]
      // console.log(period_, planValue)
      return planValue
    },


    // Left Distribution Curve 
    get_Left_Distribution(period_, volume_) {
      if(period_ == 0) return
      let calInteg = 0
      let planValue = []
      let xScale = d3.scaleLinear()
      .domain([0, period_]).range([0, 1]);

      function integrand(x) { return x * Math.pow((1 - x), 4) }
      for(let x = 0; x < period_; x++) { calInteg = calInteg + integrand(xScale(x)) * (1 / period_) }
      function integrand2(x) { return (x * Math.pow((1 - x), 4)) / calInteg }

      let result = 0
      for(let x = 0; x < period_; x++) {
        planValue[x] = (integrand2(xScale(x))*(1/period_)) * volume_
        result += (integrand2(xScale(x))*(1/period_)) * volume_
        // console.log(x, '/', planValue[x])
      }

      if(volume_== 0) planValue = [0]
      if(period_ == 1) planValue = [volume_]

      // console.log(period_, planValue)
      return planValue
    },

    
  }
}