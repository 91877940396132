import moment from 'moment'
import * as d3 from 'd3'

export default {
  methods: {

    draw_Skyline(selection, unit, x_, y_, idx_) {

      let skyline = selection
      .append('g')
      .attr('transform', `translate(${x_}, ${y_})`)
      .attr('font-family', 'Segoe UI').attr('font-size', 9)

      let x = 0
      this.timeline.week.forEach(w => {

        let y = -8
        let dateFiltered = this.Systems.filter(f => f.UNIT == unit.UNIT && f.cDate == w.eDate)

        dateFiltered.forEach(d => {
          let system = skyline
          .append('g')
          .attr('id', `SSYS_${d.SUBSYS_NO}`)
          .attr('class', `${x}_${y}`)
          .attr('transform', `translate(${x}, ${y})`)
          .style('cursor', 'pointer')
          .on('mouseover', function() {
            let className = d3.select(this).attr('class').split('_').map(Number)
            let gx = className[0] + 100 + 7
            let gy = className[1] + 250 + y_ - 120
            d3.select(`#Ballon_Group`).attr('transform', `translate(${gx}, ${gy})`).attr('opacity', 1)
            d3.select(this).transition().duration(150).attr('opacity', .5)
            d3.select(`#Ballon_Text`).text(d.SUBSYS_NO)
          })
          .on('mouseout', function() {
            d3.select(`#Ballon_Group`).attr('opacity', 0)
            d3.select(this).transition().duration(150).attr('opacity', 1)
          })
          .on('click', ()=> {
            this.redraw_Charts(unit, idx_, d.SUBSYS_NO) // ---> Chart redraw
          })

          system
          .append('rect')
          .attr('x', 0).attr('y', 0).attr('height', 9).attr('width', 14)
          .attr('stroke-width', 1).attr('stroke-opacity', .5)
          .attr('stroke', d.CSTD_WALKDOWN && d.CSTD_ACTUAL == undefined ? '#000' : '#bcbcbc')
          .attr('fill', d.CSTD_ACTUAL ? '#4CAE4E' : '#FFDA00')
          .attr('fill-opacity', d.CSTD_ACTUAL ? 1 : d.AB_PRO/100)

          y -= 11
        })

        if(dateFiltered.length > 0) x += 17
      })

      // Days to Turnover
      skyline
      .append('text')
      .attr('x', x-2).attr('y', 10)
      .attr('font-size', 9).attr('fill', '#757575').attr('text-anchor', 'end')
      .text(`Days to Turnover`)

      let days = moment(unit.MAX_PLAN).diff(moment(new Date()),'days')

      skyline
      .append('text')
      .attr('x', x-2).attr('y', 22)
      .attr('font-size', 10.5).attr('font-weight', 500)
      .attr('fill', days > 0 ? '#44A9DF' : '#EC407A').attr('text-anchor', 'end')
      .text(`${days} Days`)
    },

    

  }
}