import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms() {
      this.formSelection = this.svg

      .append('text')
      .attr('transform', `translate(30, 40)`)
      .style('font-size', 20).style('fill', 'skyblue')
      .text('Icicle Chart for Milestones (Waypoint & CSU)')

      this.sOptions = this.svg
      .append('g')
      .attr('id', 'OPTION')
      .attr('transform', `translate(${this.options.x}, ${this.options.y})`)
      .style('font-family', 'roboto')

      this.option_Milestone()
      this.option_Schedule()
    },


    option_Milestone() {
      let opt_Milestone = this.sOptions
      .append('g')
      .attr('id', `MS_Item_Group`)
      .attr('transform', `translate(0, 0)`)
      .style('font-family', 'roboto')

      let x_ = 0
      this.options.milestone.code.forEach((d,i) => {
        
        let msitem = opt_Milestone
        .append('g')
        .attr('id', `MS_Item_${d}`)
        .attr('transform', `translate(${x_}, 10)`)
        .style('opacity', this.options.milestone.cStatus == d ? 1 : 0.8)
        .style('cursor', 'pointer')
        .on('mouseover', () => { 
          d3.select(`#MS_Item_${d}`).style('opacity', 0.5) 
        })
        .on('mouseout', () => { 
          d3.select(`#MS_Item_${d}`).style('opacity', d == d ? 1 : 0.8) 
        })
        .on('click', ()=> {
          d3.select(`#MS_Item_${this.options.milestone.cStatus}`).style('opacity', 0.8) 
          d3.select(`#MS_Item_text_${this.options.milestone.cStatus}`).attr('font-weight', 400).attr('fill', '#757575')
          d3.select(`#MS_Item_in_${this.options.milestone.cStatus}`).attr('fill', '#fff')
          d3.select(`#OMS_Item_Out_${this.options.milestone.cStatus}`).attr('stroke', '#bcbcbc')

          this.options.milestone.cStatus = d
          d3.select(`#MS_Item${d}`).style('opacity', 1) 
          d3.select(`MS_Item_text_${d}`).attr('font-weight', 600).attr('fill', '#000')
          d3.select(`#MS_Item_in_${d}`).attr('fill', '#83D2F5')
          d3.select(`#MS_Item_Out_${this.options.milestone.cStatus}`).attr('stroke', '#83D2F5')

          this.icicle_Controller('milestone') // ---> Func_Controller.js
          //for pdf
          this.set_selectedItems()
        })
        msitem
        .append('circle')
        .attr('id', `MS_Item_Out_${d}`)
        .attr('transform', `translate(0,-.5)`)
        .attr('r', 5).attr('stroke', this.options.milestone.cStatus == d ? '#83D2F5' : '#bcbcbc')
        .attr('stroke-width', 1).attr('fill', '#fff')

        msitem
        .append('circle')
        .attr('id', `MS_Item_in_${d}`)
        .attr('transform', `translate(0,-.5)`)
        .attr('r', 3).style('stroke', '#333').attr('stroke-width', 0)
        .attr('fill', this.options.milestone.cStatus == d ? '#83D2F5' : '#fff')

        msitem
        .append('text')
        .attr('id', `MS_Item_text_${d}`)
        .attr('transform', `translate(9, 0)`)
        .style('font-size', 10).style('fill', '#333')
        .attr('font-weight', this.options.milestone.cStatus == d ? 500 : 'normal')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(d)

        x_ += this.getNodeElValue(`#MS_Item_${d}`, 'width') + 15
      })
    },

    option_Schedule(){
      let sSchedule = this.sOptions
      .append('g')
      .attr('id', `SCHEDULE_OPTION`)
      .attr('transform', `translate(0,20)`)
      .style('font-family', 'roboto')

      let x_ = 0
      this.options.schedule.code.forEach((d,i) => {
        
        let schedule = sSchedule
        .append('g')
        .attr('id', `SCHEDULE_${d}`)
        .attr('transform', `translate(${x_}, 10)`)
        .style('opacity', this.options.schedule.cStatus == d ? 1 : 0.8)
        .style('cursor', 'pointer')
        .on('mouseover', () => { 
          d3.select(`#SCHEDULE_${d}`).style('opacity', 0.5) 
        })
        .on('mouseout', () => { 
          d3.select(`#SCHEDULE_${d}`).style('opacity', d == d ? 1 : 0.8) 
        })
        .on('click', ()=> {
          d3.select(`#SCHEDULE_${this.options.schedule.cStatus}`).attr('opacity', 0.8) 
          d3.select(`#SCHEDULE_text_${this.options.schedule.cStatus}`).attr('font-weight', 400).attr('fill', '#757575')
          d3.select(`#SCHEDULE_in_${this.options.schedule.cStatus}`).attr('fill', '#fff')
          d3.select(`#SCHEDULE_out_${this.options.schedule.cStatus}`).attr('stroke', '#bcbcbc')

          this.options.schedule.cStatus = d
          d3.select(`#SCHEDULE_${d}`).attr('opacity', 1) 
          d3.select(`#SCHEDULE_text_${d}`).attr('font-weight', 600).attr('fill', '#000')
          d3.select(`#SCHEDULE_in_${d}`).attr('fill', '#83D2F5')
          d3.select(`#SCHEDULE_out_${d}`).attr('stroke', '#83D2F5')

          this.icicle_Controller('schedule') // ---> Func_Controller.js
          //for pdf
          this.set_selectedItems()
        })

        schedule
        .append('circle')
        .attr('id', `SCHEDULE_out_${d}`)
        .attr('transform', `translate(0, -.5)`)
        .attr('r', 3.5).attr('stroke-width', .7)
        .attr('stroke', this.options.schedule.cStatus == d ? '#83D2F5' : '#bcbcbc')
        .attr('fill', 'none')

        schedule
        .append('circle')
        .attr('id', `SCHEDULE_in_${d}`)
        .attr('transform', `translate(0, -.5)`)
        .attr('r', 2).style('stroke', '#333').attr('stroke-width', 0)
        .attr('fill', this.options.schedule.cStatus == d ? '#83D2F5' : '#fff')
  
        schedule
        .append('text')
        .attr('id', `SCHEDULE_text_${d}`)
        .attr('transform', (d == 'NONE') ? `translate(10, 0)`: `translate(6, 0)`)
        .attr('font-size', 10)
        .attr('font-weight', this.options.schedule.cStatus == d ? 500 : 'normal')
        .attr('fill', '#333')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(this.options.schedule.cStatus == d ? this.options.schedule.name[i] : `${this.options.schedule.name[i]}(${this.options.schedule.count[i]})`)

        x_ += this.getNodeElValue(`#SCHEDULE_text_${d}`, 'width') + 30
      })
    }

  }
}