import * as d3 from 'd3'

export default {
  methods: {

    draw_Skyline(selection, unit, x_, y_, idx_) {

      let this_ = this
      let gY = y_
      let skyline = selection
      .append('g')
      .attr('transform', `translate(${x_ + 50}, ${y_})`)
      .attr('font-family', 'Segoe UI').attr('font-size', 9)

      let x = 0, count = 0
      this.timeline.week.forEach((w, wi) => {

        let y = -8
        let dateFiltered = this.Systems.filter(f => f.UNIT == unit.UNIT && f.cDate == w.eDate)

        dateFiltered.forEach(d => {
          let system = skyline
          .append('g')
          .attr('id', `SSYS_${d.SUBSYS_NO}`)
          .attr('class', `${x}_${y}`)
          .attr('transform', `translate(${x}, ${y})`)
          .style('cursor', 'pointer')
          .on('mouseover', function() {
            d3.select('#Ballon_Border').attr('stroke', this_.color21[idx_])
            let className = d3.select(this).attr('class').split('_').map(Number)
            let x_ = className[0] + 80 + 20
            let y_ = className[1] + 230 + 2 + gY - 150
            d3.select(`#Ballon_Group`).moveToFront().attr('transform', `translate(${x_}, ${y_})`).attr('opacity', 1)
            d3.select(this).transition().duration(150).attr('opacity', .5)
            d3.select(`#Ballon_Text`).text(d.SUBSYS_NO)
          })
          .on('mouseout', function() {
            d3.select(`#Ballon_Group`).attr('opacity', 0)
            d3.select(this).transition().duration(150).attr('opacity', 1)
          })
          .on('click', ()=> {
            this.call_Subsystem(d.UNIT, d.SUBSYS_NO) // --> Event.js
          })
          system
          .append('rect')
          .attr('x', 0).attr('y', 0)
          .attr('height', 9).attr('width', 14)
          .attr('stroke', '#bcbcbc').attr('stroke-width', 1).attr('stroke-opacity', .5)
          .attr('fill', d.CSTD_ACTUAL ? '#4CAE4E' : '#FFDA00')
          .attr('fill-opacity', d.AB_PRO/100)

          y -= 11
        })

        if(dateFiltered.length > 0) {
          x += 17
          count += 1
        }
      })
      
      

  
    },



    
    
  }
}