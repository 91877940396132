export default {
  
  methods: {

    // BAR CHART 
    draw_BarCharts(selection, gX, item, idx) {
      let data = this.grassroots
      let s = {
        top: 45,
        barH: 120,
        barW: 40,
        pLeft: 15,
        pRight: 15,
      }

      let SystemChart = selection
      .append('g')
      .attr('transform', `translate(${gX}, 30)`)
      .style('font-family', 'roboto')
      .attr('cursor', 'default')   

      SystemChart
      .append('rect')
      .attr('x', 0).attr('y', 0)
      .attr('rx', 5).attr('ry', 5)
      .attr('fill', this.turnover.bgColor[idx]).attr('opacity', .6)
      .attr('width', 100).attr('height', 170)

      // BAR CHART - TITLE
      SystemChart 
      .append('text')
      .attr('x', 10).attr('y', 17)
      .attr('fill', '#000').attr('text-anchor', 'start')
      .attr('font-size', 11).text(this.turnover.title[idx])

      // BAR CHART - Background
      SystemChart
      .append('rect')
      .attr('x', s.pLeft).attr('y', s.top)
      .attr('rx', 3).attr('ry', 3)
      .attr('fill', this.turnover.bgColor[idx]).attr('opacity', .9)
      .attr('width', s.barW).attr('height',s.barH)

       // BAR CHART - Actual bar chart
      let pro = data[`${item}_PRO`]
      let proLev =  s.top + s.barH - (s.barH*(pro/100))
      SystemChart
      .append('rect')
      .attr('x', s.pLeft).attr('y', proLev)
      .attr('rx', 3).attr('ry', 3)
      .attr('fill', `url(#${this.localId}_${this.turnover.bColor[idx]}_V)`)
      .attr('width', s.barW).attr('height', s.barH*(pro/100))

      // Value - Progress
      SystemChart 
      .append('text')
      .attr('x', s.pLeft + s.barW/2)
      .attr('y', pro > 20 ? proLev + 3 : proLev - 3)
      .attr('font-size', 9).attr('text-anchor', 'middle')
      .attr('fill', '#fff')
      .attr('alignment-baseline', pro > 20 ? 'hanging' :'alphabetic') // alphabetic
      .text(pro.toFixed(1) + '%')
      
      // Value - Total 
      SystemChart 
      .append('text')
      .attr('x', s.pLeft + s.barW/2).attr('y', s.top - 2)
      .attr('font-size', 9).attr('text-anchor', 'middle')
      .attr('fill', '#757575')
      .attr('alignment-baseline', 'alphabetic') // alphabetic
      .text(data[`${item}_TOT`])
      .attr('cursor', 'pointer')   
      .on('click', () => { this.link_Action({scope:'GR', code:item, coltype:'TOT'}) })
      
      // Value - Actual
      SystemChart
      .append('text')
      .attr('x', s.pLeft + s.barW + s.pRight).attr('y', s.top + s.barH - (pro/2))
      .attr('font-size', 9).attr('fill', '#757575').attr('alignment-baseline', 'middle')
      .text(data[`${item}_ACT`])
      .attr('cursor', 'pointer')   
      .on('click', () => { this.link_Action({scope:'GR', code:item, coltype:'ACT'}) })

       // Value - Remain
      SystemChart
      .append('text')
      .attr('x', s.pLeft + s.barW + s.pRight).attr('y', s.top + (s.barH-pro)/2)
      .attr('font-size', 9).attr('fill', '#757575').attr('alignment-baseline', 'middle')
      .text(data[`${item}_REM`])
      .attr('cursor', 'pointer')   
      .on('click', () => { this.link_Action({scope:'GR', code:item, coltype:'REM'}) })

      
       // LINE ------------------------------
      SystemChart
      .append('line')
      .attr('x1', s.pLeft + s.barW + 5.5).attr('y1', s.top +.5).attr('x2', s.pLeft + s.barW + 5.5).attr('y2', s.top + .5 + s.barH)
      .attr('stroke', '#bcbcbc').attr('stroke-width', .4)

      SystemChart
      .append('line')
      .attr('x1', s.pLeft + s.barW + 2).attr('y1', s.top-.5).attr('x2', s.pLeft + s.barW + 12).attr('y2', s.top-.5)
      .attr('stroke', '#bcbcbc').attr('stroke-width', .4)

      SystemChart
      .append('line')
      .attr('x1', s.pLeft + s.barW + 2).attr('y1', s.top + s.barH - pro).attr('x2', s.pLeft + s.barW + 12).attr('y2', s.top + s.barH - pro)
      .attr('stroke', '#bcbcbc').attr('stroke-width', .4)

      SystemChart
      .append('line')
      .attr('x1', s.pLeft + s.barW + 2).attr('y1', s.top + s.barH).attr('x2', s.pLeft + s.barW + 12).attr('y2', s.top + s.barH)
      .attr('stroke', '#bcbcbc').attr('stroke-width', .4)
    },

    
  }
}