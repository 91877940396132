import Defs from '../../../../../../includes/primitives/colorDefs'
import * as d3 from 'd3'

export default {
  data: () => ({
    Area_Canvas:    null,
    Area_Chart:     null,
    
    // SQL Data Set
    rawData:        null,
    Overall:        null,
    DiscType:     null,
    cutOff:         null,

    canvas:         null,
    timeline:       null,
    grid:           null,
    barWidth:       null,
    scale:          {primary: null, secondary: null},

    filterStatus:   {},

    barColor: {
      OV:     '#F7BACF',
      DISC:   '#83D2F5',
      ITR:    '#FFDA00',
    },

    //pdf
    selectedItems : null,
    getItems      : null,
  }),


  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },


  methods: {

    setDefaultValues(){

      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      this.Overall = JSON.parse(JSON.stringify(this.Queries.SQL1[0]))
      this.DiscType = JSON.parse(JSON.stringify(this.Queries.SQL2))
      let sql3 = JSON.parse(JSON.stringify(this.Queries.SQL3[0]))

      this.DiscType.forEach((d, i) => {
        
        d.idx = i
        d.checked = (d.ITEM=='OV') ? true : false
        if (d.ITEM=='DISC') {
          d.max_Wk = this.getMaxBound_v2(d3.max(this.rawData.filter(f=> f.ITEM == d.ITEM && f.DISC == d.DISC), c => c.CLOSED_WK > c.PL_CLOSED_WK ? c.CLOSED_WK : c.PL_CLOSED_WK))
          d.max_Cumul = this.getMaxBound_v2(this.rawData.find(f=> f.ITR_TYPE == 'OV' && f.DISC == d.DISC).TOTAL)
        } else if (d.ITEM=='ITR') {
          d.max_Wk = this.getMaxBound_v2(d3.max(this.rawData.filter(f=> f.ITEM == d.ITEM && f.ITR_TYPE == d.ITR_TYPE), c => c.CLOSED_WK > c.PL_CLOSED_WK ? c.CLOSED_WK : c.PL_CLOSED_WK))
          d.max_Cumul = this.getMaxBound_v2(this.rawData.find(f=> f.ITR_TYPE == d.ITR_TYPE && f.DISC == 'OV').TOTAL)
        } else if (d.ITEM=='OV') {
          d.max_Wk = this.getMaxBound_v2(d3.max(this.rawData.filter(f=> f.ITEM == d.ITEM), c => c.CLOSED_WK > c.PL_CLOSED_WK ? c.CLOSED_WK : c.PL_CLOSED_WK))
          d.max_Cumul = this.getMaxBound_v2(this.rawData.find(f=> f.ITR_TYPE == 'OV' && f.DISC == 'OV').TOTAL)
        }
          
      })
      // console.log(this.DiscType)
      
      // Cnavas 
      this.canvas = {
        width           : sql3['CANVAS_WIDTH'],
        height          : sql3['CANVAS_HEIGHT'],
        
        padding : {
          top           : sql3['PADDING_TOP'],
          right         : sql3['PADDING_RIGHT'],
          bottom        : sql3['PADDING_BOTTOM'],
          left          : sql3['PADDING_LEFT'],
        },
        chart : {
          width         : sql3['CANVAS_WIDTH'] - sql3['PADDING_LEFT'] - sql3['PADDING_RIGHT'],
          height        : sql3['CANVAS_HEIGHT'] - sql3['PADDING_TOP'] - sql3['PADDING_BOTTOM'],
        },
      }

      // Timeline 
      this.timeline = {
        length          : this.canvas.chart.width,
        startDate       : sql3['START_DATE'],
        endDate         : sql3['END_DATE'],
        weekEnd         : sql3['WEEKEND'],
        day             : [],
        week            : [],
        month           : [],
        year            : [],
        scale           : null,

        cutoff          : sql3['CUTOFF'],
      }

      // Grids
      this.grid = []
      for (let i = 0; i < 6; i++) {
        this.grid.push(this.canvas.chart.height - ((this.canvas.chart.height / 5) * i))
      } 
      
      this.init_gmx_TimelineValues(this.timeline, 'day')
      this.barWidth = this.canvas.chart.width / this.timeline.week.length

      this.filterStatus = this.DiscType.find(f=> f.ITEM == 'OV')
    },


    set_Gradients() {
      // set Gradient color for the Timeline
      Defs.setDefs_Gradients(this.svg, 'V', 'LinearA4', 'LightBlue', [.8, .5, .0, .5], this.localId)
      Defs.setDefs_Gradients(this.svg, 'V', 'LinearA4', 'Yellow', [.8, .5, .0, .5], this.localId)
      Defs.setDefs_Gradients(this.svg, 'V', 'LinearA4', 'YellowGreen', [.8, .5, .0, .5], this.localId)
    },

    set_selectedItems(){
      this.selectedItems = { 
        ...this.selectedItems,
        filterStatus: this.filterStatus,
      }
    },    
  }
}