import icon from '../../../../../../includes/primitives/icons'
import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms() {

      let HeaderGroup = this.svg
      .append('g')
      .attr('id', `CANVAS_${this.localId}`)
      .attr('transform', `translate(0,0)`)
      .style('font-family', 'roboto')
      
      // stg_Scope
      // .append('line')
      // .attr('x1', 790).attr('y1', 0)
      // .attr('x2', 790).attr('y2', 500)
      // .attr('stroke', 'red')

      // stg_Scope
      // .append('line')
      // .attr('x1', 790).attr('y1', 0)
      // .attr('x2', 790).attr('y2', 500)
      // .attr('stroke', 'red')

      this.draw_Overall(HeaderGroup)
      
      this.draw_BarCharts(HeaderGroup, this.header.x[1], 'A', 0)
      this.draw_BarCharts(HeaderGroup, this.header.x[2], 'B', 1)
      this.draw_BarCharts(HeaderGroup, this.header.x[3], 'WD', 2)
      this.draw_BarCharts(HeaderGroup, this.header.x[4], 'SSMCC', 3)

      this.draw_Summary(HeaderGroup, this.header.x[5], 'MC', 0)
      this.draw_Summary(HeaderGroup, this.header.x[6], 'SC', 1)
    },


    
    

   

  }
}