import * as d3 from 'd3'
export default {
  
  methods: {
    draw_Weeks() {
      let Weeks = this.Area_Canvas
      .append('g')
      .attr('transform', `translate(470.5, 600.5)`)
      .style('font-family', 'roboto').attr('cursor', 'default')

      this.draw_WeekPerformance(Weeks, {
        x: 25.5,
        y: 150.5,
        weekGap: 3,
        width: [40, 30],
        tColor: '#44A9DF',
        sColor: '#44A9DF',
        scope:  'Existing Site',
        data: this.Weeks.filter(f=> f.SCOPE == 'Existing Site' && f.ITEM == this.selectedTrend)
      })

      this.draw_WeekPerformance(Weeks, {
        x: 25.5,
        y: 345.5,
        weekGap: 3,
        width: [40, 30],
        tColor: '#FFDA00',
        sColor: '#FAAA18',
        scope:  'Grassroots',
        data: this.Weeks.filter(f=> f.SCOPE == 'Grassroots' && f.ITEM == this.selectedTrend)
      })
    },


    draw_WeekPerformance(selection, s) {

      s.data.forEach(d=> {
        // d.date = d.DATE
        if(d.WEEK =='LAST' || d.WEEK =='THIS') {
          d.name = 'Total'
          d.width = s.width[0]
          d.tColor = '#333'
        } else {
          d.name = d.WEEK
          d.width = s.width[1]
          d.tColor = '#bcbcbc'
        }
        d.value = d.VALUE
      })
      
      let weekStatus = selection
      .append('g')
      .attr('transform', `translate(${s.x}, ${s.y + .5})`)

      weekStatus
      .append('text')
      .attr('x', 0).attr('y', 8)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('LAST WEEK')

      weekStatus
      .append('text')
      .attr('x', 160).attr('y', 8)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', s.tColor)
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('THIS WEEK')

      weekStatus
      .append('line')
      .attr('x1', 35).attr('y1', 8).attr('x2', 130).attr('y2', 8).style('stroke', '#757575').style('stroke-width', 0.3)
      weekStatus
      .append('line')
      .attr('x1', 190).attr('y1', 8).attr('x2', 300).attr('y2', 8).style('stroke', '#757575').style('stroke-width', 0.3)

      
      let drawX = 0
      s.data.forEach((d,i)=> {
        d.x = drawX
        let type = (i ==0 || i == 8) ? 'week' : 'day'
        weekStatus
        .append('text')
        .attr('x', drawX).attr('y', 22)
        .style('font-family', 'roboto').style('font-size', 9).style('fill', d.tColor).attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(d.name)

        weekStatus
        .append('rect')
        .attr('id', `week_BOX_${s.scope.substr(0,1)}_${i}`)
        .attr('transform', `translate(${drawX - (d.width/2)},30)`)
        .attr('width', d.width).attr('height', 14).attr('stroke-width', .3)
        .attr('stroke', () => { if (d.name == 'Total') return s.sColor; else return '#bcbcbc' })
        .attr('fill', () => { if(d.name != 'Total' && d.value > 0) return '#F4F5F5'; else return '#fff' })

        weekStatus
        .append('text')
        .attr('id', `week_TXT_${s.scope.substr(0,1)}_${i}`)
        .attr('transform', `translate(${drawX}, 38)`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text((d.value > 0) ? d.value : '')
        .attr('cursor', 'pointer')
        .call(this.call_WeekPerformance, {scope: d.SCOPE, item: d.ITEM, date: d.DATE, type: type})


        drawX += d.width + s.weekGap
        if(i==7) drawX += 10
      })

    },


    redraw_WeekPerformance(scope, item) {

      let weekData = this.Weeks.filter(f=> f.SCOPE == scope && f.ITEM == item)
      weekData.forEach((d,i)=> {
        d3.select(`#week_BOX_${scope.substr(0,1)}_${i}`).transition().duration(500)
        .attr('fill', d.VALUE > 0 && (i>0 && i<weekData.length-1) ? '#F4F5F5' : '#fff')

        let from_ = d3.select(`#week_TXT_${scope.substr(0,1)}_${i}`).text()
        if(from_ == '') from_ = 0

        let type = (i ==0 || i == 8) ? 'week' : 'day'
        d3.select(`#week_TXT_${scope.substr(0,1)}_${i}`)
        .call(this.call_WeekPerformance, {scope: d.SCOPE, item: d.ITEM, date: d.DATE, type: type})

        d3.select(`#week_TXT_${scope.substr(0,1)}_${i}`)
        .transition()
        .ease(d3.easeLinear)
        .duration(500)
        .tween("text", function() {
          if(d.VALUE > 0) {
            const interpolator = d3.interpolateNumber(from_, d.VALUE)
            return function(t) {
              d3.select(`#week_TXT_${scope.substr(0,1)}_${i}`).text(Math.round(interpolator(t)))
            }
          } else { d3.select(`#week_TXT_${scope.substr(0,1)}_${i}`).text('') }
        })
      })
    },
   
  }
}