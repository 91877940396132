import * as d3 from 'd3'

export default {

  methods: {

    draw_Legends() {
      this.legendGroup = this.svg
      .append('g')
      .attr('id', 'Legend')
      .attr('transform', `translate(${this.legends.x}, ${this.legends.y})`)

      this.legendGroup
      .append('text')
      .attr('transform', `translate(0, 35)`)
      .style('font-size', 12).style('fill', '#000').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(`Legends`)

      this.legendGroup
      .append('text')
      .attr('transform', `translate(10, 53)`)
      .style('font-size', 10.5).attr('font-weight', 400).style('fill', '#44A9DF').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(`A & B ITRs Status`)

      this.legendGroup
      .append('text')
      .attr('transform', `translate(10, 95)`)
      .style('font-size', 10.5).attr('font-weight', 400).style('fill', '#F35E90').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(`Punch ${this.punch.prefix.substring(0,1)}`)

      this.legend_Sheet() // ---> below
      this.legend_Punch() // ---> below
      this.legend_LinkLine() // ---> below
    },


    legend_Sheet() {
      let x_ = 0
      let line = 306

      this.sheetGroup = this.legendGroup
      .append('g')
      .attr('id', `Sheet_Group`)
      .attr('transform', `translate(18, 70)`)

      this.sheet.styles.forEach((s, i) => {
        
        let legendItem = this.sheetGroup
        .append('g')
        .attr('id', `Sheet_${s.status}`)
        .attr('transform', `translate(${x_}, 0)`)
        .attr('font-family', 'roboto')
        .attr('opacity', this.legends.sheetStatus == s.status ? 1 : 0.8)
        .style('cursor', 'pointer')
        .on('mouseover', () => { 
          d3.select(`#Sheet_${s.status}`).attr('opacity', 0.5) 
        })
        .on('mouseout', () => { 
          d3.select(`#Sheet_${s.status}`).attr('opacity', this.legends.sheetStatus == s.status ? 1 : 0.8) 
        })
        .on('click', ()=> {
          d3.select(`#Sheet_${this.legends.sheetStatus}`).attr('opacity', 0.8)
          d3.select(`#Sheet_text_${this.legends.sheetStatus}`).attr('font-weight', 400).attr('fill', this.sheet.titleColor)

          this.legends.sheetStatus = s.status
          this.legends.layerStatus = s.cstd
          d3.select(`#Sheet_${s.status}`).style('opacity', 1)
          d3.select(`#Sheet_text_${s.status}`).attr('font-weight', 600).attr('fill', '#000')
          
          this.filtering_Search() // ---> Event.js
          this.filterStatus = 'ON'
        })
        
        legendItem
        .append('rect')
        .attr('id', `Sheet_box_${s.status}`)
        .attr('transform', `translate(-5, -6)`)
        .attr('rx', 2).attr('ry', 2)
        .attr('width', 12).style('height', 12)
        .attr('stroke', s.sColor)
        .attr('stroke-width', s.sWidth)
        .attr('fill', s.color)

        legendItem
        .append('text')
        .attr('id', `Sheet_text_${s.status}`)
        .attr('transform', `translate(11, 1)`)
        .attr('font-size', 10)
        .attr('font-weight', this.legends.sheetStatus == s.status ? 600 : 400)
        .attr('fill', '#000')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(`${s.text} (${this.sheet.values[i]})`)

        x_ += this.getNodeElValue(`#Sheet_${s.status}`, 'width') + 12
      })
      
    },

    

    legend_Punch() {
      let prefix = this.punch.prefix
      let x_ = 0
      let line = 309

      this.punch.styles.forEach((s, i) => {
        
        let legendItem = this.legendGroup
        .append('g')
        .attr('id', `${prefix}_${s.status}`)
        .attr('transform', `translate(${x_+ 18}, 110)`)
        .style('font-family', 'roboto')
        .style('opacity', this.legends.punchStatus == s.status ? 1 : 0.8)
        .style('cursor', 'pointer')
        .on('mouseover', () => { 
          d3.select(`#${prefix}_${s.status}`).style('opacity', 0.5) 
        })
        .on('mouseout', () => { 
          d3.select(`#${prefix}_${s.status}`).style('opacity', this.legends.punchStatus == s.status ? 1 : 0.8) 
        })
        .on('click', ()=> {
          d3.select(`#${prefix}_${this.legends.punchStatus}`).style('opacity', 0.8)
          d3.select(`#${prefix}_box_${this.legends.punchStatus}`).attr('stroke', '#757575')
          d3.select(`#${prefix}_text_${this.legends.punchStatus}`).attr('font-weight', 400)

          this.legends.punchStatus = s.status
          d3.select(`#${prefix}_${this.legends.punchStatus}`).style('opacity', 1) 
          d3.select(`#${prefix}_box_${this.legends.punchStatus}`).attr('stroke', '#F7BACF')
          d3.select(`#${prefix}_text_${this.legends.punchStatus}`).attr('font-weight', 600)

          this.filtering_Search() // ---> Event.js
          this.filterStatus = 'ON'
        })
        
        legendItem
        .append('circle')
        .attr('id', `${prefix}_box_${s.status}`)
        .attr('transform', `translate(0,-.5)`)
        .attr('r', 4)
        .attr('stroke', s.status == 'NONE' ? '#F7BACF' : '#757575')
        .attr('stroke-width', .5)
        .attr('fill', '#fff')

        legendItem
        .append('text')
        .attr('id', `${prefix}_text_${s.status}`)
        .attr('transform', `translate(7, 0)`)
        .style('font-size', 10)
        .attr('font-weight', this.legends.punchStatus == s.status ? 600 : 400)
        .attr('fill', '#000')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(s.status == 'NONE' ? `${s.text}` : `${s.text} (${this.punch.values[s.status]})`)

        x_ += this.getNodeElValue(`#${prefix}_${s.status}`, 'width') + 12
      })
    },


    legend_LinkLine(){

      let s1 = 19
      let linkLine = this.legendGroup
      
      .append('g')
      .attr('id', `Punch_Line`)
      .attr('visibility', 'hidden')

      linkLine
      .append('line')
      .attr('id', `Punch_S`)
      .attr('x1', s1).attr('y1', 76.5)
      .attr('x2', s1).attr('y2', 82.5)
      .attr('stroke', '#F7BACF').attr('stroke-width', .5)

      linkLine
      .append('line')
      .attr('id', `Punch_M`)
      .attr('x1', s1).attr('y1', 82.5)
      .attr('x2', this.getNodeElValue(`#Sheet_TOTAL`, 'width')).attr('y2', 82.5)
      .attr('stroke', '#F7BACF').attr('stroke-width', .5)

      linkLine
      .append('line')
      .attr('id', `Punch_E`)
      .attr('x1', this.getNodeElValue(`#Sheet_TOTAL`, 'width')).attr('y1', 82.5)
      .attr('x2', this.getNodeElValue(`#Sheet_TOTAL`, 'width')).attr('y2', 106)
      .attr('stroke', '#F7BACF').attr('stroke-width', .5)
    },
  }
}