import * as d3 from 'd3'

export default {

  methods: {

    icicle_Controller(from_){
      let mston_ = this.options.milestone.cStatus
      let sch_ = this.options.schedule.cStatus
  
      let filteredData = this.msList.filter(f=> f.MS_NO.substr(0, 2) == mston_)
      

      // Count Schedule
      if(from_ == 'milestone') {
        this.options.schedule.code.forEach((d,i)=> {
          if(d=='NONE') return
          this.options.schedule.count[i] = filteredData.filter(f=> f.sch == d).length
          d3.select(`#SCHEDULE_text_${d}`)
          .text(this.options.schedule.cStatus == d ? this.options.schedule.name[i] : `${this.options.schedule.name[i]}(${this.options.schedule.count[i]})`)
        })
      }

      if(sch_ !== 'NONE') filteredData = filteredData.filter(f=> f.sch == sch_)

      // Remove Selection
      if(this.sTable) this.sTable.remove()
      if(this.sIcicleChart) this.sIcicleChart.remove()

      this.draw_MilestoneTable(filteredData) // <--- Below
      this.draw_IcicleChart(filteredData) // <--- Below
    },



    draw_IcicleChart(data_){
      // The main controller for drawing the icicle chart of each milestone
      let lastPlan = null
      let lastActual = null

      this.sIcicleChart = this.sTimeline
      .append('g')
      .attr('id', `ICICLE_Group`)
      .attr('transform', `translate(0, ${this.styles.actual.topMargin})`)

      data_.forEach((m, mi)=> {
        this.sIcicleChart
        .append('g')
        .attr('id', `icicle_${m.MS_NO}`)
        .attr('transform', `translate(${this.icicle.padding.left},0)`)
        .style('cursor', 'pointer')
        .call( this.call_IcicleChart, m) // --->

        this.rawData.filter(f => m.MS_NO == f.MS_NO).forEach( (d, di) => {

          let planDate = this.timeline.plan.scale(new Date(d.FORECAST))
          let actualDate = this.timeline.actual.scale(new Date(d.CUTOFF)) + this.icicle.padding.top

          if (di > 0 && d.icon !== '') {
            this.draw_lines({
              code: m.MS_NO,
              idx: di,
              x1: lastPlan,
              y1: lastActual,
              x2: planDate,
              y2: actualDate,
              color:  this.icon.colors[mi],
            }) // --->
          }

          this.draw_Icicle_Icon({
            code: m.MS_NO,
            x:   planDate, 
            y: actualDate, 
            color:  this.icon.colors[mi],
            scale:  0.9,
            icon:   d.icon,
            stroke: this.icon.stroke,
          }) // --->

          if(d.icon == 'start' || d.icon == 'ongoing' || d.icon == 'completed') 
            this.draw_TextinIcon({
              code: m.MS_NO,
              x: planDate, 
              y: actualDate, 
              txt: m.IDX, 
              icon: d.icon
            }) // --->

          lastPlan = this.timeline.plan.scale(new Date(d.FORECAST))
          lastActual = this.timeline.actual.scale(new Date(d.CUTOFF)) + this.icicle.padding.top
        })
      })
    },

  }
}