import * as d3 from 'd3'

export default {
  methods: {
    
    pdf_redrawing(id_) {

      let redrawing = this.svg
      .append('g')
      .attr('transform', `translate(${this.Canvas.CanvasWidth - 160}, 0)`)
      .attr('font-family', 'roboto')
      .attr('cursor', 'default')

      redrawing
      .append('text')
      .attr('x', 0).attr('y', 20).attr('alignment-baseline', 'middle')
      .attr('font-size', 12).attr('fill', '#44A9DF')
      .text('REMOVE').attr('cursor', 'pointer')
      .on('mouseover', function() {
        d3.select(this).attr('opacity', .5)
      })
      .on('mouseout', function() {
        d3.select(this).attr('opacity', 1)
      })
      .on('click', ()=> {
        d3.select(`#${id_}`).remove()
      })

      redrawing
      .append('text')
      .attr('x', 75).attr('y', 20).attr('alignment-baseline', 'middle')
      .attr('font-size', 12).attr('fill', '#EC407A')
      .text('Redrawing').attr('cursor', 'pointer')
      .on('mouseover', function() {
        d3.select(this).attr('opacity', .5)
      })
      .on('mouseout', function() {
        d3.select(this).attr('opacity', 1)
      })
      .on('click', ()=> {
        this.drawing()
      })
    },

    
    pdf_selectedItems(){  
      let get_Group = []
      let get_Item = []
      this.MilestoneGroup.forEach((d,i)=> {
        get_Group.push({
          y:            d.y,
          bind:         d.bind,
          itemDisplay:  d.itemDisplay,
        })
      })

      this.rawData.forEach((d,i)=> {
        get_Item.push({
          y: d.y,
          bind: d.bind,
        })
      })
      
      this.selectedItems = {  
        // ...this.selectedItems,
        status: 'Y',
        group : get_Group,
        item  : get_Item,
        height: this.Canvas.CanvasHeight
      }
    },
    
  }
}
