import * as d3 from 'd3'

export default {
  
  methods: {
    /*
      draw_Grids()
      draw_Legends()
      draw_lines(d)
      draw_Icicle_Icon(d)
      draw_TextinIcon(d)
    */
   
    draw_Grids() {

      let Grids = this.svg
      d3.select('#plan_timeline')
      .append('g')
      .attr('transform', `translate(${0}, ${this.styles.actual.topMargin})`)

      .selectAll('line')
      .data(this.timeline.plan.values.month)
      .enter()
      .append('line')
      .attr('x1', d => this.timeline.plan.scale(new Date(d.endDate))).attr('y1', 0)
      .attr('x2',d => this.timeline.plan.scale(new Date(d.endDate)))
      .attr('y2', this.timeline.actual.scale(new Date(this.timeline.actual.values.month[this.timeline.actual.values.month.length-1].endDate)))
      .attr('stroke-width', 0.5).attr('stroke', '#bcbcbc').attr('opacity', 0.4)
    },

        
    draw_Legends() {

      let Legends = this.svg
      .append('g')
      .attr('transform', `translate(${this.icicle.x + 7}, ${this.icicle.y - 5})`)
      .attr('fill', '#333')
      .attr('font-size', 10)

      Legends
      .append('path')
      .attr('transform', `translate(0,-4)scale(0.7)`)
      .attr('d', this.icon.start)
      .attr('fill', '#E0E0DF').attr('opacity', 1)
      .attr('stroke', '#bcbcbc').attr('stroke-width', 0.5)

      Legends
      .append('text')
      .attr('x',10).attr('y',0).text('START')

      Legends
      .append('path')
      .attr('transform', `translate(65,-4)scale(0.7)`)
      .attr('d', this.icon.ongoing)
      .attr('fill', '#E0E0DF').attr('opacity', 1)
      .attr('stroke', '#bcbcbc').attr('stroke-width', 0.5)

      Legends
      .append('text')
      .attr('x',75).attr('y',0).text('ONGOING')

      Legends
      .append('path')
      .attr('transform', `translate(140,-4)scale(0.7)`)
      .attr('d', this.icon.completed)
      .attr('fill', '#E0E0DF').attr('opacity', 1)
      .attr('stroke', '#bcbcbc').attr('stroke-width', 0.5)

      Legends
      .append('text')
      .attr('x',150).attr('y',0).text('COMPLETED')
    },
    
    draw_lines(d) {

      d3.select( `#icicle_${d.code}`)
      .append('line')
      .attr('id', `line_${d.code}_${d.idx}`)
      .attr('x1', d.x1).attr('y1', d.y1)
      .attr('x2', d.x2).attr('y2', d.y2)
      .attr('stroke', d.color)
      .attr('stroke-width', 1.5)
      .attr('opacity', 1)
      d3.select(`#line_${d.code}_${d.idx}`).moveToBack()
    },

    draw_Icicle_Icon(d) {

      d3.select( `#icicle_${d.code}`)
      .append('path')
      .attr('transform', `translate(${d.x}, ${d.y})scale(${d.scale})`)
      .attr('d', this.icon[d.icon])
      .attr('fill', d.icon == 'togo' ? '#fff' : d.color)
      .attr('stroke', d.icon == 'togo' ? d.color : d.stroke)
      .attr('stroke-width', d.icon == 'togo' ? 1.5 : 0.5)
      .attr('opacity', 1)
    },

    draw_TextinIcon(d) {

      d3.select( `#icicle_${d.code}`)
      .append('text')
      .attr('transform', `translate(${d.x - 0.5}, ${d.y + 1})`)
      .attr('font-family', 'roboto').attr('fill', '#000')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .attr('font-size', d.icon == 'start' ? 10 : 8)
      .text(d.txt)
    },

  }
}