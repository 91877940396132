import moment from 'moment'
import * as d3 from 'd3'

export default {
  methods: {

    draw_Timeline(selection, unit, x_, y_) {

      let totalLength = this.timeline.length + this.timeline.column

      let TimelineBG = selection
      .append('g')
      .attr('transform', `translate(${x_ - 0.5}, ${y_})`)
      .attr('font-family', 'Segoe UI')
      
      TimelineBG
      .append('path') // shadow
      .attr('d', `M 30, ${this.timeline.height + 2} H ${totalLength - 30} L ${totalLength},${this.timeline.height + 8} H0 Z`)
      .attr('fill', `url(#shadowTimeline)`)

      TimelineBG
      .append('rect') 
      .attr('x', 0).attr('y', 0).attr('width', this.timeline.column).attr('height', 3)
      .attr('fill', `url(#${this.localId}_LightGray_)`).attr('opacity', .99)

      TimelineBG
      .append('rect')
      .attr('x', 0).attr('y', 3).attr('width', this.timeline.column).attr('height', this.timeline.height)
      .attr('fill', `url(#${this.localId}_LightGray_)`).attr('opacity', .5)

      // Time Now
      TimelineBG
      .append('rect')
      .attr('x', 0).attr('y', 2).attr('width', this.timeline.column + this.timeline.scale(new Date(this.timeline.cutoff)))
      .attr('height', this.timeline.height)
      .attr('fill', `url(#${this.localId}_LightBlue_)`).attr('opacity', .5)

      TimelineBG
      .append('rect') 
      .attr('x', 0).attr('y', this.timeline.height + 1).attr('width', this.timeline.column).attr('height', 4)
      .attr('fill', `url(#${this.localId}_LightGray_)`)



      TimelineBG
      .append('rect') 
      .attr('x', this.timeline.column).attr('y', 0).attr('width', this.timeline.length).attr('height', 3)
      .attr('fill', `url(#${this.localId}_LightGray_)`).attr('opacity', .99)

      TimelineBG
      .append('rect')
      .attr('x', this.timeline.column).attr('y', 3).attr('width', this.timeline.length).attr('height', this.timeline.height)
      .attr('fill', `url(#${this.localId}_LightGray_)`).attr('opacity', .5)

      TimelineBG
      .append('rect') 
      .attr('x', this.timeline.column).attr('y', this.timeline.height + 1).attr('width', this.timeline.length).attr('height', 4)
      .attr('fill', `url(#${this.localId}_LightGray_)`)

      TimelineBG
      .append('text')
      .attr('x', 10).attr('y', 20)
      .attr('font-size', 14).attr('fill', '#333').attr('font-weight', 450)
      .text(`${unit.UNIT}`)

      TimelineBG
      .append('text')
      .attr('x', 10).attr('y', 30)
      .attr('font-size', 9).attr('fill', '#757575')
      .text(`${unit.UNIT_DESC}`)


      let Timeline = selection
      .append('g')
      .attr('transform', `translate(${x_ + this.timeline.column}, ${y_})`)

      // Month Text & Timeline Seperator for the Month
      Timeline
      .append('g')
      .selectAll('text')
      .data(this.timeline.month)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate))
      .attr('y', 25)
      .style('font-size', 9)
      .attr('fill', '#333')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'hanging')
      .attr('cursor', 'default')
      .text(d => d.name.toUpperCase())

      Timeline
      .append('g')
      .selectAll('line')
      .data(this.timeline.month)
      .enter()
      .append('line')
      .attr('x1', d => this.timeline.scale(d.eDate)).attr('y1', 24)
      .attr('x2', d => this.timeline.scale(d.eDate)).attr('y2', 35)
      .attr('stroke', '#bcbcbc').attr('oapcity', .1)
      .attr('stroke-width', (d,i) => (i != this.timeline.month.length-1) ? 1 : 0)

      // Year Text & Timeline Seperator for the Year
      Timeline
      .append('g')
      .selectAll('text')
      .data(this.timeline.year)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate))
      .attr('y', 8)
      .style('font-size', 12)
      .attr('fill', '#333')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'hanging')
      .attr('cursor', 'default')
      .text(d => d.name)

      Timeline
      .append('g')
      .selectAll('path')
      .data(this.timeline.year)
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        if (i != this.timeline.year.length-1) return `M${this.timeline.scale(d.eDate)}, ${2} V${19}`
      })
      .attr('stroke', '#bcbcbc').attr('oapcity', .1)
      .attr('stroke-width', 1)

    }, 


    draw_HeaderTable(selection) {

      let columns = selection
      .append('g')
      .attr('transform', `translate(${this.timeline.x}, ${this.timeline.y})`)

      columns
      .append('path') // shadow
      .attr('d', `M 30, ${this.timeline.height + 2} H ${this.timeline.length+this.timeline.column - 30} L ${this.timeline.length + this.timeline.column},${this.timeline.height + 8} H0 Z`)
      .attr('fill', `url(#shadowTimeline)`) 

      columns
      .append('rect') 
      .attr('x', 0).attr('y', 0).attr('width', this.timeline.column).attr('height', 3)
      .attr('fill', `url(#${this.localId}_LightGray_)`).attr('opacity', .99)

      columns
      .append('rect')
      .attr('x', 0).attr('y', 3).attr('width', this.timeline.column).attr('height', this.timeline.height)
      .attr('fill', `url(#${this.localId}_LightGray_)`).attr('opacity', .5)

      // Time Now
      columns
      .append('rect')
      .attr('x', 0).attr('y', 2).attr('width', this.timeline.column + this.timeline.scale(new Date(this.timeline.cutoff)))
      .attr('height', this.timeline.height)
      .attr('fill', `url(#${this.localId}_LightBlue_)`).attr('opacity', .5)

      columns
      .append('rect') 
      .attr('x', 0).attr('y', this.timeline.height + 1).attr('width', this.timeline.column).attr('height', 4)
      .attr('fill', `url(#${this.localId}_LightGray_)`)

      
      let tableHeader = columns
      .append('g')
      .attr('transform', `translate(20, 24)`)
      .style('font-family', 'roboto').attr('cursor', 'default')

      tableHeader
      .append('text')
      .attr('x', 0).attr('y', 0)
      .attr('font-size', 12).attr('fill', '#333').attr('font-weight', 450)
      .text('Milestone Description')

      tableHeader
      .append('text')
      .attr('x', 348).attr('y', -7).attr('font-weight', 420)
      .attr('font-size', 11).attr('fill', '#333').attr('text-anchor', 'middle')
      .text('Forecast')

      tableHeader
      .append('text')
      .attr('x', 348).attr('y', +6).attr('font-weight', 420)
      .attr('font-size', 11).attr('fill', '#333').attr('text-anchor', 'middle')
      .text('Date')
    }
  }
}