import * as d3 from 'd3'
export default {
  
  methods: {

    draw_pie(angle, fa, len, d) {
      let pie = this.pie
      let aRad = (angle - d.gap) * Math.PI / 180

      let pieArea = this.Area_Canvas
      .append('g')
      .attr('transform', `translate(${pie.x}, ${pie.y}) rotate(${(pie.spin + fa)})`)
      .attr('font-family', 'roboto')

      pieArea
      .append('path')
      .attr('d', `M 0 0 L ${len} 0 A ${len} ${len} 0 0 1 ${len * Math.cos(aRad)} ${len * Math.sin(aRad)} Z`)
      .attr('fill', d.bColor).attr('fill-opacity', d.opacity[0])
      .attr('stroke', d.sColor).attr('stroke-width', d.sWidth).attr('stroke-opacity', d.opacity[1])
    },


    draw_pieText(angle, len, fa, d) {
      let pie = this.pie

      let pieArea = this.Area_Canvas
      .append('g')
      .attr('id', `PIE_TEXT_${d.data.UNIT_NO}`)
      .attr('transform', `translate(${pie.x}, ${pie.y}) rotate(${(pie.spin + fa)})`)
      .attr('font-family', 'roboto').style('cursor', 'pointer')

      pieArea
      .append('text')
      .attr('transform', `translate(${0}, ${0}) rotate(${angle/2})`)
      .attr('x', pie.ra + 5)
      .attr('font-size', 11).attr('font-family', 'roboto')
      .attr('alignment-baseline', 'middle')
      .attr('fill', '#000').attr('font-weight', 420)
      .text(d.data.UNIT)
      .on('mouseover', ()=> {
        this.mouse_Event('over', d.data.UNIT_NO)
      })
      .on('mouseout', ()=> {
        this.mouse_Event('out', d.data.UNIT_NO)
      })
      .on('click', ()=> {
        this.call_Unit(d.data.UNIT, d.data.UNIT_NO) // --> Event.js
      })
    },


    draw_Grids(sRa) {
      let ra = this.pie.ra
      let lenhth = ra - (ra * sRa)

      let grids = this.Area_Canvas
      .append('g')
      .attr('transform', `translate(${this.pie.x}, ${this.pie.y-ra})`)
      .attr('font-family', 'roboto').attr('opacity', .7)

      grids
      .append('line')
      .attr('x1', 0).attr('y1', 0)
      .attr('x2', 0).attr('y2', lenhth)
      .attr('stroke', '#bcbcbc').attr('stroke-width', 1)

      for(let i=0; i<=5; i++) {
        grids
        .append('line')
        .attr('x1', -3).attr('y1', (lenhth/5)*i)
        .attr('x2', 3).attr('y2', (lenhth/5)*i)
        .attr('stroke', '#bcbcbc').attr('stroke-width', 1)

        grids
        .append('text')
        .attr('x', 3).attr('y', (lenhth/5)*i - 2)
        .attr('font-size', 8).attr('fill', '#000')
        .text(`${100 - (i*20)}%`)
      }
    },


    draw_Donut(angle, fa, len, sRa, d) {

      let pie = this.pie
      let aRad = (angle - d.gap) * Math.PI / 180

      let pieArea = this.Area_Canvas
      .append('g')
      .attr('id', `PIE_${d.id}_${d.data.UNIT_NO}`)
      .attr('transform', `translate(${pie.x}, ${pie.y}) rotate(${(pie.spin + fa)})`)
      .style('cursor', 'pointer').attr('opacity', 1)
      .on('mouseover', ()=> {
        d3.select(`#pieTitle`).transition().duration(150).attr('opacity', 0)
        this.mouse_Event('over', d.data.UNIT_NO)
        d3.select(`#unitSTATUS_A_TOT`).text(d.data.A_TOT.toLocaleString('ko-KR'))
        d3.select(`#unitSTATUS_A_ACT`).text(d.data.A_ACT.toLocaleString('ko-KR'))
        d3.select(`#unitSTATUS_A_REM`).text(d.data.A_REM.toLocaleString('ko-KR'))
        d3.select(`#unitSTATUS_B_TOT`).text(d.data.B_TOT.toLocaleString('ko-KR'))
        d3.select(`#unitSTATUS_B_ACT`).text(d.data.B_ACT.toLocaleString('ko-KR'))
        d3.select(`#unitSTATUS_B_REM`).text(d.data.B_REM.toLocaleString('ko-KR'))
        d3.select(`#unitSTATUS`).transition().duration(150).attr('opacity', 1)
      })
      .on('mouseout', ()=> {
        this.mouse_Event('out', d.data.UNIT_NO)
        d3.select(`#unitSTATUS`).transition().duration(150).attr('opacity', 0)
        d3.select(`#pieTitle`).transition().duration(150).attr('opacity', 1)
      })
      .on('click', ()=> {
        this.call_Unit(d.data.UNIT, d.data.UNIT_NO) // --> Event.js
      })

      pieArea
      .append('path')
      .attr('id', `${d.id}_${d.data.UNIT_NO}`)
      .attr('d', `
                  M ${pie.ra * sRa} 0 
                  L ${len} 0 
                  A ${len} ${len} 0 0 1 ${len * Math.cos(aRad)} ${len * Math.sin(aRad)}
                  L ${pie.ra * sRa * Math.cos(aRad)} ${pie.ra * sRa * Math.sin(aRad)}
                  A ${pie.ra * sRa} ${pie.ra * sRa} 0 0 0 ${pie.ra * sRa} 0
      `)
      .attr('fill', d.bColor).attr('fill-opacity', d.opacity[0])
      .attr('stroke', d.sColor).attr('stroke-width', d.sWidth).attr('stroke-opacity', d.opacity[1])
      .attr('filter', d.id == 'ACTUAL' ? 'url(#dropshadow)' : '')
    },
    
    mouse_Event(mouse, unit) {
      this.Gantt.forEach(d => {
        if(mouse == 'over' && unit == d.UNIT_NO) {
          d3.select(`#PIE_TEXT_${d.UNIT_NO}`).transition().duration(150).attr('opacity', 1)
          d3.select(`#PIE_TOTAL_${d.UNIT_NO}`).transition().duration(150).attr('opacity', 1)
          d3.select(`#PIE_ACTUAL_${d.UNIT_NO}`).transition().duration(150).attr('opacity', 1)
          d3.select(`#GANTT_${d.UNIT_NO}`).transition().duration(150).attr('opacity', 1)
        } else {
          d3.select(`#PIE_TEXT_${d.UNIT_NO}`).transition().duration(150).attr('opacity', .3)
          d3.select(`#PIE_TOTAL_${d.UNIT_NO}`).transition().duration(150).attr('opacity', .3)
          d3.select(`#PIE_ACTUAL_${d.UNIT_NO}`).transition().duration(150).attr('opacity', .3)
          d3.select(`#GANTT_${d.UNIT_NO}`).transition().duration(150).attr('opacity', .3)
        }
    
        if(mouse == 'out') {
          d3.select(`#PIE_TEXT_${d.UNIT_NO}`).transition().duration(150).attr('opacity', 1)
          d3.select(`#PIE_TOTAL_${d.UNIT_NO}`).transition().duration(150).attr('opacity', 1)
          d3.select(`#PIE_ACTUAL_${d.UNIT_NO}`).transition().duration(150).attr('opacity', 1)
          d3.select(`#GANTT_${d.UNIT_NO}`).transition().duration(150).attr('opacity', 1)
        }
      })
    },

    draw_ArcsText(angle, sRa, fa, d) {
      let pie = this.pie
      let a = angle - d.gap

      let pieArea = this.Area_Canvas
      .append('g')
      .attr('transform', `translate(${pie.x}, ${pie.y}) rotate(${(pie.spin + fa)})`)
      .attr('font-family', 'roboto')

      pieArea
      .append('text')
      .append('textPath')
      .attr('xlink:href', `#TOTAL_${d.data}`)
      .attr('font-size', 11)
      .attr('alignment-baseline', 'ideographic')
      .style('text-anchor','middle')
      .attr('startOffset', `${((1-sRa) + (Math.PI*a) / 360) / (2*(1-sRa) + (Math.PI*a)/180 * (1+sRa)) * 100}%`)
      .attr('fill', '#000')
      .text(d.data)
    },

    unit_Status(selection, itr, y) {
      let code = ['TOT', 'ACT', 'REM']
      let name = ['Total', 'Actual', 'Remain']
      let no = 123445
      if(itr == 'B') {
        code = code.reverse()
        name = name.reverse()
      }

      code.forEach((d,i)=> {
        selection
        .append('text')
        .attr('x', -5).attr('y', y).attr('font-size', 11)
        .attr('fill', '#757575').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(name[i])

        selection
        .append('text')
        .attr('id', `unitSTATUS_${itr}_${d}`)
        .attr('x', 0).attr('y', y).attr('font-size', 11)
        .attr('fill', '#44A9DF').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(no.toLocaleString('ko-KR'))

        y += 15
      })
      
    },

  }
}