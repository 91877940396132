import gmx_zMixins         from '../../../../../../includes/Jmixins/zMixins'

// Locally declared common Data-Props & SVG Drawing Support
import lmx_watching             from './watching'

// Charts 
import lmx_LocalValues          from './set_LocalValues'

import lmx_draw_Header          from './draw_Header'
import lmx_header_Sheets        from './header_Sheets'
import lmx_header_Discipline    from './header_Discipline'
import lmx_redraw_Charts        from './redraw_Charts'

import lmx_Axis                 from './draw_Axis'
import lmx_Charts               from './draw_Charts'
import lmx_Deviation            from './draw_Deviation'

import lmx_events               from './Events'


export default {
  
  mixins: [
    gmx_zMixins, 
    
    // Local Mixins
    lmx_watching,
    lmx_LocalValues,
    lmx_events,

    lmx_draw_Header,
    lmx_header_Sheets,
    lmx_header_Discipline,

    lmx_redraw_Charts,
    lmx_Deviation,
    
    lmx_Axis,
    lmx_Charts,
    
  ],

}
