import * as d3 from 'd3'

export default {
  methods: {

    load_UnitSummary(unit, i) {
      let y = 15
      let pnt =  this.innerSvg.select(`#SUM-${unit.UNIT_NO}`)
      let cx = Number(pnt.attr('cx'))
      let cy = Number(pnt.attr('cy')) + 1.5
      
      this.stg_Summary = this.innerSvg
      .append('g')
      .attr('id', `UNIT_SUMMARY`)
      .attr('transform', `translate(${cx}, ${cy})`)
      .attr('opacity', 0)

      this.stg_Summary
      .append('rect')
      .attr('x', 0).attr('y', 0)
      .attr('width', 214).attr('height', 52)
      .attr('fill', '#83D2F5').attr('opacity', .85)

      this.stg_Summary
      .append('rect')
      .attr('x', 0).attr('y', 55)
      .attr('width', 214).attr('height', 52)
      .attr('fill', '#B4E3FA').attr('opacity', .85)

      this.unitSummary.code.forEach((code,i)=> {
        
        this.stg_Summary // Total
        .append('text')
        .attr('transform', `translate(7, ${y})`)
        .attr('font-size', 9.5).attr('fill', '#000')
        .text(this.unitSummary.name[i])

        this.stg_Summary // Total
        .append('text')
        .attr('transform', `translate(75, ${y})`)
        .attr('font-size', 9).attr('fill', this.unitSummary.vColor[i]).attr('text-anchor', 'end')
        .text(unit[`${code}_TOT`])

        this.stg_Summary // Chart - Background 
        .append('rect')
        .attr('x', 80).attr('y', y - 9)
        .attr('width', 100).attr('height', 11).attr('fill', '#E0E0DF').attr('opacity', .5)

        this.stg_Summary // Chart - Progress
        .append('rect')
        .attr('x', 80).attr('y', y - 9)
        .attr('width', unit[`${code}_PRO`]).attr('height', 11).attr('fill', this.unitSummary.bColor[i])

        this.stg_Summary // Actual
        .append('text')
        .attr('x', unit[`${code}_PRO`] > 70 ? 78 + unit[`${code}_PRO`] : 82 + unit[`${code}_PRO`])
        .attr('y', y)
        .attr('font-size', 9)
        .attr('fill', unit[`${code}_PRO`] > 70 ? '#fff' : this.unitSummary.vColor[i])
        .attr('text-anchor', unit[`${code}_PRO`] > 70 ? 'end' : 'start')
        .text(unit[`${code}_ACT`])

        this.stg_Summary // Remain
        .append('text')
        .attr('transform', `translate(182, ${y})`)
        .attr('font-size', 9).attr('fill', this.unitSummary.vColor[i]).attr('text-anchor', 'start')
        .text(unit[`${code}_REM`])

        if(i==2) y += this.unitSummary.lHeight + 10
        else y += this.unitSummary.lHeight
      })
      d3.select(`#UNIT_SUMMARY`).transition().delay(200).duration(200).style('opacity', 1)
    },
    
  }
}